import { StringMapWithRename } from '@angular/compiler/src/compiler_facade_interface';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/Services/auth.service'
import { UserService } from '../Services/user.service';
import { environment } from 'src/environments/environment';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  dialogTitle: String;
  Fotgorpassword: FormGroup;
  ChangepasswordForm: FormGroup;
  ResetPasswordForm: FormGroup;
  submitted = false;
  matcher = new MyErrorStateMatcher();

  enteruserpart: boolean = true;
  enterpasspart: boolean = false;
  ChangepasswordFormResponse: any;
  hide = true;
  InputEmail: any;
  ResetPasswordResponse: any;
  Meg_UpdatePassword: boolean = false;
  Meg_ResetPassword: boolean = false;
  fulldiv: boolean = true;
  MessageconfirmPass: boolean = false;
  serviceType: String

  constructor(private authService: AuthService,
    private userService : UserService,
     @Inject(MAT_DIALOG_DATA) private data: any) {

    // this.Fotgorpassword = this.formBuilder.group({
    //   newpassword: ['', [Validators.required]],
    //   newconfirmpassword: [''],
    //   enterotp:['']
    // }, { validator: this.checkPasswords },

    // );

    ;

    this.serviceType = data.serviceType
    this.dialogTitle = data.title

    this.ChangepasswordForm = new FormGroup({
      Email: new FormControl(''),
      Merchant: new FormControl('MET'),
      Otp: new FormControl('', (Validators.required, Validators.minLength(4))),
      Password: new FormControl('', (Validators.required, Validators.minLength(6))),
      confirmPass: new FormControl('', (Validators.required, Validators.minLength(6))),
      Mode: new FormControl('Web'),
      ServiceType: new FormControl(this.serviceType),
      Domain: new FormControl(location.host)

    }),
      { validator: this.checkPasswords }



    let email = '';
    if (this.serviceType == "RESETPASSWORD" || this.serviceType == "railonline"){
      email = this.authService.getUserEmail();
    }

    this.ResetPasswordForm = new FormGroup({
      Email: new FormControl(email, [Validators.required]),
      Mode: new FormControl('Web'),
      ServiceType: new FormControl(this.serviceType),
      Domain : new FormControl( environment.production ? location.host : localStorage.getItem('domain'), [Validators.required])
    })


  }
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.Password.value;
    let confirmPass = group.controls.confirmPass.value;
    let enterotp = group.controls.Otp.value;
    return pass === confirmPass ? null : { notSame: true }

  }

  resetpass() {
    this.enteruserpart = false;
    this.enterpasspart = true;
  }


  fun_resetpass() {
    this.InputEmail = this.ResetPasswordForm.value.Email;
    this.userService.ResetPassword(this.ResetPasswordForm.value)
      .subscribe(
        SenderMobReq => {
          this.ResetPasswordResponse = SenderMobReq;
          this.Wait_emiallogin();
          if (this.ResetPasswordResponse.statusCode === 0) {
            this.enteruserpart = false;
            this.enterpasspart = true;
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }

  handlePasswordResetResponse(res){

  }


  fun_ChanePassword() {

    if (this.ChangepasswordForm.value.Password != this.ChangepasswordForm.value.confirmPass) {
      this.Wait_Messageconfirm();
      return;
    }
    this.ChangepasswordForm.value.Email = this.InputEmail;
    this.userService.ValidateResetPassword(this.ChangepasswordForm.value)
      .subscribe(
        SenderMobReq => {
          this.ChangepasswordFormResponse = SenderMobReq;
          console.log(this.ChangepasswordFormResponse.statusMessage);
          if (this.ChangepasswordFormResponse.statusCode === 0) {

            this.Wait_UpdatePasswordSuccess();
            this.enteruserpart = false;
            this.enterpasspart = true;

          } else {
            this.Wait_UpdatePasswordError();
          }

        },
        (error) => {
          console.log(error);
        }

      )
  }



  ngOnInit(): void {
  }
  Wait_UpdatePasswordError(): void {
    this.Meg_UpdatePassword = true
    setTimeout(function () {
      this.Meg_UpdatePassword = false
    }.bind(this), 2000);
  }


  Wait_Messageconfirm(): void {
    this.MessageconfirmPass = true;
    setTimeout(function () {
      this.MessageconfirmPass = false
    }.bind(this), 3000);
  }

  Wait_UpdatePasswordSuccess(): void {
    this.Meg_UpdatePassword = true;
    setTimeout(function () {
      this.fulldiv = false;
      this.Meg_UpdatePassword = false
      this.enterpasspart = false;

    }.bind(this), 3000);
  }

  Wait_emiallogin(): void {
    this.Meg_ResetPassword = true;
    setTimeout(function () {
      this.Meg_ResetPassword = false;
      this.ResetPasswordForm.patchValue({
        Email: '',
      });

    }.bind(this), 4000);
  }



  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}