import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../Services/auth.service';
import { StateCity } from '../Services/StateCity';
import { Login } from '../Classes/login';
import { StateCityclass } from '../Classes/Statecity';
import { Router } from '@angular/router';
import { UserService } from '../Services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { ForgotpasswordComponent } from '../forgotpassword/forgotpassword.component';
import { KycComponent } from '../kyc/kyc.component';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';




@Component({
  selector: 'profileupdate',
  templateUrl: './profileupdate.component.html',
  styleUrls: ['./profileupdate.component.css']
})

export class ProfileupdateComponent implements OnInit {
  ProfileForm: FormGroup;
  IsRailEnrolled = false
  IsKYCRequired = false;

  loading = true
  profileData: any;
  C_StateCityclass: StateCityclass[];
  checked1 = true; checked2 = true;
  checked3 = true; indeterminate = true;
  labelPosition: 'before' | 'after' = 'after';
  postId; statusMessage;
  cityData;
  selectedValue: string;
  isReadonly = true; Loginresponse: string;
  loginResponseJSON: any;
  _ResponsestatusMessage: boolean = false;
  Loginuser: boolean = false; GetName: any;
  AgentCode: any; Merchant: any;
  panCardImage: any;
  addressProofImage: any;
  states: Observable<any[]>;
  cities: Observable<any[]>;

  constructor(private Authagentservices: AuthService,
    private userService: UserService,
    private route: Router,
    private stateCityService: StateCity,
    private cd: ChangeDetectorRef,
    private alertService: ToastrService,
    private dialog: MatDialog,
  ) {

  }


  populateProfileForm(profileData) {

    this.ProfileForm.patchValue({
      //  UserId:new FormControl(this._UpdateProfiledata.profileData[0].userId,[Validators.required,Validators.minLength(3)]),
      Email: profileData[0].email,
      Mobile: profileData[0].mobile,
      Merchant: profileData[0].merchant,
      AgencyName: profileData[0].agencyName,
      OutletId: profileData[0].outletId,
      UserType: profileData[0].userId,
      FirstName: profileData[0].firstName,
      LastName: profileData[0].lastName,
      PanName: profileData[0].panName,
      PanNumber: profileData[0].panNumber,
      TypeOfAddress: profileData[0].typeofAddress,
      AddressProofDoc: profileData[0].addressProfDoc,
      PanCardDoc: profileData[0].panCardDoc,
      //AddressProffDocUrl:'AddressProffDocUrl'),
      AddressProofNumber: profileData[0].addressProofNumber,
      City: profileData[0].city,
      State: profileData[0].state,
      Country: profileData[0].country,
      PinCode: profileData[0].pinCode,
      Logo: profileData[0].logo,
      AgentCode: profileData[0].identifier,
      Address: profileData[0].address,
      GSTNo: profileData[0].gstNo
    });

  }

  ngOnInit() {
    this.ProfileForm = new FormGroup({
      //  UserId:new FormControl(this._UpdateProfiledata.profileData[0].userId,[Validators.required,Validators.minLength(3)]),
      Email: new FormControl('', [Validators.required, Validators.email]),
      Mobile: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      Merchant: new FormControl(''),
      AgencyName: new FormControl('', [Validators.required]),
      OutletId: new FormControl(''),
      UserType: new FormControl(''),
      Title: new FormControl('Mr'),
      FirstName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      LastName: new FormControl(''),
      PanName: new FormControl('', [Validators.required]),
      PanNumber: new FormControl('', [Validators.required, Validators.maxLength(10)]),
      TypeOfAddress: new FormControl('TypeOfAddress'),
      AddressProofDoc: new FormControl(''),
      PanCardDoc: new FormControl(''),
      AddressProofNumber: new FormControl(''),
      PassPortExpiryDate: new FormControl('01-01-2020'),

      City: new FormControl('', [Validators.required]),
      State: new FormControl('', [Validators.required]),
      Country: new FormControl('India'),
      PinCode: new FormControl('', [Validators.required, Validators.maxLength(6)]),

      Logo: new FormControl(''),
      AgentCode: new FormControl(''),
      Address: new FormControl('', [Validators.required]),
      GSTNo: new FormControl('')
    });

    this.ProfileForm.controls['Email'].disable();
    this.ProfileForm.controls['Mobile'].disable();
    this.ProfileForm.controls['AgencyName'].disable();
    this.ProfileForm.controls['PanName'].disable();
    this.ProfileForm.controls['PanNumber'].disable();
    this.Authagentservices.CheckSesstion();
    this.Loginresponse = localStorage.getItem('loginData');
    this.loginResponseJSON = <any>JSON.parse(this.Loginresponse);

    this.isReadonly = !this.isReadonly;

    this.fetchProfile();

    this.stateCityService.fetchStates().subscribe(StatecityData => {
      let stateData: any = StatecityData
      this.states = stateData.stateData
    });

    this.AgentCode = this.loginResponseJSON.loginData[0].identifier;
    this.Merchant = this.loginResponseJSON.loginData[0].merchant;
    this.IsRailEnrolled = this.loginResponseJSON.loginData[0].rail
    let Updatedata = this.AgentCode + "," + this.Merchant;


    this.ProfileForm.get('State').valueChanges.subscribe((selectedValue) => {
      // Trigger the action to populate the second dropdown here
      this.fetchCities(selectedValue);
    });
  }

  fetchProfile() {
    this.userService.fetchprofile().subscribe(
      data => {
        this.loading = false
        this.profileData = <any>data;
        this.populateProfileForm(this.profileData['profileData']);
        if (this.profileData.profileData[0].kycRequired && !this.profileData.profileData[0].kycDone) {
          this.IsKYCRequired = true
        } else {
          this.IsKYCRequired = false
        }
      }
    );
  }

  uploadFile(event) {

    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.panCardImage = reader.result;
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }


  uploadFileAddressProofDoc(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.addressProofImage = reader.result
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }


  updateProfile() {
    this.ProfileForm.value.AddressProofDoc = this.addressProofImage;
    this.ProfileForm.value.Logo = this.panCardImage;

    this.userService.updateProfile(this.ProfileForm.value).subscribe(
      data => {
        const response = <any>data;
        if (response['statusCode'] == 0) {
          this.alertService.success(response['statusMessage'])
        } else {
          this.alertService.error(response['statusMessage'])
        }
      }, err => {
        console.log(err);
      }

    );

  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const Logo1 = event.target.files[0];
      this.ProfileForm.patchValue({ Logo: Logo1 });
    }
  }


  fetchCities(id: string) {
    this.stateCityService.fetchCities(id)
      .subscribe(
        data => {
          let cityData: any = data
          this.cities = cityData.cityData;
        },
        (error) => {
          console.log(error);
        }
      )
  }


  ResponsestatusMessage_wait(): void {
    this._ResponsestatusMessage = true;
    setTimeout(function () {
      this._ResponsestatusMessage = false;
    }.bind(this), 2000);
  }

  opanDialog(serviceType, title) {

    if (serviceType == 'kyc') {
      const dialogRef = this.dialog.open(KycComponent, { data: this.profileData.profileData[0] });
      dialogRef.afterClosed().subscribe(result => {
        if (result != null && result.success) {
          Swal.fire({
            icon: 'info',
            title: 'Success',
            text: 'eKYC completed successfully',
          })
          this.fetchProfile();
        }
      });
    }
    else {
      const dialogRef = this.dialog.open(ForgotpasswordComponent, { data: { serviceType: serviceType, title: title } });
    }
  }

  viewDoc(docType){
    this.userService.fetchDocument(docType).subscribe( (res: any)=>{
      console.log(JSON.stringify(res))
    })
  }

}