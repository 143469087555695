import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StateCityclass } from '../Classes/Statecity';
import { environment } from 'src/environments/environment';

const httpoptons = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  })
};


const url = environment.API_BASE_URL + '/api/auth/getstate';

const cityurl = environment.API_BASE_URL + '/api/auth/getcity';

@Injectable()



export class StateCity {
  constructor(private httpClient: HttpClient) { }

  // StateBind():Observable<StateCityclass[]>{
  //
  //     return  this.httpClient.get<StateCityclass[]>(url);
  // }
  fetchStates() {
    return this.httpClient.get(url);
  }

  fetchCities(name: string) {
    const parm = new HttpParams().set('statename', name);
    return this.httpClient.get(cityurl, { params: parm });
  }

}

