
<div class="container-fluid customehigt bg-light">
    <div class="container py-2">
      
        
         <div class="depositrequest"> 
            <h3>Category List</h3>
            <form class="form-group" [formGroup]="Categorylistgroup">
              
              <div class="row">
                <div class="col-xl-3">
                    <mat-form-field >
                      <mat-label>Category</mat-label>
                      <input  fromControlName="Category" matInput placeholder="Enter Category Name">
                      </mat-form-field>
                  </div>
                  <div class="col-xl-4">
                    <button class="btn-block btnprimary"  mat-raised-button (click)="fun_GetCategory()" >Search</button>
    
                     </div>
                     <p class="text-danger mt-2 text-center font-weight-bold" *ngIf="Showmessage">{{_CategoryListResponse.statusMessage}}</p>
              </div>
        
         
            </form>
          </div>
          <div class="row mb-5 p-1" #TABLE>
            <div class="col-xl-12 table-responsive">
              <table class="table table-hover table-list ">
                <thead>
                    <tr>
                        <td>S.No</td>
                        <td>Category Name </td>
                        <td>Agent</td>
                        
                        <td>Distributor</td>
                        <td>Agent Status</td>
                        
                    </tr>
              
              
              
             
                                        
                </thead>
                <tbody [formGroup]="Categorylistgroup"   *ngIf="Showmessage">
           
            
                  <tr *ngFor="let data of _CategoryListResponse.commisonListData;let i = index">
                    <td>{{i+1}}</td>
                    <td>{{data.category}}</td>
                    <td>{{data.agent}}</td>
                            
                     <td>{{data.distributor}}</td> 
                      <td>{{data.agentStatus}}</td>
                    
                  </tr>
                 
              
              
              </tbody>
            
              
              
              </table>
            
            </div>
          </div>
    </div>
  </div>