import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AccountsService } from 'src/app/Services/accounts.service';
import { CommonService } from 'src/app/Services/common.service'
import * as XLSX from 'xlsx'
import { AuthService } from 'src/app/Services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DateAdapter } from '@angular/material/core';


@Component({
  selector: 'app-creditrequestlist',
  templateUrl: './creditrequestlist.component.html',
  styleUrls: ['./creditrequestlist.component.css']
})
export class CreditrequestlistComponent implements OnInit {
  @ViewChild('TABLE') table: ElementRef;

  Loginresponse; _Responsedata;
  agentdetailspop: boolean = false;
  AcceptCredit_Div: boolean = false;
  DeclineCredit_Div: boolean = false;
  _CreditReqShowmessage: boolean = false;
  _DelineReqShowmessage: boolean = false;
  Showmessageonly: boolean = false;
  Cre_transactionId: any;
  Cre_approvedAmt: any;
  loaderhide = false
  CreditreRuestListFrom: FormGroup;
  CreditreRuestListAcceptFrom: FormGroup;
  CreditreRuestListDeclineFrom: FormGroup;
  _CreditReqlist; Showmessage;
  _Usertype: any;
  ShowListdata: boolean = false;
  _AgentcodeBind: any;
  ShowmessageAgentcode: boolean = false;
  selectedValueAgentCode: any;
  filteredAgents: Observable<any[]>;

  constructor(private _AccountsService: AccountsService,
    private _common: CommonService,
    private _AuthAgentService: AuthService,
    private route: Router,private alertService: ToastrService,
    private dateAdapter: DateAdapter<Date>) {
      this.dateAdapter.setLocale('en-GB');
    }
  
  private filterAgentDropDown(){
    this.filteredAgents = this.CreditreRuestListFrom.get('AgentCode').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  ngOnInit(): void {
    
    this._AuthAgentService.CheckSesstion();
    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata = <any>JSON.parse(this.Loginresponse);
    this._Usertype = this._Responsedata.loginData[0].userType;

    if (this._Usertype == "OO" || this._Usertype == "OOU") {
      this.ShowListdata = true;
    }

    if (this._Usertype != "OO" && this._Usertype != "OOU") {
      this.route.navigate(['/dashboard']);
    }

    this.CreditreRuestListFrom = new FormGroup({
      FromDate: new FormControl(new Date(), Validators.required),
      Todate: new FormControl(new Date()),
      Mode: new FormControl('WEB'),
      Merchant: new FormControl(this._Responsedata.loginData[0].merchant),
      LoggedInUserType: new FormControl(this._Responsedata.loginData[0].userType),
      AgentCode: new FormControl(''),
      ACode: new FormControl(''),
    })

    this.CreditreRuestListAcceptFrom = new FormGroup({
      TransactionId: new FormControl(''),
      StaffRemarks: new FormControl(''),
      ApprovedAmount: new FormControl(''),
      UpdatedBy: new FormControl(this._Responsedata.loginData[0].identifier),
      Merchant: new FormControl(this._Responsedata.loginData[0].merchant),
      Mode: new FormControl('Web'),
      LoggedInUserType: new FormControl(this._Responsedata.loginData[0].userType),
    })

    this.CreditreRuestListDeclineFrom = new FormGroup({
      TransactionId: new FormControl(''),
      StaffRemarks: new FormControl(''),
      UpdatedBy: new FormControl(this._Responsedata.loginData[0].identifier),
      Merchant: new FormControl(this._Responsedata.loginData[0].merchant),
      Mode: new FormControl('Web'),
      LoggedInUserType: new FormControl(this._Responsedata.loginData[0].userType),
    })
    this.Fun_Onload();
    this.FunCrditreq();
  }

  Fun_Onload() {
    try {
      this._common.AgentcodeBind().subscribe(
        StatecityData => {
          this._AgentcodeBind = StatecityData
          this.filterAgentDropDown()
          if (this._AgentcodeBind.statusCode === 0) { this.ShowmessageAgentcode = true; }
        }
      )
    } catch (error) {
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const filteredData =  this._AgentcodeBind.data.filter(option => option.agentCodeAndName.toLowerCase().indexOf(filterValue) === 0);
    this.CreditreRuestListFrom.get('ACode').setValue(filteredData[0].agentCode);
    return filteredData;
  }

  FunCrditreq() {
    this._AccountsService.CreditRequestListeditList(this.CreditreRuestListFrom.value).subscribe(
      UserListData => {
        this._CreditReqlist = UserListData;
        this.Showmessage = true;
        if(this._CreditReqlist.statusCode == 1){
          this.alertService.error(this._CreditReqlist.statusMessage)
        }
        else{
          this.alertService.success(this._CreditReqlist.statusMessage)
        }
        // this.filterAgentDropDown();
        this.pageloadwait();
      },
      (error) => {
        console.log(error);
      }
    )
  }

  AcceptReq() {
    this.loaderhide = true
    this._AccountsService.ReqDCreditAccept(this.CreditreRuestListAcceptFrom.value).subscribe(
      UserListData => {
        this.loaderhide = false
        this._CreditReqlist = UserListData;
        if(this._CreditReqlist.statusCode == 0){
          this.alertService.success(this._CreditReqlist.statusMessage)
        }
        else{
          this.alertService.error(this._CreditReqlist.statusMessage)
        }
        this.AcceptReq_wait();
        //this.FunCrditreq();
      },
      (error) => {
        console.log(error);
        this.loaderhide = false

      }
    )
  }

  DeclineReq() {
    this.loaderhide = true

    this._AccountsService.ReqDCreditDecline(this.CreditreRuestListDeclineFrom.value).subscribe(
      UserListData => {
        this.loaderhide = false
        this._CreditReqlist = UserListData;
        this.DeclineReq_wait();
        this.FunCrditreq();
        if(this._CreditReqlist.statusCode == 0){
          this.alertService.success(this._CreditReqlist.statusMessage)
        }
        else{
          this.alertService.error(this._CreditReqlist.statusMessage)
        }
      },
      (error) => {
        console.log(error);
        this.loaderhide = false

      }
    )
  }

  fun_AcceptCredit(transactionId, approvedAmt) {
    this.AcceptCredit_Div = true
    this.Cre_approvedAmt = approvedAmt;
    this.Cre_transactionId = transactionId;
  }

  fun_DeclineCredit(transactionId, approvedAmt) {
    this.DeclineCredit_Div = true
    this.Cre_approvedAmt = approvedAmt;
    this.Cre_transactionId = transactionId;
  }

  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'File');
    /* save to file */
    XLSX.writeFile(wb, 'File.xlsx');
  }

  closeagentdetailsbtn() {
    this.AcceptCredit_Div = false
    this.DeclineCredit_Div = false
  }

  pageloadwait(): void {
    this.Showmessageonly = true;
    setTimeout(function () {
      this.Showmessageonly = false;
    }.bind(this), 2000);
  }

  AcceptReq_wait(): void {
    this._CreditReqShowmessage = true;
    setTimeout(function () {
      this._CreditReqShowmessage = false;
      this.CreditreRuestListAcceptFrom.patchValue({
        StaffRemarks: ''
      });
      if (this._CreditReqlist.statusCode == 0) {
        this.AcceptCredit_Div = false;
      }
    }.bind(this), 3000);
  }

  DeclineReq_wait(): void {
    this._DelineReqShowmessage = true;
    setTimeout(function () {
      this._DelineReqShowmessage = false;
      this.CreditreRuestListDeclineFrom.patchValue({
        StaffRemarks: ''
      });
      if (this._CreditReqlist.statusCode == 0) {
        this.AcceptCredit_Div = false;
      }
    }.bind(this), 3000);
  }

  resetAgentCode(){
    this.CreditreRuestListFrom.get('AgentCode').setValue('');
    this.CreditreRuestListFrom.get('ACode').setValue('');
  }

}