import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subject, throwError } from 'rxjs';
import { Idle } from '@ng-idle/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

const httpOptions = {
  // headers:new HttpHeaders({'Content-Type':  'multipart/form-data'})
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  })
};
const httpoptonsfILE = {
  // headers:new HttpHeaders({'Content-Type':  'multipart/form-data'})
  headers: new HttpHeaders({
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
  })
};

const url = environment.API_BASE_URL + '/api/auth/SignUp';
const urllogin = environment.API_BASE_URL + '/api/auth/Authenticate';


@Injectable()
export class AuthService {

  emitChangeSource = new Subject<any>();
  changeEmitted$ = this.emitChangeSource.asObservable();
  idleState = 'Not started.';
  timedOut = false;

  constructor(private httpClient: HttpClient, private idle: Idle, private router: Router) { }

  IsRailTxnEnabled() {
    return this.httpClient.get(environment.Api_urluser + 'railenabled');
  }

  getToken() {
    if (localStorage.loginData) {
      return JSON.parse(localStorage.loginData).loginData[0].token;
    } else {
      return '';
    }
  }

  getLoggedInUserType() {
    const jwtHelper = new JwtHelperService();
    const decodedToken = jwtHelper.decodeToken(this.getToken());
    return decodedToken.UserType;
  }

  getLoggedInUser() {
    const jwtHelper = new JwtHelperService();
    const decodedToken = jwtHelper.decodeToken(this.getToken());
    return decodedToken.Identifier;
  }

  getUserEmail() {
    const jwtHelper = new JwtHelperService();
    const decodedToken = jwtHelper.decodeToken(this.getToken());
    return decodedToken.Email;
  }


  emitChange(type) {
    this.emitChangeSource.next(type);
  }

  getsubject(): Observable<any> {
    return this.emitChangeSource.asObservable();
  }

  registerUser(data: any, anonymous: boolean) {
    if (anonymous)
      return this.httpClient.post(environment.Api_urluser + 'SignUp', data, httpOptions);
    else
      return this.httpClient.post(environment.Api_urluser + 'SignUpWithLogin', data, httpOptions);
  }


  doLogin(data: any) {
    let requestPayload = { Domain: data.Domain, email: data.Email, password: data.EncPassword }
    return this.httpClient.post<any>(urllogin, requestPayload, httpOptions);
  }


  loggedIn() {
    return !!JSON.parse(localStorage.loginData).loginData[0].token;
  }


  CheckSesstion() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  logout() {
    window.localStorage.removeItem('loginData')
    window.localStorage.removeItem('apf')
    this.emitChange('logout-success');
    this.router.navigate(['/login']);
  }
}

