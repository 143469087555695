<div class="kyc" *ngIf="loading">
    Loading...
</div>

<div class="kyc container-fluid" *ngIf="!loading">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <h3 style="text-align: left;">Initiate KYC - {{agencyData.agencyName}}</h3>
                <br>
                <h4 style="text-align: left;">You need to complete your KYC process to access any financial services
                    (DMT or
                    AEPS)</h4>
            </div>
        </div>
        <form [formGroup]="KYCForm" *ngIf="!kycOTPFlag">
            <div class="row">
                <div class="col-md-3">
                    <mat-form-field>
                        <mat-label>Aadhaar No.</mat-label>
                        <input autocomplete="off" placeholder="Enter Aadhaar No" formControlName="aadhaarNo" matInput
                            maxlength="12" (keypress)="numberOnly($event)">
                    </mat-form-field>
                </div>
                <div class="col-md-3">

                    <mat-form-field>
                        <mat-label>Pan No </mat-label>
                        <input autocomplete="off" placeholder="Enter Pan No" formControlName="panNo" matInput>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <mat-form-field>
                        <mat-label>Bank A/C No.</mat-label>
                        <input autocomplete="off" placeholder="Enter Account No" formControlName="bankAccountNo" matInput
                            maxlength="40" (keypress)="numberOnly($event)">
                    </mat-form-field>
                </div>
                <div class="col-md-3">

                    <mat-form-field>
                        <mat-label>Bank IFSC Code</mat-label>
                        <input autocomplete="off" placeholder="Enter IFSC Code" formControlName="bankIfsc" matInput>
                    </mat-form-field>
                </div>
            </div>    
            
            <div class="row col-xl-12 text-center p-4">
                <mat-radio-group aria-label="Select an option" formControlName="device">&nbsp;&nbsp;&nbsp;
                    <mat-radio-button *ngFor="let dvc of devices" value={{dvc}}>{{dvc}}</mat-radio-button>
                </mat-radio-group>
                </div>                
            <br>
            <mat-checkbox formControlName="consent">
                <span class="checkbox-label" [style.white-space]="'normal'" [style.word-wrap]="'break-word'">
                    I hereby give my consent and submit voluntarily at my own discretion, my Aadhaar Number or VID for
                    the purpose of establishing my identity on the portal. The Aadhaar submitted herewith shall not be
                    used for any purpose other than mentioned, or as per the requirements of the law.
                </span>
            </mat-checkbox>
            <br><br>
            <div class="row">
                <div class="col-md-2">
                    <button mat-raised-button type="submit" [disabled]="!this.KYCForm.valid" class=" btnprimary"
                        (click)="initiateKYC()">Submit</button>
                </div>
                <div class="col-md-3">
                    <button mat-raised-button class="btnprimary" mat-dialog-close><i class="fa fa-times"
                            aria-hidden="true"></i>
                        Close
                    </button>
                </div>
            </div>
        </form>

        <div class="row py-2" *ngIf="initiationError">
            <div class="col-md-12">
                <span style="color:red">{{initiationErrorMsg}}</span>
            </div>
        </div>

        <form [formGroup]="KYCOTPValidationForm" *ngIf="kycOTPFlag">
            <div class="row col-xl-12">
                <h4 style="text-align: left;">Please provide OTP received on your registered mobile number for Aadhaar
                </h4>
            </div>

            <div class="row py-2 col-xl-3">
                <mat-form-field>
                    <mat-label>OTP </mat-label>
                    <input autocomplete="off" placeholder="Enter OTP" formControlName="otp" matInput maxlength="8"
                        (keypress)="numberOnly($event)">
                </mat-form-field>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <button mat-raised-button type="submit" [disabled]="!this.KYCForm.valid" class=" btnprimary"
                        (click)="validateOTP()">Submit</button>
                </div>
                <div class="col-md-3">
                    <button mat-raised-button class="btnprimary" mat-dialog-close><i class="fa fa-times"
                            aria-hidden="true"></i>
                        Close
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>