import { Component, OnInit } from '@angular/core';
import { AuthService } from '../Services/auth.service';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private _AuthAgentService: AuthService, ) { }
  Loginresponse: string;
  profileData: any;

   trendTitle = 'Monthly Sales';
   trendType = 'LineChart';
   columnNames = ['Month','DMT','AEPS','Air','Recharge']
   trendData = [
      ['Jan', 3,2,1,2],
      ['Feb', 2,5,3,4],
      ['Mar', 2,6,5,7],
      ['Apr', 3,8,5,11],
      ['May', 3,8,5,11],
      ['Jun', 3,8,5,11],
      ['Jul', 3,8,5,11],
      ['Aug', 3,8,5,11],
      ['Sep', 3,8,5,11],
      ['Oct', 3,8,5,11],
      ['Nov', 3,8,5,11],
      ['Dec', 3,8,5,11]
     ];
   trendOptions = {
      hAxis: {
         title: 'Months'
      },
      vAxis: {
         title: 'Amount (in Rs)'
      },
   };
   

   salesTitle = 'Sales per service';
   salesType = 'PieChart';
   salesData = [
      ['DMT', 3],
      ['AEPS', 2],
      ['AIR', 10],
      ['RAIL', 3],
      ['RECHARGE', 3]
     ];
   width = 650;
   height = 400;

  ngOnInit() {
    // this._AuthAgentService.CheckSesstion();
    this.Loginresponse = localStorage.getItem('loginData');
    this.profileData = (JSON.parse(this.Loginresponse)).loginData[0];
  }


  public chartType: string = 'line';

  public chartDatasets: Array<any> = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'My First dataset' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'My Second dataset' }
  ];

  public chartLabels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  public chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(105, 0, 132, .2)',
      borderColor: 'rgba(200, 99, 132, .7)',
      borderWidth: 2,
    },
    {
      backgroundColor: 'rgba(0, 137, 132, .2)',
      borderColor: 'rgba(0, 10, 130, .7)',
      borderWidth: 2,
    }
  ];

  public chartOptions: any = {
    responsive: true
  };
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }
}
