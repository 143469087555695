import { Injectable } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle';
import {AuthService} from './auth.service'
import {Router}from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class CredentialscheckService {

  constructor(private _BnNgIdleService:BnNgIdleService,
    private _AuthAgentService:AuthService,
    private _Router:Router) { }
  Loginresponse;
  _Responsedata;
  UserTypeCheck


    CheckCredential(){
      this._Responsedata = <any>JSON.parse(localStorage.getItem('loginData'));
      if  (this._Responsedata !=null){
        this.UserTypeCheck=this._Responsedata.loginData[0].userType;
        return  this.UserTypeCheck;
      }
    }
  

  CheckSession(){
  this._BnNgIdleService.startWatching(10000).subscribe((isTimedOut: boolean) => {
    if (isTimedOut) {
      window.localStorage.clear();
      this._AuthAgentService.emitChange('login-success');
      this._Router.navigate(['/login']);
    }

  });

  }
}

