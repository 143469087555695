import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DmtService } from 'src/app/Services/dmt.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CapturebiometricComponent } from '../capturebiometric/capturebiometric.component';
import { AepsService } from '../aeps.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TransactionreportComponent } from '../transactionreport/transactionreport.component';
import { environment } from 'src/environments/environment';
import { OutletloginComponent } from '../outletlogin/outletlogin.component';
import { KycComponent } from 'src/app/kyc/kyc.component';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-cash-withdrawal',
  templateUrl: './cash-withdrawal.component.html',
  styleUrls: ['./cash-withdrawal.component.css']
})
export class CashWithdrawalComponent implements OnInit {
  private Virtualid: boolean = false

  BankListResponse;
  filteredBanks: Observable<any[]>;
  ServerError = false;
  transactionForm: FormGroup;
  latitude: any;
  longitude: any;
  transactions: any;
  minAmount = 100;
  devices: any = []
  txnTypes: any = []
  processing = false;
  outletLoginError: boolean = false;
  outletLoginErrorMsg: any;
  outletLoginExpired = false;
  outletLoginForm: FormGroup;
  kycPending: boolean = true;

  constructor(private _DmtService: DmtService, public dialog: MatDialog, private aepsService: AepsService,
    private router: Router, private userService: UserService) { }

  ngOnInit(): void {

    this.getbanklist();
    this.initForm();


    // this.userService.fetchAndStoreProfile().then(res => {
    //   let profileData: any = res
    //   if (profileData.kycRequired && !profileData.kycDone) {
    //     this.initiateKYC();
    //     this.kycPending = true;
    //   } else {
    //     this.kycPending = false;
    //   }
    // })

    this.aepsService.fetchUserRegistrationDetails().subscribe((res: any) => {
      if (res.data.isRegistsered) {
        this.kycPending = false;
      }
      else {
        this.kycPending = true;
        this.initiateKYC();
      }
    })
  }


  ngAfterContentInit(): void {
    setTimeout(() => {
      this.fetchProductInfo();
    });
    this.getLocation();
  }

  initiateKYC() {
    const loginData = JSON.parse(localStorage.getItem('loginData'))
    const agentData = loginData.loginData[0]
    const dialogRef = this.dialog.open(KycComponent, { data: agentData });
  }

  checkOutletLogin() {
    this.outletLoginExpired = true;
    this.aepsService.checkOutletLoginStatus().subscribe(res => {
      let outletLoginStatus: any = res
      this.outletLoginError = !outletLoginStatus.success

      if (outletLoginStatus.success) {
        if (outletLoginStatus.data.loginExpired) {
          this.processOutletLogin();
        }
        else {
          this.outletLoginExpired = false
        }
      } else {
        this.outletLoginError = true;
        this.outletLoginErrorMsg = outletLoginStatus.message;
      }
    })
  }

  processOutletLogin() {
    const outletLoginDialog = this.dialog.open(OutletloginComponent, { data: {} });
    outletLoginDialog.afterClosed().subscribe(result => {
      if (result != null) {
        if (result.success) {
          this.outletLoginExpired = false;
          this.outletLoginError = false;
        } else {
          this.outletLoginError = true;
          this.outletLoginErrorMsg = `Error ${result.message}`
        }
      }
    });
  }


  private initForm() {

    let amountValidator = [Validators.required, Validators.min(this.minAmount)]

    this.transactionForm = new FormGroup({
      device: new FormControl('Mantra', [Validators.required]),
      txnType: new FormControl('AEPS_CASH_WITHDRAWAL', [Validators.required]),
      bankName: new FormControl('', [Validators.required]),
      bankiin: new FormControl('', [Validators.required]),
      aadhaarNumber: new FormControl('', [Validators.required, Validators.minLength(12), Validators.maxLength(12)]),
      mobile: new FormControl('', [Validators.required]),
      amount: new FormControl(0),
      dvcData: new FormControl(''),
      latitude: new FormControl(''),
      longitude: new FormControl(''),
      useDummy: new FormControl(environment.AEPS_DUMMY_RESPONSE)
    });

    this.transactionForm.get("mobile").valueChanges.subscribe(x => {
      if (x.length == 10)
        this.fetchRecentTransactions(x);
      else
        this.transactions = [];
    });

    this.transactionForm.get("txnType").valueChanges.subscribe(x => {
      if (x == 'AEPS_CASH_WITHDRAWAL' || x == 'AEPS_AADHAAR_PAY') {
        this.transactionForm.get("amount").setValidators(amountValidator);
        // this.transactionForm.get("amount").clearValidators();
      } else {
        // To handle the bug in angular, clear validators wont work unless errors are cleared, hence settign it to a valid value
        this.transactionForm.patchValue({
          amount: this.minAmount
        })
        this.transactionForm.get("amount").clearValidators();
      }
    });
  }

  resetForm() {
    this.getFormValidationErrors();
    // this.initForm();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.BankListResponse.filter(item => item.bankName.toLowerCase().indexOf(filterValue) == 0);
  }

  getbanklist() {
    this.aepsService.fetchBanks().subscribe(
      response => {
        this.BankListResponse = response;
        this.BankListResponse = this.BankListResponse.filter(e => e.iin != '')
        this.filteredBanks = this.transactionForm.get('bankName').valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      },
      (error) => {
        console.log(error);
        this.ServerError = true;
      }
    );
  }

  setTxnAmount(amount) {
    this.transactionForm.patchValue({
      amount: amount
    })
  }

  setBank(bankName) {
    let choosenBank = this.BankListResponse.find(e => e.bankName == bankName);
    this.transactionForm.patchValue({
      bankName: bankName,
      bankiin: choosenBank.iin
    })
  }

  process() {

    this.checkOutletLogin();

    this.transactionForm.patchValue({
      // txnType: this.transactionForm.value.txnType,
      amount: parseInt(this.transactionForm.value.amount),
    })
    const biometircDialog = this.dialog.open(CapturebiometricComponent, { width: '350px', data: this.transactionForm.value });
    biometircDialog.afterClosed().subscribe(result => {

      if (result != undefined && result.success) {
        let aDt: any = {}
        let dvcData: any = result.data

        this.transactionForm.patchValue({
          dvcData: btoa(dvcData),
        })

        this.processing = true
        this.aepsService.executeTransaction(this.transactionForm.value).subscribe(result => {
          this.processing = false;
          let response: any = result

          if (response.statuscode == 'TXN' || response.statuscode == 'TUP') {
            response.txnType = this.txnTypes.filter(x => x.key == this.transactionForm.value.txnType)[0].value
            const reportDialog = this.dialog.open(TransactionreportComponent, { width: ' 700px', data: response });
            reportDialog.afterClosed().subscribe(result => {
              this.router.navigate(['/aeps/transactions'])
            });
          } else {
            swal.fire({
              icon: 'info',
              title: 'Error',
              text: response.error.errorMessage,
            })
          }
        })
      } else {
        this.processing = false;
        swal.fire('Unable to capture the biometric data from device')
      }
    })
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position) {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.transactionForm.patchValue({
            latitude: this.latitude,
            longitude: this.longitude
          });
        }
      },
        (geoError: PositionError) => {
          console.log(geoError);
          console.log('Error', geoError.message);
        }
      );
    } else {
      console.log('Error', 'Geolocation is not supported by this browser.', 'error');
    }
  }

  fetchRecentTransactions(mobileNo) {
    this.aepsService.fetchRecentTransactions(mobileNo).subscribe(result => {
      this.transactions = result
    })
  }

  fetchProductInfo() {
    this.aepsService.fetchMetaInfo().subscribe(res => {
      let data: any = res
      this.devices = data.devices
      this.txnTypes = data.txnType
    })
  }

  getFormValidationErrors() {
    Object.keys(this.transactionForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.transactionForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }


}
