<div class="container-fluid customehigt bg-light">
  <div class="container">
    <div class="row py-5 justify-content-center">
      <!-- Sender -->
        <div class="col-xl-12 mb-3">
        <div class="senderbox">
            <h1 class="mb-0">Cashout Request</h1>
            <form class="p-4" >
                <div class="row">
                    <div class="col-xl-4">
                      <Label class="method">Payment Method</Label>
                      <mat-radio-group aria-label="Select an option">
                        <mat-radio-button value="1">NEFT</mat-radio-button>
                        <mat-radio-button value="2">IMPS</mat-radio-button>
                      </mat-radio-group>
                    </div>
                    <div class="col-xl-4">
                      <mat-form-field>
                        <mat-label>Amount requested (In <i class="fa fa-inr" aria-hidden="true"></i>)</mat-label>
                          <input matInput autocomplete="off" placeholder="Enter Amount"  >
                          <span class="mxiamout">Minimum 500 <i class="fa fa-inr" aria-hidden="true"></i></span>

                      </mat-form-field>

                    </div>
                    <div class="col-xl-4">
                      <div class="senderbtn">
                        <button  class="btnprimary mx-auto" mat-raised-button >Search</button>
                      </div>
                    </div>
                    <div class="col-xl-12 text-right">
                      <p><strong>Change - </strong> <i class="fa fa-inr" aria-hidden="true"></i> 5 + GST Per Transection </p>
                    </div>
            
            
                  
                  
                    
                    
                </div>
            </form>
        </div>
        </div>
          <!-- Sender -->

      
    </div>
  </div>
</div>