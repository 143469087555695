<div class="container-fluid">    
    <mat-dialog-content>
        <h2>Agent Biometric Login </h2>
        
        <h3>You will need to do biometric login before performing any AEPS transaction, this needs to be done daily once
        </h3>


        <form [formGroup]="outletLoginForm">
            
            <div class="row col-xl-6 p-2" >
                <mat-form-field>
                    <mat-label>Aadhaar Number</mat-label>
                    <input maxlength="12" autocomplete="off" matInput formControlName="aadhaarNumber"
                        (keypress)="numberOnly($event)" class="Upper" required>
                </mat-form-field>

            </div>
            <div class="row col-xl-6  p-2">
                <mat-radio-group aria-label="Select an option" formControlName="device">&nbsp;&nbsp;&nbsp;
                    <mat-radio-button *ngFor="let dvc of devices" value={{dvc}}>{{dvc}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="row p-4">
                <button  [disabled]='outletLoginForm.invalid || processing' class="btnprimary mx-auto" mat-raised-button (click)="doOutletLogin()">
                    Agent Login
                </button>
            </div>

        </form>

    </mat-dialog-content>
</div>