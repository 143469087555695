import { Component, OnInit } from '@angular/core';
import { AccountsService } from 'src/app/Services/accounts.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/Services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { PaymentService } from 'src/app/payments/services/payment.service';
import * as uuid from 'uuid';
import { CoreDataService } from 'src/app/admin/services/core-data.service';

@Component({
  selector: 'deposit-request',
  templateUrl: './deposit-request.component.html',
  styleUrls: ['./deposit-request.component.css']
})

export class DepositRequestComponent implements OnInit {
  DepositFrom: FormGroup;
  _DepositReq; Loginresponse; _Responsedata;
  Showmessage: boolean = false;
  BankErrorMessage: boolean = false;
  selectedStatus: any;
  clierAmount;
  banks: Observable<any[]>;
  depositModes: Observable<any[]>;

  constructor(private accountService: AccountsService, private _AuthAgentService: AuthService, private alertService: ToastrService,
    private router: Router, private coreDataService: CoreDataService) {
    // do nothing
  }


  ngOnInit(): void {

    this._AuthAgentService.CheckSesstion();
    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata = <any>JSON.parse(this.Loginresponse);

    let merchant = this._Responsedata.loginData[0].merchant;

    this.DepositFrom = new FormGroup({
      DepositType: new FormControl('', [Validators.required]),
      RequestedAmount: new FormControl('', [Validators.required]),
      BankName: new FormControl('', [Validators.required]),
      AgentRemarks: new FormControl('', [Validators.required]),
      DepositRefNumber: new FormControl('', [Validators.required]),
      DepositDate: new FormControl(new Date()),
    })

    this.accountService.fetchBankList(merchant).subscribe(res => {
      let bankList: any = res;
      this.banks = bankList.data
    })

    this.coreDataService.fetchDepositModes(merchant).subscribe((res:any)  =>{
      this.depositModes = res.data[0].attributes.deposit_modes.data;
    })
  }

  submitDepositRequest() {
    if (this.DepositFrom.value.BankName == "Select") {
      this.BankErrorMessage = true;
      return false;
    }

    this.accountService.DepositRequest(this.DepositFrom)
      .subscribe(
        (res: any) => {
          this._DepositReq = res;

          if (this._DepositReq.statusCode == 0) {
            this._DepositReq.amount = this.DepositFrom.value.RequestedAmount
            this.handleDepositRequestCreation(this._DepositReq);
          }
          else {
            this.alertService.error(this._DepositReq.statusMessage)
          }
        },
        (error) => {
          this.alertService.error(error)
        }
      )
  }

  handleDepositRequestCreation(depositRequestDetails: any) {
    if (this.DepositFrom.value.DepositType == 'OnlinePG') {
      let sessionId = uuid.v4();
      sessionStorage.setItem('sessionId', sessionId);
      this.router.navigate(['/payments'], { queryParams: { amt: depositRequestDetails.amount, tid: depositRequestDetails.tid, pc: 'DEPOSIT', mode: 'PG', surl: '/depositlist', furl: '/depositlist' } })
    } else {
      this.alertService.success(this._DepositReq.statusMessage)
      this.router.navigate(['/depositlist']);
    }
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  Depwait(): void {
    this.Showmessage = true;
    setTimeout(function () {
      this.Showmessage = false;
      this.first.reset();
      this.AgentRemarksB.reset();
      this.DepositRefNumberB.reset();
      console.log(this.edited);
    }.bind(this), 2000);
  }

  // Define a function to enable or disable validation
  toggleValidation(controlName: string, enableValidation: boolean) {
    const control = this.DepositFrom.get(controlName);
    control.clearValidators();
    if (enableValidation) {
      control.setValidators([Validators.required]);
    }
    control.updateValueAndValidity();
  }

  enableDisableValidation() {
    let enableValidation = false;
    if (this.DepositFrom.value.DepositType == 'OnlinePG') {
      enableValidation = false
    } else {
      enableValidation = true;
    }
    this.toggleValidation('BankName', enableValidation)
    this.toggleValidation('AgentRemarks', enableValidation)
    this.toggleValidation('DepositRefNumber', enableValidation)
  }

  get first(): any { return this.DepositFrom.get('RequestedAmount'); }
  get AgentRemarksB(): any { return this.DepositFrom.get('AgentRemarks'); }
  get DepositRefNumberB(): any { return this.DepositFrom.get('DepositRefNumber'); }
}


