<div class="otpvalidate">
    <form [formGroup]="OTPForm">
        <mat-form-field>
            <mat-label>Enter OTP</mat-label>
            <input autocomplete="off" placeholder="Enter OTP" formControlName="otp" matInput
                [type]="hide ? 'password' : 'text'" maxlength="8" (keypress)="numberOnly($event)">
            <mat-error *ngIf="OTPForm.hasError('required', 'password')" required>
                Please enter OTP
            </mat-error>

            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide OTP'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>

        </mat-form-field>


        <button mat-button type="submit" [disabled]="!this.OTPForm.valid" class="btn btn-block loginbtn"
            (click)="submitOTP()">Submit</button>
        <button class="closebtnnew btn  btn-block" mat-dialog-close><i class="fa fa-times" aria-hidden="true"></i> Close
        </button>

    </form>
</div>