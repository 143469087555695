import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators} from '@angular/forms';
import {AccountsService} from 'src/app/Services/accounts.service';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/Services/category.service';
import { AuthService } from 'src/app/Services/auth.service';

@Component({
  selector: 'app-creditrequest',
  templateUrl: './creditrequest.component.html',
  styleUrls: ['./creditrequest.component.css']
})
export class CreditrequestComponent implements OnInit {
  Loginresponse;_Responsedata;
  CreditRequestForm:FormGroup;
  _CreditReq;Showmessage;
  _Usertype:any;
  ShowListdata:boolean=false;

  constructor( private _AccountsService:AccountsService,
    private route :Router,private _AuthAgentService: AuthService,){ }
  
  ngOnInit(): void {
   
    this._AuthAgentService.CheckSesstion();
    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata=<any>JSON.parse(this.Loginresponse);
    this._Usertype=this._Responsedata.loginData[0].userType;
    if(this._Usertype !="A"){ this.ShowListdata=true; }

    this.CreditRequestForm=new FormGroup({
      AgentCode:new FormControl(this._Responsedata.loginData[0].identifier),
      AgencyName : new FormControl(this._Responsedata.loginData[0].agencyName),
      RequestedAmount : new FormControl('',[Validators.required]),
      AgentRemarks : new FormControl('',[Validators.required]),
      Mode : new FormControl('WEB'),
     
      Merchant:new FormControl(this._Responsedata.loginData[0].merchant),
      UserType:new FormControl(this._Responsedata.loginData[0].userType),
    })
  }

  Funcreditreq(){
    
    this._AccountsService.CreditReq(this.CreditRequestForm)
    .subscribe(
      UserListData=>
      {
        this._CreditReq=UserListData;
     
       
        this.Sendmoneyiwait()
      
      },
      (error)=>
      {
        console.log(error);
      }
    
      )
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  Sendmoneyiwait(): void {
      this.Showmessage=true;
      setTimeout(function() {
      this.Showmessage=false;
      this.route.navigate(['/creditlist']);
      }.bind(this), 2500);
   }

}
