<div class="container-fluid bg-black" style="position:absolute;">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-xl-3 text-left my-auto">
                <p class="copyright" i18n>@copyright, {{MERCHANT_NAME}}</p>

            </div>
            <div class="col-xl-6 text-center my-3 p-0">
                <ul class="footerlinks">
                    <li><a routerLink="./about">About Us</a></li>
                    <li> / </li>
                    <li><a routerLink="./privacy-policy">Privacy Policy</a></li>
                    <li> / </li>
                    <li><a routerLink="./terms-and-conditions">Terms & Conditions</a></li>
                    <!-- <li> / </li>
                    <li><a href="">Our Services</a></li>  -->
                    <ng-container *ngIf="this.MERCHANT_NAME == 'MET'">
                        <li> / </li>
                        <li><a routerLink="./contact">Contact Us</a></li>
                    </ng-container>
                </ul>

            </div>
            <div class="col-xl-2  text-right sociallinks">
                <h5>Follow Us</h5>
                <ul>
                    <li><a href="https://www.google.com"><i class="fa fa-facebook" aria-hidden="true"></i>
                        </a></li>
                    <li><a href="https://www.google.com"><i class="fa fa-linkedin" aria-hidden="true"></i>
                        </a></li>
                    <li><a href="https://www.google.com"><i class="fa fa-twitter" aria-hidden="true"></i>
                        </a></li>

                </ul>
            </div>
        </div>
    </div>
</div>