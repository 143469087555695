import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './Services/auth.service';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

// const httpOptions = {headers:new HttpHeaders ({'Content-Type': 'application/json','Access-Control-Allow-Origin': '*'})};

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private injector: Injector, private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthService);
    let tokenizedReq;
    if (req.url.indexOf('upload') > 0) {
      tokenizedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer  ${authService.getToken()}`,
          'Access-Control-Allow-Origin': '*'
        }
      });
    } else {
      tokenizedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer  ${authService.getToken()}`,
          Mode: 'WEB',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });
    }
    return next.handle(tokenizedReq).pipe(tap(evt => { }, err => {
      if (err.status == 401) {
        this.authService.logout();
      }
    }));
  }
}
