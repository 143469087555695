import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AccountsService } from 'src/app/Services/accounts.service';
import * as XLSX from 'xlsx';
import { CategoryService } from 'src/app/Services/category.service';
import { AuthService } from 'src/app/Services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-creditlist',
  templateUrl: './creditlist.component.html',
  styleUrls: ['./creditlist.component.css']
})

export class CreditlistComponent implements OnInit {
  @ViewChild('TABLE') table: ElementRef;

  Loginresponse; _Responsedata;
  CreditListForm: FormGroup;
  _Creditlist; Showmessage;
  _Usertype: any;
  ShowListdata = false;
  _AgentcodeBind: any;
  ShowmessageAgentcode = false;
  Showmessageonly = false;
  selectedAgentCode: string;
  Exporttoexcelshow = false;
  filteredAgents: Observable<any[]>;

  constructor(private _AccountsService: AccountsService, private _CategoryService: CategoryService,
              private _AuthAgentService: AuthService, private alertService: ToastrService,
              private userService: UserService,) { }

  private filterAgentDropDown() {
    this.filteredAgents = this.CreditListForm.get('AgentCode').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  ngOnInit(): void {

    this._AuthAgentService.CheckSesstion();
    try {
      this.userService.AgentcodeBind().subscribe(
        StatecityData => {
          this._AgentcodeBind = StatecityData;
          this.filterAgentDropDown();
          if (this._AgentcodeBind.statusCode === 0) {
            this.ShowmessageAgentcode = true;
            this.Exporttoexcelshow = true;
          }
        }
      );
    }
    catch (error) {
    }

    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata = (JSON.parse(this.Loginresponse) as any);
    this._Usertype = this._Responsedata.loginData[0].userType;
    if (this._Usertype == 'OO' || this._Usertype == 'OOU' || this._Usertype == 'S') {
      this.ShowListdata = true;
    }

    this.CreditListForm = new FormGroup({
      FromDate: new FormControl(new Date()),
      Todate: new FormControl(new Date()),
      AgentCode: new FormControl(''),
      ACode: new FormControl(''),
      Mode: new FormControl('WEB'),
      Merchant: new FormControl(this._Responsedata.loginData[0].merchant),
      LoggedInUserType: new FormControl(this._Responsedata.loginData[0].userType),
      LoggedInAgentCode: new FormControl(this._Responsedata.loginData[0].identifier),
    });
    this.fetchCreditList();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const filteredData = this._AgentcodeBind.data.filter(option => option.agentCodeAndName.toLowerCase().indexOf(filterValue) === 0);
    this.CreditListForm.get('ACode').setValue(filteredData[0].agentCode);
    return filteredData;
  }

  fetchCreditList() {
    const formData = this.CreditListForm.value;
    if (formData.AgentCode != '') {
      formData.AgentCode = this.CreditListForm.value.ACode;
    }
    this._AccountsService.CreditList(formData)
      .subscribe(
        UserListData => {
          this._Creditlist = UserListData;
          if (this._Creditlist.statusCode == 1) {
            this.alertService.error(this._Creditlist.statusMessage);
          }
          else {
            this.alertService.success(this._Creditlist.statusMessage);
          }
          this.Showmessage = true;
          this.pageloadwait();
          this.Exporttoexcelshow = true;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'File');
    /* save to file */
    XLSX.writeFile(wb, 'File.xlsx');
  }

  pageloadwait(): void {
    this.Showmessageonly = true;
    setTimeout(function() {
      this.Showmessageonly = false;
    }.bind(this), 2000);
  }

  resetAgentCode() {
    this.CreditListForm.get('AgentCode').setValue('');
    this.CreditListForm.get('ACode').setValue('');
  }

}
