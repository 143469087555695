<div class="container-fluid contactbg">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 text-center">
                <h1>Privacy Policy</h1>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid bg-light py-5">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 text-justify abcont">
               <p>Our Commitment To Privacy Your privacy is important to us. To better protect your privacy we provide this notice explaining our online information practices and the choices you can make about the way your information is collected and used. To make this notice easy to find, we make it available on our homepage and at every point where personally identifiable information may be requested. The Information We Collect: This notice applies to all information collected or submitted on {{MERCHANT_NAME}}. The website offers travel related services such as air tickets,utility services, mobile recharge,money transfer etc. We collect the following personal information to provide the services mentioned above. Name, Address,Date of birth, Passport number, Email address Phone number Frequent Flyer Numbers IP address

            </p>
            <h2>The Way We Use Information:</h2>
               <p>We use the information you provide about yourself when placing an order only to complete that order. We do not share this information with outside parties except to the extent necessary to complete that order. We will use this information to occasionally inform you of promotions being run on the website. Within each email, you will be provided an option to opt out from receiving such emails in the future. We use return email addresses to answer the email we receive. Such addresses are not used for any other purpose and are not shared with outside parties. We use non-identifying and aggregate information to better design our website and to share with advertisers. For example, we may tell an advertiser that X number of individuals visited a certain area on our website, but we would not disclose anything that could be used to identify those individuals. Finally, we never use or share the personally identifiable information provided to us online in ways unrelated to the ones described above without also providing you an opportunity to opt-out or otherwise prohibit such unrelated uses.
            </p>
            <h2>Our Commitment To Data Security:
            </h2>

               <p>To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we have put in place appropriate physical, electronic, and managerial procedures to safeguard and secure the information we collect online.
            </p>
            <h2>How You Can Access Or Correct Your Information:

            </h2>

               <p>You can access all your personally identifiable information that we collect online and maintain by contacting our customer service department. We use this procedure to better safeguard your information. You can correct factual errors in your personally identifiable information by sending us a request that credibly shows error. To protect your privacy and security, we will also take reasonable steps to verify your identity before granting access or making corrections.

            </p>
            <h2>How To Contact Us: </h2>
            <p>Should you have other questions or concerns about these privacy policies, please call us at <a href="tel:+919999421962">+91-9999421962</a> or send us an email at <a href="mailto:support@{{MERCHANT_NAME}}">support@{{MERCHANT_NAME}}</a>
            </p>

            </div>
        </div>
    </div>
</div>
