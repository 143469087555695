import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AepsService } from '../aeps.service';
import { CapturebiometricComponent } from '../capturebiometric/capturebiometric.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-outletlogin',
  templateUrl: './outletlogin.component.html',
  styleUrls: ['./outletlogin.component.css']
})
export class OutletloginComponent implements OnInit {

  outletLoginForm: FormGroup
  devices = null;
  processing = false;

  constructor(public dialog: MatDialog,
    private aepsService: AepsService,
    private dialogRef: MatDialogRef<OutletloginComponent>) { }

  ngOnInit(): void {

    this.fetchProductInfo();
    this.initForm();

  }

  initForm() {
    this.outletLoginForm = new FormGroup({
      device: new FormControl('Mantra', [Validators.required]),
      useDummy: new FormControl(environment.AEPS_DUMMY_RESPONSE),
      aadhaarNumber: new FormControl('', [Validators.required, Validators.minLength(12), Validators.maxLength(12)]),
      dvcData: new FormControl(''),
      latitude: new FormControl(''),
      longitude: new FormControl(''),
    });
  }

  doOutletLogin() {
    this.getLocation().then(res => {
      this.processing = true;
      const biometircDialog = this.dialog.open(CapturebiometricComponent, { width: '350px', data: this.outletLoginForm.value });
      biometircDialog.afterClosed().subscribe(result => {
        if (result != undefined && result.success) {
          let aDt: any = {}
          let dvcData: any = result.data
          this.outletLoginForm.patchValue({
            dvcData: btoa(dvcData),
          })
          this.aepsService.doOutletLogin(this.outletLoginForm.value).subscribe(res => {
            let response: any = res;
            this.processing = false;
            this.dialogRef.close({ success: response.success });
          }, err => {
            this.processing = false;
            this.dialogRef.close({ success: false, message: err.error.message });
          });
        }
      });
    })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Failure',
          text: 'Unable to capture location, please enable location in browser settings to proceed',
        })
      });

  }


  fetchProductInfo() {
    this.aepsService.fetchMetaInfo().subscribe(res => {
      let data: any = res
      this.devices = data.devices
    })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  getLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: Position) => {
          if (position) {
            console.log('Latitude: ' + position.coords.latitude + 'Longitude: ' + position.coords.longitude);
            this.outletLoginForm.patchValue({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            });
            resolve(true);
          }
        },
          (geoError: PositionError) => {
            console.log(geoError);
            console.log('Error', geoError.message);
            reject(false)
          }
        );
      } else {
        console.log('Error', 'Geolocation is not supported by this browser.', 'error');
        reject(false)
      }
    });

  }
}
