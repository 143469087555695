import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cashout-request',
  templateUrl: './cashout-request.component.html',
  styleUrls: ['./cashout-request.component.css']
})
export class CashoutRequestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
