import { Component, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../Services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserlistActivation } from '../Classes/userlist-activation'

import * as XLSX from 'xlsx'
import { CategoryService } from '../Services/category.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UserService } from '../Services/user.service';


@Component({
  selector: 'Agen-Activation-ListComponent',
  styleUrls: ['./agen-activation-list.component.css'],
  templateUrl: './agen-activation-list.component.html',
})
export class AgenActivationListComponent {
  constructor(private AuthService: AuthService,
    private userService: UserService,
    private _CategoryService: CategoryService,
    private route: Router, private alertService: ToastrService) { }
  @ViewChild('TABLE') table: ElementRef;

  agentdetailspop: boolean = false;
  imgereceipt: boolean = false;
  deactivatepop: boolean = false;
  activatepop: boolean = false;
  SearchForm: FormGroup;
  ActionActivationForm: FormGroup;

  _Userlist;
  _Useractivation;
  _Userdeactivation;
  Showmessage: boolean = false; ShowmessageD: boolean = false;
  ShowmessageUserList: boolean = false;
  ShowmessageUseractivation: boolean = false;
  Responsestatusmessage: boolean = false;
  AgenActivationListModule: UserlistActivation;
  _AgentCode;
  _UpdatedBy;
  _Fulldata: any;
  Loginresponse: string;
  _Responsedata: any;
  _ActiveStatus;
  _Usertype: any;
  ShowListdata: boolean = false;

  pinVshow: any; stateVshow: any; cityVshow: any; addressVshow: any;
  ngOnInit(): void {

    this.AuthService.CheckSesstion();
    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata = <any>JSON.parse(this.Loginresponse);
    this._Usertype = this._Responsedata.loginData[0].userType;
    if (this._Usertype == "OO" || this._Usertype == "OOU") { this.ShowListdata = true; }

    if (this._Usertype != "OO" && this._Usertype != "OOU") {
      this.route.navigate(['/dashboard']);
    }

    this.SearchForm = new FormGroup({
      Status: new FormControl('0', [Validators.required]),

    })

    this.ActionActivationForm = new FormGroup({
      UserType: new FormControl(this._Responsedata.loginData[0].userType),
      Merchant: new FormControl(this._Responsedata.loginData[0].merchant),
      AgentCode: new FormControl(this._Responsedata.loginData[0].identifier),
      UpdatedBy: new FormControl(this._Responsedata.loginData[0].identifier),

    })

    this.SearchForm.value.Status = 0;
    this.userService.fetchActivationList(this.SearchForm.value)
      .subscribe(
        UserListData => {
          this._Userlist = UserListData;
          this.load_wait()

          this.ShowmessageUserList = true;
        },
        (error) => {
          console.log(error);
        }

      )
  }

  opanagentdetails(pinV, stateV, cityV, addressV) {

    this.pinVshow = pinV;
    this.stateVshow = stateV;
    this.cityVshow = cityV;
    this.addressVshow = addressV;
    this.agentdetailspop = true
  }
  opanreceipt() {
    this.imgereceipt = true
  }
  closeagentdetailsbtn() {
    this.agentdetailspop = false;
    this.imgereceipt = false;
    this.deactivatepop = false;
    this.activatepop = false;
  }

  searchStatus: any = 0;

  fun_Search() {
    this.searchStatus = this.SearchForm.value.Status;
    this.userService.fetchActivationList(this.SearchForm.value)
      .subscribe(
        UserListData => {
          this._Userlist = UserListData;
          this.ShowmessageUserList = true;
          if (this._Userlist.statusCode == 0) {
            this.alertService.success(this._Userlist.statusMessage)
          }
          else {
            // this.alertService.error(this._Userlist.statusMessage)
          }
        },
        (error) => {
          console.log(error);
        }

      )
  }

  Activeuser(_AgentCode: string, _UpdatedBy: string) {

    this.ActionActivationForm.value.AgentCode = _AgentCode;
    this.ActionActivationForm.value.UpdatedBy = _UpdatedBy;
    this.ActionActivationForm.value.ActiveStatus = "1";

    this.userService.ActivationUser(this.ActionActivationForm.value)
      .subscribe(
        UserListData => {
          this._Useractivation = UserListData;

          if (this._Useractivation.statusCode == 0 ) {
          Swal.fire({
            title: 'User Activation',
            text: `${this._Useractivation.statusMessage}`,
            icon: 'info',
          })
          this.fun_Search();
        } else {
          Swal.fire({
            title: 'User Activation',
            text: `${this._Useractivation.statusMessage}`,
            icon: 'error',
          })          
        }
         
        },
        (error) => {
          console.log(error);
        }
      )
  }


  Deactiveuser(_AgentCode: string, _UpdatedBy: string) {


    this.ActionActivationForm.value.AgentCode = _AgentCode;
    this.ActionActivationForm.value.UpdatedBy = _UpdatedBy;
    this.ActionActivationForm.value.ActiveStatus = "2";
    this.userService.DeactivationUser(this.ActionActivationForm.value)
      .subscribe(
        UserListData => {
          this.deactivatepop = true;
          this._Userdeactivation = UserListData;

          this.ShowmessageD = true;
          //this.Reloaduserlist();
        },
        (error) => {
          console.log(error);
        }
      )
  }

  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'File');

    /* save to file */
    XLSX.writeFile(wb, 'File.xlsx');



  }

  Reloaduserlist() {
    this.deactivatepop = false
    this.activatepop = false;

    this.SearchForm.value.Status = 0;
    this.userService.fetchActivationList(this.SearchForm.value)
      .subscribe(
        UserListData => {
          this._Userlist = UserListData;

          this.ShowmessageUserList = true;
        },
        (error) => {
          console.log(error);
        }

      )
  }




  load_wait(): void {
    this.Responsestatusmessage = true;
    setTimeout(function () {

      this.Responsestatusmessage = false

    }.bind(this), 3000);
  }


}




