import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../Services/auth.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Userlist } from '../Classes/userlist';
import * as XLSX from 'xlsx';
import { CategoryService } from '../Services/category.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { map, startWith } from 'rxjs/operators';
import { UserService } from '../Services/user.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.css'],

})
export class UserlistComponent implements OnInit {
  constructor(private AuthService: AuthService,
              private userService: UserService,
              private _CategoryService: CategoryService,
              private route: Router, private alertService: ToastrService) { }
  @ViewChild('TABLE') table: ElementRef;

  parentType: '';
  loaderhide = false;
  panno = false;
  UserlistForm: FormGroup;
  AgentMoveForm: FormGroup;
  ActiveDeactiveForm: FormGroup;
  AgentMappingForm: FormGroup;
  _Userlist: Observable<any[]>;
  Loginresponse;
  _Responsedata;
  _UserActiveInactiveResponse: any;
  UserActiveInactivemessage = false;
  selectedValueAgentCode: any;
  // Agent_popupType: boolean = false;
  // Sales_popupType: boolean = false;
  ShowmessageAgentcode = false;
  _AgentcodeBind: any;
  _Usertype: any;
  _AgentMappinglist: [];
  perentid = false;
  patentIdtd1 = true;
  patentIdtd2 = false;
  _Usercode: any;
  _AgentMovelist: any;
  _AgentMovemessage = false;
  selectedAgencyName: any;
  selectedAgencyCode: any;
  agencyParentOrSalesPerson: any;
  _GetSalesist: any;
  GetSalesBind = false;
  UserListData: any;
  filteredAgents: Observable<any[]>;
  Showmessage = false;
  UserListModule: Userlist;
  popupChangeType: string;

  User_TypeStaff: boolean;
  User_TypeSales: boolean;
  User_TypeEmp: boolean;
  User_TypeDist: boolean;
  PayoutForm: FormGroup;
  agentProfile = {dmt: false, kycStatus: false};

  private filterAgentDropDown() {
    this.filteredAgents = this.UserlistForm.get('AgentCode').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  ngOnInit(): void {

    this.AuthService.CheckSesstion();

    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata = (JSON.parse(this.Loginresponse) as any);
    this._Usertype = this._Responsedata.loginData[0].userType;
    this.agentProfile = this._Responsedata.loginData[0];
    // console.log(this._Usertype);
    if (this._Usertype != 'OO' && this._Usertype != 'OOU' && this._Usertype != 'S' && this._Usertype != 'D') {
      this.route.navigate(['/dashboard']);
    }
    if (this._Usertype == 'OOU') {
      this.User_TypeStaff = true;
    }
    if (this._Usertype == 'S') {
      this.User_TypeSales = true;
    }
    if (this._Usertype == 'OOU' || this._Usertype == 'S') {
      this.User_TypeEmp = true;
    }
    if (this._Usertype == 'D') {
      this.User_TypeDist = true;
    }
    // this._Usercode = this._Usertype;
    // console.log(this._Usercode)
    // if(this._Usercode == "A" && this._Usercode == "D"){
    //     this.patentIdtd1 = true
    // }
    // else{
    //   this.patentIdtd2 = true
    // }

    try {

      this.userService.AgentcodeBind().subscribe(agentData => {
        const responseData: any = agentData;
        this._AgentcodeBind = responseData;
        this.filterAgentDropDown();
        if (responseData.statusCode == 0) {
          this.ShowmessageAgentcode = true;
        }
      }
      );
    } catch (error) {
      console.log(error);
    }


    this.UserlistForm = new FormGroup({
      AgentCode: new FormControl(''),
      ACode: new FormControl(''),
      FromDate: new FormControl(new Date()),
      Todate: new FormControl(new Date()),
      UserStatus: new FormControl('')
    });


    this.ActiveDeactiveForm = new FormGroup({
      LoggedInAgentCode: new FormControl(this._Responsedata.loginData[0].identifier),
      Merchant: new FormControl(this._Responsedata.loginData[0].merchant),
      AgentCode: new FormControl(''),
      Mode: new FormControl('WEB'),
      Status: new FormControl()
    });

    this.AgentMoveForm = new FormGroup({
      AgentCode: new FormControl(''),
      Mode: new FormControl('WEB'),
      Parent: new FormControl('')
      // SalesCode: new FormControl(this._Responsedata.loginData[0].salesCode)
    });

    this.AgentMappingForm = new FormGroup({
      LoggedInUserType: new FormControl(this._Responsedata.loginData[0].userType),
      LoggedInAgentCode: new FormControl(this._Responsedata.loginData[0].identifier),
      Merchant: new FormControl(this._Responsedata.loginData[0].merchant),
      Mode: new FormControl('WEB'),
    });

    this.PayoutForm = new FormGroup({
      LoggedInAgentCode: new FormControl(this._Responsedata.loginData[0].identifier),
      Merchant: new FormControl(this._Responsedata.loginData[0].merchant),
      AgentCode: new FormControl(''),
      Mode: new FormControl('WEB'),
      Status: new FormControl()
    });

    this.onload_Userlist();
    // this.GetSalesIdentifier();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const filteredData = this._AgentcodeBind.data.filter(option => option.agentCodeAndName.toLowerCase().indexOf(filterValue) === 0);
    this.UserlistForm.get('ACode').setValue(filteredData[0].agentCode);
    return filteredData;
  }

  Funsearch() {
    const formData = this.UserlistForm.value;
    if (formData.AgentCode != '') {
      formData.AgentCode = this.UserlistForm.value.ACode;
    }

    this.userService.Userlist(this.UserlistForm.value).subscribe(UserListData => {
      // this.loadclik_wait();
      const responseData: any = UserListData;
      this._Userlist = responseData.userData;
      // console.log(this._Userlist);
      if (responseData.statusCode != 0) {
        this.alertService.error(responseData.statusMessage);
      }
      this.Showmessage = true;
    },
      (error) => {
        this.alertService.error("Unable to fetch data, pleae try again");
      }
    );
  }

  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'File');

    /* save to file */
    XLSX.writeFile(wb, 'File.xlsx');

  }


  parentIdpop(popupType, name, code, id) {
    if (this._Usertype != 'S') {
      this._AgentMappinglist = [];
      // this.alertService.info(popupType);
      this.userService.GetAgentMapping(popupType, this.AgentMappingForm.value)
        .subscribe(
          UserListData => {
            const responseData: any = UserListData;
            this.popupChangeType = popupType;
            this.selectedAgencyName = name;
            this.selectedAgencyCode = code;
            this.perentid = true;
            this.agencyParentOrSalesPerson = id;
            this.parentType = popupType;
            if (popupType == 'Parent') {
              this._AgentMappinglist = responseData.data;
            }
            else {
              this._AgentMappinglist = responseData.salesCode;
              this.load_wait();
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  // GetSalesIdentifier() {
  //   // debugger
  //   this.AuthService.GetSalesIdentifier()
  //     .subscribe(
  //       UserListData => {
  //         this._GetSalesist = UserListData;
  //         console.log(this._GetSalesist);
  //         this.GetSalesBind=true;
  //         this.load_wait()
  //         console.log(UserListData)
  //       },
  //       (error) => {
  //         console.log(error);

  //       }

  //     )
  // }

  closeagentdetailsbtn() {
    this.perentid = false;
    this.panno = false;
  }

  fun_Actionuser(status: any, agentCode: any, agencyName: string) {
    let fullName = `${agencyName} - ${agentCode}`
    Swal.fire({
      title : 'Are you sure?',
      text: status==3 ? `Inactivate ${fullName}` : `Activate  ${fullName}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continue'
    }).then(res=>{
      if (res.isConfirmed){
        this.ActiveDeactiveForm.value.Status = status;
        this.ActiveDeactiveForm.value.AgentCode = agentCode;
        this.userService.UserActiveInactive(this.ActiveDeactiveForm.value)
          .subscribe(
            UserActiveInactiveReq => {
              this.alertService.success('Status updated sucessfully');
              this._UserActiveInactiveResponse = UserActiveInactiveReq;
              this.Funsearch();
            },
            (error) => {
             this.alertService.error('Unable to update status');
            }
          );
      }
    })
    
  }

  // fun_moveagent
  fun_AgentMove() {
    this.loaderhide = true;
    this.AgentMoveForm.value.AgentCode = this.selectedAgencyCode;
    this.userService.AgentMove(this.AgentMoveForm.value, this.parentType)
      .subscribe(
        UserListData => {
          this.loaderhide = false;
          this._AgentMovelist = UserListData;
          // console.log(this._Userlist);
          this.AgentMove_wait();
          if (this._AgentMovelist.statusCode == 0) {
            this.alertService.success(this._AgentMovelist.statusMessage);
          }
          else {
            this.alertService.error(this._AgentMovelist.statusMessage);
          }
        },
        (error) => {
          console.log(error);
          this.loaderhide = false;
        }
      );
  }

  onload_Userlist() {
    this.userService.Userlist(this.UserlistForm.value).subscribe(UserListData => {
      const responseData: any = UserListData;
      this._Userlist = responseData.userData;
      this.Showmessage = true;
      this.loadclik_wait();
    });
  }

  Actioniwait(): void {
    this.UserActiveInactivemessage = true;

    // this.Transferform.reset();
    setTimeout(function() {

      this.UserActiveInactivemessage = false;

    }.bind(this), 3000);
  }

  load_wait(): void {
    this.Showmessage = true;
    setTimeout(function() {
      this.Showmessage = false;
    }.bind(this), 3000);
  }

  loadclik_wait(): void {
    this.Showmessage = true;
    setTimeout(function() {
      this.Showmessage = false;
    }.bind(this), 3000);
  }

  AgentMove_wait(): void {
    this._AgentMovemessage = true;
    setTimeout(function() {
      this._AgentMovemessage = false;
      this.perentid = false;
      this.salesid = false;
      this.onload_Userlist();
    }.bind(this), 3000);
  }

  pannoclick() {
    this.panno = true;
  }

  resetAgentCode() {
    this.UserlistForm.get('AgentCode').setValue('');
    this.UserlistForm.get('ACode').setValue('');
  }

  switchDMTMode(status: boolean, agentCode: any) {
    const updaterequest = { usePayout: !status, identifier: agentCode };

    this.userService.switchDMTMode(updaterequest).subscribe(
      response => {
        this.Funsearch();
      },
      (error) => {
        this.alertService.error('Could not update mode ' + error);
      }
    );
  }
}
