import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CategoryService } from 'src/app/Services/category.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/Services/common.service';
import { startWith, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { AuthService } from 'src/app/Services/auth.service';
import { Router } from '@angular/router';
import { MastersService } from 'src/app/Services/masters.service';
import { UserService } from 'src/app/Services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-category-agent-mapping',
  templateUrl: './category-agent-mapping.component.html',
  styleUrls: ['./category-agent-mapping.component.css']
})
export class CategoryAgentMappingComponent implements OnInit {
  @ViewChild('TABLE') table: ElementRef;
  loaderhide = false;
  CategoryagentmappingForm: FormGroup;
  AutocompleteForm: FormGroup;
  CategoryUpdateForm: FormGroup;
  selectedValue: string;
  selectedValueAgentCode: string;

  agentmappingcreate = false;
  categoryupdatepop = false;
  agentmappingcreatebtn = true;
  _AutocompleteLisdBind: any;
  _AutocompleteLisdBindDiv = true;
  _Responsedata: any;
  Loginresponse: any;
  _Usertype: any;
  _ProductBind: Observable<any[]>;
  Showmessage = false;
  _CategoryLisdBind: any;
  ShowListdata = false;
  ShowmessageCat = false;
  ShowmessageProduct = true;
  ShowmessageUpdateCat = false;
  _UpdateCategoryLisdBind: any;
  _CategoryProductLisdBind: Observable<any>[];

  AgentBind: any;
  ProductBind: any;
  CategoryBind: any;
  _AgentcodeBind: any;
  ShowmessageAgentcode = false;
  Selectanyone = false;
  filteredAgents: Observable<any[]>;

  constructor(private _CategoryService: CategoryService,
    private _CommonService: CommonService, private _AuthAgentService: AuthService,
    private masterDataService: MastersService,
    private userService: UserService,
    private http: HttpClient, private route: Router, private alertService: ToastrService) { }

  private filterAgentDropDown() {
    this.filteredAgents = this.CategoryagentmappingForm.get('AgentCode').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  ngOnInit(): void {
    this._AuthAgentService.CheckSesstion();
    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata = (JSON.parse(this.Loginresponse) as any);
    this._Usertype = this._Responsedata.loginData[0].userType;
    if (this._Usertype != 'A') { this.ShowListdata = true; }

    if (this._Usertype != 'OO' && this._Usertype != 'OOU') {
      this.route.navigate(['/dashboard']);
    }

    this.fetchProduct();
    this.fetchAgents();
    this.initializeForm();


  }


  initializeForm() {
    this.CategoryagentmappingForm = new FormGroup({
      Category: new FormControl(''),
      Product: new FormControl('', [Validators.required]),
      AgentCode: new FormControl(''),
      ACode: new FormControl(''),
      Mode: new FormControl('WEB'),
      Merchant: new FormControl(this._Responsedata.loginData[0].merchant),
      LoggedInUserType: new FormControl(this._Responsedata.loginData[0].userType),
      LoggedInAgentCode: new FormControl(this._Responsedata.loginData[0].identifier),
    });

    this.CategoryUpdateForm = new FormGroup({
      Category: new FormControl('', [Validators.required]),
      AgentCode: new FormControl('', [Validators.required]),
      Product: new FormControl('', [Validators.required]),
    });
  }


  fetchProduct() {
    this.masterDataService.fetchProduct('all').subscribe(
      res => {
        let productData: any = res
        this._ProductBind = productData.data;
      }
    );
  }

  fetchAgents() {
    try {
      this.userService.AgentcodeBind().subscribe(
        StatecityData => {
          this._AgentcodeBind = StatecityData;
          this.filterAgentDropDown();
          if (this._AgentcodeBind.statusCode === 0) { this.ShowmessageAgentcode = true; }
        }
      );
    } catch (error) {
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const filteredData = this._AgentcodeBind.data.filter(option => option.agentCodeAndName.toLowerCase().indexOf(filterValue) === 0);
    this.CategoryagentmappingForm.get('ACode').setValue(filteredData[0].agentCode);
    return filteredData;
  }

  CategorySearch() {
    {
      this.loaderhide = true;
      const formData = this.CategoryagentmappingForm.value;
      if (formData.AgentCode != '') {
        formData.AgentCode = this.CategoryagentmappingForm.value.ACode;
      }

      if (this.CategoryagentmappingForm.value.Category == '' && this.CategoryagentmappingForm.value.AgentCode == undefined) {
        // this.Selectanyone_wait()
        this.alertService.warning('Please select any one Category / Agent Code');
        this.loaderhide = false;
        return;
      }

      this._CategoryService.AgentCategorySearch(formData)
        .subscribe(
          data => {
            this._CategoryLisdBind = data;
            this.Showmessage = true;
            if (this._CategoryLisdBind.statusCode == 0) {
              this.loaderhide = false;
            }
            else {
              this.alertService.error(this._CategoryLisdBind.statusMessage);
              this.loaderhide = false;
            }
          },
          (error) => {
            this.alertService.error('Unable to fetch records, please try again later');
          }
        );
    }
  }

  onChangeProductdataSelected(event) {
    this.Showmessage = false;
    this._CategoryService.CategoryList({ Product: event.value, Type: 'Mapped' })
      .subscribe(
        res => {
          let data: any = res
          this._CategoryProductLisdBind = data.categoryData;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  CategoryUpdate() {
    this.loaderhide = true;
    {
      this._CategoryService.UpdateAgentCategory(this.CategoryUpdateForm.value)
        .subscribe(
          data => {
            this.loaderhide = false;
            this._UpdateCategoryLisdBind = data;
            if (this._UpdateCategoryLisdBind.statusCode == 0) {
              this.alertService.success(this._UpdateCategoryLisdBind.statusMessage);
              this.CategorySearch();
            }
            else {
              this.alertService.error(this._UpdateCategoryLisdBind.statusMessage);
            }
            this.closeagentdetailsbtn();
          },
          (error) => {
            this.alertService.error('Unable to update mapping, please try again later');
            this.loaderhide = false;
          }
        );
    }
  }
  closeagentdetailsbtn() {
    this.categoryupdatepop = false;
  }

  ExportTOExcel() {

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'File');

    /* save to file */
    XLSX.writeFile(wb, 'File.xlsx');
  }

  FUN_Autocomplete(id: string) {
    this.AutocompleteForm.value.AgentCode = id;
    this._CommonService.AutoComplete(this.AutocompleteForm.value)
      .subscribe(
        data => {
          this._AutocompleteLisdBind = data;
          if (this._AutocompleteLisdBind.statusCode == 0) {
            this._AutocompleteLisdBindDiv = true;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  fun_UpadteCategory(category: string, agent: string, product: string) {
    this.categoryupdatepop = true;
    this.CategoryUpdateForm.patchValue({
      Category: category,
      AgentCode: agent,
      Product: product,
    })

  }


  CategoryUpdatewait(): void {
    this.ShowmessageUpdateCat = true;

    setTimeout(function () {
      this.ShowmessageUpdateCat = false;
      this.CategorySearch();
      this.categoryupdatepop = false;
    }.bind(this), 2000);
  }

  resetAgentCode() {
    this.CategoryagentmappingForm.get('AgentCode').setValue('');
    this.CategoryagentmappingForm.get('ACode').setValue('');
  }

  // Selectanyone_wait(): void {
  //   this.Selectanyone = true;
  //   setTimeout(function () {
  //     this.Selectanyone = false;

  //   }.bind(this), 2000);
  // }

}
