import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm, ValidationErrors } from '@angular/forms';
import { DmtService } from 'src/app/Services/dmt.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CredentialscheckService } from '../../Services/credentialscheck.service';
import { HttpClient } from '@angular/common/http';
import $ from 'jquery';
import { ErrorMsgComponent } from '../../error-msg/error-msg.component';
import { AuthService } from 'src/app/Services/auth.service';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/Services/accounts.service';
import { OtpvalidationComponent } from 'src/app/otpvalidation/otpvalidation.component';
import { KycComponent } from 'src/app/kyc/kyc.component';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/Services/user.service';
import { WalletService } from 'src/app/Services/wallet.service';

declare const myFunction: any;
declare const PopulateDropDownList: any;
declare const BindBnak: any;


@Component({
  selector: 'app-money-transfer',
  templateUrl: './money-transfer.component.html',
  styleUrls: ['./money-transfer.component.css']
})
export class MoneyTransferComponent implements OnInit {

  constructor(private _DmtService: DmtService,
    private _CredentialscheckService: CredentialscheckService,
    private http: HttpClient, private dialog: MatDialog,
    private _AuthAgentService: AuthService,
    private route: Router, private alertService: ToastrService,
    private accountService: AccountsService,
    private walletService: WalletService,
    private userService: UserService) { }

  get CheckAmount() {
    return this.Transferform.get('Amount');
  }

  get AccountNumber(): any { return this.beneficiaryForm.get('AccountNumber'); }
  get Mobile(): any { return this.beneficiaryForm.get('Mobile'); }
  get IfscCode(): any { return this.beneficiaryForm.get('IfscCode'); }
  get Name(): any { return this.beneficiaryForm.get('Name'); }
  get Bank(): any { return this.beneficiaryForm.get('Bank'); }

  // @ViewChild('Addbeneficiaryform') public Addbeneficiaryform_CL: NgForm;
  // @ViewChild('Senderdetailform') public Senderdetailform_CL: NgForm;
  // @ViewChild('ResendValidatebeneficiaryOtpform') public ResendValidatebeneficiaryOtpform_CL: NgForm;
  // @ViewChild('RemitterRegistrationform') public RemitterRegistrationform_CL: NgForm;
  // @ViewChild('RemitterRegOtpVerifyform') public RemitterRegOtpVerifyform_CL: NgForm;
  // @ViewChild('BenificiaryDeleteform') public BenificiaryDeleteform_CL: NgForm;
  // @ViewChild('BenificiaryDeleteValidateform') public BenificiaryDeleteValidateform_CL: NgForm;
  // @ViewChild('Transferform') public Transferform_CL: NgForm;
  // @ViewChild('AccountValidatonform') public AccountValidatonform_CL: NgForm;

  theCheckbox = true;
  marked = true;
  Senderdetailform: FormGroup;
  sancashpophide = false;
  deletaccount = false;
  loaderhide = false;
  receiptmoney = false;
  beneficiaryForm: FormGroup;
  // Beniverifyotpform:FormGroup;
  ResendValidatebeneficiaryOtpform: FormGroup;
  RemitterRegistrationform: FormGroup;
  RemitterRegOtpVerifyform: FormGroup;
  BenificiaryDeleteform: FormGroup;
  BenificiaryDeleteValidateform: FormGroup;
  Transferform: FormGroup;

  AccountValidatonform: FormGroup;
  AccountValidatonResponse: any;
  AccountValidatonResponseMessage = false;
  AccountValidatonResponseMessageSubmit = false;
  ServerError = false;

  ValidatonResponseMessageID: any;

  latitude: any;
  longitude: any;
  ipAddress: any;
  _MyLimit;
  RemainingLimit: any;
  LimitamountMeg = false;
  limitAmountMsg = false;
  newRemaininglimit: any;
  transferAmount: any;

  ValidatonResponseaccountNumber: any;
  ValidatonResponseifscCode: any;
  ValidatonResponsename: any;
  // ValidatebeneficiaryOtpform:FormGroup;

  submitted = false;
  RemitterRegOtpDiv = false;
  remiiteraddsubmit = true;
  remiterregipnl = false;
  addbenificiaryotp = false;


  _Sender = false;
  _SenderDetails = false;
  _BeneficiaryDetails = false;

  _AddBeneficiary = false;
  _SenderResponse; Loginresponse; _Responsedata;
  _AddBeneficiaryResponse;
  _DeleteBeneficiaryResponse;
  _RemitterRegistrationResponse;
  _RemitterRegistrationOtpResponse;
  _Checkstatus;
  _CheckstatusDiv = true;
  otpReferenceNumber; _VerifyRegistrationOTP_remiiterId;
  OtpSendMsgDiv = false;
  _OtpSendMsg;
  OtpSendMsgAddRemmiterDiv = false;
  _OtpSendMsgAddRemmiter;
  btnBenisubmit = true;

  OtpBeniDiv = false;
  _OtpBenimsg;

  ResendOtpBeniDiv = false;
  IFSCdiv = false;
  _ResendOtpBenimsg;
  selectedStatus: any;

  AddBeniDiv = false;
  beneficiaryResponseStatusCode;
  _ValidateBeniOtpResponse;
  _ValidateBeniResendOtpResponse;
  AddBeniDivResponseMeg = false;
  DeleteBeniDivResponseMeg = false;
  SenderResponseResponseMeg = false;
  DeleteAccountResponseMeg = false;
  Areyousure = false;
  Areyousend = true;
  RemitterId; Send_name;
  Send_account; Send_bank;
  _SendMoneyResponse;
  _FinalSendMoneyMessage = false;
  // SendmoneyiwaitMessageLimit:boolean=false;
  _FinalSendMobileno;
  _FinalSendBenificieryId;
  BankListResponse;
  filteredBanks: Observable<any[]>;

  BankIfcCode;
  PleaseenteramountMeg = false;
  Showmessagelist = false;
  SenderMobileNo: any;
  RemitterMobileNo: string;

  _Usertype: any;
  // -------- errormessages --------------//
  ShowmessagePincodew = false;
  _DeleteBeneficiaryResponseData;
  // --------errormessages------------ //

  BeneficiaryIFSCCode: any;
  timeout: any = null;

  isAccountValidated = false;
  kycPending: boolean
  showRemitterRegistration = false;

  states = [{code: '07', name: 'Delhi'}];
  opanDialog() {
    const dialogRef = this.dialog.open(ErrorMsgComponent);
  }


  ngOnInit(): void {

    this.kycPending = false;
    this._AuthAgentService.CheckSesstion();
    this.getLocation();
    this._CredentialscheckService.CheckSession();
    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata = (JSON.parse(this.Loginresponse) as any);
    this._Usertype = this._Responsedata.loginData[0].userType;


    if (this._Usertype == 'D' || this._Usertype == 'OOU' || this._Usertype == 'S') {
      this.route.navigate(['/dashboard']);
    }
    this.getbanklist();


    $('#close-sidebar2').click(function () {
      $('.page-wrapper').removeClass('toggled');
    });
    $('#show-sidebar2').click(function () {
      $('.page-wrapper').addClass('toggled');
    });


    this.selectedStatus = 'IMPS';
    // script code


    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata = (JSON.parse(this.Loginresponse) as any);

    this.Senderdetailform = new FormGroup({
      Mobile: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
    });

    this.beneficiaryForm = new FormGroup({
      Bank: new FormControl(''),
      BankId: new FormControl(''),
      AccountNumber: new FormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(20)]),
      IfscCode: new FormControl('', [Validators.required, Validators.minLength(11), Validators.maxLength(16)]),
      Name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      RemitterMobile: new FormControl(''),
      Mobile: new FormControl('',[Validators.required]),
      Address: new FormControl('',[Validators.required]),
      PinCode: new FormControl('',[Validators.required]),
      StateCode: new FormControl('',[Validators.required]),
      DOB: new FormControl('',[Validators.required])
    });

    this.RemitterRegOtpVerifyform = new FormGroup({
      Otp: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
    });

    this.ResendValidatebeneficiaryOtpform = new FormGroup({
      RemitterId: new FormControl(''),
      BeneficiaryId: new FormControl(''),
    });

    this.BenificiaryDeleteform = new FormGroup({
      BeneficiaryId: new FormControl('', [Validators.required]),
      RemitterMobileNo: new FormControl('', [Validators.required]),
    });

    this.BenificiaryDeleteValidateform = new FormGroup({
      RemitterId: new FormControl(''),
      BenificieryId: new FormControl(''),
      Otp: new FormControl(''),
    });

    this.Transferform = new FormGroup({
      RemitterMobile: new FormControl(''),
      BenificieryId: new FormControl(''),
      Amount: new FormControl('', [Validators.required, Validators.minLength(3)]),
      TxnMode: new FormControl('', [Validators.required]),
      AccountNumber: new FormControl(''),
      BeneficiaryName: new FormControl(''),
      latitude: new FormControl(''),
      longitude: new FormControl('')
    });

    this.AccountValidatonform = new FormGroup({
      AccountNumber: new FormControl(''),
      IfscCode: new FormControl(''),
      BenificieryName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      Latitude: new FormControl('', [Validators.required]),
      Longitude: new FormControl(this._Responsedata.loginData[0].identifier),
      Ip: new FormControl(''),
      BeneId: new FormControl('', [Validators.required]),
      BankId: new FormControl('',[Validators.required]),
      RemitterMobile: new FormControl('',[Validators.required]),
    });


    // this.userService.fetchAndStoreProfile().then(res => {

    //   let profileData: any = res
    //   if (profileData.kycRequired && !profileData.kycDone) {
    //     this.initiateKYC();
    //     this.kycPending = false;
    //   } else {
    //     this.kycPending = false;
    //   }
    // })

  } // End ngInit


  initiateKYC() {

    const dialogRef = this.dialog.open(KycComponent, { data: JSON.parse(localStorage.getItem('apf')) });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result.success == true) {
        this.kycPending = false;
        Swal.fire({
          icon: 'info',
          title: 'Success',
          text: 'eKYC completed successfully',
        })
      }
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.BankListResponse.filter(item => item.bank_name.toLowerCase().indexOf(filterValue) == 0);
  }

  onKeySearch(event: any) {
    if (this.Senderdetailform.value.Mobile.length == 10) {
      const $this = this;
      this.timeout = setTimeout(function () {
        if (event.keyCode != 13) {
          $this.executeListing(event.target.value);
        }
      }, 1);
    }
  }

  private executeListing(value: string) {
    this.GetSender();
  }


  showMoneyTransferPopUp(send_name: any, send_account: any, send_bank: any, send_mobile: any, send_ifsc: any, send_status: any, benificiaryId: any) {
    // if (this.Transferform.value.Amount==""|| this.Transferform.value.Amount==null){
    //   this.PleaseenteramountMeg=true;
    //   return false;
    // }

    this._FinalSendMobileno = send_mobile.innerText;
    this._FinalSendBenificieryId = benificiaryId.innerText;
    this.Send_name = send_name.innerText;
    this.Send_account = send_account.innerText;
    this.Send_bank = send_bank.innerText;
    this.sancashpophide = true;
    this.BeneficiaryIFSCCode = send_ifsc.innerText;
  }

  closeagentdetailsbtn() {
    this.receiptmoney = false;
    this.GetSender();
  }


  fun_Areyousure() {

    if (this.Transferform.value.Amount == '' || this.Transferform.value.Amount == null) {
      this.PleaseenteramountMeg = true;
      return false;
    }
    if (this.Transferform.value.Amount > this.RemainingLimit) {
      // console.log(this.RemainingLimit);
      if (this.Transferform.value.Amount > this.newRemaininglimit) {
        // console.log(this.newRemaininglimit);
        this.waitMessageLimit();
        return;
      }
      this.messageLimit();
    }
    this.Areyousure = true;
    this.Areyousend = false;
  }


  fun_areyoucancel() {
    this.Areyousure = false;
    this.Areyousend = true;
    this._FinalSendMoneyMessage = false;
    this.Transferform.patchValue({ Amount: '', TxnMode: 'IMPS' });
  }

  transferMoney() {
    this.loaderhide = true;
    this.Areyousend = true;
    this.Areyousure = false;
    if (this.Transferform.value.Amount == '' || this.Transferform.value.Amount == null) {
      this.PleaseenteramountMeg = true;
      return false;
    }
    if (this.Transferform.value.Amount > this.RemainingLimit) {
      if (this.Transferform.value.Amount > this.newRemaininglimit) {
        this.loaderhide = false;
        this.waitMessageLimit();
        return;
      }
      this.messageLimit();
    }

    // this.Transferform.value.RemitterMobile = this._FinalSendMobileno;
    this.Transferform.value.RemitterMobile = this.RemitterMobileNo;
    this.Transferform.value.BenificieryId = this._FinalSendBenificieryId;
    this.Transferform.value.AccountNumber = this.Send_account;
    this.Transferform.value.BankName = this.Send_bank;
    this.Transferform.value.Name = this.Send_name;
    this.Transferform.value.IfscCode = this.BeneficiaryIFSCCode;
    this.Transferform.value.latitude = this.latitude;
    this.Transferform.value.longitude = this.longitude;
    this._DmtService.FunTransferf(this.Transferform.value, false)
      .subscribe(
        SenderMobReq => {
          this.loaderhide = false;
          this._SendMoneyResponse = SenderMobReq;
          // console.log(this._SendMoneyResponse);
          // console.log(this._SendMoneyResponse.statusMessage);
          this._FinalSendMoneyMessage = true;
          this.Transferform.patchValue({ Amount: '', TxnMode: 'IMPS' });
          this.SendmoneyiwaitMessage();
          if (this._SendMoneyResponse.statusCode == 0 || this._SendMoneyResponse.statusCode == 5) {
            this.alertService.success(this._SendMoneyResponse.statusMessage);
            this.Sendmoneyiwait();
          }
          else {
            this.alertService.error(this._SendMoneyResponse.statusMessage);
          }
          // this.loaderhide=false;
          this.walletService.notifyWalletBalanceUpdateSubscribers();
        },
        (error) => {
          // console.log(error);
          this.loaderhide = false;
        }
      );
  }

  fun_hidesandpop() {
    this.sancashpophide = false;
    this.Areyousure = false;
    this.Areyousend = true;
    this._FinalSendMoneyMessage = false;
    this.Transferform.patchValue({ Amount: '', TxnMode: 'IMPS' });
  }

  fun_hidesandpopdelac() {
    this.deletaccount = false;
  }

  GetSender() {
    this.showRemitterRegistration = false;
    this._AddBeneficiary = false;
    this.loaderhide = true;
    this.RemitterMobileNo = this.Senderdetailform.value.Mobile;
    this._DmtService.fetchRemitterDetail(this.Senderdetailform.value)
      .subscribe(
        res => {
          this._SenderResponse = res;

          this._Checkstatus = this._SenderResponse.statusCode;
          this._CheckstatusDiv = true;
          this._SenderDetails = true;
          this._BeneficiaryDetails = true;

          if (this._Checkstatus == 0) {
            // this.RemitterId = this._SenderResponse.remitterDetailsData[0].remitter[0].id;
            this.SenderMobileNo = this._SenderResponse.remitter.mobile;
            this.RemainingLimit = this._SenderResponse.remitter.remainingLimit;
            if (this._Responsedata.loginData[0].identifier == 'MT0A1') {
              this.newRemaininglimit = this.RemainingLimit + 175000
            }
            else {
              this.newRemaininglimit = this.RemainingLimit + 25000
            }
          }


          if (this._SenderResponse.statusCode == 1) {
            this.alertService.warning(this._SenderResponse.statusMessage);
          }

          if (this._Checkstatus == 2) {
            this.showRemitterRegistration = true;
            this.RemitterRegistrationform = new FormGroup({
              Mobile: new FormControl(this.Senderdetailform.value.Mobile, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
              FirstName: new FormControl('', [Validators.required]),
              LastName: new FormControl('', [Validators.required]),
              Address: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(200)]),
              PinCode: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
              Otp: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(6)]),
              OtpRefNo: new FormControl('', [Validators.required]),
              DOB: new FormControl('', [Validators.required]),
              StateCode: new FormControl('', [Validators.required])
            });
            this.RemitterRegistrationform.patchValue({ OtpRefNo: this._SenderResponse.otpReference });
            this.Messagecodewait();
          }

          if (this._Checkstatus == 3) {
            this.alertService.error(this._SenderResponse.statusMessage);
          }

          if (this._Checkstatus == 4) {
            this.alertService.info(this._SenderResponse.statusMessage);
            this.otpReferenceNumber = this._SenderResponse.otpReference
            this.otpValidate()
          }

          // PopulateDropDownList();
        },
        (error) => {
          this.loaderhide = false;
          this.alertService.error('Error occurred, please try again later');
        }, () => {
          this.loaderhide = false;
        }
      );
  }

  AddBeneficiary() {
    // this.BankIfcCode = '';
    this.beneficiaryForm.patchValue(
      { AccountNumber: '', RemitterMobile: '', IfscCode: '', Name: '', Bank: '', BankId: '', Mobile: '' }
    );
    this._AddBeneficiary = true;
    this.isAccountValidated = false;
    $(document).ready(function () {
      const y = $(window).scrollTop();
      $(window).scrollTop(y + 350);
    });
  }

  // fun_bindbank(){
  //   PopulateDropDownList();
  // }

  myFunction1(event) {
    console.log(event);
    myFunction(event);
  }

  SandCash() { }

  Registerbeneficiary() {
    this.loaderhide = true;
    this.beneficiaryForm.value.RemitterMobile = this.Senderdetailform.value.Mobile;
    this._DmtService.AddBeneficiary(this.beneficiaryForm.value)
      .subscribe(
        registerBeneficiaryResponse => {
          this.loaderhide = false;
          this._AddBeneficiaryResponse = registerBeneficiaryResponse;
          this.beneficiaryResponseStatusCode = this._AddBeneficiaryResponse.statusCode;
          // this.AddBeniDivResponseMeg=true;
          // this.AddBeniwait();

          if (this._AddBeneficiaryResponse.success) {
            this.alertService.success(this._AddBeneficiaryResponse.message)
            this._AddBeneficiary = false;
            // this.otpReferenceNumber = this._AddBeneficiaryResponse.data.otpReference;
            // this.otpValidate();
          }
          else {
            this.alertService.error(this._AddBeneficiaryResponse.message);
          }

        },
        (error) => {
          console.log(error);
          this.loaderhide = false;
        }
      );
  }

  private otpValidate() {
    const dialogRef = this.openOTPDialog();
    dialogRef.afterClosed().subscribe(result => {
      const data = { otpReferenceNo: this.otpReferenceNumber, otp: result.data.otp };
      this._DmtService.OTPValidate(data).subscribe(otpresponse => {
        let response: any = otpresponse
        if (response.statusCode == 0) {
          this.alertService.success("OTP Validated Successfully");
          this.GetSender();
        } else {
          this.alertService.error("Incorrect OTP");
        }
      });
    });
  }

  fun_BenificiaryDelete(benificiaryId: any, remitterId: any) {
    // console.log(benificiaryId.innerText, remitterId.innerText)
    this.BenificiaryDeleteform.patchValue({
      RemitterMobileNo: this.Senderdetailform.value.Mobile,
      BeneficiaryId: benificiaryId.innerText
    })

    this._DmtService.deleteBeneficiary(this.BenificiaryDeleteform.value)
      .subscribe(
        beneDeleteResponse => {
          this._DeleteBeneficiaryResponse = beneDeleteResponse;
          this._SenderDetails = true;
          this._BeneficiaryDetails = true;
          this.deletaccount = true;
          this.OnDeletewait();
        },
        (error) => {
          console.log(error);
        }
      );
  }


  openOTPDialog() {
    const dialogRef = this.dialog.open(OtpvalidationComponent, { data: {} });
    return dialogRef;
  }

  fun_BeniDeleteValidate() {
    this.BenificiaryDeleteValidateform.value.RemitterId = this.BenificiaryDeleteform.value.RemitterId;
    this.BenificiaryDeleteValidateform.value.BenificieryId = this.BenificiaryDeleteform.value.BenificiaryId;
    this.BenificiaryDeleteValidateform.value.otpReferenceNo = this._DeleteBeneficiaryResponse.otpReference

    this._DmtService.FunBeniDeleteValidate(this.BenificiaryDeleteValidateform.value)
      .subscribe(
        DeleteBeneficiaryReq => {
          this._DeleteBeneficiaryResponseData = DeleteBeneficiaryReq;

          if (this._DeleteBeneficiaryResponseData.statusCode == 0) {
            this._SenderDetails = true;
            this._BeneficiaryDetails = true;
            this.DeleteBeniwait();
            this.GetSender();
          } else {
            this.DeleteBeniwaitWrongOtp();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  fun_RemitterRegistration() {

      Object.keys(this.RemitterRegistrationform.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.RemitterRegistrationform.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
           console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
    

    this._DmtService.FunRemitterRegistration(this.RemitterRegistrationform.value, false)
      .subscribe(
        remitterRegResponse => {
          this._RemitterRegistrationResponse = remitterRegResponse;
          this.RemitterRegistrationwait();
          this._OtpSendMsg = this._RemitterRegistrationResponse.statusMessage;
          this.otpReferenceNumber = this._RemitterRegistrationResponse.otpReferenceNo;

          if (this._RemitterRegistrationResponse.statusCode == 0) {

            if (this._RemitterRegistrationResponse.stateCode == 1) {
              this.remiiteraddsubmit = false;
              this.RemitterRegOtpDiv = true;
            } else {
              this.showRemitterRegistration = false;
              this.alertService.success(this._RemitterRegistrationResponse.statusMessage);
            }
          }
          else {
            this.alertService.error(this._RemitterRegistrationResponse.statusMessage);
          }
        },
        (error) => {
          this.alertService.error("Unable to process request, please try again later");
          // console.log(error);
        }
      );
  }

  fun_RemitterRegVerifyOtp() {
    // this.RemitterRegOtpVerifyform.value.Mobile = this.RemitterRegistrationform.value.Mobile;
    this.RemitterRegOtpVerifyform.value.otpReferenceNo = this.otpReferenceNumber;
    this._DmtService.FunRemitterValidate(this.RemitterRegOtpVerifyform.value, false)
      .subscribe(
        otpVerifyResponse => {
          this._RemitterRegistrationOtpResponse = otpVerifyResponse;
          this.RemitterRegOtpVerifyformotp_wait();
          this._OtpSendMsgAddRemmiter = this._RemitterRegistrationOtpResponse.statusMessage;

          if (this._RemitterRegistrationOtpResponse.statusCode == 0) {
            this.alertService.success(this._RemitterRegistrationOtpResponse.statusMessage);
            this.GetSender();
            this.RemitterRegistrationform.reset();
            // this.RemitterRegOtpVerifyform.setValue({Otp:''})
          }
          else {
            this.alertService.error(this._RemitterRegistrationOtpResponse.statusMessage);
          }
          this.RemitterRegOtpVerifyform.setValue({ Otp: '' });
        },
        (error) => {
          this.alertService.error("Unable to process your request, please try again later");
          console.log(error);
        }
      );
  }

  // fun_Beniverifyotp(){
  //
  //   this.Beniverifyotpform.value.RemitterId=this._AddBeneficiaryResponse.addbenificiaryresponsedata[0].addbenificiarydata[0].remitterId;
  //   this.Beniverifyotpform.value.BenificiaryId= this._AddBeneficiaryResponse.addbenificiaryresponsedata[0].addremitterdata[0].remitterId;
  //   this.Beniverifyotpform.value.TransactionId=this._AddBeneficiaryResponse.transactionId;
  //   this._DmtService.FunBenificiaryValidate(this.Beniverifyotpform.value)
  //   .subscribe(
  //     ValidateBeniOtpReq=>
  //     {
  //       this._ValidateBeniOtpResponse=ValidateBeniOtpReq;
  //       this.OtpBeniDiv=true;
  //       this._OtpBenimsg= this._ValidateBeniOtpResponse.statusMessage

  //     },
  //     (error)=>
  //     {
  //       console.log(error);
  //     }

  //     )

  // }


  fun_BeniverifyResndotp() {

    this.OtpBeniDiv = false;
    this.ResendValidatebeneficiaryOtpform.value.RemitterId = this.BenificiaryDeleteform.value.RemitterId;
    this.ResendValidatebeneficiaryOtpform.value.BeneficiaryId = this.BenificiaryDeleteform.value.BenificiaryId;

    this._DmtService.FunBeneficiaryResendOtp(this.ResendValidatebeneficiaryOtpform.value)
      .subscribe(
        ValidateBeniResendOtpReq => {
          this._ValidateBeniResendOtpResponse = ValidateBeniResendOtpReq;

          this.BeniverifyResndotpwait();
          this._ResendOtpBenimsg = this._ValidateBeniOtpResponse.statusMessage;
        },
        (error) => {
          console.log(error);
        }

      );

  }

  fun_Allclear() {
    this.Senderdetailform.patchValue({
      Mobile: '',
    });
    this._CheckstatusDiv = false;
    this._SenderDetails = false;
    this._BeneficiaryDetails = false;
    this._AddBeneficiary = false;
    this.beneficiaryForm.reset();
    this.ResendValidatebeneficiaryOtpform.reset();
    this.RemitterRegistrationform.reset();
    this.RemitterRegOtpVerifyform.reset();
    this.BenificiaryDeleteform.reset();
    this.BenificiaryDeleteValidateform.reset();
    this.Transferform.reset();
    this.AccountValidatonform.reset();
  }

  CL_Remiterregic() {
    this._Checkstatus == 1;
    this._CheckstatusDiv = false;
  }

  CL_Beneficiary() {
    this._AddBeneficiary = false;
  }

  CL_Sendcash() {
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  fun_onselectbank(bank: any) {
    this.beneficiaryForm.get('IfscCode').setValue(bank.branch_ifsc);
    this.beneficiaryForm.get('BankId').setValue(bank.id);
    // this.IFSCdiv;
  }

  getbanklist() {
    this._DmtService.BankList().subscribe(
      response => {
        this.BankListResponse = response;
        this.filteredBanks = this.beneficiaryForm.get('Bank').valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );

      },
      (error) => {
        console.log(error);
        this.ServerError = true;
      }
    );
  }

  MyLimit() {
    this._MyLimit = this._SenderResponse.remitterDetailsData[0].remitter[0].remainingLimit;
  }

  // inputChange(event) {
  //   if (this.Transferform.value.Amount==""|| this.Transferform.value.Amount==null){
  //     this.PleaseenteramountMeg=true;
  //     return false;
  //   }

  // if(this.Transferform.value.Amount>this.RemainingLimit){
  //   this.LimitamountMeg=true;
  //   return false;
  // }
  // this.PleaseenteramountMeg=false;
  // this.LimitamountMeg=false;

  // }

  // Checkamount(Amount:any){
  //   if(Amount>this._MyLimit)
  //   alert("Hello");

  // }

  AddBeniwait(): void {
    this.AddBeniDivResponseMeg = true;
    this.beneficiaryForm.reset();
    setTimeout(function () {
      this.AddBeniDivResponseMeg = false;
    }.bind(this), 3000);
  }

  DeleteBeniwait(): void {
    this.DeleteBeniDivResponseMeg = true;
    this.DeleteAccountResponseMeg = true;
    setTimeout(function () {
      this.DeleteBeniDivResponseMeg = false;
      this.DeleteAccountResponseMeg = false;
      this.deletaccount = false;

      this.BenificiaryDeleteValidateform.patchValue({
        Otp: ''
      });
      console.log(this.edited);
    }.bind(this), 3000);
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position) {
          // console.log('Latitude: ' + position.coords.latitude + 'Longitude: ' + position.coords.longitude);
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
        }
      },
        (geoError: PositionError) => {
          console.log(geoError);
          console.log('Error', geoError.message);
        }
      );
    } else {
      console.log('Error', 'Geolocation is not supported by this browser.', 'error');
    }
  }

  fun_AccountValidaton(name, accNo, ifsc, beneId, bankId) {
    this.loaderhide = true;
    this.AccountValidatonform.patchValue({
      AccountNumber: accNo,
      IfscCode: ifsc,
      BenificieryName: name,
      Latitude: this.latitude,
      Longitude: this.longitude,
      Ip: this.ipAddress,
      BeneId: beneId,
      BankId: bankId,
      RemitterMobile : this.RemitterMobileNo
    })

    // this.AccountValidatonform.value.TransactionType='IMPS';
    this._DmtService.AccountValidaton(this.AccountValidatonform.value).subscribe(
      accValidationResp => {
        this.AccountValidatonResponse = accValidationResp;
        this.loaderhide = false;

        if (this.AccountValidatonResponse.statusMessage == 'Invalid or empty Latitude') {
          this.AccountValidatonResponse.statusMessage = 'Please allow location from your browser';
        }

        if (this.AccountValidatonResponse.statusCode == 0) {
          this.alertService.success('Account validated successfuly');
          this.GetSender();
        } else {
          this.alertService.error(this.AccountValidatonResponse.statusMessage);
        }

      },
      (error) => {
        this.alertService.error('Error validating account');
        this.loaderhide = false;
      }
    );

  }

  toggleVisibility(e) {
    console.log(this.marked = e.target.checked);
  }

  fun_clear_AddBeneficiary() {
    this.beneficiaryForm.reset();
  }

  RemitterRegOtpVerifyformotp_wait(): void {
    this.OtpSendMsgAddRemmiterDiv = true;

    setTimeout(function () {
      this.OtpSendMsgAddRemmiterDiv = false;
    }.bind(this), 2500);
  }

  beniAddAccValidtioniwait(): void {
    this.AccountValidatonResponseMessageSubmit = true;

    setTimeout(function () {
      this.AccountValidatonResponseMessageSubmit = false;
    }.bind(this), 2500);
  }

  Sendmoneyiwait(): void {

    setTimeout(function () {
      this.sancashpophide = false;
      this.receiptmoney = true;
    }.bind(this), 2500);
  }

  SendmoneyiwaitMessage(): void {
    this._FinalSendMoneyMessage = true;
    // this.Transferform.reset();
    setTimeout(function () {
      this._FinalSendMoneyMessage = false;
    }.bind(this), 3000);
  }

  waitMessageLimit(): void {
    this.LimitamountMeg = true;
    setTimeout(function () {
      this.LimitamountMeg = false;
    }.bind(this), 10000);
  }

  messageLimit(): void {
    this.limitAmountMsg = true;
    setTimeout(function () {
      this.limitAmountMsg = false;
    }.bind(this), 8000);
  }

  fun_beniAddAccValidtion() {

    this.AccountValidatonform.patchValue({
      AccountNumber: this.beneficiaryForm.value.AccountNumber,
      IfscCode: this.beneficiaryForm.value.IfscCode,
      BenificieryName: this.beneficiaryForm.value.Name,
      Latitude: this.latitude,
      Longitude: this.longitude,
      Ip: this.ipAddress
    })

    this.loaderhide = true;
    // this.AccountValidatonform.value.TransactionType='IMPS';
    this._DmtService.AccountValidaton(this.AccountValidatonform.value).subscribe(
      AccountValidatonReq => {
        this.AccountValidatonResponse = AccountValidatonReq;
        this.loaderhide = false;
        // {"statusCode":1,"statusMessage":"Invalid Account Number","data":null}

        if (this.AccountValidatonResponse.statusCode == 0) {
          this.alertService.success(this.AccountValidatonResponse.statusMessage);
          this.isAccountValidated = true;
          this.beneficiaryForm.patchValue({
            Name: this.AccountValidatonResponse.data[0].name
          });
        } else {
          this.alertService.error(this.AccountValidatonResponse.statusMessage);
          this.AccountValidatonResponseMessage = true;
          this.beniAddAccValidtioniwait();
        }
      },
      (error) => {
        console.log(error);
        this.AccountValidatonResponseMessage = true;
      }
    );
  }

  Messagecodewait(): void {
    this.SenderResponseResponseMeg = true;

    setTimeout(function () {
      this.SenderResponseResponseMeg = false;
    }.bind(this), 2000);
  }

  RemitterRegistrationwait(): void {
    this.OtpSendMsgDiv = true;

    setTimeout(function () {
      this.OtpSendMsgDiv = false;
    }.bind(this), 2000);
  }

  DeleteBeniwaitWrongOtp(): void {
    this.DeleteAccountResponseMeg = true;

    setTimeout(function () {
      this.DeleteAccountResponseMeg = false;
    }.bind(this), 2000);
  }

  BeniverifyResndotpwait(): void {
    this.ResendOtpBeniDiv = true;

    setTimeout(function () {
      this.BenificiaryDeleteValidateform.patchValue({ Otp: '' });
      this.ResendOtpBeniDiv = false;
    }.bind(this), 2000);
  }

  OnDeletewait(): void {
    this.DeleteBeniDivResponseMeg = true;

    setTimeout(function () {
      this.DeleteBeniDivResponseMeg = false;
      this.BenificiaryDeleteValidateform.patchValue({
        Otp: ''
      });
    }.bind(this), 2000);
  }

  // insufficientWalletBalance()  {
  //   //this._SendMoneyResponse.Status = false;
  //   if (this._SendMoneyResponse.Status == "Insufficient Wallet Balance") {
  //     this._SendMoneyResponse.Status = "Network error, please try again after sometime"
  //   }
  //   // return true;
  // }
}
