<div class="container-fluid customeheight bg-light">

  <div class="container-fluid">
    <div class="row py-2">
      <div class="depositrequest">
        <div class="row">
          <div class="col-xl-12">
            <h3>Deposit Update</h3>
          </div>
        </div>
        <form class="form-group mb-0" [formGroup]="DepositRequestsFilterForm">

          <div class="row">
            <div class="col-xl-2 col-6">
              <mat-form-field class="example-full-width">
                <mat-label>From Date</mat-label>
                <input matInput [matDatepicker]="pickerFromDate" formControlName="FromDate">
                <mat-datepicker-toggle matSuffix [for]="pickerFromDate">
                </mat-datepicker-toggle>
                <mat-datepicker #pickerFromDate></mat-datepicker>
              </mat-form-field>
            </div>



            <div class="col-xl-2 col-6">

              <mat-form-field class="example-full-width">
                <mat-label>To Date</mat-label>
                <input matInput [matDatepicker]="pickerTodate" formControlName="Todate">
                <mat-datepicker-toggle matSuffix [for]="pickerTodate">

                </mat-datepicker-toggle>
                <mat-datepicker #pickerTodate></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-xl-1 mb-2 col-6">
              <mat-form-field>
                <mat-label>Status</mat-label>
                <mat-select disableRipple formControlName="Status">
                  <mat-option value="All">All</mat-option>
                  <mat-option value="Pending">Pending</mat-option>
                  <mat-option value="Approved">Approved</mat-option>
                  <mat-option value="Rejected">Rejected</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-xl-1 col-6">
              <mat-form-field>
                <mat-label>Bank</mat-label>
                <mat-select disableRipple formControlName="BankName">
                  <mat-option value="">All</mat-option>
                  <mat-option *ngFor="let bank of banks"
                    value="{{bank.attributes.shortName}}">{{bank.attributes.name}}</mat-option>
                  <mat-option value="Off">OFFICE</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-xl-3 mb-2 col-6">
              <mat-form-field>
                <mat-label>Deposit Mode</mat-label>
                <mat-select disableRipple formControlName="TypeofSubmit">
                  <mat-option value="All">All</mat-option>
                  <mat-option *ngFor="let depositMode of depositModes"
                    value="{{depositMode.attributes.code}}">{{depositMode.attributes.description}}</mat-option>

                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-xl-1">
              <div class="row">
                <div class="col-xl-12">
                  <div class="buttnclass">
                    <button [disabled]="!this.DepositRequestsFilterForm.valid" class="btn-block btnprimary mr-3"
                      mat-raised-button (click)="fetchDepositRequests()">Search</button>
                  </div>
                </div>
                <!-- <p class="text-danger mt-2 text-center font-weight-bold text-capitalize" *ngIf="Showmessageonly">{{_DepositReq.statusMessage}}</p>   -->

                <!-- <div class="col-xl-6">
                    <button  class="btn-block registrationbtn exportbtn" mat-raised-button  >Export to Excel</button>
                </div> -->
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row mb-5" #TABLE>
      <div class="col-xl-12 table-responsive p-0">
        <table class="table table-hover table-list ">
          <thead>
            <tr>
              <td>S.No</td>
              <td>Transaction Id</td>
              <td *ngIf="ShowListdata">Agency Name / Code</td>

              <!-- <td>Deposit Date</td> -->
              <td>Bank Name</td>
              <td>Bank Ref Number</td>
              <td>Type Of Request</td>
              <td>Requested Date</td>
              <td>Requested Amount</td>
              <td>Approved Amount</td>
              <td>Approved Date</td>              
              <td>Remarks</td>
              <td>Accept</td>
              <td>Reject</td>

            </tr>

          </thead>
          <tbody>
            <tr *ngFor="let data of depositRequests ;let i = index">
              <td>{{i+1}}</td>
              <td><span title="Status: {{data.depositStatusDescr}}" class="{{data.depositStatusDescr}}">{{data.transactionId}}</span></td>
              <td *ngIf="ShowListdata">{{data.agencyName}} / {{data.identifier}}</td>
              <!-- <td>{{data.depositDate|date : "dd/MM/yyyy  h:mm:ss a"}}</td> -->
              <td>{{data.bankName}}</td>
              <td>{{data.bankRefNum}}</td>
              <td>{{data.typeOfRequest}}</td>
              <td>{{data.requestedDate |date : "dd/MM/yyyy h:mm:ss a"}}</td>
              <td>{{data.requestedAmt | currency:'INR'}}</td>
              <td><span *ngIf="data.depositStatus==1">{{data.approvedAmt| currency:'INR'}}</span> </td>
              <td>{{data.approvedDate |date : "dd/MM/yyyy h:mm:ss a"}}</td>
              <td>{{data.agentRemarks}}</td>

              <ng-container *ngIf="data.typeOfRequest!= 'OnlinePG'">
                <td>
                  <button class="btn-success" data-toggle="tooltip" data-placement="top" title="Update"
                    mat-raised-button (click)="showUpdateDialog(data, true)" *ngIf="data.depositStatus == 0">
                    <mat-icon>check</mat-icon>
                  </button>
                </td>
                <td><button class="btn-danger" data-toggle="tooltip" data-placement="top" title="Reject"
                    mat-raised-button (click)="showUpdateDialog(data, false)" *ngIf="data.depositStatus == 0">
                    <mat-icon>cancel</mat-icon>
                  </button></td>
              </ng-container>

              <ng-container *ngIf="data.typeOfRequest== 'OnlinePG'">
                <td></td>
                <td></td>
              </ng-container>
            </tr>
          </tbody>
        </table>
        <!-- <p >{{_Userlist.statusMessage}}</p> -->
      </div>
    </div>
  </div>
</div>


<!-- deposit  popup -->
<div class="agentdetailsbox" [formGroup]="Req_DepositUpdateForm" id="slip" *ngIf="depositupdatepop">
  <div class="innerboxagntde morebox">
    <div class="closebtn" (click)="closeagentdetailsbtn()">
      <img src="../../../assets/images/icons/close.svg" alt="">
    </div>
    <h5>Approve Request</h5>
    <div class="row justify-content-between">
      <div class="col-xl-12">
        <mat-form-field>
          <mat-label> TransactionId</mat-label>
          <input matInput formControlName="TransactionId" readonly>
        </mat-form-field>
      </div>
      <div class="col-xl-12">
        <mat-form-field>
          <mat-label> Approved Amount</mat-label>
          <input matInput formControlName="ApprovedAmount" maxlength="10" autocomplete="off">
        </mat-form-field>
        <p class="text-danger"><b>{{ Req_DepositUpdateForm.get('ApprovedAmount').value | numbertowords | titlecase
            }}</b></p>
      </div>
      <div class="col-xl-12">
        <mat-form-field>
          <mat-label> Bank Ref Number</mat-label>
          <input matInput formControlName="BankRefNumber" maxlength="22" autocomplete="off">
        </mat-form-field>
      </div>
      <div class="col-xl-12">
        <mat-form-field>
          <mat-label> Remarks </mat-label>
          <input matInput formControlName="StaffRemarks" #StaffRemarks autocomplete="off" maxlength="120">
        </mat-form-field>
      </div>
      <div class="col-xl-12 justify-content-between">
        <button mat-raised-button class="btn btnprimary" [disabled]="!this.Req_DepositUpdateForm.valid"
          (click)="updateRequest('APPROVED')">Approve</button>
        <!-- <p class="text-danger mt-2 text-center font-weight-bold text-capitalize" *ngIf="ShowmessageUpdate">{{_DepositUpdateReq.statusMessage}}</p>   -->
      </div>
    </div>

  </div>
</div>
<!-- deposit popup -->



<!-- deposit  popup -->
<div class="agentdetailsbox" [formGroup]="Req_DepositRejectForm" id="slip1" *ngIf="depositdeletepop">
  <div class="innerboxagntde morebox">
    <div class="closebtn" (click)="closeagentdetailsbtn()">
      <img src="../../../assets/images/icons/close.svg" alt="">
    </div>
    <h5>Reject Request</h5>
    <div class="row justify-content-between">
      <div class="col-xl-12">
        <mat-form-field>
          <mat-label> TransactionId</mat-label>
          <input matInput formControlName="TransactionId" readonly >
        </mat-form-field>
      </div>


      <div class="col-xl-12">
        <mat-form-field>
          <mat-label> Remarks </mat-label>
          <input matInput formControlName="StaffRemarks" #StaffRemarks autocomplete="off" maxlength="120">
        </mat-form-field>
      </div>
      <div class="col-xl-12 text-right">
        <button mat-raised-button class="btn btnprimary" [disabled]="!this.Req_DepositRejectForm.valid"
          (click)="updateRequest('REJECTED')">Reject</button>
        <p class="text-danger mt-2 text-center font-weight-bold text-uppercase" *ngIf="ShowmessageRejectReq">
          {{_DepositRejectReq.statusMessage}}</p>
      </div>
    </div>

  </div>
</div>
<!-- deposit popup -->


<div class="loader-maine" *ngIf="loaderhide">
  <div class="loader">
    <!--<div class="loader-inner">cvxcv</div>-->
    <div class="loader-inner"></div>
    <div class="loader-inner box"></div>
    <div class="box-1"></div>
    <div class="box-2"></div>
    <div class="box-3"></div>
  </div>
</div>