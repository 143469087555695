<div class="container-fluid customehigt bg-light">
    <div class="container">
        <div class="col-xl-12 pt-4 pb-5">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="home-tab" data-toggle="tab" href="#mobilerecharge" role="tab" aria-controls="home" aria-selected="true">
                      <img src="../../../assets/images/icons/phone.svg">
                      <p>Mobile Recharge</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="profile-tab" data-toggle="tab" href="#dth" role="tab" aria-controls="profile" aria-selected="false">
                    <img src="../../../assets/images/icons/antenna.svg">
                    <p>DTH</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="contact-tab" data-toggle="tab" href="#Broadband" role="tab" aria-controls="contact" aria-selected="false">
                    <img src="../../../assets/images/icons/router.svg">
                    <p>Broadband Postpaid</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="contact-tab" data-toggle="tab" href="#Gas" role="tab" aria-controls="contact" aria-selected="false">
                    <img src="../../../assets/images/icons/gas.svg">
                    <p>Gas</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="contact-tab" data-toggle="tab" href="#Electricity" role="tab" aria-controls="contact" aria-selected="false">
                    <img src="../../../assets/images/icons/idea.svg">
                    <p>Electricity</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="contact-tab" data-toggle="tab" href="#Water" role="tab" aria-controls="contact" aria-selected="false">
                    <img src="../../../assets/images/icons/water.svg">
                    <p>Water</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="contact-tab" data-toggle="tab" href="#Loan" role="tab" aria-controls="contact" aria-selected="false">
                    <img src="../../../assets/images/icons/loan.svg">
                    <p>Loan Repayment</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="contact-tab" data-toggle="tab" href="#LPG" role="tab" aria-controls="contact" aria-selected="false">
                    <img src="../../../assets/images/icons/gas-cylinder.svg">
                    <p>LPG Gas</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="contact-tab" data-toggle="tab" href="#Insurance" role="tab" aria-controls="contact" aria-selected="false">
                    <img src="../../../assets/images/icons/life-insurance.svg">
                    <p>Life Insurance</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="contact-tab" data-toggle="tab" href="#Complaint" role="tab" aria-controls="contact" aria-selected="false">
                    <img src="../../../assets/images/icons/technical-support.svg">
                    <p>Complaint</p>
                  </a>
                </li>
                
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="mobilerecharge" role="tabpanel" aria-labelledby="mobilerecharge">
                   <form action="">
                    <div class="row">
                        <h2 class="recharge-heading">Mobile Recharge</h2>
                        <div class="col-xl-4 col-md-4"> 
                            <mat-form-field>
                            <mat-label>Select Operator</mat-label>
                            <mat-select >
                              <mat-option value="deepak">
                                Airtel
                              </mat-option>
                              <mat-option value="kumar">
                                Idea
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                          <div class="col-xl-4 col-md-4"> 
                            <mat-form-field>
                                <mat-label>Mobile No.</mat-label>
                                <input matInput>
                              </mat-form-field>
                        </div>
                        <div class="col-xl-4 col-md-4"> 
                        <button mat-raised-button  class="validateacc"><i class="fa fa-paper-plane" aria-hidden="true"></i>
                            Submit</button>
                            </div>
                    </div>
                    <div class="row">
                     <div class="col-xl-12">
                      <table class="table-list" border="1">
                        <thead>
                          <tr>
                            <td>Biller Name</td>
                            <td>Consumer No.	</td>
                            <td>Bill Amount</td>
                            <td>Pay</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Videocon D2H</td>
                            <td>7503306304	</td>
                            <td>&#8377; 442</td>
                            <td class="text-center"><button mat-raised-button (click)="Pay()" class="paybutton">Pay</button></td>
                          </tr>
                        </tbody>
                      </table>
                     </div>
                    </div>
                   </form>
                </div>
                <div class="tab-pane fade" id="dth" role="tabpanel" aria-labelledby="dth">
                  <form action="">
                   <div class="row">
                       <h2 class="recharge-heading">DTH Recharge</h2>
                       <div class="col-xl-4 col-md-4"> 
                           <mat-form-field>
                           <mat-label>Select Operator</mat-label>
                           <mat-select >
                             <mat-option value="deepak">
                               Airtel
                             </mat-option>
                             <mat-option value="kumar">
                               Idea
                             </mat-option>
                           </mat-select>
                         </mat-form-field>
                       </div>
                         <div class="col-xl-4 col-md-4"> 
                           <mat-form-field>
                               <mat-label>Mobile No.</mat-label>
                               <input matInput>
                             </mat-form-field>
                       </div>
                       <div class="col-xl-4 col-md-4"> 
                       <button mat-raised-button  class="validateacc"><i class="fa fa-paper-plane" aria-hidden="true"></i>
                           Submit</button>
                           </div>
                   </div>
                   <div class="row">
                    <div class="col-xl-12">
                      <table class="table-list" border="1">
                        <thead>
                          <tr>
                            <td>Biller Name</td>
                            <td>Consumer No.	</td>
                            <td>Bill Amount</td>
                            <td>Pay</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Videocon D2H</td>
                            <td>7503306304	</td>
                            <td>&#8377; 442</td>
                            <td class="text-center"><button mat-raised-button (click)="Pay()" class="paybutton">Pay</button></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                   </div>
                  </form>
               </div>
               <div class="tab-pane fade" id="Broadband" role="tabpanel" aria-labelledby="Broadband">
                <form action="">
                 <div class="row">
                     <h2 class="recharge-heading">Broadband Postpaid</h2>
                     <div class="col-xl-4 col-md-4"> 
                         <mat-form-field>
                         <mat-label>Select Operator</mat-label>
                         <mat-select >
                           <mat-option value="deepak">
                             Airtel
                           </mat-option>
                           <mat-option value="kumar">
                             Idea
                           </mat-option>
                         </mat-select>
                       </mat-form-field>
                     </div>
                       <div class="col-xl-4 col-md-4"> 
                         <mat-form-field>
                             <mat-label>Mobile No.</mat-label>
                             <input matInput>
                           </mat-form-field>
                     </div>
                     <div class="col-xl-4 col-md-4"> 
                     <button mat-raised-button  class="validateacc"><i class="fa fa-paper-plane" aria-hidden="true"></i>
                         Submit</button>
                         </div>
                 </div>
                 <div class="row">
                  <div class="col-xl-12">
                    <table class="table-list" border="1">
                      <thead>
                        <tr>
                          <td>Biller Name</td>
                          <td>Consumer No.	</td>
                          <td>Bill Amount</td>
                          <td>Pay</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Videocon D2H</td>
                          <td>7503306304	</td>
                          <td>&#8377; 442</td>
                          <td class="text-center"><button mat-raised-button (click)="Pay()" class="paybutton">Pay</button></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                 </div>
                </form>
             </div>
             <div class="tab-pane fade" id="Gas" role="tabpanel" aria-labelledby="Gas">
              <form action="">
               <div class="row">
                   <h2 class="recharge-heading">Gas</h2>
                   <div class="col-xl-4 col-md-4"> 
                       <mat-form-field>
                       <mat-label>Select Operator</mat-label>
                       <mat-select >
                         <mat-option value="deepak">
                           Airtel
                         </mat-option>
                         <mat-option value="kumar">
                           Idea
                         </mat-option>
                       </mat-select>
                     </mat-form-field>
                   </div>
                     <div class="col-xl-4 col-md-4"> 
                       <mat-form-field>
                           <mat-label>Mobile No.</mat-label>
                           <input matInput>
                         </mat-form-field>
                   </div>
                   <div class="col-xl-4 col-md-4"> 
                   <button mat-raised-button  class="validateacc"><i class="fa fa-paper-plane" aria-hidden="true"></i>
                       Submit</button>
                       </div>
               </div>
               <div class="row">
                <div class="col-xl-12">
                  <table class="table-list" border="1">
                    <thead>
                      <tr>
                        <td>Biller Name</td>
                        <td>Consumer No.	</td>
                        <td>Bill Amount</td>
                        <td>Pay</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Videocon D2H</td>
                        <td>7503306304	</td>
                        <td>&#8377; 442</td>
                        <td class="text-center"><button mat-raised-button (click)="Pay()" class="paybutton">Pay</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
               </div>
              </form>
             </div>
             <div class="tab-pane fade" id="Electricity" role="tabpanel" aria-labelledby="Electricity">
              <form action="">
               <div class="row">
                   <h2 class="recharge-heading">Electricity</h2>
                   <div class="col-xl-4 col-md-4"> 
                       <mat-form-field>
                       <mat-label>Select Operator</mat-label>
                       <mat-select >
                         <mat-option value="deepak">
                           Airtel
                         </mat-option>
                         <mat-option value="kumar">
                           Idea
                         </mat-option>
                       </mat-select>
                     </mat-form-field>
                   </div>
                     <div class="col-xl-4 col-md-4"> 
                       <mat-form-field>
                           <mat-label>Mobile No.</mat-label>
                           <input matInput>
                         </mat-form-field>
                   </div>
                   <div class="col-xl-4 col-md-4"> 
                   <button mat-raised-button  class="validateacc"><i class="fa fa-paper-plane" aria-hidden="true"></i>
                       Submit</button>
                       </div>
               </div>
               <div class="row">
                <div class="col-xl-12">
                  <table class="table-list" border="1">
                    <thead>
                      <tr>
                        <td>Biller Name</td>
                        <td>Consumer No.	</td>
                        <td>Bill Amount</td>
                        <td>Pay</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Videocon D2H</td>
                        <td>7503306304	</td>
                        <td>&#8377; 442</td>
                        <td class="text-center"><button mat-raised-button (click)="Pay()" class="paybutton">Pay</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
               </div>
              </form>
             </div>
             <div class="tab-pane fade" id="Water" role="tabpanel" aria-labelledby="Water">
              <form action="">
               <div class="row">
                   <h2 class="recharge-heading">Water</h2>
                   <div class="col-xl-4 col-md-4"> 
                       <mat-form-field>
                       <mat-label>Select Operator</mat-label>
                       <mat-select >
                         <mat-option value="deepak">
                           Airtel
                         </mat-option>
                         <mat-option value="kumar">
                           Idea
                         </mat-option>
                       </mat-select>
                     </mat-form-field>
                   </div>
                     <div class="col-xl-4 col-md-4"> 
                       <mat-form-field>
                           <mat-label>Mobile No.</mat-label>
                           <input matInput>
                         </mat-form-field>
                   </div>
                   <div class="col-xl-4 col-md-4"> 
                   <button mat-raised-button  class="validateacc"><i class="fa fa-paper-plane" aria-hidden="true"></i>
                       Submit</button>
                       </div>
               </div>
               <div class="row">
                <div class="col-xl-12">
                  <table class="table-list" border="1">
                    <thead>
                      <tr>
                        <td>Biller Name</td>
                        <td>Consumer No.	</td>
                        <td>Bill Amount</td>
                        <td>Pay</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Videocon D2H</td>
                        <td>7503306304	</td>
                        <td>&#8377; 442</td>
                        <td class="text-center"><button mat-raised-button (click)="Pay()" class="paybutton">Pay</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
               </div>
              </form>
             </div>
             <div class="tab-pane fade" id="Loan" role="tabpanel" aria-labelledby="Loan">
              <form action="">
               <div class="row">
                   <h2 class="recharge-heading">Loan Repayment</h2>
                   <div class="col-xl-4 col-md-4"> 
                       <mat-form-field>
                       <mat-label>Select Operator</mat-label>
                       <mat-select >
                         <mat-option value="deepak">
                           Airtel
                         </mat-option>
                         <mat-option value="kumar">
                           Idea
                         </mat-option>
                       </mat-select>
                     </mat-form-field>
                   </div>
                     <div class="col-xl-4 col-md-4"> 
                       <mat-form-field>
                           <mat-label>Mobile No.</mat-label>
                           <input matInput>
                         </mat-form-field>
                   </div>
                   <div class="col-xl-4 col-md-4"> 
                   <button mat-raised-button  class="validateacc"><i class="fa fa-paper-plane" aria-hidden="true"></i>
                       Submit</button>
                       </div>
               </div>
               <div class="row">
                <div class="col-xl-12">
                  <table class="table-list" border="1">
                    <thead>
                      <tr>
                        <td>Biller Name</td>
                        <td>Consumer No.	</td>
                        <td>Bill Amount</td>
                        <td>Pay</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Videocon D2H</td>
                        <td>7503306304	</td>
                        <td>&#8377; 442</td>
                        <td class="text-center"><button mat-raised-button (click)="Pay()" class="paybutton">Pay</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
               </div>
              </form>
             </div>
             <div class="tab-pane fade" id="LPG" role="tabpanel" aria-labelledby="LPG">
              <form action="">
               <div class="row">
                   <h2 class="recharge-heading">LPG Gas</h2>
                   <div class="col-xl-4 col-md-4"> 
                       <mat-form-field>
                       <mat-label>Select Operator</mat-label>
                       <mat-select >
                         <mat-option value="deepak">
                           Airtel
                         </mat-option>
                         <mat-option value="kumar">
                           Idea
                         </mat-option>
                       </mat-select>
                     </mat-form-field>
                   </div>
                     <div class="col-xl-4 col-md-4"> 
                       <mat-form-field>
                           <mat-label>Mobile No.</mat-label>
                           <input matInput>
                         </mat-form-field>
                   </div>
                   <div class="col-xl-4 col-md-4"> 
                   <button mat-raised-button  class="validateacc"><i class="fa fa-paper-plane" aria-hidden="true"></i>
                       Submit</button>
                       </div>
               </div>
               <div class="row">
                <div class="col-xl-12">
                  <table class="table-list" border="1">
                    <thead>
                      <tr>
                        <td>Biller Name</td>
                        <td>Consumer No.	</td>
                        <td>Bill Amount</td>
                        <td>Pay</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Videocon D2H</td>
                        <td>7503306304	</td>
                        <td>&#8377; 442</td>
                        <td class="text-center"><button mat-raised-button (click)="Pay()" class="paybutton">Pay</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
               </div>
              </form>
             </div>
             <div class="tab-pane fade" id="Insurance" role="tabpanel" aria-labelledby="Insurance">
              <form action="">
               <div class="row">
                   <h2 class="recharge-heading">Life Insurance</h2>
                   <div class="col-xl-4 col-md-4"> 
                       <mat-form-field>
                       <mat-label>Select Operator</mat-label>
                       <mat-select >
                         <mat-option value="deepak">
                           Airtel
                         </mat-option>
                         <mat-option value="kumar">
                           Idea
                         </mat-option>
                       </mat-select>
                     </mat-form-field>
                   </div>
                     <div class="col-xl-4 col-md-4"> 
                       <mat-form-field>
                           <mat-label>Mobile No.</mat-label>
                           <input matInput>
                         </mat-form-field>
                   </div>
                   <div class="col-xl-4 col-md-4"> 
                   <button mat-raised-button  class="validateacc"><i class="fa fa-paper-plane" aria-hidden="true"></i>
                       Submit</button>
                       </div>
               </div>
               <div class="row">
                <div class="col-xl-12">
                  <table class="table-list" border="1">
                    <thead>
                      <tr>
                        <td>Biller Name</td>
                        <td>Consumer No.	</td>
                        <td>Bill Amount</td>
                        <td>Pay</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Videocon D2H</td>
                        <td>7503306304	</td>
                        <td>&#8377; 442</td>
                        <td class="text-center"><button mat-raised-button (click)="Pay()" class="paybutton">Pay</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
               </div>
              </form>
             </div>
             <div class="tab-pane fade" id="Complaint" role="tabpanel" aria-labelledby="Complaint">
              <form action="">
               <div class="row">
                   <h2 class="recharge-heading">Complaint</h2>
                   <div class="col-xl-4 col-md-4"> 
                       <mat-form-field>
                       <mat-label>Select Operator</mat-label>
                       <mat-select >
                         <mat-option value="deepak">
                           Airtel
                         </mat-option>
                         <mat-option value="kumar">
                           Idea
                         </mat-option>
                       </mat-select>
                     </mat-form-field>
                   </div>
                     <div class="col-xl-4 col-md-4"> 
                       <mat-form-field>
                           <mat-label>Mobile No.</mat-label>
                           <input matInput>
                         </mat-form-field>
                   </div>
                   <div class="col-xl-4 col-md-4"> 
                   <button mat-raised-button  class="validateacc"><i class="fa fa-paper-plane" aria-hidden="true"></i>
                       Submit</button>
                       </div>
               </div>
               <div class="row">
                <div class="col-xl-12">
                  <table class="table-list" border="1">
                    <thead>
                      <tr>
                        <td>Biller Name</td>
                        <td>Consumer No.	</td>
                        <td>Bill Amount</td>
                        <td>Pay</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Videocon D2H</td>
                        <td>7503306304	</td>
                        <td>&#8377; 442</td>
                        <td class="text-center"><button mat-raised-button (click)="Pay()" class="paybutton">Pay</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
               </div>
              </form>
             </div>
              </div>
        </div>
    </div>
</div>

<div class="transectionpopup" *ngIf="transectionSuccess">
  <div class="msgboxtra">
    <div class="msginnerbx">
      <img src="../../../assets/images/icons/like.svg" alt="">
      <h1 class="text-success">Transection Successful</h1>
      <p>Transection ID : <Span>7896541255</Span></p>
      <p>Transection Amount : <Span> &#8377; 450</Span></p>
      <button mat-raised-button class="trnsebtn paybutton" (click)="backhome()">Back</button>
    </div>
  </div>
</div>