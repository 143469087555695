<mat-card class="example-card">
    <mat-card-header>
      <div mat-card-avatar class="example-header-image"></div>
      
    </mat-card-header>
    
    <mat-card-content>
      <p>
        
        Get all online services in single platform, Flight /Hotel /Bus /Money Transfer/ AEPS/ Bill Recharge/ Micro ATM.
      </p>
    </mat-card-content>
  
  </mat-card>