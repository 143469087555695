import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FaviconServiceService {
  private faviconLink: HTMLLinkElement;
  constructor() {
    this.faviconLink = this.createFaviconLink();
  }

  private createFaviconLink(): HTMLLinkElement {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/x-icon';
    document.head.appendChild(link);
    return link;
  }

  setFaviconUrl(url: string): void {
    this.faviconLink.href = url;
  }
}