
<div class="container-fluid customehigt bg-light">
    <div class="container py-5">
      
        
         <div class="depositrequest"> 
            <h3>Add Credit</h3>
            <form class="form-group" >
              

              
              <div class="row">
         
                <div class="col-xl-4">
                  <mat-form-field >
                  <mat-label>Type</mat-label>
                  <mat-select disableRipple  >
                    <mat-option value="DMT">DMT</mat-option>
                    <mat-option value="AEPS">AEPS</mat-option>
                    <mat-option value="RECHARGE">RECHARGE</mat-option>
                    <mat-option value="BUS">BUS</mat-option>
                
                  </mat-select>
                </mat-form-field>
                   </div>
                   <div class="col-xl-4">
                    <mat-form-field >
                    <mat-label>Agent / SubAgent</mat-label>
                    <mat-select disableRipple  >
                     
                  
                    </mat-select>
                  </mat-form-field>
                     </div>
          
          
               

              <div class="col-xl-4">
                <mat-form-field >
                  <mat-label>Available Limit (Deposit)</mat-label>
                  <input  matInput placeholder="Available Limit">
                  
                
                  </mat-form-field>
              </div>
              <div class="col-xl-4">
                <mat-form-field >
                  <mat-label>Sales Persons</mat-label>
                  <input  matInput placeholder="Sales Persons">
                  
                
                  </mat-form-field>
              </div>
              <div class="col-xl-4">
                <mat-form-field >
                  <mat-label>Add Credit</mat-label>
                  <input  matInput placeholder="Add Credit">
                  
                
                  </mat-form-field>
              </div>
              <div class="col-xl-4">
                <mat-form-field >
                  <mat-label>Reduce Credit</mat-label>
                  <input  matInput placeholder="Reduce Credit">
                  
                
                  </mat-form-field>
              </div>
              <div class="col-xl-4">
                <mat-form-field >
                  <mat-label>Ditributer</mat-label>
                  <input  matInput placeholder="Ditributer">
                  
                
                  </mat-form-field>
              </div>
              <div class="col-xl-4 commentcus">
                <mat-form-field >
                  <mat-label>Comment</mat-label>
                  <textarea matInput placeholder="Comment"></textarea>
                </mat-form-field>
              </div>

              <div class="col-xl-4">
                <div class="buttnclass">
                <button class="btn-block btnprimary mr-3"  mat-raised-button  >Search</button>
                <button   mat-raised-button color="primary"class="downloadexal" data-toggle="tooltip"
                    data-placement="top" title="Export as Excel" (click)="ExportTOExcel()"><i class="fa fa-download" aria-hidden="true"></i></button>
                  </div>
                <!-- <button class="btn-block registrationbtn" disabled mat-raised-button  >Back</button> -->
                  <!-- <p class="alert-danger" *ngIf="Showmessage">{{_DepositReq.statusMessage}}</p> -->
              </div>
              
             
             
          </div>
       
            </form>
          </div>
         
    </div>
  </div>