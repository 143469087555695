<div class="container-fluid customeheight bg-light">
    
    <div class="container-fluid">
      <div class="row  py-2">
        <div class="depositrequest">

        <div class="row">
          <div class="col-xl-12">
            <h3>Credit List</h3>
          </div>
        </div>
          <form class="form-group mb-0"  [formGroup]="CreditListForm">
           
          <div class="row">
       
           
           
            <div class="col-xl-3 col-6">
             
      
                <mat-form-field class="example-full-width">
                  <mat-label>From Date</mat-label>
                  <input matInput [matDatepicker]="pickerFromDate" formControlName="FromDate">
                  <mat-datepicker-toggle matSuffix [for]="pickerFromDate">
                 
                  </mat-datepicker-toggle>
                  <mat-datepicker #pickerFromDate></mat-datepicker>
                </mat-form-field>
            </div>
      
      
            
            <div class="col-xl-3 col-6">
      
              <mat-form-field class="example-full-width">
                <mat-label>To Date</mat-label>
                <input matInput [matDatepicker]="pickerTodate" formControlName="Todate" >
                <mat-datepicker-toggle matSuffix [for]="pickerTodate">
                 
                </mat-datepicker-toggle>
                <mat-datepicker #pickerTodate></mat-datepicker>
              </mat-form-field>
      
      
            </div>
         

              <div class="col-xl-3"  *ngIf="ShowListdata">
                <mat-form-field >
                  <mat-label>Agent Name/Code</mat-label>
                  <input (click)="resetAgentCode()" type="text" placeholder="Select Agent Code" aria-label="Number" matInput
                    formControlName="AgentCode" [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let binddata of filteredAgents | async" [value]="binddata.agentCodeAndName">
                      {{binddata.agentCodeAndName}}
                    </mat-option>
                  </mat-autocomplete>
                  <!-- <mat-select  disableRipple  #AgentCode [(ngModel)]="selectedAgentCode"
                   formControlName="AgentCode">
                   <mat-option>Select</mat-option>
                    <mat-option  *ngFor="let binddata of _AgentcodeBind.data" [value]="binddata.agentCodeAndName.split('(')[1].split(')')[0]" >{{binddata.agentCodeAndName}}</mat-option >
                  </mat-select > -->
                </mat-form-field >
              </div>

              <div class="col-xl-3 ml-auto">

                <div class="buttnclass">
                  <button type="submit"  [disabled]="!this.CreditListForm.valid" class="btn-block btnprimary mr-3" mat-raised-button  (click)="fetchCreditList()">Search</button>
                  <button  mat-raised-button color="primary"class="downloadexal" data-toggle="tooltip"
                    data-placement="top" title="Export as Excel" (click)="ExportTOExcel()"><i class="fa fa-download" aria-hidden="true"></i></button>
                </div>
                        <!-- <p class="text-danger mt-2 text-center font-weight-bold text-capitalize" *ngIf="Showmessageonly">{{_Creditlist.statusMessage}}</p> -->
              </div>
            
          </div>
          </form>
          </div>
        </div>
      
      
      
      
          <div class="row mb-5" #TABLE>
            <div class="col-xl-12 table-responsive p-0">
              <table class="table table-hover table-list ">
                <thead>
                  <tr>
                    <td>S.No</td>
                    <td>Transaction Id</td>
                    <td *ngIf="ShowListdata" >Agency Name / Code</td>
                    <td>Approve/Reject <br> Date</td>
                    <td>Approved <br> Amount</td>
                    <td>Requested <br> Amount</td>
                    <td>Requested <br> Date</td>                                                                    
                    <td>Status</td>
                    <td>Agent Remarks</td>
                    <td>Staff Remarks</td>
                    <td>Updated By</td>
                  </tr>
                </thead>
                <tbody [formGroup]="CreditListForm"   *ngIf="Showmessage">
                  <tr *ngFor="let data of _Creditlist.creditListData;let i = index">
                    <td>{{i+1}}</td>
                    <td>{{data.transactionId}}</td>
                    <td *ngIf="ShowListdata">{{data.agencyName}} / {{data.agentCode}}</td>

                    <td *ngIf="data.creditStatus =='Pending'"></td>
                    <td *ngIf="data.creditStatus !='Pending'">{{data.approvedDate|date : "dd/MM/yyyy  h:mm:ss a"}}</td>

                    <td *ngIf="data.creditStatus =='Pending'"></td>
                    <td *ngIf="data.creditStatus !='Pending'">{{data.approvedAmt}}</td>

                    <td>{{data.requestedAmt}}</td>
                    <td>{{data.requestedDate|date : "dd/MM/yyyy  h:mm:ss a"}}</td>
                    <td>{{data.creditStatus}}</td>
                    <td>{{data.agentRemarks}}</td>
                    <td>{{data.staffRemarks}}</td>
                    <td>{{data.updatedBy}}</td>
                  </tr>
                 
              
                 
              
              </tbody>
            
              
              </table>
            
            </div>
          </div>
    </div>
      
</div>
    