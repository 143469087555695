<div class="container-fluid customehigt bg-light">
  <div class="container-fluid ">

    <div class="row py-2">
      <div class="depositrequest">
        <h3>Commission Category Listing & Creation</h3>
        <form class="form-group mb-0" [formGroup]="CategoryListForm">

          <div class="row justify-content-between">

            <div class="col-xl-8">
              <div class="row">
                <div class="col-xl-6">
                  <mat-form-field>
                    <mat-label>Select Product</mat-label>
                    <mat-select disableRipple #Product [(ngModel)]="selectedValue"
                      (ngModelChange)='onChangeProductdataSelected($event)' formControlName="Product">
                      <mat-option *ngFor="let binddata of products " [value]="binddata.productName">
                        {{binddata.productName}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

              </div>
            </div>


            <div class="col-xl-4">
              <div class="buttnclass">
                <button class="btn-block btnprimary mr-3" mat-raised-button (click)="fun_GetCategory()"
                  [disabled]="!this.CategoryListForm.valid">Search</button>
                <button class="btn-block btnprimary mr-3 mt-0" mat-raised-button (click)="fun_ShowCat()">Add</button>
                <button mat-raised-button color="primary" class="downloadexal" data-toggle="tooltip"
                  data-placement="top" title="Export as Excel" (click)="ExportTOExcel()"><i class="fa fa-download"
                    aria-hidden="true"></i></button>
              </div>

            </div>
          </div>
        </form>

      </div>
    </div>

    <div class="row mb-5" #TABLE>
      <div class="col-xl-12 table-responsive p-0">
        <table class="table table-hover table-list ">
          <thead>
            <tr>
              <td>S.No</td>
              <td>Category</td>
              <td>Product</td>
              <td>Created By</td>
              <td>IsDirect</td>

            </tr>

          </thead>
          <tbody *ngIf="Showmessage">
            <tr *ngFor="let data of _CategoryListResponse.categoryData;let i = index">
              <td>{{i+1}}</td>
              <td>{{data.category}}</td>
              <td>{{data.product}}</td>
              <td>{{data.createdBy}}</td>
              <td>{{data.isDirect}}</td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</div>

<div class="agentdetailsbox" [formGroup]="CategoryCreationForm" *ngIf="addcategorypanel">
  <div class="innerboxagntde morebox">
    <div class="closebtn" (click)="closeagentdetailsbtn()">
      <img src="../../../assets/images/icons/close.svg" alt="">
    </div>
    <h5>Create Category</h5>
    <div class="row justify-content-center">
      <div class="col-xl-4">
        <mat-form-field>
          <mat-label>Select Product</mat-label>
          <mat-select disableRipple #ProductCat formControlName="Product">
            <mat-option *ngFor="let binddata of products" [value]="binddata.productName">
              {{binddata.productName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-xl-4">
        <mat-form-field>
          <mat-label>Category</mat-label>
          <input matInput autocomplete="off" placeholder="Enter Category" maxlength="20" formControlName="Category">
       
        </mat-form-field>
      </div>

      <div class="col-xl-4">
        <label class="agrnttypebtn" for="">Agent Type</label>



        <mat-radio-group aria-label="Select an option" formControlName="IsDirect" >
          <mat-radio-button value="true" >Direct</mat-radio-button>
          <mat-radio-button value="false" > In-Direct</mat-radio-button>
        </mat-radio-group>

        <div class="transectiontyp">
        </div>
      </div>
    </div>
    <div class="row justify-content-end">

      <div class="col-xl-4 my-auto">
        <div class="buttnclass">
          <button class="btn-block btnprimary" [disabled]="!this.CategoryCreationForm.valid" mat-raised-button
            (click)="fun_CreateCat()">Create Category</button>
        </div>

      </div>
    </div>
  </div>
  