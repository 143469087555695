import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  private walletBalanceUpdateEvent = new Subject();
  $walletBalanceUpdateEvent = this.walletBalanceUpdateEvent.asObservable();

  
  constructor(private httpClient: HttpClient) { }

  notifyWalletBalanceUpdateSubscribers() {
    this.walletBalanceUpdateEvent.next();
  }

  LedgerDetails(data: any) {
    const Fdate = new Date(data.FromDate);
    const Tdate = new Date(data.Todate);
    const F = this.FormatDate(Fdate);
    const T = this.FormatDate(Tdate);

    const pvalue = '?FromDate=' + F + '&Todate=' + T + '&TransactionId=' + data.TransactionId + '&TransactionType=' + data.TransactionType + '&AgentCode=' + data.AgentCode;
    // let pvalue='?FromDate='+F+'&Todate='+T+'&AgentCode='+data.AgentCode+'&Merchant='+data.Merchant+'&Mode='+data.Mode+'&LoggedInUserType='+data.LoggedInUserType+
    // '&TransactionType='+data.TransactionType+'&TransactionId='+data.TransactionId+'&LoggedInAgentCode='+data.TransactionType

    return this.httpClient.get(environment.Api_urlwallet + 'LedgerDetails' + pvalue);
  }


  WalletSummary(data: any) {
    if (data.AgentCode == undefined) {
      data.AgentCode = '';
    }
    const pvalue = '?Limit=' + data.Limit + '&AgentCode=' + data.AgentCode;
    return this.httpClient.get(environment.Api_urlwallet + 'WalletSummary' + pvalue);
  }


  WalletDetail() {
    return this.httpClient.get(environment.Api_urlwallet + 'walletdetail');
  }

  WalletUpdate(data: any) {
    return this.httpClient.post(environment.Api_urlwallet + 'WalletUpdate', data);
  }


  FormatDate(date: Date): string {

    const day = date.getDate();
    const months = date.getMonth() + 1;
    const year = date.getFullYear();
    let day1;
    let months1;

    if (day < 10) {
      day1 = `0${day}`;
    } else { day1 = `${day}`; }

    if (months < 10) {
      months1 = `0${months}`;
    } else { months1 = `${months}`; }
    return `${year}-${months1}-${day1}`;
  }
}
