import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  fetchDocument(docType: any) {
     return this.httpClient.get(environment.Api_urluser + 'profile/doc?dt=' + docType);
  }

  fetchAgentProfile(agentCode: any) {
    return this.httpClient.get(environment.Api_urluser + 'profile/' + agentCode);
  }
  
  fetchMerchantProfile() {
    return this.httpClient.get(environment.Api_urluser + 'merchant/profile');
  }

  initiateKYC(data: any) {
    return this.httpClient.post(environment.Api_urluser + 'kyc', data);
  }

  validateKYCOTP(data: any) {
    return this.httpClient.post(environment.Api_urluser + 'kycvalidate', data);
  }

  constructor(private httpClient: HttpClient) { }

  fetchprofile() {
    return this.httpClient.get(environment.Api_urluser + 'profile');
  }

  updateProfile(data: any) {
    return this.httpClient.post<any>(environment.Api_urluser + 'profile', data);
  }

  fetchAndStoreProfile() {
    return new Promise((resolve, reject) => {
      this.fetchprofile().subscribe(
        data => {
          let profileData: any = data
          profileData = profileData.profileData[0];
          localStorage.setItem('apf', JSON.stringify(profileData))
          resolve(profileData);
        }
      );
    })

  }


  Userlist(data: any) {
    const Fdate = new Date(data.FromDate);
    const Tdate = new Date(data.Todate);
    const F = this.FormatDate(Fdate);
    const T = this.FormatDate(Tdate);
    if (data.AgentCode == undefined) {
      data.AgentCode = '';
    }

    const pvalue = '?FromDate=' + F + '&Todate=' + T + '&AgentCode=' + data.AgentCode + '&UserStatus=' + data.UserStatus;
    return this.httpClient.get(environment.Api_urluser + "users" + pvalue);
  }


  UserActiveInactive(data: any) {
    return this.httpClient.put(environment.Api_urluser + 'UserActiveInactive', data);
  }

  switchDMTMode(data) {
    return this.httpClient.put(environment.Api_urluser + 'switchdmtmode', data);
  }

  fetchActivationList(data: any) {
    const pvalue = '?Status=' + data.Status;
    return this.httpClient.get(environment.Api_urluser + 'ActivationList' + pvalue);
  }


  ActivationUser(data: any) {
    return this.httpClient.put(environment.Api_urluser + 'ActivateUser', data);
  }


  DeactivationUser(data: any) {
    return this.httpClient.put(environment.Api_urluser + 'ActivateUser', data);
  }


  ResetPassword(data: any) {
    if (data.ServiceType == 'FORGOTPASSWORD')
      return this.httpClient.post<any>(environment.Api_urlauth + 'forgotpassword', data);
    else
      return this.httpClient.post<any>(environment.Api_urluser + 'ResetPassword', data);
  }

  ValidateResetPassword(data: any) {
    if (data.ServiceType == 'FORGOTPASSWORD')
      return this.httpClient.post<any>(environment.Api_urlauth + 'validateforgotpassword', data);
    else
      return this.httpClient.post<any>(environment.Api_urluser + 'ValidateResetPassword', data);

  }
  AgentMove(data: any, type: string) {
    data.type = type;
    return this.httpClient.post<any>(environment.Api_urluser + 'AgentMove', data);
  }


  GetAgentMapping(mappingType, data: any) {
    if (mappingType == 'Parent') {
      const pvalue = '?Type=' + data.LoggedInUserType;
      return this.httpClient.get(environment.Api_urluser + 'GetAgentMapping' + pvalue);
    } else {
      return this.httpClient.get<any>(environment.Api_urluser + 'GetSalesIdentifier' + '');
    }
  }

  FormatDate(date: Date): string {
    const day = date.getDate();
    const months = date.getMonth() + 1;
    const year = date.getFullYear();
    let day1;
    let months1;

    if (day < 10) {
      day1 = `0${day}`;
    } else { day1 = `${day}`; }

    if (months < 10) {
      months1 = `0${months}`;
    } else { months1 = `${months}`; }
    return `${year}-${months1}-${day1}`;
  }

  AgentcodeBind() {
    return this.httpClient.get(environment.Api_urluser + 'AutoCompleteNew');
  }

  uploadFile(data){
    const file: File  = data.file
    const type:string  =  data.type

    const formData = new FormData();
    formData.append("type", type);
    formData.append("file", file);

    return this.httpClient.post(environment.Api_urluser + "upload", formData);
  }
}
