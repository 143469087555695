import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AccountsService } from '../Services/accounts.service';
import { WalletService } from '../Services/wallet.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],


})
export class NavigationComponent implements OnInit {

  walletbalpanl = false;
  Loginresponse;
  _Responsedata;
  Walletesponse;
  creditBalance = 0;
  depositBalance = 0;
  totalBalance = 0;
  Showmessage = false;
  Walleatcheck = 0;
  loading = false;

  constructor(private accountsService: AccountsService, private walletService: WalletService, private alertService: ToastrService) { }

  walletbal() {
    this.walletbalpanl = true;
  }

  ngOnInit(): void {

    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata = (JSON.parse(this.Loginresponse) as any);

    this.fetchWalletDetails(1, 1);

    this.walletService.$walletBalanceUpdateEvent.subscribe(() => {
      this.fetchWalletDetails(1, 1);
    });

    let mData: any = {};
    if (localStorage.getItem('mData') != null) {
      mData = JSON.parse(localStorage.getItem('mData'));
      document.documentElement.style.setProperty('--nav-bg-color', mData.colors.nav.bgClr);
    }


  }

  fetchWalletDetails(id: number, index: number) {
    // this.showContentDetails = (this.showContentDetails == index) ? -1 : index;
    this.loading = true;
    this.walletService.WalletDetail().subscribe(WalletRes => {
      this.Walletesponse = WalletRes;
      this.creditBalance = this.Walletesponse.walletDetail[0].creditBalance;
      this.depositBalance = this.Walletesponse.walletDetail[0].depositBalance;
      this.totalBalance = this.Walletesponse.walletDetail[0].totalBalance;
    },
      (error) => {
        this.alertService.warning('Unable to fetch wallet balance')
      }
      , () => {
        this.loading = false;
      });
  }

}