
<mat-dialog-content id="print-section">
    <h3 mat-dialog-title class="col-md-12 detailcls" style="text-align: center;">
        <img src="../../../assets/images/logo/MET/logo.png">
        <br>
        {{txnType}} Receipt
        <!-- <b> Account No : {{data.data.accountNumber}} <br>
            Balance: {{data.data.bankAccountBalance | currency : '&#8377;'}}
        </b> -->
    </h3>    
    <table  >
        <tr>
            <td>Transaction Id</td>
            <td>{{txnId}}</td>
        </tr> 
        <tr>
            <td>Date</td>
            <td>{{timeStamp}}</td>
        </tr> 
        <tr>
            <td>Status</td>
            <td>{{status}}</td>
        </tr>                
        <tr>
            <td>Bank Name</td>
            <td>{{data.bankName}}</td>
        </tr>        
        <tr *ngIf="data.transactionValue > 0">
            <td>Amount</td>
            <td>{{data.transactionValue | currency : '&#8377;'}}</td>
        </tr>
        <tr>
            <td>Account Balance</td>
            <td>{{data.bankAccountBalance | currency : '&#8377;'}}</td>
        </tr>        

    </table>
    <br>
    <table mat-table [dataSource]="miniStatement" class="mat-elevation-z8" *ngIf="miniStatement.length > 0">
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let ms"> {{ms.date}} </td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef> Amount </th>
            <td mat-cell *matCellDef="let ms"> {{ms.amount}} </td>
        </ng-container>

        <ng-container matColumnDef="txnType">
            <th mat-header-cell *matHeaderCellDef> Transaction Type </th>
            <td mat-cell *matCellDef="let ms"> {{ms.txnType}} </td>
        </ng-container>

        <ng-container matColumnDef="narration">
            <th mat-header-cell *matHeaderCellDef> Narration </th>
            <td mat-cell *matCellDef="let ms"> {{ms.narration}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
</mat-dialog-content>