import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs';

const httpoptons = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  })
};
const httpoptonsa = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient) { }

  public fetchStates(): Observable<HttpEvent<any>> {
    const endpoint = 'https://india.getsandbox.com/states';
    const req = new HttpRequest('GET', endpoint);
    return this.http.request(req);
  }


  AutoComplete(data:any) {
    let pvalue='?Merchant='+data.Merchant+'&AgentCode='+data.AgentCode+'&Mode='+data.Mode+'&LoggedInUserType='+data.LoggedInUserType+'&LoggedInAgentCode='+data.LoggedInAgentCode
    // const req = new HttpRequest('GET', environment.Api_urladmin+'AutoComplete'+pvalue);
    return  this.http.get(environment.Api_urlmasters+'AutoComplete'+pvalue); 
  }

  AgentcodeBind() {
    return this.http.get(environment.Api_urlmasters + 'AutoCompleteNew');
  }



}
