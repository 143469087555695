<div class="container-fluid bg-white border-bottom px-4">
  <div class="row justify-content-between">
    <div class="col-xl-10 col-10 col-md-11">
      <div class="row align-items-center mobiletever">
        <div class="col-xl-2 col-10 textcenter col-md-2">
          <a class="navbar-brand" routerLink="/{{defaultService}}">
            <img width="100" src="{{logoUrl}}" class="img-fluid"></a>
        </div>
        <div class="col-xl-10 col-2 col-md-10">
          <nav class="navbar navbar-expand-lg navbar-light p-0 mobile-responsive">
            <button class="navbar-toggler desktopmenu" type="button" data-toggle="collapse"
              data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse desktopmenu" id="navbarNavDropdown">
              <ul class="navbar-nav mx-auto">


                <li class="nav-item dropdown" *ngFor="let menu of menus">
                  <div class="myacchover">
                    <a *ngIf="menu.link ==null" class="nav-link dropdown-toggle" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false" id="navbarDropdownMenuLink">
                      <i class="fa {{menu.icon}}" aria-hidden="true"></i>
                      {{menu.name}}
                    </a>
                    <a *ngIf="menu.link !=null" class="nav-link dropdown-toggle" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false" id="navbarDropdownMenuLink" routerLink="{{menu.link}}">
                      <i class="fa {{menu.icon}}" aria-hidden="true"></i>
                      {{menu.name}}
                    </a>
                    <div class="dropdown-menu mainemanudrop dropdown-menu-right mr-4 "
                      aria-labelledby="navbarDropdownMenuLink mt-2">
                      <div class="admintoggelsbmenu">
                        <ul class="sbmenu">
                          <li *ngFor="let menuItem of menu.menuItems">
                            <a *ngIf="menuItem.linkType == 0" routerLink='{{menuItem.url}}'><i
                                class="fa fa-chevron-circle-right" aria-hidden="true"></i>
                              {{menuItem.name}}</a>
                            <a *ngIf="menuItem.linkType == 2" (click)="this[menuItem.url]()"><i
                                class="fa fa-chevron-circle-right" aria-hidden="true"></i>
                              {{menuItem.name}}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>

              </ul>
            </div>

            <div class="mobimeneu">
              <button class="mobileMenuToggle"><i class="fa fa-bars" aria-hidden="true"></i></button>
            </div>
            <div class="mobimeneucontent">
              <div class="mobilemenuclose">
                <button id="closeMobileMenu"><i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <div class="MmenuContent ">
                <ul class="navbar-nav mr-auto">
                  <li class="nav-item dropdown" *ngFor="let menu of menus">
                    <div class="myacchover">
                      <a class="nav-link "><i class="fa {{menu.icon}}" aria-hidden="true"></i> {{menu.name}}</a>

                      <div class="dropdown-menu mainemanudrop dropdown-menu-right mr-4 "
                        aria-labelledby="navbarDropdownMenuLink mt-2">
                        <div class="admintoggelsbmenu">
                          <ul class="sbmenu">
                            <li *ngFor="let menuItem of menu.menuItems">
                              <div class="mainemenu-sbdrop">
                                <a routerLink='{{menuItem.url}}'><i class="fa fa-chevron-circle-right" aria-hidden="true"></i>{{menuItem.name}}</a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div class="col-xl-2 col-2  col-md-1 textright">
      <div class="dropdown mobilewidth">
        <div class="myacchover backgroundnone">
          <a class="nav-link myaccount flexitems dropdown-toggle" id="myaccount" aria-haspopup="true"
            aria-expanded="false">
            <mat-icon>account_box</mat-icon>

            <p class="mobileusername">{{_Responsedata.loginData[0].agencyName}}</p>

          </a>
          <div class="dropdown-menu dropmenu dropdown-menu-right" aria-labelledby="myaccount">
            <div class="userdetails">
              <div class="usernamecl">{{_Responsedata.loginData[0].firstName}} ({{_Responsedata.loginData[0].identifier}})</div>
              <!-- <div class="usertypecl">{{_Responsedata.loginData[0].identifier}}</div> -->
            </div>
            <div class="admintoggelsbmenu">
              <a (click)="showProfile()"><i class="fa fa-user" aria-hidden="true"></i> My Profile</a>
              <a (click)="Funlogout()"><i class="fa fa-sign-out" aria-hidden="true"></i> Log Out</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-navigation></app-navigation>



<!-- <form #aepsform action="https://aeps.{{MERCHANT_DOMAIN}}" method="post" target="_self">
  <input type="hidden" name="params" value="{{AEPS_DATA}}">
</form>

<form #rbpform action="https://aeps.{{MERCHANT_DOMAIN}}" method="post" target="_self">
  <input type="hidden" name="params" value="{{RBP_DATA}}">
</form> -->