<div class="container-fluid customehigt bg-light">
  <div class="container-fluid">

    <div class="row  py-2">

      <div class="depositrequest">
        <h3>Commission Creation</h3>

        <form [formGroup]="Categorylistgroup">
          <div class="row justify-content-between">
            <div class="col-xl-8">
              <div class="row">
                <div class="col-xl-6">
                  <mat-form-field>
                    <mat-label>Select Product</mat-label>
                    <mat-select disableRipple #Product (selectionChange)='onChangeProductdataSelected($event)'
                      formControlName="Product">
                      <mat-option *ngFor="let binddata of _ProductBind" [value]="binddata.productName">
                        {{binddata.productName}}</mat-option>

                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-xl-6">
                  <mat-form-field>
                    <mat-label>Category</mat-label>
                    <!-- <mat-select disableRipple >
                      <mat-option value="">All</mat-option> -->
                    <mat-select disableRipple #Product formControlName="Category">
                      <mat-option *ngFor="let binddata of _CategoryLisdBind"
                        [value]="binddata.category">{{binddata.category}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="col-xl-4">
              <div class="buttnclass">
                <button class="btn-block btnprimary mr-3" [disabled]="!this.Categorylistgroup.valid" mat-raised-button
                  (click)="fetchCommissionRules()">Search</button>
                <button class="btn-block btnprimary mr-3 mt-0" mat-raised-button
                  (click)="fun_CreatecatDiv()">Add</button>
                <button mat-raised-button color="primary" class="downloadexal" data-toggle="tooltip"
                  data-placement="top" title="Export as Excel" (click)="ExportTOExcel()"><i class="fa fa-download"
                    aria-hidden="true"></i></button>
              </div>
            </div>
          </div>
        </form>

        <div class="col-xl-12">
          <form class="form-group" [formGroup]="CommissionCreationFormGroup" *ngIf="CreateCommDiv">
            <div class="row justify-content-between customerowdiv">
              <div class="col-xl-2">
                <mat-form-field>
                  <mat-label>Select Product</mat-label>
                  <mat-select disableRipple #ProductAdd (selectionChange)='onChangeProductdataSelectedAdd($event)'
                    formControlName="Product">
                    <mat-option *ngFor="let binddata of _ProductBind" [value]="binddata.productName">
                      {{binddata.productName}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-2">
                <mat-form-field>
                  <mat-label>Category</mat-label>
                  <mat-select formControlName="Category" name="category_check"
                    (selectionChange)="ChangeAction(category_check)" [(ngModel)]="category_check">
                    <mat-option *ngFor="let binddata of _CategoryLisdBindAdd" [value]="binddata.category">
                      {{binddata.category}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-2">
                <mat-form-field>
                  <mat-label>{{userType}}(%)</mat-label>
                  <input id="txtAgent" appAppTwoDigitDecimaNumber maxlength="5" formControlName="Agent" matInput
                    placeholder="Enter {{userType}} (%)" autocomplete="off">
                </mat-form-field>
                <p class="text-danger mt-2 text-center font-weight-bold " *ngIf="AgentValueCheck">Amount should not be
                  greater than 30% </p>
              </div>
              <div class="col-xl-2" *ngIf="Distibutordiv">
                <mat-form-field>
                  <mat-label>Distributor (%)</mat-label>
                  <input id="txtDistributor" maxlength="5" appAppTwoDigitDecimaNumber formControlName="Distributor"
                    matInput placeholder="Enter Distributor (%)" autocomplete="off">


                </mat-form-field>
                <p class="text-danger mt-2 text-center font-weight-bold " *ngIf="DistributorValueCheck">Amount should
                  not be greater than 70% </p>

                <p class="text-danger mt-2 text-center font-weight-bold text-capitalize" *ngIf="DistibutorMissingValue">
                  Please enter Distributor Commission </p>

              </div>
              <div class="col-xl-2">

                <button style="width: 100%;" class="btn-block btnprimary" mat-raised-button
                  [disabled]="!this.CommissionCreationFormGroup.valid" (click)="fun_Createcateogry()">Create</button>

              </div>
            </div>

            <p class="text-danger mt-2 text-center font-weight-bold text-capitalize" *ngIf="Showmessage">
              {{_CreateCommissionResponse.statusMessage}}</p>


          </form>
        </div>

      </div>

    </div>

    <div class="row mb-5" #TABLE>
      <div class="col-xl-12 p-0 table-responsive">
        <table class="table table-hover table-list ">
          <thead>
            <tr>
              <td>S.No</td>
              <td>Category Name </td>
              <td>IsDirect</td>
              <td>{{userType}} (%)</td>
              <td>Distributor (%)</td>
              <td>Product</td>

              <td>Created Date</td>
              <td>Updated Date</td>
              <td>Update</td>
              <td>Delete</td>
            </tr>
          </thead>

          <tbody [formGroup]="Categorylistgroup" *ngIf="Showmessagelist">
            <tr *ngFor="let data of _CategoryListResponse.commisonListData;let i = index">
              <td>{{i+1}}</td>
              <td #CategoryName>{{data.category}}</td>
              <td #Categorytype>{{data.isDirect}}</td>
              <td #AgentCom>{{data.agent}}</td>

              <td #DistributorCom>{{data.distributor}}</td>
              <td #productbind>{{data.product}}</td>
              <td>{{data.createdDate |date : "dd/MM/yyyy h:mm:ss a"}}</td>
              <td>{{data.updatedDate |date : "dd/MM/yyyy h:mm:ss a"}}</td>
              <td><button class="btn-success" mat-raised-button
                  (click)="fun_UpadteCat(CategoryName,AgentCom,DistributorCom,productbind,Categorytype)"><i
                    class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button></td>
              <td><button class=" btn-danger" data-toggle="tooltip" data-placement="top" title="Delete"
                  mat-raised-button (click)="deleteCommissionRule(data.category, data.product)"><i class="fa fa-trash"
                    aria-hidden="true"></i>
                </button></td>

              <!-- //statusMessage -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>


</div>
<!-- delete commission -->

<!-- Delete Commission -->
<!-- <div [formGroup]="CommissionUpadtegroup"  class="modal fade" id="slip" tabindex="-1" role="dialog" aria-labelledby="slip" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title my-auto">Update Commission</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="col-xl-12">
            
            <mat-form-field >
                <mat-label> Praduct</mat-label>
                <input matInput  formControlName="Product" readonly [(ngModel)]="ProductBind"  #updaateCategory>
           </mat-form-field>
               </div>

               <div class="col-xl-12">
            
                <mat-form-field >
                    <mat-label> Category</mat-label>
                    <input matInput  formControlName="Category"  [(ngModel)]="CategoryBind"  #updaateCategory>
               </mat-form-field>
                   </div>
               <div class="col-xl-12">
                <mat-form-field >
                    <mat-label> Agent Commission</mat-label>
                    <input id="txt_updateAgent"  (keypress)="isNumbertxt_updateAgent($event)"   formControlName="Agent" [(ngModel)]="AgentBind" matInput maxlength="10"   #updaateAgentCom>
               </mat-form-field>
                   </div>
            <div class="col-xl-12">
                <mat-form-field >
                    <mat-label> Distributor Commission </mat-label>
                    <input id="txt_updateDistributor"  (keypress)="isNumbertxt_updateDistributor($event)"   formControlName="Distributor" [(ngModel)]="DistributorBind" matInput  maxlength="10" #updaateDistributorCom>
               </mat-form-field>
                   </div>
                
       <button class="slipbtn" mat-raised-button class="slipbtn"  (click)="fun_UpdateCom()" >Update</button>
    
        </div>
        
         <p class="text-danger mt-2 text-center font-weight-bold text-capitalize" *ngIf="ShowmessageUpdate">{{_UpdateCatResponse.statusMessage}}</p>   
      </div>
    </div>
  </div> -->
<!-- Update Commission -->
<div class="agentdetailsbox" [formGroup]="CommissionUpadtegroup" id="CommissionUpadtegroup" *ngIf="commisionupdate">
  <div class="innerboxagntde morebox">
    <div class="closebtn" (click)="closeagentdetailsbtn()">
      <img src="../../../assets/images/icons/close.svg" alt="">
    </div>
    <h5>Update Commission</h5>
    <div class="row justify-content-between">
      <div class="col-xl-12">

        <mat-form-field>
          <mat-label> Product</mat-label>
          <input matInput formControlName="Product" readonly [(ngModel)]="ProductBind" #updaateCategory>
        </mat-form-field>
      </div>

      <div class="col-xl-12">

        <mat-form-field>
          <mat-label> Category</mat-label>
          <input matInput readonly formControlName="Category" [(ngModel)]="CategoryBind" #updaateCategory>
        </mat-form-field>
      </div>
      <div class="col-xl-12">
        <mat-form-field>
          <mat-label> {{userType}} Commission</mat-label>
          <input id="txt_updateAgent" appAppTwoDigitDecimaNumber formControlName="Agent" [(ngModel)]="AgentBind"
            matInput maxlength="5" #updaateAgentCom>
        </mat-form-field>
        <p class="text-danger mt-2 text-center font-weight-bold " *ngIf="AgentValueCheckUpdate">Amount should not be
          greater than 80% </p>

      </div>
      <div class="col-xl-12" *ngIf="CategorytypeDistributorCom">
        <mat-form-field>
          <mat-label> Distributor Commission </mat-label>
          <input id="txt_updateDistributor" appAppTwoDigitDecimaNumber formControlName="Distributor"
            [(ngModel)]="DistributorBind" matInput maxlength="5" #updaateDistributorCom>
        </mat-form-field>
        <p class="text-danger mt-2 text-center font-weight-bold " *ngIf="AgentValueCheckUpdate">Amount should not be
          greater than 20% </p>

      </div>


      <div class="col-xl-12 text-right">
        <button class="slipbtn" mat-raised-button class="slipbtn" (click)="fun_UpdateCom()">Update</button>
        <!-- <p class="text-danger mt-2 text-center font-weight-bold text-capitalize" *ngIf="ShowmessageUpdate">{{_UpdateCatResponse.statusMessage}}</p>    -->
      </div>
    </div>

  </div>
</div>
<!-- Update Commission -->

<!-- <div [formGroup]="CategoryDeletegroup"  class="modal fade" id="slipDelete" tabindex="-1" role="dialog" aria-labelledby="slip" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title my-auto">Delete Commission</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          
          <div class="col-xl-12" hidden> 
            <mat-form-field >
                <mat-label> Product</mat-label>
                <input matInput readonly formControlName="Product"  [(ngModel)]="DelProductBind"  >
           </mat-form-field>
               </div>
          <div class="col-xl-12">
            <mat-form-field >
                <mat-label> Category</mat-label>
                <input matInput readonly formControlName="Category"  [(ngModel)]="CategoryBind" >
           </mat-form-field>
               </div>
             
            <div>
                
       <button class="slipbtn" mat-raised-button class="slipbtn"  (click)="fun_DeleteCom()" >Delete</button>
    
        </div>
        
         <p class="text-danger mt-2 text-center font-weight-bold text-capitalize"  *ngIf="Showmessagedelete">{{_DeleteListResponse.statusMessage}}</p>   
      </div>
    </div>
  </div>
</div> -->

<div class="agentdetailsbox" [formGroup]="CommissionCreationFormGroup" *ngIf="addcommission">
  <div class="innerboxagntde morebox">
    <div class="closebtn" (click)="closeagentdetailsbtn()">
      <img src="../../../assets/images/icons/close.svg" alt="">
    </div>
    <h5>Create Commission Rule</h5>
    <div class="row justify-content-center customerowdiv">
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Select Product</mat-label>
          <mat-select disableRipple #ProductAdd (selectionChange)='onChangeProductdataSelectedAdd($event)'
            formControlName="Product">
            <mat-option *ngFor="let binddata of _ProductBind" [value]="binddata.productName">
              {{binddata.productName}}</mat-option>

          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Category</mat-label>
          <mat-select formControlName="Category" name="category_check" (selectionChange)="ChangeAction(category_check)"
            [(ngModel)]="category_check">
            <mat-option *ngFor="let binddata of _CategoryLisdBindAdd" [value]="binddata.category">
              {{binddata.category}}</mat-option>
          </mat-select>
        </mat-form-field>

      </div>
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>{{userType}} (%)</mat-label>
          <input id="txtAgent" appAppTwoDigitDecimaNumber maxlength="5" formControlName="Agent" matInput
            placeholder="Enter {{userType}} (%)" autocomplete="off">
        </mat-form-field>
        <p class="text-danger mt-2 text-center font-weight-bold " *ngIf="AgentValueCheck">Amount should not be greater
          than 80% </p>
      </div>
      <div class="col-md-3" *ngIf="Distibutordiv">
        <mat-form-field>
          <mat-label>Distributor (%)</mat-label>
          <input id="txtDistributor" maxlength="5" appAppTwoDigitDecimaNumber formControlName="Distributor" matInput
            placeholder="Enter Distributor (%)" autocomplete="off">
        </mat-form-field>
        <p class="text-danger mt-2 text-center font-weight-bold " *ngIf="DistributorValueCheck">Amount should not be
          greater than 20% </p>
        <p class="text-danger mt-2 text-center font-weight-bold text-capitalize" *ngIf="DistibutorMissingValue">Please
          enter Distributor Commission </p>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Cutover Amount</mat-label>
          <input id="txtAgent" appAppTwoDigitDecimaNumber maxlength="5" formControlName="Cutover" matInput
            placeholder="Enter Cutover Amount" autocomplete="off">
        </mat-form-field>




      </div>
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>{{userType}}(%)</mat-label>
          <input id="txtAgent" appAppTwoDigitDecimaNumber maxlength="5" formControlName="Agent" matInput
            placeholder="Enter {{userType}} (%)" autocomplete="off">
        </mat-form-field>
        <p class="text-danger mt-2 text-center font-weight-bold " *ngIf="AgentValueCheck">Amount should not be greater
          than 10% </p>
      </div>
      <div class="col-md-3" *ngIf="Distibutordiv">
        <mat-form-field>
          <mat-label>Distributor (%)</mat-label>
          <input id="txtDistributor" maxlength="5" appAppTwoDigitDecimaNumber formControlName="Distributor" matInput
            placeholder="Enter Distributor (%)" autocomplete="off">
        </mat-form-field>
        <p class="text-danger mt-2 text-center font-weight-bold " *ngIf="DistributorValueCheck">Amount should not be
          greater than 10% </p>

        <p class="text-danger mt-2 text-center font-weight-bold text-capitalize" *ngIf="DistibutorMissingValue">Please
          enter Distributor Commission </p>

      </div>
      <div class="col-md-3">

        <button style="width: 100%;" class="btn-block btnprimary" mat-raised-button
          [disabled]="!this.CommissionCreationFormGroup.valid" (click)="fun_Createcateogry()">Create</button>

      </div>
    </div>

    <p class="text-danger mt-2 text-center font-weight-bold text-capitalize" *ngIf="Showmessage">
      {{_CreateCommissionResponse.statusMessage}}</p>

  </div>
</div>
<!-- <script src="../../../assets/Js/Validations.js"></script> -->

<div class="loader-maine" *ngIf="loaderhide">
  <div class="loader">
    <!--<div class="loader-inner">cvxcv</div>-->
    <div class="loader-inner"></div>
    <div class="loader-inner box"></div>
    <div class="box-1"></div>
    <div class="box-2"></div>
    <div class="box-3"></div>
  </div>
</div>