<p class="m-0"></p>
<!-- <p><strong>{{idleState}}</strong></p> -->

<div *ngIf="headershow; else Loginheadershow">
  <app-header></app-header>
</div>

<ng-template #Loginheadershow>
  <loginheader></loginheader>
  <!-- <app-navigation></app-navigation> -->
</ng-template>

<!-- <span id="toTopLink" >Scroll to Top</span> -->

<router-outlet></router-outlet>
<app-footer></app-footer>