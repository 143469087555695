import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  })
};


@Injectable({
  providedIn: 'root'
})

export class DmtService {

  constructor(private httpClient: HttpClient) { }


  CreateCommission(Fulldata: any) {
    return this.httpClient.post<any>(environment.Api_urldmt + 'CreateCommission', Fulldata, httpOptions);
  }

  DeleteCommission(Fulldata: any) {
    return this.httpClient.post<any>(environment.Api_urldmt + 'DeleteCommission', Fulldata, httpOptions);
  }

  UpdateCommission(Fulldata: any) {
    return this.httpClient.post<any>(environment.Api_urldmt + 'UpdateCommission', Fulldata, httpOptions);
  }

  fetchRemitterDetail(data: any) {
    return this.httpClient.post<any>(environment.Api_urldmt + 'RemitterDetail', data, httpOptions);
  }


  FunRemitterRegistration(Fulldata: any, useMockService) {
    if (!useMockService) {
      return this.httpClient.post<any>(environment.Api_urldmt + 'RemitterRegistration', Fulldata, httpOptions);
    }
    else {
      const simpleObservable = new Observable((observer) => {
        // observable execution
        observer.next({ statusCode: 0, statusMessage: 'Transaction Successful', data: [{ verifiedStatus: 1, remiiterId: 7581689 }] });
        observer.complete();
      });
      return simpleObservable;
    }
  }

  FunRemitterValidate(Fulldata: any, useMockService) {
    if (!useMockService) {
      return this.httpClient.post<any>(environment.Api_urldmt + 'RemitterValidate', Fulldata, httpOptions);
    }
    else {
      const simpleObservable = new Observable((observer) => {
        // observable execution
        observer.next({ statusCode: 0, statusMessage: 'Transaction Successful', data: [{ verifiedStatus: 1, remiiterId: 7581689 }] });
        observer.complete();
      });
      return simpleObservable;
    }
  }

  AddBeneficiary(Fulldata: any) {
    if (environment.DMT_DUMMY_RESPONSE) {
      return Observable.create(observer => {
        const data = { success: true, data: { otpReference: "4c74496f645931502f473368712f5646513264715455794e51667152564d68554f7a78496c714e5154484c79644336314f2b6777345042324b4c3736504e4f345267714f3454756d77675a416d7966636b77746f2f6e646c41715443667970434762613155476c7845396d6b4d6c33486365774c724c6878317051427035476361425638773367563546533775366635696966556d773d3d" } }
        observer.next(data);
        observer.complete()
      });
    }
    else {
      return this.httpClient.post<any>(environment.Api_urldmt + 'beneficiary', Fulldata, httpOptions);
    }
  }


  OTPValidate(data) {
    if (environment.DMT_DUMMY_RESPONSE) {
      return Observable.create(observer => {
        const data = { statusCode: 0, statusMessage: "Beneficiary registered successfully" }
        observer.next(data);
        observer.complete()
      });
    }
    else {
      return this.httpClient.post<any>(environment.Api_urldmt + 'beneficiary/BenificiaryValidate', data, httpOptions);
    }
  }

  // FunBenificiaryValidate(Fulldata:any){
  //
  //   return  this.httpClient.post<any>(environment.Api_urldmt+'BenificiaryValidate',Fulldata,httpoptons)
  //   }

  FunBeneficiaryResendOtp(Fulldata: any) {

    return this.httpClient.post<any>(environment.Api_urldmt + 'BeneficiaryResendOtp', Fulldata, httpOptions);
  }

  deleteBeneficiary(data: any) {
    return this.httpClient.post<any>(environment.Api_urldmt + `deletebeneficiary`, data);
  }

  FunBeniDeleteValidate(Fulldata: any) {

    return this.httpClient.post<any>(environment.Api_urldmt + 'BeniDeleteValidate', Fulldata, httpOptions);
  }

  FunTransferf(Fulldata: any, useMockService) {
    if (!useMockService) {
      return this.httpClient.post<any>(environment.Api_urldmt + 'Transfer', Fulldata, httpOptions);
    }
    else {
      const simpleObservable = new Observable((observer) => {
        // observable execution
        observer.next({ statusCode: 5, statusMessage: 'Partial Success', response: [{ ipayReferenceId: null, ipayRefNumber: null, ipayOprId: null, transactionId: 'D140906MCC0MT0A7', amount: 5000.0, status: 'Transaction Under Process', updatedBy: null, customerCharge: 50.0 }, { ipayReferenceId: '1200914130050SFAMJ', ipayRefNumber: '025813797820', ipayOprId: '025813797820', transactionId: 'D140906MCC1MT0A7', amount: 5000.0, status: 'Transaction Successful', updatedBy: null, customerCharge: 50.0 }, { ipayReferenceId: null, ipayRefNumber: null, ipayOprId: null, transactionId: 'D140906MCC2MT0A7', amount: 5000.0, status: 'Insufficient Wallet Balance', updatedBy: null, customerCharge: 50.0 }, { ipayReferenceId: null, ipayRefNumber: null, ipayOprId: null, transactionId: 'D140906MCC3MT0A7', amount: 5000.0, status: 'Insufficient Wallet Balance', updatedBy: null, customerCharge: 50.0 }], accountDetail: [{ benificieryName: 'RAVI PRATAP VERMA S', mobileNumber: '6006011328', transactionType: 'IMPS', accountNumber: '1857001500028494', bankName: 'PUNJAB NATIONAL BANK', agencyName: 'avish communiction', agentCode: 'MT0A7' }] });
        // observer.next({"statusCode":0,"statusMessage":"Transaction Successful","response":[{"ipayReferenceId":"1200914154304JEXUH","ipayRefNumber":"025815952091","ipayOprId":"025815952091","transactionId":"D14090RO3G0MT0A4","amount":5000.0,"status":"Insufficient Wallet Balance","updatedBy":null,"customerCharge":50.0}],"accountDetail":[{"benificieryName":"Miss Anar Kali","mobileNumber":"9682327609","transactionType":"IMPS","accountNumber":"59162664095","bankName":"ALLAHABAD BANK","agencyName":"MetSavings Pvt Ltd","agentCode":"MT0A4"}]})
        observer.complete();
      });
      return simpleObservable;
    }
  }

  BankList() {

    // return this.httpClient.get(environment.Api_urldmt + 'GetBanks');
    return this.httpClient.get(environment.Api_urldmt + 'bank');
  }

  DmtTransactionList(data: any) {
    const Fdate = new Date(data.FromDate);
    const Tdate = new Date(data.Todate);
    const F = this.FormatDate(Fdate);
    const T = this.FormatDate(Tdate);
    const pvalue1 = '?FromDate=' + F + '&Todate=' + T + '&LoggedInUserType=' + data.LoggedInUserType + '&Merchant=' + data.Merchant + '&AgentCode=' + data.AgentCode;
    const pvalue = '?FromDate=' + F + '&Todate=' + T + '&TransactionId=' + data.TransactionId + '&LoggedInUserType=' + data.LoggedInUserType + '&AgentCode=' + data.AgentCode + '&TxnDescription=' + data.TxnDescription + '&ApiTransactionId=' + data.ApiTransactionId + '&Merchant=' + data.Merchant + '&Mode=' + data.Mode + '&LoggedAgentCode=' + data.LoggedAgentCode + '&RemitterMobile=' + data.RemitterMobile;
    // AgentCode=&TxnDescription=&ApiTransactionId=&LoggedInUserType=OO&Merchant=MET&Mode=WEB&LoggedAgentCode=
    return this.httpClient.get(environment.Api_urldmt + 'TransactionList' + pvalue);
  }

  TransactionReciept(data: any) {

    const pvalue = '?TransactionId=' + data.TransactionId + '&Merchant=' + data.Merchant + '&Mode=' + data.Mode + '&LoggedInUserType=' + data.LoggedInUserType + '&LoggedInAgentCode=' + data.LoggedInAgentCode;                                                                                              // AgentCode=&TxnDescription=&ApiTransactionId=&LoggedInUserType=OO&Merchant=MET&Mode=WEB&LoggedAgentCode=
    return this.httpClient.get(environment.Api_urldmt + 'TransactionReciept' + pvalue);
  }

  AccountValidaton(Fulldata: any) {

    return this.httpClient.post<any>(environment.Api_urldmt + 'AccountValidaton', Fulldata, httpOptions);
  }

  StatusCheck(Fulldata: any) {

    return this.httpClient.post<any>(environment.Api_urldmt + 'StatusCheck', Fulldata, httpOptions);
  }

  FormatDate(date: Date): string {

    const day = date.getDate();
    const months = date.getMonth() + 1;
    const year = date.getFullYear();
    let day1;
    let months1;

    if (day < 10) {
      day1 = `0${day}`;
    } else { day1 = `${day}`; }

    if (months < 10) {
      months1 = `0${months}`;
    } else { months1 = `${months}`; }
    return `${year}-${months1}-${day1}`;
  }

  GetCommissionList(data: any) {
    const pvalue = '?Category=' + data.Category + '&Product=' + data.Product;
    return this.httpClient.get(environment.Api_urldmt + 'GetCommissionList' + pvalue);

  }

}
