import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/Services/auth.service';

@Component({
  selector: 'app-recharge',
  templateUrl: './recharge.component.html',
  styleUrls: ['./recharge.component.css']
})
export class RechargeComponent implements OnInit {
  transectionSuccess:boolean = false
  constructor(private _AuthAgentService: AuthService,) { }

  ngOnInit(): void {
    this._AuthAgentService.CheckSesstion();



  }
  Pay(){
    this.transectionSuccess = true
  }
  backhome(){
    this.transectionSuccess = false

  }
}
