<!-- <div class="row outerlogin align-items-center justify-content-center"> -->
<div class="row m-auto container">
    <!-- <div class="col-xl-5 p-0 text-center"> -->
    <div class="servicebox p-3 col-xl-5" *ngIf="showOfferings">
        <div class="servibx-inn">
            <div class="serviceimg">
                <img src="../../assets/images/icons/business.svg" class="img-fluid">
            </div>
            <div class="servicetxt">
                <h3>Become a Travel Agent</h3>
                <p>Get all online services under single platform, Flight Booking/Hotel Booking/Money
                    Transfer.</p>
            </div>

        </div>
        <div class="servibx-inn">
            <div class="serviceimg">
                <img src="../../assets/images/icons/interface.svg" class="img-fluid">
            </div>
            <div class="servicetxt">
                <h3>Travel Booking API</h3>
                <p>Get your api for your application at very cheap price. Flight Booking API/Hotel
                    Booking API/Money Transfer API.</p>
            </div>

        </div>
        <div class="servibx-inn m-0">
            <div class="serviceimg">
                <img src="../../assets/images/icons/online.svg" class="img-fluid">
            </div>
            <div class="servicetxt">
                <h3>White Labelled Solution</h3>
                <p>Get your agency online with in 10 min only with Flight Booking/Hotel Booking/Money
                    Transfer/IRCTC Booking.</p>
            </div>

        </div>

    </div>
    <div class="loginbox col-xl-5 p-2 text-center">
        <h2>Login</h2>
        <p>Welcome Back !</p>
        <p>Did you <a style="cursor: hand" (click)="opanDialog('FORGOTPASSWORD', 'Forgot Password')">Forget your
                Password?</a></p>
        <form class="example-form" [formGroup]="_logingroup">
            <mat-form-field>
                <mat-label>Enter Email or Phone No.</mat-label>
                <input matInput formControlName="Email" #email>
                <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Enter your password</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="Password" required>
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field *ngIf="!IsProduction">
                <mat-label>Domain</mat-label>
                <select matNativeControl required formControlName="Domain" #domain
                    (change)='onDomainChanged(domain.value)'>
                    <option value="metsavings.in">metsavings.in</option>
                    <option value="b2b.flyholidays.in">b2b.flyholidays.in</option>
                    <option value="admin.metsavings.in">admin.metsavings.in</option>
                    <option value="wldemo.metsavings.in">wldemo.metsavings.in</option>
                </select>
            </mat-form-field>
            <button mat-raised-button type="submit" class="btnprimary" (click)="loginUser()"
                [disabled]="!this._logingroup.valid">Login</button>

            <p class="alert-danger" *ngIf="_Show">{{loginResponseJSON.statusMessage}}</p>

        </form>
    </div>

<!-- 
<form #form ngNoForm
  id="nonseamless" 
  method="post" 
  name="redirect" 
  action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
    <input type="text" id="encRequest" name="encRequest">
    <input type="hidden" name="access_code" id="access_code" value="AVOQ42KL24BN35QONB">
</form>

<button class="btnprimary" mat-raised-button cdkFocusInitial (click)="processPayment()">Continue</button> -->

    <!-- </div> -->
    <!-- </div> -->
    <!-- </div> -->