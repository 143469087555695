<div class="container-fluid contactbg">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 text-center">
                <h1>Contact Us</h1>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid bg-light">
    <div class="container">
        <div class="row flex-row-reverse ">
            <div class="col-xl-4 my-5">
                <div class="contactbox">
                    <h2>Enquire Now!</h2>
                 <form action="">
                     <mat-form-field>
                     <mat-label>Enter User Name</mat-label>
                     <input matInput>
                     <!-- <mat-error *ngIf="email.invalid"></mat-error> -->
                     </mat-form-field>
                     <mat-form-field>
                     <mat-label>Enter Email ID</mat-label>
                     <input matInput>
                     <!-- <mat-error *ngIf="email.invalid"></mat-error> -->
                     </mat-form-field>
                     <mat-form-field>
                     <mat-label>Enter Phone No.</mat-label>
                     <input matInput>
                     <!-- <mat-error *ngIf="email.invalid"></mat-error> -->
                     </mat-form-field>
                     <mat-form-field class="example-full-width">
                         <mat-label>Leave a comment</mat-label>
                         <textarea rows="4" matInput placeholder="Ex. It makes me feel..."></textarea>
                       </mat-form-field>
                       <button mat-button disabled type="submit" class="btn-block btnprimary" >Continue</button>
                 </form>
                </div>
             </div>
            <div class="col-xl-8 my-auto">
                <div class="row">
                    <div class="col-xl-6 address text-center">
                        <img src="../../assets/images/icons/map.svg">
                        <h1>Canada Office</h1>
                        <p>7100 Woodbine Ave., Suite 408 <br>

                            Markham, ON L3R 5J2 Canada</p>
                    </div>
                    <div class="col-xl-6 address text-center">
                        <img src="../../assets/images/icons/map.svg">
                        <h1>Delhi Office </h1>
                        <p>B4/5 Keshav Puram, <br> Near Metro Station New Delhi-India</p>
                    </div>
                    <div class="col-xl-6 address text-center">
                        <img src="../../assets/images/icons/map.svg">
                        <h1>Jammu Office</h1>
                        <p>G-31, B C Road <br> Trikuta Complex Jammu-India</p>
                    </div>
                    <div class="col-xl-6 address text-center">
                        <img src="../../assets/images/icons/map.svg">
                        <h1>Jaipur Office </h1>
                        <p>E-101, Shivam Company, <br> Mansarovar Park Jaipur-India</p>
                    </div>

                </div>
            </div>
           
        </div>
    </div>
</div>
