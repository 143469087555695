import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AepsService {

  constructor(private httpClient: HttpClient,
    private datepipe: DatePipe) { }

  fetchIPayRedirectionData(product: string) {
    return this.httpClient.get(environment.API_BASE_URL + `/api/${product}/data`);
  }

  
}
