import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { CategoryService } from 'src/app/Services/category.service';
import { AuthService } from 'src/app/Services/auth.service';

@Component({
  selector: 'app-categorylist',
  templateUrl: './categorylist.component.html',
  styleUrls: ['./categorylist.component.css']
})
export class CategorylistComponent implements OnInit {

  Categorylistgroup: FormGroup;
  Loginresponse;
  _Responsedata;
  Showmessage = false;
  _CategoryListResponse;
  _Usertype: any;
  ShowListdata = false;
  constructor(private _CategoryService: CategoryService, private _AuthAgentService: AuthService,) { }


  // Category=DELTA&Merchant=MET&LoggedInUserType=OO&LoggedInAgentCode=Mt0001
  ngOnInit(): void {
    this._AuthAgentService.CheckSesstion();
    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata = (JSON.parse(this.Loginresponse) as any);
    this._Usertype = this._Responsedata.loginData[0].userType;
    // if(this._Usertype =="OO" || this._Usertype =="OOU"){ this.ShowListdata=true; }
    if (this._Usertype == 'OO') { this.ShowListdata = true; }
    this.Categorylistgroup = new FormGroup({
      Category: new FormControl(''),
      // Mode : new FormControl('WEB'),
      Merchant: new FormControl(this._Responsedata.loginData[0].merchant),
      LoggedInUserType: new FormControl(this._Responsedata.loginData[0].userType),
      LoggedInAgentCode: new FormControl(this._Responsedata.loginData[0].identifier),

    });


    this.fun_GetCategory();

  }


  fun_GetCategory() {
    this._CategoryService.GetCategory(this.Categorylistgroup.value)
      .subscribe(
        TransactionList => {
          this._CategoryListResponse = TransactionList;
          console.log(this._CategoryListResponse);
          this.Showmessage = true;
        },
        (error) => {
          console.log(error);
        }

      );
  }
}
