<div class="container-fluid customehigt bg-light">
  <div class="container-fluid">
    <div class="dmttraseccont ">

      <div class="row py-2">
        <div class="depositrequest ">
          <h3>DMT Transaction List</h3>
          <form class="form-group" [formGroup]="DmtListForm">

            <div class="row">

              <div class="col-md-1">
                  <mat-form-field class="example-full-width">
                  <mat-label>From Date</mat-label>
                  <input formControlName="FromDate" matInput [matDatepicker]="pickerFromDate">
                  <mat-datepicker-toggle matSuffix [for]="pickerFromDate">

                  </mat-datepicker-toggle>
                  <mat-datepicker #pickerFromDate></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="col-md-1">

                <mat-form-field class="example-full-width">
                  <mat-label>To Date</mat-label>
                  <input formControlName="Todate" matInput [matDatepicker]="pickerTodate">
                  <mat-datepicker-toggle matSuffix [for]="pickerTodate">

                  </mat-datepicker-toggle>
                  <mat-datepicker #pickerTodate></mat-datepicker>
                </mat-form-field>

              </div>

              <div class="col-md-1">
                <mat-form-field>
                  <mat-label>Status</mat-label>
                  <mat-select disableRipple formControlName="TxnDescription">
                    <mat-option value="">All</mat-option>
                    <mat-option value="Success">Success</mat-option>
                    <mat-option value="Pending">Pending</mat-option>
                    <mat-option value="Failed">Failed</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
                            
              <div class="col-md-2">
                <mat-form-field>
                  <mat-label>Transaction ID</mat-label>
                  <input matInput placeholder="Enter ID" formControlName="TransactionId">
                </mat-form-field>
              </div>
              
              <div class="col-md-2" *ngIf="ShowListdata">
                <mat-form-field>
                  <mat-label>Api OrderID</mat-label>
                  <input matInput placeholder="Enter ID" formControlName="ApiTransactionId">
                </mat-form-field>
              </div>
              
              <div class="col-md-1">
                <mat-form-field>
                  <mat-label>Sender Mobile</mat-label>
                  <input matInput placeholder="Enter Mobile#" formControlName="RemitterMobile">
                </mat-form-field>
              </div>

              

              <div class="col-md-2" *ngIf="ShowListdata">
                <mat-form-field>
                  <mat-label>Agent Code/Name</mat-label>
                  <input (click)="resetAgentCode()" type="text" placeholder="Select Agent Code" aria-label="Number" matInput
                    formControlName="AgentCode" [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let binddata of filteredAgents | async" [value]="binddata.agentCodeAndName">
                      {{binddata.agentCodeAndName}}
                    </mat-option>
                  </mat-autocomplete>
                  <!-- <mat-select disableRipple #AgentCode formControlName="AgentCode">
                    <mat-option value="">Select</mat-option>
                    <mat-option *ngFor="let binddata of _AgentcodeBind.data"
                      [value]="binddata.agentCodeAndName.split('(')[1].split(')')[0]">{{binddata.agentCodeAndName}}
                    </mat-option>
                  </mat-select> -->
                </mat-form-field>
              </div>

              <div class="col-md-2 ml-auto">
                <div class="buttnclass">
                  <button class="btn-block btnprimary mr-3" mat-raised-button
                    (click)="Fun_DmtTransactionList()">Search</button>
                  <button mat-raised-button color="primary" class="downloadexal" data-toggle="tooltip"
                    data-placement="top" title="Export as Excel" (click)="ExportTOExcel()"><i class="fa fa-download"
                      aria-hidden="true"></i></button>
                </div>
                <!-- <p class="text-danger mt-2 text-center font-weight-bold" *ngIf="Showmessagetext">
                  {{_TransactionListResponse.statusMessage}}</p> -->
              </div>
              
            </div>
            <p class="text-danger mt-2 text-center font-weight-bold" *ngIf="StatusCheckmessage">
              Pending TransactionId--{{ this.StatusCheckForm.value.TransactionId}} --
              {{_TransactionListResponse.statusMessage}}</p>
          </form>
        </div>
      </div>
      <div class="row mb-5" #TABLE>
        <div class="col-xl-12 table-responsive p-0">
          <table class="table table-hover table-list ">
            <thead>
              <tr>
                <td>S.No</td>
                <td>Transaction ID </td>
                <td *ngIf="ShowListdata">Agency Name/ Code</td>
                <td>Sender Mobile</td>
                <td>Name</td>
                <td>Account Number</td>
                <td>Bank Name</td>
                <!-- <td>IFSC</td> -->
                <td>Txn Date</td>
                <td>Txn Status</td>
                <td>Txn Type</td>
                <td>Operator Id</td>
                <td>Net Amount</td>
                <td>Txn Amount</td>
                <td>Gross Amount</td>
                <!-- <td>Refund Amount</td> -->
                <!--<td>Benificery Id</td>-->
                <!--<ng-container *ngIf="User_TypeAdmin || User_TypeAgent">-->
                <td>Agent Comm</td>
                <!-- <td>Agent Gst</td> -->
                <td>Agent Tds</td>
                <!--</ng-container>-->
                <ng-container *ngIf="!User_TypeAgent">
                  <td>Dist Comm</td>
                  <!-- <td>Dist Gst</td> -->
                  <td>Dist Tds</td>
                </ng-container>
                <ng-container *ngIf="User_TypeAdmin">
                  <td>Api OrderID</td>
                  <td>Comm</td>
                  <!-- <td>Gst</td> -->
                  <td>Tds</td>
                  <!-- <td>Fee</td> -->
                  <td>Sup Fee</td>
                  <!--<td>Customer Fee</td>-->
                  <!-- <td>Sup GST</td> -->
                  <td>Sup TDS</td>
                  <td>Flag</td>
                </ng-container>
                <!--<td>Check Status</td>-->
              </tr>

            </thead>
            <tbody [formGroup]="DmtListForm" *ngIf="Showmessage">

              <tr *ngFor="let data of _TransactionListResponse.transactionlist;let i = index">
                <td>{{i+1}}</td>
                <td *ngIf="data.txnType != 'AccVal' ">
                  <p class="m-0" style="cursor: pointer; color: #ff0000;" (click)="fun_receipt(data.transactionId)">
                    {{data.transactionId}}</p>
                </td>
                <td *ngIf="data.txnType == 'AccVal' ">
                  <p class="m-0">
                    {{data.transactionId}}</p>
                </td>
                <td *ngIf="ShowListdata">{{data.agencyName}}/{{data.agentCode}}</td>
                <td>{{data.remitterMobile}}</td>
                <td>{{data.name}}</td>
                <td>{{data.accountNumber}}</td>
                <td>{{data.bankName}}</td>
                <!-- <td>{{data.ifscCode}}</td> -->
                <td>{{data.createdDate|date : "dd/MM/yyyy  h:mm:ss a"}}</td>
                <ng-container *ngIf="data.txnStatus=='Pending'; then hasName; else noName"></ng-container>
                <ng-template #hasName let-name>
                  <td>
                    <p class="m-0" style="cursor: pointer; color: #ff0000;"
                      (click)="fun_StatusCheck(data.transactionId,data.agentCode)">{{data.txnStatus}}</p>
                  </td>
                </ng-template>
                <ng-template #noName>
                  <td ><span class="{{data.txnStatus}}">{{data.txnStatus}}</span></td>
                </ng-template>
                <td>{{data.txnType}}</td>
                <td>{{data.apiTransactionId}}</td>
                <td>{{data.netAmount}}</td>
                <td>{{data.txnAmount}}</td>
                <td>{{data.grossAmount}}</td>
                <!-- <td>{{data.refundAmount}}</td> -->
                <!--<td>{{data.benificeryId}}</td> -->
                <!--<ng-container *ngIf="User_TypeAdmin || User_TypeAgent">-->
                <td>{{data.agentCommision}}</td>
                <!-- <td>{{data.agentGst}}</td> -->
                <td>{{data.agentTds}}</td>
                <!--</ng-container>-->
                <ng-container *ngIf="!User_TypeAgent">
                  <td>{{data.distributorCommision}}</td>
                  <!-- <td>{{data.distributorGst}}</td> -->
                  <td>{{data.distributorTds}}</td>
                </ng-container>
                <ng-container *ngIf="User_TypeAdmin">
                  <td>{{data.apiReferenceId}}</td>
                  <td>{{data.companyCommision}}</td>
                  <!-- <td>{{data.companyGst}}</td> -->
                  <td>{{data.companyTds}}</td>
                  <!-- <td>{{data.fee}}</td> -->
                  <td>{{data.supplierCharge}}</td>
                  <!--<td>{{data.customerFee}}</td>-->
                  <!-- <td>{{data.gst}}</td> -->
                  <td>{{data.supTds}}</td>
                  <td>{{data.isCashPO}}</td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- REceipt -->

<div class="agentdetailsbox" *ngIf="receiptmoney">
  <div class="innerboxagntde" >
    <div class="closebtn" (click)="closeagentdetailsbtn()">
      <img src="../../../assets/images/icons/close.svg" alt="">
    </div>
    
    <div class="receiptoverflow">
      <div class="row justify-content-between" id="printsection">
        <div class="col-xl-12"><h5 class="mb-0">Transaction Receipt</h5></div>
        <div class="col-xl-12">
          <div class="row justify-content-between align-items-center">
            <div class="col-md-6">
              <tr class="mt-2 text-center font-weight-bold" *ngFor="let accountdata of _TransactionRecieptResponse.accountDetail">
                <td><div class="printagentname">{{accountdata.agencyName}}</div></td>
              </tr>
            </div>
            <div class="col-md-6 text-right">
              <img src="../../../assets/images/logo/MET/logo.png">
            </div>
          </div>
          <table class="transaction-receipt mobileresosive1 transectionsdata mb-3" border="1">
            <thead>
              <tr>
                <td>Sender Mobile</td> 
                <td>Benificiery Name</td>
                <td>Account Number</td> 
                <td>Bank Name</td>              
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let accountdata of _TransactionRecieptResponse.accountDetail;let i = index">
                <td>{{accountdata.mobileNumber}}</td>
                <td>{{accountdata.benificieryName}}</td>
                <td>{{accountdata.accountNumber}}</td>
                <td>{{accountdata.bankName}}</td>
              </tr>
            </tbody>
          </table>
          <table class="transaction-receipt mobileresosive2 transectionsdata" border="1">
            <thead>
              <tr>
                <td>S.No</td>
                <td>Transaction Id</td>
                <!-- <td>Order Id</td> -->
                <td>Operator Id</td>
                <td>Txn Amount</td>
                <!-- <td>Charge</td> -->
                <td>Status</td>
                <td>TxnDate</td>
                            
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of _TransactionRecieptResponse.transactionDetail;let i = index">
                <td>{{i+1}}</td>
                <td>{{data.transactionId}}</td>
                <!-- <td>{{data.apiReferenceId}}</td> -->
                <td>{{data.apiTransactionId}} &nbsp;</td>
                <td>{{data.txnAmount}} </td>
                <!-- <td>{{data.customerCharge}}</td> -->
                <td>{{data.status}}</td>
                <td>{{data.txnDate|date : "dd/MM/yyyy  h:mm:ss a"}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-xl-3">
          <div class="recptbtnbx">
            <!-- <button class="backrecpt" mat-raised-button (click)="closeagentdetailsbtn()">Back</button> -->
            <button class="printrecpt" [useExistingCss]="true" mat-raised-button printSectionId="printsection"
              ngxPrint>Print</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Receipt -->


<div class="loader-maine" *ngIf="loaderhide">

  <div class="loader">
    <!--<div class="loader-inner">cvxcv</div>-->
    <div class="loader-inner"></div>
    <div class="loader-inner box"></div>
    <div class="box-1"></div>
    <div class="box-2"></div>
    <div class="box-3"></div>
  </div>

</div>