import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../app/material/material.module';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { HomeComponent } from './home/home.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { AuthService } from './Services/auth.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { StateCity } from '../app/Services/StateCity';
import { AuthGuard } from './auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginheaderComponent } from './loginheader/loginheader.component';
import { ProfileupdateComponent } from './profileupdate/profileupdate.component';
import { AgenActivationListComponent } from './agen-activation-list/agen-activation-list.component';
import { UserlistComponent } from './userlist/userlist.component';
import { NavigationComponent } from './navigation/navigation.component';
import { UtilitytransactionlistComponent } from './utilitytransactionlist/utilitytransactionlist.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TermsandconditionComponent } from './termsandcondition/termsandcondition.component';
import { DepositRequestComponent } from './Accounts/deposit-request/deposit-request.component';
import { DepositlistComponent } from './Accounts/depositlist/depositlist.component';
import { DepositupdatereportComponent } from './Accounts/depositupdatereport/depositupdatereport.component';
import { AccountsService } from './Services/accounts.service';
import { CreditrequestComponent } from './Accounts/creditrequest/creditrequest.component';
import { CreditlistComponent } from './Accounts/creditlist/creditlist.component';
import { CreditrequestlistComponent } from './Accounts/creditrequestlist/creditrequestlist.component';
import { MoneyTransferComponent } from './DMT/money-transfer/money-transfer.component';
import { DmtService } from './Services/dmt.service';
import { CashWithdrawalComponent } from './AEPS/cash-withdrawal/cash-withdrawal.component';
import { BalanceEnquiryComponent } from './AEPS/balance-enquiry/balance-enquiry.component';
import { RechargeComponent } from './utility/recharge/recharge.component';
import { AepsMenuComponent } from './AEPS/aeps-menu/aeps-menu.component';
import { DmtnavbarComponent } from './DMT/dmtnavbar/dmtnavbar.component';
import { CashoutRequestComponent } from './AEPS/cashout-request/cashout-request.component';
import { CashoutRequestHistoryComponent } from './AEPS/cashout-request-history/cashout-request-history.component';
import { AccountStatementComponent } from './List/account-statement/account-statement.component';
import { DmtTransectionListComponent } from './List/dmt-transection-list/dmt-transection-list.component';
import { AgentPaymentListComponent } from './List/agent-payment-list/agent-payment-list.component';
import { AddCreditComponent } from './List/add-credit/add-credit.component';
import { CategoryCreationComponent } from './List/category-creation/category-creation.component';
import { CommissionCreationComponent } from './List/commission-creation/CommissionCreationComponent';
import { CategoryAgentMappingComponent } from './List/category-agent-mapping/category-agent-mapping.component';
import { HashLocationStrategy, LocationStrategy, DatePipe } from '@angular/common';
import { CategorylistComponent } from './List/categorylist/categorylist.component';
import { AlphabetOnlyDirective } from './alphabet-only.directive';
import { NumberOnlyDirectiveDirective } from './number-only-directive.directive';
import { BnNgIdleService } from 'bn-ng-idle';
import { CommonService } from './Services/common.service';
import { NgxPrintModule } from 'ngx-print';
import { ErrorMsgComponent } from './error-msg/error-msg.component';

// Import your library
import { AlertModule } from 'ngx-alerts';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AppTwoDigitDecimaNumberDirective } from './app-two-digit-decima-number.directive';
import { AphaNumericDirective } from './alphanumeric.directive';
import { TokenInterceptorService } from './token-interceptor.service';
import { NumbertowordsPipe } from './numbertowords.pipe';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GoogleChartsModule } from 'angular-google-charts';

// import { TranslateModule } from '@ngx-translate/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LabelControl } from './label-control.directive';
import { OtpvalidationComponent } from './otpvalidation/otpvalidation.component';
import { KycComponent } from './kyc/kyc.component';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    ForgotpasswordComponent,
    HomeComponent,
    NotfoundComponent,
    FooterComponent,
    HeaderComponent,
    DashboardComponent,
    LoginheaderComponent,
    ProfileupdateComponent,
    AgenActivationListComponent,
    UserlistComponent,
    NavigationComponent,
    UtilitytransactionlistComponent,
    AboutComponent,
    ContactComponent,
    PrivacypolicyComponent,
    TermsandconditionComponent,
    DepositRequestComponent,
    DepositlistComponent,
    DepositupdatereportComponent,
    CreditrequestComponent,
    CreditlistComponent,
    CreditrequestlistComponent,
    MoneyTransferComponent,
    CashWithdrawalComponent,
    BalanceEnquiryComponent,
    RechargeComponent,
    AepsMenuComponent,
    DmtnavbarComponent,
    CashoutRequestComponent,
    CashoutRequestHistoryComponent,
    AccountStatementComponent,
    DmtTransectionListComponent,
    AgentPaymentListComponent,
    AddCreditComponent,
    CategoryCreationComponent,
    CommissionCreationComponent,
    CategoryAgentMappingComponent,
    CategorylistComponent,
    AlphabetOnlyDirective,
    NumberOnlyDirectiveDirective,
    AphaNumericDirective,
    ErrorMsgComponent,
    AppTwoDigitDecimaNumberDirective,
    NumbertowordsPipe,
    LabelControl,
    OtpvalidationComponent,
    KycComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    MaterialModule,
    NgxPrintModule,
    BrowserAnimationsModule,
    BrowserModule,
    NgIdleKeepaliveModule.forRoot(),
    AlertModule.forRoot({ maxMessages: 1, timeout: 5000, position: 'left' }),
    GoogleChartsModule,
    ToastrModule.forRoot({progressBar: true, preventDuplicates: true,})
  ],
  // providers: [AuthAgentService,StateCity,AuthGuard,AccountsService,DmtService],
  // providers: [AuthAgentService,StateCity,AuthGuard,AccountsService,DmtService],

  providers: [
    Title,
    AuthService,
    StateCity,
    AuthGuard,
    AccountsService,
    DmtService,
    DatePipe,
    // [
    //   Location,
    //     {
    //         provide: LocationStrategy,
    //         useClass: HashLocationStrategy
    //       }
    // ],
    BnNgIdleService,
    CommonService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },

    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' } //Added by Mandeep - Change date format through out the application

  ],
  bootstrap: [AppComponent],
  exports: [LoginComponent, LoginheaderComponent]
})
export class AppModule { }
