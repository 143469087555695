import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BiometricService {

  
  constructor(private http: HttpClient) { }

  capture(deviceType) {
    let rdSvcURL = 'https://127.0.0.1:11200/rd/capture';
    let body = '<PidOptions> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="60000" otp="" posh="UNKNOWN" env="P" wadh="" /> <Demo></Demo><CustOpts> <Param name="ValidationKey" value="" /> </CustOpts> </PidOptions>';
    if (deviceType == 'Mantra'){
      rdSvcURL = 'https://127.0.0.1:8005/rd/capture';
    }
    if (deviceType == 'Startek'){
      rdSvcURL = 'https://127.0.0.1:1200/rd/capture';
    }
    if (deviceType == 'Morpho'){
      rdSvcURL = 'http://127.0.0.1:11100/capture';
    }
    return this.http.request('CAPTURE', rdSvcURL, { body: body , responseType : 'text'});
  }
}
