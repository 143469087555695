import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termsandcondition',
  templateUrl: './termsandcondition.component.html',
  styleUrls: ['./termsandcondition.component.css']
})
export class TermsandconditionComponent implements OnInit {

  constructor() { }
  MERCHANT_NAME: string;
  ngOnInit(): void {
    this.MERCHANT_NAME = this.MERCHANT_NAME;
  }

}
