import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AccountsService } from 'src/app/Services/accounts.service';
import { AuthService } from 'src/app/Services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-depositupdatereport',
  templateUrl: './depositupdatereport.component.html',
  styleUrls: ['./depositupdatereport.component.css']
})
export class DepositupdatereportComponent implements OnInit {
  Loginresponse: any;
  _Responsedata;
  depositupdatepop = false;
  depositdeletepop = false;
  DepositRequestsFilterForm: FormGroup;
  loaderhide = false;
  Req_DepositUpdateForm: FormGroup;
  Req_DepositRejectForm: FormGroup;

  depositRequests;
  Showmessage;
  ShowmessageUpdate;
  ShowmessageRejectReq;
  ShowmessageError;
  _DepositUpdateReq;
  _DepositRejectReq;

  TransactionId: string;
  ApprovedAmount: number;
  BankRefNumber: any;
  selectedStatus: any;
  _Usertype: any;
  ShowListdata = false;
  Showmessageonly = false;
  loggedInUser: any;
  banks: any;
  depositModes: any;

  constructor(private _AccountsService: AccountsService,
    private _AuthAgentService: AuthService,
    private route: Router, private alertService: ToastrService) { }

  closeagentdetailsbtn() {
    this.depositupdatepop = false;
    this.depositdeletepop = false;

  }
  ngOnInit(): void {

    this._AuthAgentService.CheckSesstion();
    this.Loginresponse = localStorage.getItem('loginData');
    this.loggedInUser = (JSON.parse(this.Loginresponse)).loginData[0];
    this._Usertype = this.loggedInUser.userType;
    if (this._Usertype == 'OO' || this._Usertype == 'OOU') { this.ShowListdata = true; }

    // if (this._Usertype !='OO' && this._Usertype !='OOU' ){
    //       this.route.navigate(['/dashboard']);
    //     }


    this.selectedStatus = '';
    this.DepositRequestsFilterForm = new FormGroup({
      FromDate: new FormControl(new Date()),
      Todate: new FormControl(new Date()),
      BankName: new FormControl(''),
      TypeofSubmit: new FormControl('All'),
      AgentCode: new FormControl(''),
      Status: new FormControl('Pending'),
    });

    this.Req_DepositUpdateForm = new FormGroup({
      TransactionId: new FormControl(''),
      StaffRemarks: new FormControl('', Validators.required),
      ApprovedAmount: new FormControl(''),
      BankRefNumber: new FormControl('', Validators.required),
      Status: new FormControl('', [Validators.required])
    });

    this.Req_DepositRejectForm = new FormGroup({
      TransactionId: new FormControl(''),
      StaffRemarks: new FormControl('', Validators.required),
      ApprovedAmount: new FormControl(0, [Validators.required]),
      BankRefNumber: new FormControl('', Validators.required),
      Status: new FormControl('', [Validators.required])
    });

    this.fetchDepositRequests();

    this._AccountsService.fetchBankList(this.loggedInUser.merchant).subscribe(res => {
      let bankList: any = res;
      this.banks = bankList.data
    })

    this._AccountsService.fetchDepositModes().subscribe((res: any) => {
      this.depositModes = res.data;
    });

  }

  fetchDepositRequests() {
    this.depositRequests = [];
    this._AccountsService.fetchDepositRequests(this.DepositRequestsFilterForm.value)
      .subscribe(
        (res: any) => {
          this.depositRequests = res.data;

          if (res.statusCode == 1) {
            this.alertService.error(res.statusMessage);
          }
        },
        (error) => {
          this.alertService.error('Unable to fetch records, please try again later');
        }
      );
  }

  updateRequest(status: string) {
    let data: any;
    if (status == 'APPROVED')
      data = this.Req_DepositUpdateForm.value;
    if (status == 'REJECTED')
      data = this.Req_DepositRejectForm.value;

    this._AccountsService.updateDepositRequest(data)
      .subscribe(
        UserListData => {
          this._DepositUpdateReq = UserListData;
          if (this._DepositUpdateReq.statusCode == 0) {
            this.alertService.success(this._DepositUpdateReq.statusMessage);
            this.fetchDepositRequests();
          }
          else {
            this.alertService.error(this._DepositUpdateReq.statusMessage);
          }
        },
        (error) => {
          this.loaderhide = false;
          this.alertService.error('Unable to process, please try again later');
        },()=>{
          this.depositupdatepop = false;
          this.depositdeletepop = false;
        }
      );
  }


  showUpdateDialog(data: any, approve: boolean) {

    if (approve) {
      this.Req_DepositUpdateForm.patchValue({
        TransactionId: data.transactionId,
        ApprovedAmount: data.requestedAmt,
        BankRefNumber: data.bankRefNum,
        Status: 'APPROVED'
      });
      this.depositupdatepop = true;
    } else {
      this.Req_DepositRejectForm.patchValue({
        TransactionId: data.transactionId,
        ApprovedAmount: data.requestedAmt,
        BankRefNumber: data.bankRefNum,
        Status: 'REJECTED'
      });
      this.depositdeletepop = true;
    }
  }


}
