import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoreDataService {

  fetchProducts() {
    const url = `${environment.Api_urlcoreconfig}product`;
    return this.httpClient.get(url);
  }

  constructor(private httpClient: HttpClient) { }

  fetchProviders(productCode: string) {
    const url = `${environment.Api_urlcoreconfig}provider?productCode=${productCode}`;
    return this.httpClient.get(url);
  }

  fetchProviderConfig(productCode: string, providerCode: string) {
    const url = `${environment.Api_urlcoreconfig}provider/${providerCode}/${productCode}/config`;
    return this.httpClient.get(url);
  }

  fetchProductConfig(productCode: string) {
    const url = `${environment.Api_urlcoreconfig}product/${productCode}/config`;
    return this.httpClient.get(url);
  }

  saveProviderConfig(data: any) {
    const url = `${environment.Api_urlcoreconfig}provider/${data.providerCode}/${data.productCode}/config`;
    return this.httpClient.put(url, data);
  }

  saveProductConfig(data: any) {
    const url = `${environment.Api_urlcoreconfig}product/${data.productCode}/config`;
    return this.httpClient.put(url, data.productConfigs);
  }

  fetchDepositModes(merchant: string){
    return this.httpClient.get(`${environment.API_BASE_URL}/cms/api/merchants?filters[code][$eq]=${merchant}&populate=deposit_modes`)
  
  }

}
