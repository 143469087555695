import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ForgotpasswordComponent } from '../forgotpassword/forgotpassword.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../Services/auth.service';
import { FormGroup, FormControl } from '@angular/forms';
import { AccountsService } from '../Services/accounts.service';
import { CredentialscheckService } from '../Services/credentialscheck.service';
import * as $ from 'jquery';
import { AepsService } from '../Services/aeps.service';
import { PaymentGatewayService } from '../Services/payment-gateway.service';
import { environment } from 'src/environments/environment';
import { KycComponent } from '../kyc/kyc.component';
import { env } from 'process';
import { InitService } from '../init.service';
import { Title } from '@angular/platform-browser';
// import { TranslateModule } from '@ngx-translate/core';
import { UserService } from '../Services/user.service';
import Swal from 'sweetalert2';
import { FaviconServiceService } from '../favicon-service.service';
@Component({
  selector: 'loginheader',
  templateUrl: './loginheader.component.html',
  styleUrls: ['./loginheader.component.css']
})
export class LoginheaderComponent implements OnInit {

  Loginresponse: string;
  _Responsedata: any;
  Showmessage = false;

  UserTypeCheck: any;
  User_TypeAdmin = false;
  User_TypeStaff = false;
  User_TypeAgent = false;
  User_Typedist = false;
  User_TypeSales = false;
  User_Withoutlogin = false;
  AEPS_DATA: any;
  AEPS_INIT = false;
  RBP_DATA: any;
  RBP_INIT = false;
  Deposit_Update_Allowed = false;
  MERCHANT_CODE: string;
  MERCHANT_DOMAIN: string;
  menus = []
  logoUrl = ''
  defaultService = '/dashbaord';

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private _AccountsService: AccountsService,
    private authService: AuthService,
    private _CredentialscheckService: CredentialscheckService,
    private aepsService: AepsService,
    private initService: InitService,
    private titleService: Title,
    private userService: UserService,
    private faviconService: FaviconServiceService
  ) {

    this.initService.changeEmitted$.subscribe(action => {
      if (action == 'init-data-fetch') {
        this.initApp();
      }
    })
  }

  initApp() {
    let mData: any = { logo: { data: { attributes: { url: '' } } }, colors: { menu: { mClr: '#f23f3f', mIClr: '#c53131' }, nav: {} } }

    mData = this.initService.getMerchantMetaData();
    if (mData != undefined) {
      document.documentElement.style.setProperty('--menu-bg-color', mData.colors.menu.mClr)
      document.documentElement.style.setProperty('--menu-item-bg-color', mData.colors.menu.mIClr)
      document.documentElement.style.setProperty('--nav-bg-color', mData.colors.nav.bgClr)
      document.documentElement.style.setProperty('--btn-hover-color', mData.colors.nav.bgClr)
      document.documentElement.style.setProperty('--nav-lbg-color', mData.colors.nav.lbgClr)
      document.documentElement.style.setProperty('--page-title-header-bgColor', mData.colors.page.titlebgClr)
      document.documentElement.style.setProperty('--bg-img', `url(${environment.CMS_BASE_URL}${mData.bgimage.data.attributes.url})`)
      this.faviconService.setFaviconUrl(`${environment.CMS_BASE_URL}${mData.favicon.data.attributes.url}`)

      this.titleService.setTitle(mData.name);
      this.logoUrl = environment.API_BASE_URL + '/cms' + mData.logo.data.attributes.url
    }
    let agentData = JSON.parse(localStorage.getItem('loginData'));
    if (agentData != null || agentData != undefined) {
      this.defaultService = agentData.loginData[0].defaultService
    }
  }


  opanDialog(serviceType, title) {
    const dialogRef = this.dialog.open(ForgotpasswordComponent, {
      data:
        { serviceType: serviceType, title: title }
    });
  }



  ngOnInit() {

    this.initService.initializeData()

    $(document).ready(function () {
      $('.mobileMenuToggle').click(function () {
        $('.mobimeneucontent').toggleClass('mobilemenuactive');
      });
      $('#closeMobileMenu').click(function () {
        $('.mobimeneucontent').removeClass('mobilemenuactive');
      });
    });
    this.authService.CheckSesstion();

    $('.navbar-nav .nav-item .nav-link').on('click', function () {
      $('li.nav-item .nav-link.active').removeClass('active');
      $(this).addClass('active');
    });

    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata = (JSON.parse(this.Loginresponse) as any);
    this.menus = this._Responsedata.menus;
    // this.fetchAepsRedirectionData();
    // this.fetchRechargeBillPayRedirectionData();

    this.UserTypeCheck = this._CredentialscheckService.CheckCredential();
    // if (this.UserTypeCheck=="OO" || this.UserTypeCheck=="OOU"){
    if (this.UserTypeCheck == 'OO') {
      this.User_TypeAdmin = true;
    }
    else if (this.UserTypeCheck == 'OOU') {
      this.User_TypeStaff = true;
      if (this.authService.getLoggedInUser() == 'MT0O3' || this.authService.getLoggedInUser() == 'MT0O4') {
        this.Deposit_Update_Allowed = true;
      }
    }
    else if (this.UserTypeCheck == 'A') {
      this.User_TypeAgent = true;
    }
    else if (this.UserTypeCheck == 'D') {
      this.User_Typedist = true;
    }
    else if (this.UserTypeCheck == 'S') {
      this.User_TypeSales = true;
    }
    else {
      this.User_Withoutlogin = true;
    }

  }


  Funlogout() {
    this.authService.logout();
  }

  showProfile() {
    this.router.navigate(['/profile']);
  }

  FunRegistration() {
    this.router.navigate(['/registration']);
  }

  fetchAepsRedirectionData() {
    this.aepsService.fetchIPayRedirectionData('aeps').subscribe(res => {
      this.AEPS_DATA = res;
      this.AEPS_DATA = this.AEPS_DATA.data;
      this.AEPS_INIT = true;
    });
  }

  fetchRechargeBillPayRedirectionData() {
    this.aepsService.fetchIPayRedirectionData('rechargebillpay').subscribe(res => {
      this.RBP_DATA = res;
      this.RBP_DATA = this.RBP_DATA.data;
      this.RBP_INIT = true;
    });
  }

  redirectToIRCTC() {
    this.authService.IsRailTxnEnabled().subscribe(res => {
      let data: any = res
      if (data.code == "01") {
        window.open(
          'https://www.irctc.co.in/nget/train-search',
          '_blank'
        );
      }
      else {
        // show dialog to set password
        this.opanDialog('railonline', 'Set Rail Txn Password')
      }
    })
  }

}
