import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CategoryService } from 'src/app/Services/category.service';

import * as XLSX from 'xlsx';
import $ from 'jquery';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DmtService } from 'src/app/Services/dmt.service';
import { MastersService } from 'src/app/Services/masters.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-commission-creation',
  templateUrl: './commission-creation.component.html',
  styleUrls: ['./commission-creation.component.css']
})


export class CommissionCreationComponent implements OnInit {
  @ViewChild('TABLE') table: ElementRef;
  CommissionCreationFormGroup: FormGroup;
  Categorylistgroup: FormGroup;
  loaderhide = false;
  addcommission = false;

  CommissionUpadtegroup: FormGroup;
  CategoryShowgroup: FormGroup;
  Loginresponse;
  _Responsedata;
  commisionsdelete = false;
  Showmessage = false;
  Showmessagelist = false;
  CreateCommDiv = false;
  Showmessagedelete = false;
  ShowmessageUpdate = false;
  commisionupdate = false;
  CreateCommbtn = true;
  Distibutordiv = false;
  DistributorValueCheck = false;
  AgentValueCheck = false;
  DistibutorMissingValue = false;
  AgentValueCheckUpdate = false;

  _CreateCommissionResponse;
  _UpdateCatResponse;
  _CategoryLisdBindAdd: Observable<any>[];
  DelProductBind;
  category_check;

  AgentBind;
  DistributorBind;
  CategoryBind;
  ProductBind;
  Categorytype;
  loggedInUserType: any;
  ShowListdata = false;
  _CategoryListResponse;
  _ProductBind: Observable<any>[];
  _CategoryLisdBind: Observable<any>[];
  selectedValue;
  selectedValueAdd;
  selectedValueCat;
  CheckAgentType;
  CategorytypeDistributorCom = false;
  userType = 'Agent'


  constructor(private _CategoryService: CategoryService, private dmtService: DmtService, private masterDataService: MastersService,
    private route: Router, private alertService: ToastrService) { }

  ngOnInit(): void {

    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata = (JSON.parse(this.Loginresponse) as any);
    this.loggedInUserType = this._Responsedata.loginData[0].userType;
    if (this.loggedInUserType != 'A') {
      this.ShowListdata = true;
    }

    if (this.loggedInUserType == 'SA') {
      this.userType = 'Merchant'
    } else {
      this.userType = 'Agent'
    }

    // if(this._Usertype !="OO" && this._Usertype !="OOU"){
    // if (this._Usertype != 'OO') {
    //   this.route.navigate(['/dashboard']);
    // }

    this.masterDataService.fetchProduct('financial').subscribe(
      res => {
        let productData: any = res
        this._ProductBind = productData.data
      });

    this.CommissionCreationFormGroup = new FormGroup({
      Category: new FormControl('', Validators.required),
      Agent: new FormControl('', Validators.required),
      Distributor: new FormControl(''),
      Cutover: new FormControl(''),
      CutoverAgent: new FormControl(''),
      CutoverDistributor: new FormControl(''),
      Product: new FormControl(''),
      Merchant: new FormControl(this._Responsedata.loginData[0].merchant),
      LoggedInUserType: new FormControl(this._Responsedata.loginData[0].userType),
      LoggedInAgentCode: new FormControl(this._Responsedata.loginData[0].identifier),
    });

    this.CommissionUpadtegroup = new FormGroup({
      Category: new FormControl(''),
      Agent: new FormControl(''),
      Distributor: new FormControl(''),
      Product: new FormControl(''),
      // Mode : new FormControl('WEB'),
      Merchant: new FormControl(this._Responsedata.loginData[0].merchant),
      LoggedInUserType: new FormControl(this._Responsedata.loginData[0].userType),
      LoggedInAgentCode: new FormControl(this._Responsedata.loginData[0].identifier),
    });



    this.Categorylistgroup = new FormGroup({

      Category: new FormControl(''),
      Product: new FormControl('', Validators.required),
      Merchant: new FormControl(this._Responsedata.loginData[0].merchant),
      LoggedInUserType: new FormControl(this._Responsedata.loginData[0].userType),
      LoggedInAgentCode: new FormControl(this._Responsedata.loginData[0].identifier),

    });


    this.CategoryShowgroup = new FormGroup({

      Category: new FormControl(''),
      Product: new FormControl(''), //
      Merchant: new FormControl(this._Responsedata.loginData[0].merchant),
      LoggedInUserType: new FormControl(this._Responsedata.loginData[0].userType),
      LoggedInAgentCode: new FormControl(this._Responsedata.loginData[0].identifier),

    });
  }

  closeagentdetailsbtn() {
    this.commisionupdate = false;
    this.commisionsdelete = false;
    this.addcommission = false;
    this.CommissionCreationFormGroup.reset();
  }

  fun_Createcateogry() {

    if (this.CommissionCreationFormGroup.value.Agent > 80) {
      this.AgentValueCheck_wait();

      return;
    }
    if (this.CommissionCreationFormGroup.value.Distributor > 20) {

      this.DistibutorMissingValue_wait();

      return;
    }
    if (this.CheckAgentType == 'InDirect') {
      if (this.CommissionCreationFormGroup.value.Distributor == '') {
        this.Disti_wait();

      }
    } else {
      this.CommissionCreationFormGroup.value.Distributor = 0;
    }
    this.CommissionCreationFormGroup.value.Category = this.CommissionCreationFormGroup.value.Category.split(',')[0];
    this.dmtService.CreateCommission(this.CommissionCreationFormGroup.value)
      .subscribe(
        TransactionList => {
          this.Createwait();
          this._CreateCommissionResponse = TransactionList;
          this.fetchCommissionRules();
          this.addcommission = false;
          this.CommissionCreationFormGroup.reset();
        },
        (error) => {
          console.log(error);
        }

      );


  }

  fetchCommissionRules() {

    // this.Categorylistgroup.value.Category=this.CategoryShowgroup.value.Category;
    this.dmtService.GetCommissionList(this.Categorylistgroup.value)
      .subscribe(
        TransactionList => {
          this.Gelistwait();
          this._CategoryListResponse = TransactionList;
          if (this._CategoryListResponse.statusCode == 1) {
            this.alertService.error(this._CategoryListResponse.statusMessage);
          }
          if (this._CategoryListResponse.statusMessage == null) {
            this.alertService.error('No record found');
          }
        },
        (error) => {
          console.log(error);
        }

      );


  }

  fun_CreatecatDiv() {
    // this.CreateCommDiv = true;
    // this.Showmessagelist = false;
    // this.CreateCommbtn = false
    this.addcommission = true;
  }



  deleteCommissionRule(CategoryName: any, productName: any) {
    Swal.fire({
      title: 'Are you sure?',
      html: `You want to delete <b>${productName}</b> commision rule associated with category <b>${CategoryName}</b>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#d33'
    }).then((result) => {
      if (result.isConfirmed) {
        this.dmtService.DeleteCommission({ Category: CategoryName, Product: productName }).subscribe(res => {
          let apiResponse: any = res
          if (apiResponse.statusCode == 0) {
            this.alertService.success(apiResponse.statusMessage);
            this.fetchCommissionRules();
          }
          else {
            this.alertService.error(apiResponse.statusMessage);
          }
        })
      }
    });
  }

  fun_UpadteCat(CategoryName: any, AgentCom: any, DistributorCom: any, Productbind: any, Categorytype: any) {

    this.commisionupdate = true;
    // CommissionUpadtegroup
    this.AgentBind = AgentCom.innerText;
    this.DistributorBind = DistributorCom.innerText;
    this.CategoryBind = CategoryName.innerText;
    this.ProductBind = Productbind.innerText;
    this.Categorytype = Categorytype.innerText;
    if (this.Categorytype == 0) {
      this.CategorytypeDistributorCom = true;
    }
  }


  fun_UpdateCom() {
    // debugger;
    // this.loaderhide = true;
    if (this.CommissionUpadtegroup.value.Agent > 80) {
      this.AgentValueCheckUpdate_wait();

      return;
    }

    if (this.CommissionUpadtegroup.value.Distributor > 20) {
      this.AgentValueCheckUpdate_wait();
      return;
    }

    if (this.CommissionUpadtegroup.value.Distributor == '') {
      this.CommissionUpadtegroup.value.Distributor = 0;
    }
    this.dmtService.UpdateCommission(this.CommissionUpadtegroup.value)
      .subscribe(
        TransactionList => {
          this.loaderhide = false;
          this.Updatewait();
          this._UpdateCatResponse = TransactionList;
          this.fetchCommissionRules();
          if (this._UpdateCatResponse.statusCode == 0) {
            this.alertService.success(this._UpdateCatResponse.statusMessage);
          }
          else {
            this.alertService.success(this._UpdateCatResponse.statusMessage);
          }

        },
        (error) => {
          console.log(error);
          this.loaderhide = false;

        }

      );

  }



  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'File');
    /* save to file */
    XLSX.writeFile(wb, 'File.xlsx');
  }


  onChangeProductdataSelected(event) {

    this._CategoryService.CategoryList({ Product: event.value, Type: 'Mapped' })
      .subscribe(
        res => {
          let categoryData: any = res
          this._CategoryLisdBind = categoryData.categoryData;
        },
        (error) => {
          console.log(error)
        }
      );
  }

  onChangeProductdataSelectedAdd(event) {
    this._CategoryService.CategoryList({ Product: event.value, Type: 'UnMapped' })
      .subscribe(
        res => {
          let categoryData: any = res
          this._CategoryLisdBindAdd = categoryData.categoryData;
        },
        (error) => {
          this.alertService.warning('Unable to fetch categories');
        }
      );
  }




  Createwait(): void {
    this.Showmessage = true;
    setTimeout(function () {
      this.Showmessage = false;
      if (this._UpdateCatResponse.statusCode == 0) {
        this.addcommission = false;
      }
    }.bind(this), 2000);
  }

  Gelistwait(): void {
    this.Showmessagelist = true;
    setTimeout(function () {

    }.bind(this), 2000);
  }

  Updatewait(): void {
    this.ShowmessageUpdate = true;
    setTimeout(function () {
      this.ShowmessageUpdate = false;
      if (this._UpdateCatResponse.statusCode == 0) {
        this.commisionupdate = false;
      }

    }.bind(this), 2000);
  }
  Deletewait(): void {
    this.Showmessagedelete = true;
    setTimeout(function () {
      this.Showmessagedelete = false;
      if (this._DeleteListResponse.statusCode == 0) {
        this.commisionsdelete = false;
      }
    }.bind(this), 2000);
  }

  ChangeAction(category) {
    this.CheckAgentType = category.split(',')[1];
    if (this.CheckAgentType == 'InDirect') {
      this.Distibutordiv = true;
    } else { this.Distibutordiv = false; }
  }


  Disti_wait(): void {
    this.DistibutorMissingValue = true;
    setTimeout(function () {
      this.DistibutorMissingValue = false;

    }.bind(this), 2000);
  }

  AgentValueCheck_wait(): void {
    this.AgentValueCheck = true;
    setTimeout(function () {
      this.AgentValueCheck = false;

    }.bind(this), 2000);
  }
  AgentValueCheckUpdate_wait(): void {
    this.AgentValueCheckUpdate = true;
    setTimeout(function () {
      this.AgentValueCheckUpdate = false;

    }.bind(this), 2000);
  }



  DistibutorMissingValue_wait(): void {
    this.DistributorValueCheck = true;
    setTimeout(function () {
      this.DistributorValueCheck = false;

    }.bind(this), 2000);
  }

}
