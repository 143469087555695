import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent implements OnInit {

  MERCHANT_NAME: string = '<Merchant>';
  constructor() { }

  ngOnInit(): void {
    if ( localStorage.getItem('mData')!=null) {
      this.MERCHANT_NAME = JSON.parse(localStorage.getItem('mData')).name;
    }
  }

}
