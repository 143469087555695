<div style="width:400px" *ngIf="fulldiv">

  <h3 class="pt-10 pl-10">{{dialogTitle}}</h3>
  <div class="container-fluid" style="padding:24px">
    <form [formGroup]="ResetPasswordForm" *ngIf="enteruserpart">
      <div class="row">
        <div class="col-xl-12">
          <mat-form-field style="display: block;">
            <mat-label>Enter User Email</mat-label>
            <input [readonly]="serviceType =='RESETPASSWORD' || serviceType =='railonline'" matInput
              formControlName="Email" autocomplete="off" required email>
          </mat-form-field>
        </div>
      </div>
      <div class="row col-xl-12">
        <button [disabled]="!this.ResetPasswordForm.valid" mat-raised-button type="submit" (click)="fun_resetpass()"
          class="btn btnprimary">Submit</button>
      </div>
    </form>


    <div class="row col-xl-12">
      <p class="text-danger text-center adderrormsg" *ngIf="Meg_ResetPassword">
        {{ResetPasswordResponse.statusMessage}}</p>
    </div>
    <form class="example-form" *ngIf="enterpasspart" [formGroup]="ChangepasswordForm">
      <div class="row">
        <div class="col-xl-12">
          <mat-form-field style="display: block;">
            <mat-label>Enter New Password</mat-label>
            <input autocomplete="off" placeholder="Enter New Password" formControlName="Password" matInput
              [type]="hide ? 'password' : 'text'" maxlength="25">
            <mat-error *ngIf="ChangepasswordForm.hasError('required', 'password')" required>
              Please enter your newpassword
            </mat-error>

            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <mat-form-field style="display: block;">
            <mat-label>Confirm password</mat-label>
            <input autocomplete="off" [errorStateMatcher]="matcher" placeholder="Confirm password"
              formControlName="confirmPass" matInput [type]="hide ? 'password' : 'text'" maxlength="25" required>
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
              <mat-error *ngIf="ChangepasswordForm.hasError('required', 'password')">
                Confirm Password
              </mat-error>
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>

            </button>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <mat-form-field style="display: block;">
            <mat-label>Enter OTP</mat-label>
            <input autocomplete="off" matInput formControlName="Otp" maxlength="6" (keypress)="numberOnly($event)"
              required>
          </mat-form-field>

          <p></p>

          <p class="text-danger mt-2 text-center font-weight-bold" *ngIf="MessageconfirmPass">Your password and
            confirmation
            password do not match</p>
        </div>
      </div>
      <div class="row" style="display:inline">
        <button mat-raised-button type="submit" [disabled]="!this.ChangepasswordForm.valid" class="btn btnprimary"
          (click)="fun_ChanePassword()">Update Password</button>
        <p class="text-danger text-center adderrormsg" *ngIf="Meg_UpdatePassword">
          {{ChangepasswordFormResponse.statusMessage}}</p>
        <!-- <button class="closebtnnew btn " mat-dialog-close><i class="fa fa-times" aria-hidden="true"></i>
          Close
        </button> -->
      </div>

    </form>
  </div>

</div>