<div class="container-fluid customeheight bg-light">
  <div class="container-fluid py-2 px-0">
    <div class="depositrequest">
      <h3>Deposit Request</h3>
      <form class="form-group mb-0" [formGroup]="DepositFrom">
        <div class="row">
          <div class="col-xl-6">
            <div class="row ">
              <div class="col-xl-12 mb-2 text-center">
                <mat-form-field>
                  <mat-label>Deposit Type</mat-label>
                  <mat-select disableRipple formControlName="DepositType" (selectionChange)="enableDisableValidation()">
                    <mat-option *ngFor="let depositMode of depositModes"
                      value="{{depositMode.attributes.code}}">{{depositMode.attributes.description}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12 col-6">
                <mat-form-field>
                  <mat-label>Amount</mat-label>
                  <input (keypress)="numberOnly($event)" matInput placeholder="Amount" formControlName="RequestedAmount"
                    maxlength="6" autocomplete="off" #clierAmount>
                </mat-form-field>
              </div>

            </div>
            <div class="row mb-3">
              <div class="col-xl-12">
                <span><b>Amount in words: </b></span>
                <span class="text-danger"><b>{{ DepositFrom.get('RequestedAmount').value | numbertowords | titlecase
                    }}</b>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6">
                <mat-form-field>
                  <mat-label>Bank</mat-label>
                  <mat-select disableRipple formControlName="BankName">
                    <mat-option *ngFor="let bank of banks"
                      value="{{bank.attributes.shortName}}">{{bank.attributes.shortName}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <p class="text-danger text-center adderrormsg" *ngIf="BankErrorMessage">
                  Please select bank name </p>

              </div>              
              <div class="col-xl-6 col-6">
                <mat-form-field>
                  <mat-label>Reference No/Cheque No</mat-label>
                  <input matInput formControlName="DepositRefNumber" autocomplete="off" minlength="3" maxlength="22">
                </mat-form-field>
              </div>
              
            </div>
            <div class="row">
              <div class="col-xl-12">
                <mat-form-field>
                  <mat-label>Remarks</mat-label>
                  <input matInput placeholder="Remarks" formControlName="AgentRemarks" maxlength="150"
                    autocomplete="off">
                </mat-form-field>
              </div>
            </div>
            <div class="row" style="margin-bottom: 10px;">
              <div class="col-xl-12 text-center">
                <button mat-raised-button class="btn btnprimary" [disabled]="!this.DepositFrom.valid"
                  (click)="submitDepositRequest()">
                  Submit
                </button>
              </div>
            </div>

          </div>
          <div class="col-xl-6">
            <div class="row mb-5 mx-0">
              <div class="col-xl-12 table-responsive p-0">
                <table class="table table-hover table-list ">
                  <thead>
                    <tr>
                      <td>Bank Name</td>
                      <td>Account No.</td>
                      <td>Ifsc Code</td>
                      <td>Account Type</td>
                      <td>Comments</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let bank of banks">
                      <td>{{bank.attributes.name}}</td>
                      <td>{{bank.attributes.accNo}}</td>
                      <td>{{bank.attributes.ifsc}}</td>
                      <td>{{bank.attributes.accType}}</td>
                      <td>{{bank.attributes.comments}}</td>
                    </tr>
                  </tbody>
                </table>

                <!-- <p class="notetext"><span>Note : -</span> Cash Deposit => Rs.100/Transaction ( 1Lac & Below ) Rs.200/Transaction ( above 1Lac ) </p> -->
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal
<div class="modal fade" id="slip" tabindex="-1" role="dialog" aria-labelledby="slip" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title my-auto">Receipt</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img src="https://i0.wp.com/s4.scoopwhoop.com/ada/assorted/lotus.jpg" class="img-fluid">
      </div>

    </div>
  </div>
</div> -->
<!-- Modal -->
<!-- <div class="modal fade" id="slip" tabindex="-1" role="dialog" aria-labelledby="slip" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title my-auto">Receipt</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img src="https://i0.wp.com/s4.scoopwhoop.com/ada/assorted/lotus.jpg" class="img-fluid">
      </div>

    </div>
  </div>
</div> -->