import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AccountsService } from 'src/app/Services/accounts.service';
import * as XLSX from 'xlsx';
import { AuthService } from 'src/app/Services/auth.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-depositlist',
  templateUrl: './depositlist.component.html',
  styleUrls: ['./depositlist.component.css']
})
export class DepositlistComponent implements OnInit {
  @ViewChild('TABLE') table: ElementRef;

  loggedInUserData;
  _Usertype: any;
  constructor(private _AccountsService: AccountsService,
    private alertService: ToastrService) { }
  DepostiList: FormGroup;
  _DepositListReq;
  Loginresponse;
  Showmessage = false;
  ShowListdata = false;
  Bindlistdata = false;
  Responsestatusmessage = false;
  banks: any

  ngOnInit(): void {
    this.Loginresponse = localStorage.getItem('loginData');
    this.loggedInUserData = (JSON.parse(this.Loginresponse) as any).loginData[0];
    this._Usertype = this.loggedInUserData.userType;
    if (this._Usertype == 'OO' || this._Usertype == 'OOU' || this._Usertype == 'S') {
      this.ShowListdata = true;
    }

    this.DepostiList = new FormGroup({
      FromDate: new FormControl(new Date()),
      Todate: new FormControl(new Date()),
      BankName: new FormControl(''),
      TypeofSubmit: new FormControl('ALL'),
      Status: new FormControl('ALL'),
    });
    this.SearchDepositlist();

    this._AccountsService.fetchBankList(this.loggedInUserData.merchant).subscribe(res => {
      let bankList: any = res;
      this.banks = bankList.data
    })
  }

  SearchDepositlist() {
    this.Bindlistdata = false;
    this._AccountsService.fetchDepositRequests(this.DepostiList.value)
      .subscribe(
        UserListData => {
          this._DepositListReq = UserListData;
          if (this._DepositListReq.statusCode == 1) {
            this.alertService.error(this._DepositListReq.statusMessage);
          }
          else {
            // this.alertService.success(this._DepositListReq.statusMessage);
            this.Showmessage = false;
            this.Bindlistdata = true;
          }
        },
        (error) => {
          this.alertService.error('Unable to fetch records, please try again later');
        }
      );
  }

  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'File');
    /* save to file */
    XLSX.writeFile(wb, 'File.xlsx');
  }

  // load_wait(): void {
  //   this.Showmessage = true;
  //   setTimeout(function() {

  //     this.Showmessage = false;

  //   }.bind(this), 3000);
  // }
}
