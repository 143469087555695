import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AepsService {

  constructor(private httpClient: HttpClient, private datepipe: DatePipe) { }

  registerAgent(data: any) {
    let url = `${environment.API_BASE_URL}/api/aeps/register`;
    return this.httpClient.post(url, data);
  }

  fetchUserRegistrationDetails() {
    let url = `${environment.API_BASE_URL}/api/aeps/registrationstatus`;
    return this.httpClient.get(url)
  }

  executeTransaction(data) {
    if (data.useDummy) {
      return  Observable.create(observer => {
        let data = '{ \
            "statuscode": null,\
            "actcode": null,\
            "txnId" : "AEPS00001", \
            "status": "Pending",\
            "data": {\
                "bankName": "STATE BANK OF INDIA",\
                "accountNumber": "xxxxxxxx9121",\
                "ipayId": "CPJ012216519454770",\
                "transactionMode": "CR",\
                "payableValue": "1505.42",\
                "transactionValue": "1500.00",\
                "openingBalance": "46005.83",\
                "closingBalance": "47511.25",\
                "operatorId": "216519053154",\
                "walletIpayId": "1220614194551WQPAK",\
                "bankAccountBalance": "219.15",\
                "miniStatement": []\
            },\
            "timestamp": "2022-06-14 19:45:51",\
            "ipay_uuid": "h068968a8109-9c29-42da-92b5-1cb1a439e6ea",\
            "orderid": "1220614194551WQPAK",\
            "environment": "LIVE", \
            "error" : { \
              "errorMessage" : "Uid Invalid - Dummy Error" \
            } \
        }'
        observer.next(JSON.parse(data));
        observer.complete()
      })
    } else {
      let url = `${environment.API_BASE_URL}/api/aeps/transact`;
      return this.httpClient.post(url, data)
    }
  }

  fetchRecentTransactions(mobileNo) {
    let url = `${environment.API_BASE_URL}/api/aeps/transactions/recent?m=${mobileNo}`;
    return this.httpClient.get(url)
  }

  fetchMetaInfo() {
    let url = `${environment.API_BASE_URL}/api/aeps/metaInfo`;
    return this.httpClient.get(url)
  }

  fetchBanks() {
    let url = `${environment.API_BASE_URL}/api/aeps/banks`;
    return this.httpClient.get(url)
  }

  fetchTransactions(data) {
    console.log(JSON.stringify(data))
    let queryString = `fromDate=${this.datepipe.transform(data.FromDate, 'yyyy-MM-dd')}&toDate=${this.datepipe.transform(data.Todate, 'yyyy-MM-dd')}&transactionId=${data.TransactionId}&orderId=${data.ApiTransactionId}`;
    queryString = queryString + `&status=${data.status}`
    queryString = queryString + `&txnType=${data.txnType}`
    queryString = queryString + `&acode=${data.ACode}`

    if (data.TransactionId != undefined && data.TransactionId != '') {
      queryString = `transactionId=${data.TransactionId}`
    }

    const url = environment.API_BASE_URL + `/api/aeps/transactions?${queryString}`;
    return this.httpClient.get(url);
  }

  checkOutletLoginStatus(){
    const url = `${environment.API_BASE_URL}/api/aeps/outletloginstatus`;
    return this.httpClient.get(url);
  }

  doOutletLogin(data: any){
    const url = environment.API_BASE_URL + `/api/aeps/outletlogin`;
    return this.httpClient.post(url, data)
  }
}
