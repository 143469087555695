<div class="container-fluid customeheight bg-light">
  <div class="container-fluid">
    <div class="row py-2">
      <div class="depositrequest">
        <div class="row ">
          <div class="col-xl-12">
            <h3>Deposit List</h3>
          </div>
        </div>

        <form class="form-group mb-0" [formGroup]="DepostiList">
          <div class="row ">
            <div class="col-xl-3 col-6">
              <mat-form-field class="example-full-width">
                <mat-label>From Date</mat-label>
                <input matInput [matDatepicker]="pickerFromDate" formControlName="FromDate">
                <mat-datepicker-toggle matSuffix [for]="pickerFromDate">
                </mat-datepicker-toggle>
                <mat-datepicker #pickerFromDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-xl-3 col-6">
              <mat-form-field class="example-full-width">
                <mat-label>To Date</mat-label>
                <input matInput [matDatepicker]="pickerTodate" formControlName="Todate">
                <mat-datepicker-toggle matSuffix [for]="pickerTodate">
                </mat-datepicker-toggle>
                <mat-datepicker #pickerTodate></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-xl-3">
              <mat-form-field>
                <mat-label>Bank Name</mat-label>
                <mat-select disableRipple formControlName="BankName">
                  <mat-option value="">All</mat-option>
                  <mat-option *ngFor="let bank of banks" value="{{bank.attributes.shortName}}">{{bank.attributes.name}}</mat-option>
                  <!-- <mat-option value="PNB">PNB - 3173005700000039</mat-option>
                  <mat-option value="SBI">SBI - 39053678918</mat-option>
                  <mat-option value="J&K">J&K - 0316020140000002</mat-option>
                  <mat-option value="HDFC">HDFC - 50200042136815</mat-option> -->
                  <mat-option value="Office">OFFICE</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-xl-3">
              <div class="buttnclass">
                <button [disabled]="!this.DepostiList.valid" class="btn-block btnprimary mr-3" mat-raised-button
                  (click)="SearchDepositlist()">Search</button>
                <button mat-raised-button color="primary" class="downloadexal" data-toggle="tooltip"
                  data-placement="top" title="Export as Excel" (click)="ExportTOExcel()"><i class="fa fa-download"
                    aria-hidden="true"></i></button>
              </div>
              <!-- <p class="text-danger mt-2 text-center font-weight-bold" *ngIf="Showmessage">{{_DepositListReq.statusMessage}}</p>  -->
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row mb-0" #TABLE>
      <div class="col-xl-12 table-responsive p-0">
        <table class="table table-hover table-list ">
          <thead>
            <tr>
              <td>S.No</td>
              <td>Transaction Id</td>
              <td *ngIf="ShowListdata">Agency Name / Code</td>
              <td>Bank Name</td>
              <td>Type</td>
              <td>Approve / Reject Date</td>
              <!-- <td>Deposit Date</td> -->
              <td>Approved Amount</td>
              <td>Requested Amount</td>
              <td>Requested Date</td>
              <!-- <td>Status</td> -->
              <td>Ref Number</td>
              <td>Agent Remarks</td>
              <td>Staff Remarks</td>
              <td>Updated By</td>
            </tr>
          </thead>
          <tbody [formGroup]="DepostiList" *ngIf="Bindlistdata">
            <tr *ngFor="let data of _DepositListReq.data;let i = index">
              <td>{{i+1}}</td>
              <td><span title="Status : {{data.depositStatusDescr}}" class="{{data.depositStatusDescr}}">{{data.transactionId}}</span></td>
              <td *ngIf="ShowListdata">{{data.agencyName}} /{{data.agentCode}}</td>
              <td>{{data.bankName}}</td>
              <td>{{data.typeOfRequest}}</td>
              <td><span *ngIf="data.depositStatus != 'Pending'">{{data.approvedDate|date : "dd/MM/yyyy  h:mm:ss a"}}</span></td>
              <!-- <td>{{data.deposit|date : "dd/MM/yyyy  h:mm:ss a"}}</td> -->
              <td>
                <span *ngIf="data.depositStatusDescr == 'Approved' ">{{data.approvedAmt | currency:'INR'}}</span>
              </td>
              <td>
                {{data.requestedAmt | currency:'INR'}}
              </td>
              <td>{{data.requestedDate|date : "dd/MM/yyyy  h:mm:ss a"}}</td>
              <!-- <td>{{data.depositStatusDescr}}</td> -->
              <td>{{data.bankRefNum}}</td>
              <td>{{data.agentRemarks}}</td>
              <td>{{data.staffRemarks}}</td>
              <td>{{data.updatedBy}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="slip" tabindex="-1" role="dialog" aria-labelledby="slip" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title my-auto">Receipt</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img src="https://i0.wp.com/s4.scoopwhoop.com/ada/assorted/lotus.jpg" class="img-fluid">
      </div>
    </div>
  </div>
</div>