
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './Services/auth.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { InitService } from './init.service';
import { environment } from 'src/environments/environment';
import { FaviconServiceService } from './favicon-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Makeetrip';
  timed: boolean = false;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  countdown: any;


  Loginresponse: string;
  _Responsedata: any;
  Loginheadershow: boolean = false;
  headershow: boolean = false;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  // domain = 'metsavings.in'
  // domain = 'flyholidays.fintrav.in'

  constructor(
    private route: Router,
    private authService: AuthService,
    private idle: Idle,
    private keepalive: Keepalive,
    private alertService: ToastrService,
    private titleService: Title,
    private initService: InitService,
    private faviconService: FaviconServiceService
  ) {

    // this.CheckSession();
    this.authService.changeEmitted$.subscribe(
      action => {
        if (action === 'login-success') {
          this.idle.watch();
          this.setLoginState();
        }
        if (action === 'logout-success') {
          this.setLoginState();
        }        
      }
    );

  }

  ngOnInit() {
    // this.reset();

    this.setIdleSessionHandle();
    this.setLoginState();
    this.initService.emitChangeSource.subscribe(action => {
      if (action === 'init-data-fetch') {
        this.initApp();
      }
    })

    // window.addEventListener('scroll', () => {
    //   const toTopLink = document.getElementById('toTopLink');
    
    //   if (window.scrollY > 0) {
    //     toTopLink.classList.remove('hidden');
    //   } else {
    //     toTopLink.classList.add('hidden');
    //   }
    // });
  }

  initApp() {
    let mData: any = JSON.parse(localStorage.getItem('mData'))
    this.titleService.setTitle(mData.name);
    document.documentElement.style.setProperty('--btn-hover-color', mData.colors.nav.bgClr)
    document.documentElement.style.setProperty('--btn-bg-color', mData.colors.nav.bgClr)
    document.documentElement.style.setProperty('--page-title-header-bgColor', mData.colors.menu.mClr)
    document.documentElement.style.setProperty('--bg-img', `url(${environment.CMS_BASE_URL}${mData.bgimage.data.attributes.url})`)
    this.faviconService.setFaviconUrl(`${environment.CMS_BASE_URL}${mData.favicon.data.attributes.url}`)
  }

  setLoginState() {

    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata = <any>JSON.parse(this.Loginresponse);
    if (this.Loginresponse == null) {
      this.headershow = true;
      this.Loginheadershow = false;
    } else {
      this.headershow = false;
      this.Loginheadershow = true;
    }

    if (this._Responsedata !== null && this._Responsedata.statusCode == 0) {
      // this.route.navigate(['/dashboard']);
    }
  }

  reset() {
    this.idleState = 'Started.';
    this.timedOut = false;
  }




  setIdleSessionHandle() {
    
    this.idle.setIdle(1800);
    this.idle.setTimeout(5); // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);


    // this.idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    this.idle.onTimeout.subscribe(() => {
      // this.idleState = 'Timed out!';
      this.timedOut = true;
      this.authService.logout();
      Swal.fire({
        icon: 'info',
        title:'Idle Session timed out',
        text:'You will need to login again to continue'
      })
    });

    // this.idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    // this.idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');

    // sets the ping interval to 15 seconds
    // this.keepalive.interval(15);
    // this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

  }
}



