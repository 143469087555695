<div class="container-fluid bg-light customeheight">
  <div class="container-fluid">
    <div class="row py-2">
      <div class="depositrequest">
        <div class="row">
          <div class="col-xl-12">
            <h3>Credit Update </h3>
          </div>
        </div>
        <form class="form-group mb-0" [formGroup]="CreditreRuestListFrom">

          <div class="row">
            <div class="col-xl-3">
              <mat-form-field class="example-full-width">
                <mat-label>From Date</mat-label>
                <input matInput [matDatepicker]="pickerFromDate" formControlName="FromDate">
                <mat-datepicker-toggle matSuffix [for]="pickerFromDate">
                </mat-datepicker-toggle>
                <mat-datepicker #pickerFromDate></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-xl-3">
              <mat-form-field class="example-full-width">
                <mat-label>To Date</mat-label>
                <input matInput [matDatepicker]="pickerTodate" formControlName="Todate">
                <mat-datepicker-toggle matSuffix [for]="pickerTodate">
                </mat-datepicker-toggle>
                <mat-datepicker #pickerTodate></mat-datepicker>
              </mat-form-field>
            </div>

            <!-- <div class="col-xl-3" *ngIf="_Usertype">
              <mat-form-field>
                <mat-label>Agent Name/Code</mat-label>
                <input (click)="resetAgentCode()" type="text" placeholder="Select Agent Code" aria-label="Number" matInput
                  formControlName="AgentCode" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let binddata of filteredAgents | async" [value]="binddata.agentCodeAndName">
                    {{binddata.agentCodeAndName}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div> -->

            <div class="col-xl-3">
              <div class="buttnclass">
                <button type="submit" class="btn-block btnprimary mr-3"
                  [disabled]="!this.CreditreRuestListFrom.valid" mat-raised-button
                  (click)="FunCrditreq()">Search</button>
                <button hidden mat-raised-button color="primary" class="downloadexal" data-toggle="tooltip"
                  data-placement="top" title="Export as Excel" (click)="ExportTOExcel()"><i class="fa fa-download"
                  aria-hidden="true"></i></button>
              </div>
              <!-- <p class="text-danger mt-2 text-center font-weight-bold" *ngIf="Showmessageonly">
                {{_CreditReqlist.statusMessage}}</p> -->
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-xl-12 table-responsive p-0">
        <table class="table table-hover table-list ">
          <thead>
            <tr>
              <td>S.No</td>
              <td>Transaction Id</td>
              <td *ngIf="ShowListdata">Agency Name/Code</td>
              <td>Requested Date</td>
              <!--<td>Update <br> On</td>-->
              <td>Requested <br> Amount</td>
              <!--<td>Approved <br> Amount</td>
          <td>Status</td>-->
              <td>Agent <br> Remarks</td>
              <!--<td>Staff <br> Remarks</td>
          <td>Updated <br> By</td>-->
              <td>Accept</td>
              <td>Reject</td>
            </tr>
          </thead>
          <tbody *ngIf="Showmessage">
            <tr *ngFor="let data of _CreditReqlist.creditupdateList;let i = index">
              <td>{{i+1}}</td>
              <td>{{data.transactionId}}</td>
              <td *ngIf="ShowListdata">{{data.agencyName}} / {{data.agentCode}}</td>
              <td>{{data.requestedDate|date : "dd/MM/yyyy  h:mm:ss a"}}</td>
              <!--<td>{{data.approvedDate|date : "dd/MM/yyyy  h:mm:ss a"}}</td>-->
              <td>{{data.requestedAmt}}</td>
              <!--<td>{{data.approvedAmt}}</td>
          <td>{{data.creditStatus}}</td>-->
              <td>{{data.agentRemarks}}</td>
              <!--<td>{{data.staffRemarks}}</td>
          <td>{{data.staffRemarks}}</td>-->
              <td>
                <button class="btn btn-block btn-success" data-toggle="tooltip" data-placement="top" title="Accept"
                  mat-raised-button (click)="fun_AcceptCredit(data.transactionId,data.requestedAmt)"><i
                    class="fa fa-check" aria-hidden="true"></i>
                </button>
              </td>
              <td>
                <button class="btn  btn-block btn-danger" data-toggle="tooltip" data-placement="top" title="Delete"
                  mat-raised-button (click)="fun_DeclineCredit(data.transactionId,data.requestedAmt)"><i class="fa fa-times" aria-hidden="true"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="agentdetailsbox" [formGroup]="CreditreRuestListAcceptFrom" *ngIf="AcceptCredit_Div">
      <div class="innerboxagntde morebox">
        <div class="closebtn" (click)="closeagentdetailsbtn()">
          <img src="../../../assets/images/icons/close.svg" alt="">
        </div>

        <h5>Receipt</h5>
        <div class="row justify-content-between">

          <div class="col-xl-12">
            <mat-form-field>
              <mat-label> Transaction Id</mat-label>
              <input matInput readonly formControlName="TransactionId" [(ngModel)]="Cre_transactionId"
                #Accept_transactionId>
            </mat-form-field>
            <mat-form-field>
              <mat-label> Approved Amount</mat-label>
              <input matInput appNumberOnlyDirective maxlength="5" formControlName="ApprovedAmount"
                [(ngModel)]="Cre_approvedAmt" #Accept_approvedAmt>
            </mat-form-field>
            <p class="text-danger"><b>{{ CreditreRuestListAcceptFrom.get('ApprovedAmount').value  | numbertowords | titlecase }}</b></p>
          </div>

          <div class="col-xl-12">
            <mat-form-field>
              <mat-label> Remarks </mat-label>
              <input matInput formControlName="StaffRemarks" #Accept_Remarks maxlength="120">
            </mat-form-field>
          </div>

          <div class="col-xl-12 text-right">
            <button class="btn-success" mat-raised-button (click)="AcceptReq()">Accept</button>
            <!-- <p class="text-danger mt-2 text-center font-weight-bold text-capitalize" *ngIf="_CreditReqShowmessage">{{_CreditReqlist.statusMessage}}</p> -->
          </div>
        </div>
      </div>
    </div>

    <div class="agentdetailsbox" [formGroup]="CreditreRuestListDeclineFrom" *ngIf="DeclineCredit_Div">
      <div class="innerboxagntde morebox">
        <div class="closebtn" (click)="closeagentdetailsbtn()">
          <img src="../../../assets/images/icons/close.svg" alt="">
        </div>
        <h5>Receipt</h5>
        <div class="row justify-content-between">
          <div class="col-xl-12">
            <mat-form-field>
              <mat-label> TransactionId</mat-label>
              <input matInput readonly formControlName="TransactionId" [(ngModel)]="Cre_transactionId"
                #Accept_transactionId>
            </mat-form-field>
          </div>

          <div class="col-xl-12">
            <mat-form-field>
              <mat-label> Remarks </mat-label>
              <input matInput formControlName="StaffRemarks" #Accept_Remarks maxlength="120">
            </mat-form-field>
          </div>
          <div class="col-xl-12 text-right">
            <button class="btn-danger" mat-raised-button (click)="DeclineReq()">Decline</button>
            <!-- <p class="text-danger mt-2 text-center font-weight-bold text-capitalize" *ngIf="_DelineReqShowmessage">{{_CreditReqlist.statusMessage}}</p> -->
          </div>
        </div>

      </div>
    </div>
    </div>
</div>

<div class="loader-maine" *ngIf="loaderhide">
  <div class="loader">
    <!--<div class="loader-inner">cvxcv</div>-->
    <div class="loader-inner"></div>
    <div class="loader-inner box"></div>
    <div class="box-1"></div>
    <div class="box-2"></div>
    <div class="box-3"></div>
  </div>
</div>