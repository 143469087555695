
  <div class="container-fluid naviagtionbg pt-1 pb-1">
    <div class="row justify-content-end">
      <div class="col-xl-12 text-right">
        <!-- <div class="walblmain"> -->
          <span class="balancewal">Wallet Balance&nbsp;&nbsp;</span>
          <i class="fa fa-refresh mr-2" style="color:#ffffff; cursor: pointer;" aria-hidden="true" (click)="fetchWalletDetails(1,1)"></i>
          <span class="balancewal"  *ngIf="!loading">
            &nbsp;Credit : {{creditBalance | currency : 'INR' : 'symbol' : '1.2-2'}} &nbsp; || Deposit : {{depositBalance | currency : 'INR' : 'symbol' : '1.2-2'}} &nbsp; || Total Balance:
            {{totalBalance | currency : 'INR' : 'symbol' : '1.2-2'}}</span>
            <span class="balancewal"  *ngIf="loading"><i class="fa fa-hourglass" aria-hidden="true">...</i></span>
        <!-- </div> -->
      </div>
    </div>
  </div>