import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { UserService } from '../Services/user.service';
import Swal from 'sweetalert2';
import { AepsService } from '../AEPS/aeps.service';
import { CapturebiometricComponent } from '../AEPS/capturebiometric/capturebiometric.component';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.css']
})
export class KycComponent implements OnInit {

  KYCForm: FormGroup;
  KYCOTPValidationForm: FormGroup;
  hide = true;
  agencyData = null
  otpDisplay = false
  loading = true
  initiationError = false;
  initiationErrorMsg = ''
  kycOTPFlag: boolean = false;
  devices;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private dialogRef: MatDialogRef<KycComponent>, private userService: UserService,
    private aepsService: AepsService) {
    this.agencyData = data;
  }

  ngOnInit(): void {

    this.KYCForm = new FormGroup({
      aadhaarNo: new FormControl('', (Validators.required, Validators.minLength(12))),
      panNo: new FormControl('', (Validators.required, Validators.minLength(4))),
      mobileNo: new FormControl(this.agencyData.mobile, (Validators.required, Validators.minLength(10))),
      email: new FormControl(this.agencyData.email),
      latitude: new FormControl(''),
      longitude: new FormControl(''),
      consent: new FormControl('', Validators.pattern('true')),
      bankAccountNo: new FormControl('', Validators.required),
      bankIfsc: new FormControl('', Validators.required),
      device: new FormControl('Mantra', [Validators.required]),
      deviceData: new FormControl('')
    })

    this.KYCOTPValidationForm = new FormGroup({
      otpReferenceId: new FormControl('', Validators.required),
      hash: new FormControl('', Validators.required),
      otp: new FormControl('', Validators.required),
    })

    this.fetchProfile();
    this.getLocation();
    this.fetchSupportedDevices();
  }


  fetchSupportedDevices() {
    this.aepsService.fetchMetaInfo().subscribe(res => {
      let data: any = res
      this.devices = data.devices
    })
  }

  fetchProfile() {
    this.userService.fetchprofile().subscribe(res => {
      let profile: any = res;
      this.KYCForm.patchValue({ panNo: profile.profileData[0].panNumber });
      this.loading = false;
    },
      err => {
        this.dialogRef.close();
        console.error(err)
      })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  initiateKYC() {
    this.kycOTPFlag = false;
    this.initiationError = false;
    this.initiationErrorMsg = '';


    // if (environment.production) {
    this.KYCForm.patchValue({
      latitude: this.KYCForm.value.latitude.toString(),
      longitude: this.KYCForm.value.longitude.toString()
    })

    const biometircDialog = this.dialog.open(CapturebiometricComponent, {
      width: '350px', data: {
        device: 'Mantra'
        , useDummy: environment.AEPS_DUMMY_RESPONSE
      }
    });
    biometircDialog.afterClosed().subscribe(result => {

      if (result != undefined && result.success) {
        let aDt: any = {}
        let dvcData: any = result.data

        this.KYCForm.patchValue({
          deviceData: btoa(dvcData),
        })

        this.aepsService.registerAgent(this.KYCForm.value).subscribe(res => {
          let response: any = res
          this.handleKYCOTP(response);
        }, err => {
          this.initiationError = true;
          this.initiationErrorMsg = err.error.message;
        })        
      }
      
    });
    // } else {
    //   this.handleKYCOTP({"success":true,"message":null,"otpReferenceId":"OTY4ZDg2NzctMDk2ZS00MzhmLTlhNzMtOWJiZjllY2MzMWZl","hash":"04fCjjvaZPV2G44GYIRwK1BN1Irv2+e93/QGzdnl6CPP2tLljSLpyOo8wdOXsShTaGYuQ7dusLvlUoMdD7B2HcO1rWO2yoUXeLep9lrjSuhHM1Ciks3YbKu/IDbONi/3luemN//mj6MeoiB7wX94TAyrWcldStgsMDLLMjWrDXLN1NaJDi10tp7hBzMCb5JdXfhRSc9b33MHPCxEtn0JgPbBZmd59gX5mOhK7c7fqsaAhK4v6gSGwMnIGowm7E7q"});
    // }
  }

  validateOTP() {
    if (environment.production) {
      this.userService.validateKYCOTP(this.KYCOTPValidationForm.value).subscribe(res => {
        let response: any = res
        this.handleOTPValidation(response);
      }, err => {
        this.initiationError = true;
        this.initiationErrorMsg = err.error.message;
      })
    } else {
      this.handleOTPValidation({ "success": true, "code": null, "message": "The given Mobile Number is not matching with Aadhaar profile, please try again with correct details", "outletId": null });
    }
  }

  handleKYCOTP(kycInitResponse: any) {
    this.kycOTPFlag = true;
    this.KYCOTPValidationForm.patchValue({
      otpReferenceId: kycInitResponse.otpReferenceId,
      hash: kycInitResponse.hash
    })
  }


  handleOTPValidation(otpValidationResponse: any) {
    if (otpValidationResponse.success) {
      Swal.fire({
        icon: 'info',
        title: 'Success',
        text: 'eKYC completed successfully',
      })
      this.dialogRef.close({ success: true });
    } else {
      this.kycOTPFlag = false;
      this.initiationError = true;
      this.initiationErrorMsg = otpValidationResponse.message;
    }
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position) {
          this.KYCForm.patchValue({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          })
        }
      },
        (geoError: PositionError) => {

          console.log('Error', geoError.message);
        }
      );
    } else {
      console.log('Error', "Geolocation is not supported by this browser.", 'error');
    }
  }

}
