import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AccountsService } from 'src/app/Services/accounts.service';
import * as XLSX from 'xlsx'
import { AuthService } from 'src/app/Services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-credit',
  templateUrl: './add-credit.component.html',
  styleUrls: ['./add-credit.component.css']
})
export class AddCreditComponent implements OnInit {
  @ViewChild('TABLE') table: ElementRef;

  AddCreditForm:FormGroup;
  Loginresponse;
  _Responsedata;
  AddCreditResponse;
  Showmessage:boolean=false;
  _Usertype:any;
  constructor(private _AccountsService:AccountsService,
    private _AuthAgentService: AuthService,
    private route: Router,) { }
  ExportTOExcel()

  {
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'File');
    
    /* save to file */
    XLSX.writeFile(wb, 'File.xlsx');
    
  

}
  ngOnInit(): void {
    this._AuthAgentService.CheckSesstion();
    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata=<any>JSON.parse(this.Loginresponse);
    this._Usertype=this._Responsedata.loginData[0].userType;  
       if(this._Usertype !="OO" && this._Usertype !="OOU"){
          this.route.navigate(['/dashboard']);
        }


  }
  
  
  
 
}
