import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AccountsService } from 'src/app/Services/accounts.service';
import * as XLSX from 'xlsx';
import { AuthService } from 'src/app/Services/auth.service';
import { CategoryService } from 'src/app/Services/category.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { WalletService } from 'src/app/Services/wallet.service';
import { UserService } from 'src/app/Services/user.service';
@Component({
  selector: 'app-agent-payment-list',
  templateUrl: './agent-payment-list.component.html',
  styleUrls: ['./agent-payment-list.component.css']
})
export class AgentPaymentListComponent implements OnInit {
  @ViewChild('TABLE') table: ElementRef;
  agentdetailspop = false;
  Showmessage = false;
  AgentPaymentForm: FormGroup;
  ManualForm: FormGroup;
  AgentPaymentResponse;
  Loginresponse;
  _Responsedata;
  _Usertype: any;
  ShowListdata = false;
  selectedValueAgentCode: any;
  selectedStatus: any;
  TransactionId: any;
  AvailableLimit: any;
  _AgentcodeBind: any;
  ShowmessageAgentcode: any;
  AddCreditForm: FormGroup;
  _AgentcodeSend: any;
  AvailableSend: any;
  WalletUpdateShowmessage = false;
  WalletUpdateResponse: any;
  filteredAgents: Observable<any[]>;
  loaderhide = false;
  txnTypes: Observable<any[]>;

  constructor(private _AccountsService: AccountsService, private _AuthAgentService: AuthService,
              private _CategoryService: CategoryService, private alertService: ToastrService,
              private route: Router,private walletService: WalletService, private userService: UserService) { }

  private setAgentDropDownFilter(){
    this.filteredAgents = this.AgentPaymentForm.get('AgentCode').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  ngOnInit(): void {
    this._AuthAgentService.CheckSesstion();
    this.selectedStatus = 'AddCredit';
    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata = ( JSON.parse(this.Loginresponse) as any);
    this._Usertype = this._Responsedata.loginData[0].userType;

    if (this._Usertype != 'OO' && this._Usertype != 'OOU' && this._Usertype != 'D') {
      this.route.navigate(['/dashboard']);
    }
    else {
      { this.ShowListdata = true; }
    }
    try {
      this.userService.AgentcodeBind().subscribe(
        StatecityData => {
          this._AgentcodeBind = StatecityData;
          this.setAgentDropDownFilter();
          if (this._AgentcodeBind.statusCode === 0)
          {
            this.ShowmessageAgentcode = true;
          }
        }
      );
    }
    catch (error) {
    }

    this.AgentPaymentForm = new FormGroup({
      Limit: new FormControl('ALL'),
      AgentCode: new FormControl(''),
      ACode: new FormControl(''),
    });

    // this.ManualForm = new FormGroup({
    //   Limit: new FormControl('ALL'),
    //   AgentCode: new FormControl(''),
    //   Mode: new FormControl('WEB'),
    //   Merchant: new FormControl(this._Responsedata.loginData[0].merchant),
    //   LoggedInUserType: new FormControl(this._Responsedata.loginData[0].userType),
    //   AvailableLimit: new FormControl(''),
    // });


    this.AddCreditForm = new FormGroup({
      Amount: new FormControl('', [Validators.required]),
      AgentCode: new FormControl(''),
      Remarks: new FormControl('', [Validators.required]),
      TransferType: new FormControl('', [Validators.required]),
      TransactionType: new FormControl('', [Validators.required]),
      AvailableBalance: new FormControl(''),
    });

  }

  closeagentdetailsbtn() {
    this.agentdetailspop = false;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const filteredData =  this._AgentcodeBind.data.filter(option => option.agentCodeAndName.toLowerCase().indexOf(filterValue) === 0);
    this.AgentPaymentForm.get('ACode').setValue(filteredData[0].agentCode);
    return filteredData;
  }

  searchAgent() {
    const formData = this.AgentPaymentForm.value;
    if (formData.AgentCode != '') {
      formData.AgentCode = this.AgentPaymentForm.value.ACode;
    }
    this.walletService.WalletSummary(formData)
      .subscribe(
        TransactionList => {
          this.Showmessage = true;
          this.AgentPaymentResponse = TransactionList;

          if (this.AgentPaymentResponse.statusCode == 1){
            this.alertService.error(this.AgentPaymentResponse.statusMessage);
          }
          // else{
          //   this.alertService.success(this.AgentPaymentResponse.statusMessage);
          // }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  AddCredit() {
    this.loaderhide = true;
    this.walletService.WalletUpdate(this.AddCreditForm.value)
      .subscribe(
        TransactionList => {
          this.WalletUpdateResponse = TransactionList;
          this.Wallet_wait();
          this.loaderhide = false;
          if (this.WalletUpdateResponse.statusCode == 0){
            this.alertService.success(this.WalletUpdateResponse.statusMessage);
          }
          else
          {
            this.alertService.error(this.WalletUpdateResponse.statusMessage);
          }
          this.searchAgent();
          this.AddCreditForm.reset();
        },
        (error) => {
          this.loaderhide = false;
          console.log(error);
        }
      );
  }

  // ExportTOExcel() {
  //   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'File');
  //   /* save to file */
  //   XLSX.writeFile(wb, 'File.xlsx');
  // }

  Fun_Manula(Amt: number, AgentCode: any) {
    this.AvailableSend = Amt;
    this._AgentcodeSend = AgentCode;
    this.agentdetailspop = true;

    this._AccountsService.fetchTxnTypes(AgentCode).subscribe(res=>{
      let apiResponse: any = res
      this.txnTypes = apiResponse;
    })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  Wallet_wait(): void {
    // this.WalletUpdateShowmessage = true;
    // setTimeout(function() {
    //   this.WalletUpdateShowmessage = false;
    this.agentdetailspop = false;
    if (this.WalletUpdateResponse.statusCode == 0) {
        this.amount.reset();
        this.remarksB.reset();
      }

    // .bind(this), 400);
  }

  get amount(): any { return this.AddCreditForm.get('Amount'); }
  get remarksB(): any { return this.AddCreditForm.get('Remarks'); }

  resetAgentCode(){
    this.AgentPaymentForm.get('AgentCode').setValue('');
    this.AgentPaymentForm.get('ACode').setValue('');
  }

}
