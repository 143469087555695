import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators, FormGroup, ValidationErrors } from '@angular/forms';
import { AuthService } from '../Services/auth.service';
import { StateCity } from '../Services/StateCity';
import { Login } from '../Classes/login';
import { ToastrService } from 'ngx-toastr';
import { StateCityclass } from '../Classes/Statecity';
import { Router } from '@angular/router';
import { CredentialscheckService } from '../Services/credentialscheck.service';
import { mixinInitialized } from '@angular/material/core';
import { environment } from 'src/environments/environment';
import { UserService } from '../Services/user.service';

@Component({
  selector: 'registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
  providers: [AuthService, StateCity]
})

export class RegistrationComponent implements OnInit {


  RegistrationForm: FormGroup;
  C_LoginResponse: any = {};
  C_StateCityclass: StateCityclass[];
  checked1 = true; checked2 = true;
  checked3 = true; indeterminate = true;
  labelPosition: 'before' | 'after' = 'after';
  postId; statusMessage;
  cityData: any = [];
  Showmessage = false;
  selectedValue: string;
  public edited = false;
  Loginresponse: string;
  _Responsedata: any;
  Loginuser = false;
  ErrorMessage = false;
  UserType: any;
  User_TypeAdmin = false;
  User_TypeSales = false;
  User_TypeAgent = false;
  User_Typedist = false;
  User_Withoutlogin = false;
  ParentId: any;
  regUserTypes = []

  panCardDoc: File
  IsAnonymous = true

  constructor(
    private Authagentservices: AuthService,
    private userService: UserService,
    private route: Router,
    private Statecity: StateCity,
    private cd: ChangeDetectorRef,
    private _CredentialscheckService: CredentialscheckService,
    private alertService: ToastrService
  ) { 

    this.initForm();
  }

  get name() {
    return this.RegistrationForm.get('FirstName');
  }

  get lastName() {
    return this.RegistrationForm.get('LastName');
  }

  get email() {
    return this.RegistrationForm.get('Email');
  }
  get mobile() {
    return this.RegistrationForm.get('Mobile');
  }

  get panNo() {
    return this.RegistrationForm.get('PanNumber');
  }


  @ViewChild('fileInput') el: ElementRef;
  imageUrl: any = 'https://i.pinimg.com/236x/d6/27/d9/d627d9cda385317de4812a4f7bd922e9--man--iron-man.jpg';
  editFile = true;
  removeUpload = false;
  isLoading = false;

  initForm(){
    this.RegistrationForm = new FormGroup({
      Email: new FormControl('', [Validators.required, Validators.email]),
      Mobile: new FormControl('', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]),
      AgencyName: new FormControl('', [Validators.required]),
      UserType: new FormControl('', [Validators.required]),
      Title: new FormControl('Mr'),
      FirstName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      LastName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      Address: new FormControl('', [Validators.required]),

      PanName: new FormControl('', [Validators.required]),
      PanNumber: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}')]),
      PanDocId: new FormControl('', [Validators.required]),
      PanDocName: new FormControl('', [Validators.required]),

      TypeOfAddress: new FormControl('', [Validators.required]),
      AddressProofNumber: new FormControl('', [Validators.required]),
      AddressProofDocName: new FormControl('', [Validators.required]),
      AddressProofDocId: new FormControl('', [Validators.required]),

      City: new FormControl('', [Validators.required]),
      State: new FormControl('', [Validators.required]),
      Country: new FormControl('India'),

      PinCode: new FormControl('', [Validators.required, Validators.maxLength(6)]),
      GSTNo: new FormControl('')

    });
  }

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }
    return this.email.hasError('email') ? 'Not a valid email' : '';
  }

  getmoberrormsg() {
    if (this.mobile.hasError('required')) {
      return 'Enter mobile number';
    }
    return this.mobile.hasError('pattern') ? 'Not a valid mobile numbers' : '';
  }


  ngOnInit() {

    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata = (JSON.parse(this.Loginresponse) as any);


    if (this.Loginresponse != null) {
      this.IsAnonymous = false;
      this.UserType = this._Responsedata.loginData[0].userType;

      if (this.UserType == 'OO' || this.UserType == 'OOU') {
        this.User_TypeAdmin = true;
        //  return;
      }
      else if (this.UserType == 'A') {
        this.User_TypeAgent = true;
        //  return;
      }
      else if (this.UserType == 'D') {
        this.User_Typedist = true;
        //  return;
      }
      else if (this.UserType == 'S') {
        this.User_TypeSales = true;

      }
      else {
        this.User_Withoutlogin = true;
      }

      if (this._Responsedata != null) {
        this.Loginuser = true;
        this.Authagentservices.CheckSesstion();
      }

      // if (this.Loginresponse != null && this.UserType == 'D') {
      //   this.ParentId = this._Responsedata.loginData[0].identifier;
      // } else {
      //   this.ParentId = 'MT0001';
      // }

    }
    else {
      this.User_Withoutlogin = true;
    }

    this.initializeRegisterationUserType();
    this.Statecity.fetchStates().subscribe(
      StatecityData => this.statusMessage = StatecityData
    );
   

  }

  resetRegistration() {
    this.RegistrationForm.reset();
  }

  getDomain() {
    if (environment.production)
      return window.location.host
    else
      return localStorage.getItem('domain')
  }

  SubmitRegistration() {

    this.getFormValidationErrors();
    this.isLoading = true;
    this.Showmessage = false;
    this.RegistrationForm.value.domain = this.getDomain();
    this.Authagentservices.registerUser(this.RegistrationForm.value, this.IsAnonymous).subscribe(
      data => {
        const responsedata: any = data;
        this.C_LoginResponse = (data as any);
        // this.RegistrationForm.reset();
        if (this.IsAnonymous) {
          this.route.navigate(['/login']);
          this.alertService.success(responsedata.statusMessage);
        } else {
          this.alertService.error(responsedata.statusMessage);
        }
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
        this.alertService.error('Error in registration, please try again later');
      }
    );

  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const Logo1 = event.target.files[0];
      this.RegistrationForm.patchValue({ Logo: Logo1 });
    }
  }


  onChangeProductdataSelected(id: string) {
    if (id != undefined) {
      this.Statecity.fetchCities(id).subscribe(
        data => {
          this.cityData = data;
        }, (error) => {
          console.log(error);
        }
      );
    }
  }

  Regwait(): void {
    this.Showmessage = true;
    setTimeout(function () {

      this.Showmessage = false;
      this.RegistrationForm.reset();

    }.bind(this), 3000);
  }

  RegwaitError(): void {
    this.Showmessage = true;
    setTimeout(function () {

      this.Showmessage = false;

    }.bind(this), 3000);
  }


  onPanDocSelected(event) {
    this.RegistrationForm.patchValue(
      { PanDocName: event.target.files[0].name }
    );
    this.userService.uploadFile({ type: 'pan', file: event.target.files[0] }).subscribe(res => {
      let uploadRes: any = res
      this.RegistrationForm.patchValue(
        { PanDocId: uploadRes.id }
      );
    }, (error) => {
      console.log(JSON.stringify(error))
      this.alertService.error('Could not upload file, please try again later')
    })
  }

  onAddressProofDocSelected(event) {
    this.RegistrationForm.patchValue(
      { AddressProofDocName: event.target.files[0].name }
    );
    this.userService.uploadFile({ type: 'address', file: event.target.files[0] }).subscribe(res => {
      let uploadRes: any = res
      this.RegistrationForm.patchValue(
        { AddressProofDocId: uploadRes.id }
      );
      console.log(JSON.stringify(this.RegistrationForm.value));
    }, (error) => {
      this.alertService.error('Could not upload file, please try again later')
    })
    
  }

  initializeRegisterationUserType() {
    let agent = { 'Code': 'A', 'Name': 'Agent' }
    let distributor = { 'Code': 'D', 'Name': 'Distributor' }
    let staff = { 'Code': 'S', 'Name': 'Staff' }
    let sales = { 'Code': 'OOU', 'Name': 'Sales' }
    let merchant = { 'Code': 'M', 'Name': 'Merchant' }

    if (this.UserType != null && this.UserType != undefined) {
      if (this.UserType == 'SA') {
        this.regUserTypes = [merchant]
      }

      if (this.UserType == 'OO') {
        this.regUserTypes = [agent, distributor, staff, sales]
      }

      if (this.UserType == 'OOU') {
        this.regUserTypes = [agent, distributor]
      }

      if (this.UserType == 'D') {
        this.regUserTypes = [agent]
      }
    } else {
      this.regUserTypes = [agent, distributor];
    }

  }

  getFormValidationErrors() {
    Object.keys(this.RegistrationForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.RegistrationForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  resetForm(){
    this.RegistrationForm.reset();
  }
}