<div class="container-fluid customehigt bg-light">
  <div class="container-fluid">

    <div class="row py-2">
      <div class="depositrequest">
        <h3>Account Statement</h3>
        <form class="form-group mb-0" [formGroup]="AccountStatementForm">

          <div class="row">

            <div class="col-md-2 col-6">
              <mat-form-field class="example-full-width">
                <mat-label>From Date</mat-label>
                <input formControlName="FromDate" matInput [matDatepicker]="pickerFromDate">
                <mat-datepicker-toggle matSuffix [for]="pickerFromDate">
                </mat-datepicker-toggle>
                <mat-datepicker #pickerFromDate></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-2 col-6">
              <mat-form-field class="example-full-width">
                <mat-label>To Date</mat-label>
                <input formControlName="Todate" matInput [matDatepicker]="pickerTodate">
                <mat-datepicker-toggle matSuffix [for]="pickerTodate">
                </mat-datepicker-toggle>
                <mat-datepicker #pickerTodate></mat-datepicker>
              </mat-form-field>
            </div>

         
            <div class="col-md-2 col-6">
              <mat-form-field>
                <mat-label>Transaction Type</mat-label>
                <mat-select formControlName="TransactionType" disableRipple>
                  <mat-option value="">All</mat-option>
                  <mat-option value={{txnType.code}} *ngFor="let txnType of txnTypes">{{txnType.descr}}</mat-option>
                  <!-- <mat-option value="Credit">Credit</mat-option>
                  <mat-option value="Deposit">Deposit</mat-option>
                  <mat-option value="Incentive">Deposit Incentive</mat-option>    -->
                  <!-- <mat-option value="Dmt">DMT</mat-option>
                  <mat-option value="Dmt Refund">DMT Refund</mat-option>
                  <mat-option value="AccVal">AccVal</mat-option>
                  <mat-option value="AccVal Refund">AccVal Refund</mat-option>
                  <mat-option value="Cash Withdrawal">AEPS Withdrawal</mat-option>
                  <mat-option value="ACW Refund">AEPS Refund</mat-option>
                  <mat-option value="Air">Air</mat-option>
                  <mat-option value="Air Refund">Air Refund</mat-option>
                  <mat-option value="Rail">Rail</mat-option>
                  <mat-option value="Rail Refund">Rail Refund</mat-option>
                  <mat-option value="Utility">Utility</mat-option>
                  <mat-option value="Utility Refund">Utility Refund</mat-option>
                  <mat-option value="Dist Transfer">Dist Transfer</mat-option> -->
                  
                  <!-- <mat-option *ngIf = "_Usertype == 'D'" value="Dist Transfer">Dist Transfer</mat-option> -->
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-1 col-6">
              <mat-form-field>
                <mat-label>Transaction ID</mat-label>
                <input formControlName="TransactionId" matInput placeholder="Enter Transaction Id">
              </mat-form-field>
            </div>
            <div class="col-md-3" *ngIf="ShowListdata">
              <mat-form-field>
                <mat-label>Agent Name/Code</mat-label>

                <!-- <mat-select  disableRipple  #AgentCode 
                     formControlName="AgentCode">
                     <mat-option value="">All</mat-option>
                      <mat-option  *ngFor="let binddata of _AgentcodeBind.data" [value]="binddata.agentCodeAndName.split('(')[1].split(')')[0]" >{{binddata.agentCodeAndName}}</mat-option >
      
                    </mat-select > -->

                <input (click)="resetAgentCode()" type="text" placeholder="Select Agent Code" aria-label="Number" matInput
                  formControlName="AgentCode" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" style="font-size:10pt">
                  <mat-option *ngFor="let binddata of filteredAgents | async" [value]="binddata.agentCodeAndName">
                    {{binddata.agentCodeAndName}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-xl-2">
              <div class="buttnclass">

                <button class="btn-block btnprimary mr-2" style="width: 100%;" mat-raised-button
                  (click)="Fun_AccountStatementList()">Search</button>
             
                <button mat-raised-button color="primary" class="downloadexal" data-toggle="tooltip"
                  data-placement="top" title="Export as Excel" (click)="ExportTOExcel()"><i class="fa fa-download"
                    aria-hidden="true"></i></button>
              </div>
              <!-- <p class="text-danger mt-2 text-center font-weight-bold" *ngIf="Showmessage">
                {{AccountStatementListResponse.statusMessage}}</p> -->
            </div>
          </div>

        </form>
      </div>
    </div>
    <div class="row mb-5" #TABLE>
      <div class="col-md-12 table-responsive p-0">
        <table class="table table-hover table-list ">
          <thead>
            <tr>
              <td>S.No</td>
              <td *ngIf="ShowListdata">Agency Name/Code </td>
              <td>Transaction Id</td>
              <td>Transaction <br> Type</td>
              <td>Credit</td>
              <td>Debit</td>
              <td>Balance </td>
              <td>Txn Date</td>
              <td>Updated <br> By</td>
              <td>User <br>Type</td>
              <td>Remarks</td>
              <!-- <td>description</td>
              <td>merchantBalance</td>
              <td>merchantCreditedAmount</td>
              <td>merchantDebitedAmount</td>
              <td>transDescription:</td> -->
            </tr>
          </thead>

          <tbody *ngIf="Showmessagelist">
            <tr *ngFor="let data of AccountStatementListResponse.ledgerDetailData;let i = index">
              <td>{{i+1}}</td>
              <td *ngIf="ShowListdata">{{data.agencyName}}/{{data.agentCode}}</td>
              <td>{{data.transactionId}}</td>
              <td>{{data.transactionType}}</td>
              <td>{{data.creditedAmount}}</td>
              <td>{{data.debitedAmount}}</td>
              <td>{{data.agentBalance}} </td>
              <td>{{data.createdDate|date : "dd/MM/yyyy  h:mm:ss a"}}</td>
              <td>{{data.updatedBy}}</td>
              <td>{{data.userType}}</td>
              <td>{{data.remarks}}</td>
              <!-- <td>{{data.description}}</td>
              <td>{{data.merchantBalance}}</td>
              <td>{{data.merchantCreditedAmount}}</td>
              <td>{{data.merchantDebitedAmount}}</td>
              <td>{{data.transDescription}}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


<div class="loader-maine" *ngIf="loaderhide">

  <div class="loader">
    <!--<div class="loader-inner">cvxcv</div>-->
    <div class="loader-inner"></div>
    <div class="loader-inner box"></div>
    <div class="box-1"></div>
    <div class="box-2"></div>
    <div class="box-3"></div>
  </div>

</div>