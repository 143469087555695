import { Component, OnInit } from '@angular/core';
import { getMatIconFailedToSanitizeLiteralError } from '@angular/material/icon';
import { environment } from 'src/environments/environment';
import { InitService } from '../init.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  MERCHANT_NAME: string;

  constructor(private initService: InitService) { }

  ngOnInit(): void {
    this.initData();
    this.initService.emitChangeSource.subscribe(action => {
      if (action === 'init-data-fetch')
      {
       this.initData();
      }
    })

  }

  initData(){
    if (localStorage.getItem('mData')!=null)
      this.MERCHANT_NAME = JSON.parse(localStorage.getItem('mData')).name
  }

}
