<div class="container-fluid bg-light customeheight">
  <div class="container-fluid">
    <div class="row py-2">

      <div class="depositrequest">
        <h3>Agent Activation</h3>
        <form class="form-group mb-0" [formGroup]="SearchForm">

          <div class="row  justify-content-between">

            <!-- <div class="col-xl-3">
         
  
        <mat-form-field class="example-full-width">
          <mat-label>From Date</mat-label>
          <input formControlName="FromDate" matInput [matDatepicker]="pickerFromDate">
          <mat-datepicker-toggle matSuffix [for]="pickerFromDate">
         
          </mat-datepicker-toggle>
          <mat-datepicker #pickerFromDate></mat-datepicker>
        </mat-form-field>
    </div> -->


            <!--     
    <div class="col-xl-3">

      <mat-form-field class="example-full-width">
        <mat-label>To Date</mat-label>
        <input  formControlName="Todate" matInput [matDatepicker]="pickerTodate">
        <mat-datepicker-toggle matSuffix [for]="pickerTodate">
         
        </mat-datepicker-toggle>
        <mat-datepicker #pickerTodate></mat-datepicker>
      </mat-form-field>


    </div> -->

            <div class="col-xl-3">
              <mat-form-field>
                <mat-label>Status</mat-label>
                <mat-select disableRipple formControlName="Status">
                  <mat-option value="0">Newly Registered</mat-option>
                  <mat-option value="2">Rejected</mat-option>
                </mat-select>
              </mat-form-field>


            </div>

            <div class="col-xl-3">
              <div class="buttnclass">
                <button class="btn-block btnprimary " mat-raised-button (click)="fun_Search()">Search</button>



                <!-- <button   mat-raised-button color="primary"class="downloadexal" data-toggle="tooltip"  data-placement="top" title="Export as Excel" (click)="ExportTOExcel()"><i class="fa fa-download" aria-hidden="true"></i></button> -->
              </div>
              <!-- <p class="text-danger mt-2 text-center font-weight-bold" *ngIf="Responsestatusmessage">{{_Userlist.statusMessage}}</p>  -->
            </div>

          </div>
        </form>
      </div>
    </div>




    <div class="row mb-5" #TABLE>
      <div class="col-xl-12 table-responsive p-0">
        <table class="table table-hover table-list">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Mobile</th>

              <th *ngIf="ShowListdata" scope="col">Agency&nbsp;Name / Code</th>
              <th scope="col">User&nbsp;type</th>

              <th scope="col">More +</th>
              <th scope="col">Created On</th>





              <th scope="col">Parent&nbsp;Id</th>
              <th scope="col" class="text-center">Action</th>


            </tr>





          </thead>
          <tbody [formGroup]="ActionActivationForm" *ngIf="ShowmessageUserList">
            <tr *ngFor="let data of _Userlist.activationData;let i = index">
              <td>{{i+1}}</td>
              <td>{{data.firstName}}</td>
              <td>{{data.email}}</td>
              <td>{{data.mobile}}</td>
              <td *ngIf="ShowListdata">{{data.agencyName}} / {{data.agentCode}}</td>
              <td>{{data.userType}}</td>

              <td hidden #pinV>{{data.pinCode}}</td>
              <td hidden #stateV>{{data.state}}</td>
              <td hidden #cityV>{{data.city}}</td>
              <td hidden #addressV>{{data.address}}</td>
              <th scope="col"><span style="cursor: pointer; line-height: 30px; color: #ff0000;"
                  (click)="opanagentdetails(data.pinCode,data.state,data.city,data.address)">More</span></th>

              <td>{{data.registrationDate | date : "dd/MM/yyyy" }}</td>
              <td>{{data.parent}}</td>
              <td>
                <div class="actionbtyn">
                  <button data-toggle="modal" data-target="#PopActive" type="button" class=" btn-success"
                    mat-raised-button data-toggle="tooltip" data-placement="top" title="Accept"
                    (click)="Activeuser(data.agentCode,data.parent)"><i class="fa fa-check"
                      aria-hidden="true"></i></button>
                  <button *ngIf="searchStatus != '2'" data-toggle="modal" data-target="#Deactive" mat-raised-button
                    data-toggle="tooltip" data-placement="top" title="Reject" type="button" class=" btn-danger"
                    (click)="Deactiveuser(data.agentCode,data.parent)"><i class="fa fa-times"
                      aria-hidden="true"></i></button>
                </div>
              </td>


            </tr>


          </tbody>

        </table>

      </div>
    </div>
    <!-- Modal -->

    <!-- Modal -->
  </div>
</div>
<!-- <div class="modal fade" id="PopActive" tabindex="-1" role="dialog" aria-labelledby="PopActive" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title my-auto">Status</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
        
        
                   <div class="col-xl-12">
                    
                       <p class="text-danger mt-2 text-center font-weight-bold text-capitalize" *ngIf="ShowmessageUseractivation">{{_Useractivation.statusMessage}}</p>  
                        
                       
                       </div>
       <button  class="close" data-dismiss="modal" aria-label="Close"  mat-raised-button class="slipbtn" (click)="Reloaduserlist()" >ok</button>
    
        </div>
        
       
      </div>
    </div>
  </div> -->
<!-- activate popup -->
<div class="agentdetailsbox" id="PopActive" *ngIf="activatepop">
  <div class="innerboxagntde morebox">
    <div class="closebtn" (click)="closeagentdetailsbtn()">
      <img src="../../../assets/images/icons/close.svg" alt="">
    </div>
    <h5>Status</h5>
    <div class="row justify-content-between">
      <div class="col-xl-12">
        <p class="text-danger mt-2 text-center font-weight-bold text-uppercase" *ngIf="ShowmessageUseractivation">
          {{_Useractivation.statusMessage}}</p>

      </div>
      <div class="col-xl-12 text-right">
        <button mat-raised-button class="slipbtn" (click)="Reloaduserlist()">ok</button>
      </div>
    </div>

  </div>
</div>
<!-- activate popup -->

<!-- <div class="modal fade" id="Deactive" tabindex="-1" role="dialog" aria-labelledby="Deactive" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title my-auto">Status</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
        
        
                   <div class="col-xl-12">
                    
                     
                    <p class="text-danger mt-2 text-center font-weight-bold text-capitalize" *ngIf="ShowmessageD">{{_Userdeactivation.statusMessage}}</p> 
                       
                       </div>
       <button  class="close" data-dismiss="modal" aria-label="Close"  mat-raised-button class="slipbtn"  (click)="Reloaduserlist()">ok</button>
    
        </div>
        
       
      </div>
    </div>
  </div> -->
<!-- deactiveta popup -->
<div class="agentdetailsbox" id="Deactive" *ngIf="deactivatepop">
  <div class="innerboxagntde morebox">
    <div class="closebtn" (click)="closeagentdetailsbtn()">
      <img src="../../../assets/images/icons/close.svg" alt="">
    </div>
    <h5>Status</h5>
    <div class="row justify-content-between">
      <div class="col-xl-12">
        <p class="text-danger mt-2 text-center font-weight-bold text-uppercase" *ngIf="ShowmessageD">
          {{_Userdeactivation.statusMessage}}</p>

      </div>
      <div class="col-xl-12 text-right">
        <button mat-raised-button class="slipbtn" (click)="Reloaduserlist()">ok</button>
      </div>
    </div>

  </div>
</div>
<!-- deactiveta popup -->
<div class="agentdetailsbox" *ngIf="agentdetailspop">
  <div class="innerboxagntde morebox">
    <div class="closebtn" (click)="closeagentdetailsbtn()">
      <img src="../../../assets/images/icons/close.svg" alt="">
    </div>
    <h5>More Details</h5>
    <div class="row justify-content-between">
      <div class="col-xl-12">
        <div class="detailbx">
          <label>Pin&nbsp;Code</label>
          <p>{{pinVshow}}</p>
        </div>
        <div class="detailbx">
          <label>Address</label>
          <p>{{addressVshow}}</p>
        </div>
        <div class="detailbx">
          <label>State</label>
          <p>{{stateVshow}}</p>
        </div>
        <div class="detailbx">
          <label>City</label>
          <p>{{cityVshow}}</p>
        </div>
        <div class="detailbx">
          <label>Address Proof Image</label>
          <p><a (click)="opanreceipt()" href="javascript:void(0)">View</a></p>
        </div>
        <div class="detailbx">
          <label>Pancard Image</label>
          <p><a (click)="opanreceipt()" href="javascript:void(0)">View</a></p>
        </div>

      </div>

    </div>
  </div>
</div>

<div class="agentdetailsbox" *ngIf="imgereceipt">
  <div class="innerboxagntde">
    <div class="closebtn" (click)="closeagentdetailsbtn()">
      <img src="../../../assets/images/icons/close.svg" alt="">
    </div>
    <h5>Receipt</h5>
    <div class="row justify-content-between">
      <div class="col-xl-12">
        <img src="../../assets/images/background/flight-bg.jpg" class="img-fluid" alt="">

      </div>

    </div>
  </div>
</div>