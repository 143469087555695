<div class="container-fluid py-4" style="height:100%">
  <div class="container">
    <div class="row">

      <div class="registrationform">
        <h3 class="mb-2">Registration Form</h3>
        <form class="form-group" [formGroup]="RegistrationForm">
          <div class="row pl-5 pr-5 ">

            <div class="col-xl-4">
              <mat-form-field>
                <mat-label>User Type </mat-label>
                <mat-select formControlName="UserType">
                  <mat-option *ngFor="let regUserType of regUserTypes"
                    value={{regUserType.Code}}>{{regUserType.Name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-xl-8">
              <mat-form-field>
                <mat-label>Enter Agency Name</mat-label>
                <input matInput formControlName="AgencyName" autocomplete="off" appAlphabetOnly maxlength="120">
              </mat-form-field>
            </div>

          </div>
          <div class="row pl-5 pr-5 ">
            <div class="col-xl-4">
              <mat-form-field>
                <mat-label>First Name</mat-label>
                <input appAlphabetOnly matInput placeholder="First Name" formControlName="FirstName" autocomplete="off"
                  maxlength="30">
                  <div *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger">
                    <mat-error *ngIf="name.errors.required">Name is required.</mat-error>
                    <mat-error *ngIf="name.errors.minlength">Name must be at least 3 characters long.</mat-error>
                  </div>
              </mat-form-field>
            </div>
            <div class="col-xl-4">
              <mat-form-field>
                <mat-label>Last Name</mat-label>
                <input appAlphabetOnly matInput placeholder="Last Name" formControlName="LastName" autocomplete="off"
                  maxlength="30">
                <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="text-danger">
                  <mat-error *ngIf="lastName.errors.required">Last Name is required.</mat-error>
                  <mat-error *ngIf="lastName.errors.minlength">Last Name must be at least 3 characters long.</mat-error>
                </div>
              </mat-form-field>
            </div>
          </div>
          <div class="row pl-5 pr-5 ">
            <div class="col-xl-4">
              <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput placeholder="Ex. pat@example.com" formControlName="Email" autocomplete="off">
                <!-- <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error> -->
                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
                  <div *ngIf="email.errors.required">{{getErrorMessage()}}</div>
                  <div *ngIf="email.errors.email">{{getErrorMessage()}}</div>
                </div>
              </mat-form-field>
            </div>
            <div class="col-xl-4">
              <mat-form-field>
                <mat-label>Mobile</mat-label>
                <input autocomplete="off" matInput placeholder="Mobile" formControlName="Mobile" maxlength="10"
                  appNumberOnlyDirective>
                <div *ngIf="mobile.invalid && (mobile.dirty || mobile.touched)" class="text-danger">
                  <mat-error *ngIf="email.invalid">{{getmoberrormsg()}}</mat-error>
                </div>
              </mat-form-field>
            </div>
          </div>
          <div class="row pl-5 pr-5 ">
            <div class="col-xl-12">
              <mat-form-field>
                <mat-label>Address </mat-label>
                <textarea matInput formControlName="Address" maxlength="200"></textarea>
              </mat-form-field>
            </div>
            <div class="col-xl-4">
              <mat-form-field>
                <mat-label>State</mat-label>
                <mat-select *ngIf="statusMessage != undefined" disableRipple #State formControlName="State"
                  [(ngModel)]="selectedValue" (ngModelChange)='onChangeProductdataSelected($event)'>
                  <mat-option *ngFor="let binddata of statusMessage.stateData" [value]="binddata.state">
                    {{binddata.state}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-xl-4">
              <mat-form-field>
                <mat-label>City</mat-label>
                <mat-select *ngIf="cityData != undefined" disableRipple #State formControlName="City">
                  <mat-option *ngFor="let binddata of cityData.cityData" [value]="binddata.city">{{binddata.city}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-xl-4">
              <mat-form-field>
                <mat-label>Pin Code</mat-label>
                <input matInput maxlength="6" placeholder="Ex. 94105" reuired formControlName="PinCode"
                  autocomplete="off" appNumberOnlyDirective>
              </mat-form-field>
            </div>




            <div class="col-xl-4">
              <mat-form-field>
                <mat-label>Type Of Address Proof</mat-label>
                <mat-select disableRipple formControlName="TypeOfAddress">
                  <mat-option value="Adharcard">Aadhar Card</mat-option>
                  <mat-option value="Passport">Passport</mat-option>
                  <mat-option value="Votercard">Voter Card</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-xl-4">
              <mat-form-field>
                <mat-label> Address Proof Number </mat-label>
                <input matInput formControlName="AddressProofNumber" maxlength="30" appAphaNumeric>
              </mat-form-field>
            </div>
            <div class="col-xl-4">
              <div class="avatar-upload">
                <div class="avatar-edit">
                  <label >Address Proof Doc 
                    <sup style="font-size:11pt; color:red">*</sup>
                  </label>
                  <input id="addressProof"  type='file' style="display: none;" #addressProofFileUpload
                    accept=".png, .jpg, .jpeg"  (change)="onAddressProofDocSelected($event)" />

                    <button mat-mini-fab color="primary" class="upload-btn" (click)="addressProofFileUpload.click()">
                      <mat-icon>attach_file</mat-icon>
                    </button> &nbsp; {{ this.RegistrationForm.value.AddressProofDocName || 'No file selected'}}

                </div>
                <!-- <div class="avatar-preview">
                  <div id="imagePreviewAddressProffDoc" [style.backgroundImage]="'url('+ imageUrl +')'">
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="row pl-5 pr-5 ">
            <div class="col-xl-4">
              <mat-form-field>
                <mat-label> Name as on Pan Card</mat-label>
                <input matInput formControlName="PanName" autocomplete="off" appAlphabetOnly maxlength="30">
              </mat-form-field>
            </div>
            <div class="col-xl-4">
              <mat-form-field>
                <mat-label> Pan Card Number </mat-label>
                <input matInput formControlName="PanNumber" maxlength="10" appAphaNumeric autocomplete="off">                
                <div *ngIf="panNo.invalid && (panNo.dirty || panNo.touched)" class="text-danger">
                  <mat-error *ngIf="panNo.errors.pattern">Invalid Pan No</mat-error>
                </div>                
              </mat-form-field>
            </div>


            <div class="col-xl-4">
              <div class="avatar-upload">
                <div class="avatar-edit">
                  <label>Pan Card Doc<sup style="font-size:11pt; color:red">*</sup> </label>

                  <input  id="panDoc" style="display: none;"  type='file' #panCardUpload accept=".png, .jpg, .jpeg" 
                    (change)="onPanDocSelected($event)" />

                    <button mat-mini-fab color="primary" class="upload-btn" (click)="panCardUpload.click()">
                      <mat-icon>attach_file</mat-icon>
                    </button> &nbsp; {{ this.RegistrationForm.value.PanDocName || 'No file selected'}}

                </div>
                <!-- <div class="avatar-preview">
                  <div id="imagePreview" [style.backgroundImage]="'url('+ imageUrl +')'">
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="row pl-5 pr-5 ">
            <div class="col-xl-4">
              <mat-form-field>
                <mat-label> GST Number</mat-label>
                <input matInput formControlName="GSTNo" maxlength="20" appAphaNumeric autocomplete="off">                
              </mat-form-field>              
          </div>
        </div>          
          <div class="row pt-5">
              <div class="col-xl-12 d-flex justify-content-center align-items-center">
                <button [disabled]="!RegistrationForm.valid" class="btn btnprimary"  mat-raised-button (click)="SubmitRegistration()">Register</button>
                &nbsp;&nbsp;
                <button  class="btn btnprimary"  mat-raised-button (click)="resetForm()">Reset</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>