import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpoptons = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  })
};
const httpoptonsa = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})

export class CategoryService {

  constructor(private httpClient: HttpClient) { }




  BankList() {
    return this.httpClient.get(environment.Api_urldmt + 'GetBanks');
  }


  GetCategory(data: any) {
    const Fdate = new Date(data.FromDate);
    const Tdate = new Date(data.Todate);
    const F = this.FormatDate(Fdate);
    const T = this.FormatDate(Tdate);
    if (data.AgentCode == undefined) {
      data.AgentCode = '';
    }
    const pvalue = '?FromDate=' + F + '&Todate=' + T + '&TransactionId=' + data.TransactionId + '&LoggedInUserType=' + data.LoggedInUserType + '&TxnDescription=' + data.TxnDescription + '&ApiTransactionId=' + data.ApiTransactionId + '&Merchant=' + data.Merchant + '&Mode=' + data.Mode + '&LoggedAgentCode=' + data.AgentCode;
    return this.httpClient.get(environment.Api_urldmt + 'GetCommissionList' + pvalue);
  }

  CategoryList(data: any) {

    let pvalue = '?&Product=' + data.Product
    if (data.Category != undefined)
      pvalue = pvalue + '&Category=' + data.Category;
    if (data.Type != undefined)
      pvalue = pvalue + '&Type=' + data.Type

    const url =`${environment.Api_urlcommission}category${pvalue}`
    return this.httpClient.get(environment.Api_urlcommission + 'category' + pvalue);
  }

  AgentCategory(name: string) {

    const parm = new HttpParams().set('Product', name);
    return this.httpClient.get(environment.Api_urlcommission + 'AgentCategory', { params: parm });
  }

  CreateCategory(data: any) {
    return this.httpClient.post(environment.Api_urlcommission + 'category', data);
  }


  // api/admin/AgentCategorySearch?Merchant=MET&=&=WEB&=OO&Product=DMT&=DEFAULT
  AgentCategorySearch(data: any) {
    if (data.AgentCode == undefined) {
      data.AgentCode = '';
    }
    let pvalue = '?'
    pvalue = pvalue + 'AgentCode=' + data.AgentCode + '&Mode=' + data.Mode  + '&Product=' + data.Product + '&Category=' + data.Category;
    return this.httpClient.get(environment.Api_urlcommission + 'category/agentcategorysearch' + pvalue);
  }


  // fetchCommissionCategory(name: string) {
  //   const parm = new HttpParams().set('Product', name);
  //   return this.httpClient.get(environment.API_BASE_URL + '/api/category/mapped', { params: parm });
  // }


  UpdateAgentCategory(Fulldata: any) {
    return this.httpClient.put(environment.Api_urlcommission + 'category/UpdateAgentCategory', Fulldata);
  }


  GetMyCategory(data: any) {
    const pvalue = '?Product=' + data.Product + '&LoggedInAgentCode=' + data.LoggedInAgentCode + '&LoggedInUserType=' + data.LoggedInUserType + '&Merchant=' + data.Merchant;
    return this.httpClient.get(environment.Api_urlcommission + 'AgentCommsion' + pvalue);
  }

  FormatDate(date: Date): string {

    const day = date.getDate();
    const months = date.getMonth() + 1;
    const year = date.getFullYear();
    let day1;
    let months1;

    if (day < 10) {
      day1 = `0${day}`;
    } else { day1 = `${day}`; }

    if (months < 10) {
      months1 = `0${months}`;
    } else { months1 = `${months}`; }
    return `${year}-${months1}-${day1}`;
  }



}

