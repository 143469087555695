import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InitService {

  emitChangeSource = new Subject<any>();
  changeEmitted$ = this.emitChangeSource.asObservable();
  constructor(private httpClient: HttpClient) {

  }

  getDomain() {
    let domain: string
    if (environment.production)
      domain = window.location.host
    else {
      domain = localStorage.getItem('domain')
      if (domain == undefined || domain == null || domain == '')
        domain = 'metsavings.in';
    }
    return domain;
  }

  initializeData() {
    const domain = this.getDomain();
    this.httpClient.get(`${environment.API_BASE_URL}/cms/api/merchants?filters[domain][$eq]=${domain}&populate=*`).subscribe(res => {
      let merchantMetaData: any = res
      localStorage.setItem('mData', JSON.stringify(merchantMetaData.data[0].attributes))
      //TODO: Fetch Merchant Preferences
      this.notifyMerchantFetched()
    })
  }

  getMerchantMetaData() {
    if (localStorage.getItem('mData') != null)
      return JSON.parse(localStorage.getItem('mData'))
    else {
      return null
    }
  }



  fetchRegisteredMerchant() {
    return this.httpClient.get(`${environment.API_BASE_URL}/api/mmap`)
  }

  notifyMerchantFetched() {
    this.emitChangeSource.next('init-data-fetch');
  }
}
