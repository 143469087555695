import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './Services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | import('@angular/router').UrlTree |
    import('rxjs').Observable<boolean | import('@angular/router').UrlTree> | Promise<boolean | import('@angular/router').UrlTree> {

    const allowedUserTypes = route.data.allowedUserTypes;
    const allowedUsers = route.data.allowedUsers;

    if (this.authService.loggedIn()) {
      let loginData = localStorage.getItem('loginData');
      let loginDataJSON = JSON.parse(loginData)
      loginDataJSON = loginDataJSON.loginData[0];
      if (
        (allowedUserTypes.length == 0 && allowedUsers.length == 0) ||
        (allowedUserTypes.length > 0 && allowedUserTypes.indexOf(this.authService.getLoggedInUserType()) >= 0) ||
        (allowedUsers.length > 0 && allowedUsers.indexOf(this.authService.getLoggedInUser()) >= 0)) {
        //TODO: Make it configurable from backend
        if (route.routeConfig.path == 'dashboard' && loginDataJSON.defaultService != 'dashboard') {
          return false
        } else {
          return true;
        }
      }
      else {
        this.router.navigate([loginDataJSON.defaultService]);
        return false;
      }
    }
    else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
