import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DmtService } from 'src/app/Services/dmt.service';
import * as XLSX from 'xlsx';
import { AuthService } from 'src/app/Services/auth.service';
import { CategoryService } from 'src/app/Services/category.service';
import { AnyARecord } from 'dns';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-dmt-transection-list',
  templateUrl: './dmt-transection-list.component.html',
  styleUrls: ['./dmt-transection-list.component.css']
})

export class DmtTransectionListComponent implements OnInit {
  @ViewChild('TABLE') table: ElementRef;

  Loginresponse; _Responsedata;
  DmtListForm: FormGroup;
  StatusCheckForm: FormGroup;
  ReceiptForm: FormGroup;
  _TransactionListResponse;
  Showmessage = false;
  _Usertype: any;
  receiptmoney = false;
  ShowListdata = false;
  StatusCheckmessage = false;
  _AgentcodeBind: any;
  ShowmessageAgentcode = false;
  User_TypeAgent = false;
  User_Typedist = false;
  User_TypeAdmin = false;
  Showmessagetext = false;
  _TransactionRecieptResponse: any;
  loaderhide = false;
  filteredAgents: Observable<any[]>;

  constructor(private _DmtService: DmtService,
              private _AuthAgentService: AuthService,
              private userService: UserService,
              private _CategoryService: CategoryService, private alertService: ToastrService) { }

  private setAgentDropDownFilter() {
    this.filteredAgents = this.DmtListForm.get('AgentCode').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  ngOnInit(): void {
    this._AuthAgentService.CheckSesstion();
    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata = (JSON.parse(this.Loginresponse) as any);
    this._Usertype = this._Responsedata.loginData[0].userType;

    if (this._Usertype == 'OO' || this._Usertype == 'OOU' || this._Usertype == 'D' || this._Usertype == 'S') {
      this.ShowListdata = true;
    }
    if (this._Usertype == 'A') {
      this.User_TypeAgent = true;
    }
    if (this._Usertype == 'D' || this._Usertype == 'S') {
      this.User_Typedist = true;
    }
    if (this._Usertype == 'OO' || this._Usertype == 'OOU') {
      this.User_TypeAdmin = true;
    }

    try {
      this.userService.AgentcodeBind().subscribe(
        StatecityData => {
          this._AgentcodeBind = StatecityData;
          this.setAgentDropDownFilter();
          if (this._AgentcodeBind.statusCode === 0) {
            this.ShowmessageAgentcode = true;
          }
        }
      );
    }
    catch (error) {
    }

    this.DmtListForm = new FormGroup({
      FromDate: new FormControl(new Date),
      Todate: new FormControl(new Date),
      AgentCode: new FormControl(''),
      ACode: new FormControl(''),
      Mode: new FormControl('WEB'),
      Merchant: new FormControl(this._Responsedata.loginData[0].merchant),
      LoggedInUserType: new FormControl(this._Responsedata.loginData[0].userType),
      TransactionId: new FormControl(''),
      TxnDescription: new FormControl(''),
      ApiTransactionId: new FormControl(''),
      RemitterMobile: new FormControl(''),
      LoggedAgentCode: new FormControl(this._Responsedata.loginData[0].identifier),
    });

    this.StatusCheckForm = new FormGroup({
      AgentCode: new FormControl(''),
      TransactionId: new FormControl(''),
    });

    this.ReceiptForm = new FormGroup({
      AgentCode: new FormControl(''),
      Mode: new FormControl('WEB'),
      Merchant: new FormControl(this._Responsedata.loginData[0].merchant),
      LoggedInUserType: new FormControl(this._Responsedata.loginData[0].userType),
      TransactionId: new FormControl(''),
      LoggedInAgentCode: new FormControl(this._Responsedata.loginData[0].identifier),
    });

    this.Fun_DmtTransactionListonload();

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const filteredData = this._AgentcodeBind.data.filter(option => option.agentCodeAndName.toLowerCase().indexOf(filterValue) === 0);
    this.DmtListForm.get('ACode').setValue(filteredData[0].agentCode);
    return filteredData;
  }

  // const ELEMENT_DATA: PeriodicElement[]
  Fun_DmtTransactionList() {
    this.loaderhide = true;
    const formData = this.DmtListForm.value;
    if (formData.AgentCode != '') {
      formData.AgentCode = this.DmtListForm.value.ACode;
    }
    // this._DmtService.DmtTransactionList(this.DmtListForm.value)  Check with sir
    this._DmtService.DmtTransactionList(formData)
      .subscribe(
        TransactionList => {
          this.loaderhide = false;
          this.Showmessage = true;
          this._TransactionListResponse = TransactionList;

          if (this._TransactionListResponse.statusCode == 1) {
            this.alertService.error(this._TransactionListResponse.statusMessage);
          }
          else {
            this.alertService.success(this._TransactionListResponse.statusMessage);
          }

          this.DmtListForm.value.ACode = '';
          this.setAgentDropDownFilter();
        },
        (error) => {
          this.loaderhide = false;
          this.alertService.error("Unable to fetch records, please try again later");
          console.log(error);
        }
      );
  }

  Fun_DmtTransactionListonload() {
    // this.DmtListForm.value.TxnDescription="pending";
    this._DmtService.DmtTransactionList(this.DmtListForm.value)
      .subscribe(
        TransactionList => {
          this.Showmessage = true;
          this._TransactionListResponse = TransactionList;
          this.load_wait();
        },
        (error) => {
          console.log(error);
        }
      );
  }


  fun_StatusCheck(Refid: any, AgentCode: any) {
    this.StatusCheckForm.value.TransactionId = Refid;
    this.StatusCheckForm.value.AgentCode = AgentCode;
    this._DmtService.StatusCheck(this.StatusCheckForm.value).subscribe(
        TransactionList => {
          this._TransactionListResponse = TransactionList;
          this.status_wait();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'File');
    /* save to file */
    XLSX.writeFile(wb, 'File.xlsx');
  }

  fun_receipt(data_transactionId: any) {
    this.loaderhide = true;
    this.ReceiptForm.value.TransactionId = data_transactionId;

    this._DmtService.TransactionReciept(this.ReceiptForm.value)
      .subscribe(
        TransactionList => {

          this._TransactionRecieptResponse = TransactionList;
          if (this._TransactionListResponse.statusCode == 0) {
            this.receiptmoney = true;

          } else {

          }
          this.loaderhide = false;
        },
        (error) => {
          console.log(error);
        }

      );
  }


  closeagentdetailsbtn() {
    this.receiptmoney = false;
  }

  load_wait(): void {
    this.Showmessagetext = true;
    setTimeout(function() {

      this.Showmessagetext = false;

    }.bind(this), 3000);
  }

  status_wait(): void {
    this.StatusCheckmessage = true;
    setTimeout(function() {
      this.StatusCheckmessage = false;
      this.Fun_DmtTransactionListonload();
    }.bind(this), 3500);
  }

  resetAgentCode() {
    this.DmtListForm.get('AgentCode').setValue('');
    this.DmtListForm.get('ACode').setValue('');
  }

}
