import { Component, OnInit, Output, EventEmitter, Renderer2, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../Services/auth.service'
import { MatDialog } from '@angular/material/dialog';
import { ForgotpasswordComponent } from '../forgotpassword/forgotpassword.component'
import { Router } from '@angular/router';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5'
import { environment } from 'src/environments/environment';
import { InitService } from '../init.service';
import { timeStamp } from 'console';
import { getAttrsForDirectiveMatching } from '@angular/compiler/src/render3/view/util';
import { ToastrService } from 'ngx-toastr';

// import { logingconfig } from 'src/config/log'
@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  // md5 = new Md5();
  @Output() LoginFlag = new EventEmitter<any>();

  _logingroup: FormGroup;
  loginResponseJSON: any;
  loginResponse: any;
  _Show: boolean = false;
  latitude: any;
  longitude: any;
  IsProduction: boolean;
  hide = true;
  defaultDomain = 'metsavings.in'
  showOfferings = false;
  defaultService = 'dashboard';
  @ViewChild('form') form: ElementRef;
  constructor(private authService: AuthService,
    private dialog: MatDialog,
    private route: Router,
    private initService: InitService,
    private renderer: Renderer2, private el: ElementRef,
    private alertService: ToastrService) {
  }
  ngOnDestroy(): void {
    this.renderer.removeClass(this.el.nativeElement.ownerDocument.body, 'bgimg');
  }

  opanDialog(serviceType, title) {
    const dialogRef = this.dialog.open(ForgotpasswordComponent, {
      data:
        { serviceType: serviceType, title: title }
    });
  }


  ngOnInit(): void {
    this.IsProduction = environment.production
    try {
      const loginData = localStorage.getItem('loginData')
      let loginProfileData;
      if (loginData != null) {
        loginProfileData = JSON.parse(loginData).loginData[0]
        this.route.navigate(['/' + loginProfileData.defaultService]);
      }
    } catch (error) {
      console.log(error)
    }

    this.initService.initializeData();

    this.initService.changeEmitted$.subscribe(action => {
      if (action === 'init-data-fetch') {
        const merchantData: any = this.initService.getMerchantMetaData();
        this.showOfferings = merchantData.displayOfferings
      }
    })
    this.getLocation();
    
    this._logingroup = new FormGroup({
      Email: new FormControl('', [Validators.required, this.validateUserIdInput]),
      Password: new FormControl('', Validators.required),
      EncPassword: new FormControl(''),
      Domain: new FormControl(this.initService.getDomain(), Validators.required)
    })

    this.renderer.addClass(this.el.nativeElement.ownerDocument.body, 'bgimg');

  }


  onDomainChanged(domain) {
    localStorage.setItem('domain', domain);
    this.initService.initializeData();
  }

  validateUserIdInput(c: FormControl) {
    let REGEX_PHONE = /[0-9]{10}/;
    let REGEX_EMAIL = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    return (REGEX_EMAIL.test(c.value) || REGEX_PHONE.test(c.value)) ? null : {
      validateInput: {
        valid: false
      }
    };
  }

  loginUser() {
    if (this.IsProduction) {
      this._logingroup.value.Domain = window.location.host;
    }
    this._logingroup.value.EncPassword = (Md5.hashStr(this._logingroup.value.Password));
    this.authService.doLogin(this._logingroup.value).subscribe(
      res => {
        this.loginResponseJSON = res;
        this.loginResponse = <any>JSON.stringify(res)
        if (res.statusCode === 0) {
          localStorage.setItem('loginData', JSON.stringify(res));
          this.authService.emitChange('login-success');
          this.defaultService = this.loginResponseJSON.loginData[0].defaultService;
          this.route.navigate(['/' + this.defaultService]);
        } else {
          this._Show = true;
        }
      },
      err => {
        if (err instanceof HttpErrorResponse) {
          this._Show = true;
        }
        // console.log(err)
      }
    )
  }

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }
    return this.email.hasError('email') ? 'Not a valid email' : '';
  }

  get email() {
    return this._logingroup.get('Email');
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position) {
          //console.log("Latitude: " + position.coords.latitude +"Longitude: " + position.coords.longitude);
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
        }
      },
        (geoError: PositionError) => {

          console.log('Error', geoError.message);
        }
      );
    } else {
      console.log('Error', "Geolocation is not supported by this browser.", 'error');
    }
  }

  processPayment(){
    this.form.nativeElement.submit();
  }
}
