import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BiometricService } from '../biometric.service';
@Component({
  selector: 'app-capturebiometric',
  templateUrl: './capturebiometric.component.html',
  styleUrls: ['./capturebiometric.component.css']
})
export class CapturebiometricComponent implements OnInit {

  timeLeft = 60;
  interval;
  status = 'Capturing';
  parser: DOMParser;
  deviceType: string
  useDummy = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CapturebiometricComponent>,
    private biometricService: BiometricService) {
    this.parser = new DOMParser();
    this.deviceType = this.data.device
    this.useDummy = this.data.useDummy
  }

  ngOnInit(): void {
    this.startTimer();
    this.captureFingerPrint();
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.dialogRef.close({ data: { success: false } });
      }
    }, 1000)
  }

  captureFingerPrint() {
    let data = '';
    if (this.useDummy) {
      data = `<?xml version="1.0"?>
<PidData>
  <Resp errCode="0" errInfo="Success" fCount="1" fType="0" nmPoints="39" qScore="66" />
  <DeviceInfo dpId="MANTRA.MSIPL" rdsId="MANTRA.WIN.001" rdsVer="1.0.3" mi="MFS100" mc="MIIEGDCCAwCgAwIBAgIEAlMXwDANBgkqhkiG9w0BAQsFADCB6jEqMCgGA1UEAxMhRFMgTWFudHJhIFNvZnRlY2ggSW5kaWEgUHZ0IEx0ZCA3MUMwQQYDVQQzEzpCIDIwMyBTaGFwYXRoIEhleGEgb3Bwb3NpdGUgR3VqYXJhdCBIaWdoIENvdXJ0IFMgRyBIaWdod2F5MRIwEAYDVQQJEwlBaG1lZGFiYWQxEDAOBgNVBAgTB0d1amFyYXQxHTAbBgNVBAsTFFRlY2huaWNhbCBEZXBhcnRtZW50MSUwIwYDVQQKExxNYW50cmEgU29mdGVjaCBJbmRpYSBQdnQgTHRkMQswCQYDVQQGEwJJTjAeFw0yMjA0MTAxMTI0MzlaFw0yMjA1MTAxMTM5MzlaMIGwMSUwIwYDVQQDExxNYW50cmEgU29mdGVjaCBJbmRpYSBQdnQgTHRkMR4wHAYDVQQLExVCaW9tZXRyaWMgTWFudWZhY3R1cmUxDjAMBgNVBAoTBU1TSVBMMRIwEAYDVQQHEwlBSE1FREFCQUQxEDAOBgNVBAgTB0dVSkFSQVQxCzAJBgNVBAYTAklOMSQwIgYJKoZIhvcNAQkBFhVzdXBwb3J0QG1hbnRyYXRlYy5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQC31FJCn6KIqyxIdp3GDHatSKO2zPdztCc6tY5bntq7WOGmiNXhG7x5vZUfllydwsLFNFuzsIJKS42rUQcEYjsd440VR2ycQqaP0hYDwDKSPvre5CuxE1NV3NLKqcl41gFiPND6AJd5b5JRVL4btW6NMzT4B1pnqkyODpr97XdB5Cyw1zpbOchvj2Z9QUMWlKAqSBZQS7pv4vCj1vkDkivh2hPKLhrjhh9Hy0lSvt+8StxsJPZ9YRO11jp2aOHQ57FWyKNaEagBUAIWPLxR6TK6DpP8fN737MnSSgDVbSTnY9anL6ZP0mh7Omm8OAhouWs5Qof/8NVAd46pV9ifims1AgMBAAEwDQYJKoZIhvcNAQELBQADggEBADEBO8J1WIETztlB4gy1RPMPnQsTjt1azARTG/yzwoH7NArBzoBt+kn0D1sL1CieFzm1EGcppiNIfjSr9SspbIYg4JDfMVqfq6EUFIDwa7y4ND+psgGoQs+Cm3qSINlkR+pgEUuni8Ql76VCtLQwy/b4gZE9oUrZeTbfypTj3bIt0R3XoHIiZcV0OsVeYAOhoRqe750KhHCR6yEd7B+aH43UqLCBAIyZhRTDq8YepUyk4yJI2UQlOkBMyurRgZTJDtpPJJq0Kh/FKxjVBBzvZaxiesLOh6PNEvX8pZLWCjy0SkFfiCLGCzNpqjX7CSYDJUCrzyngmhyPCIio4qeUyas=" dc="2bacf9da-e823-4593-9699-9ee5223eabbd">
    <additional_info>
      <Param name="srno" value="2153666" />
      <Param name="sysid" value="6EDF8BF2CD06D28FBFF0" />
      <Param name="ts" value="2022-04-16T22:41:21+05:30" />
    </additional_info>
  </DeviceInfo>
  <Skey ci="20221021">Jvc83na+OJ5+XytJsVUWGBgckwkuAm+ybNCJyzN7+/Y2jEpq3GqufEFVdHkFqKbautM3Qjk7UrGQEVbnJThRf8W0DjbRieEwCmUfMANHhv6M/ydFGo7m6ZxK5m6EcTr2h+JmXEMgDqQfTLjHzXVlOBqEZfXu16tH/a3/pmKznQVxYZ5XMWm/Jhxzv0qxNhyoHlvMDoH3YFbryeYxhkQbU70sZgBaySANr9D8kjs/DM1+LWR4lQoLMMVz/8wJM0D1oituaahDjqmq8U9WxMdvBcMeH8Zcfg2c+N7t/F2K5xEzzFVfCqQhgj/H+/WEsvoDFhpByWrEt852CNXFBs86eA==</Skey>
  <Hmac>b5rP92Px5i9GBHIN7alLa1EsUTk2GTjp/PYpBV7pSgGTprv/bGDFE22qONZQuCb0</Hmac>
  <Data type="X">MjAyMi0wNC0xNlQyMjo0MToyMTPyUofqoyv1Vfgsg9dz7bDEn83ZPnyqjMlwZ0ZWbKsEn/KC72dh3nK95HGHjRqlQXD13E7dc853/PV5uAPDit5IEN7Di2tedwndZ3gMpksPC78wR5KSQSPYTUglgYW38UbTRDQHHuNcz7iHCoFrzxCvyWJ8ozvZkW4h5irGudlJbv2tP3l5kKeMupwjxQcgrAdcQUzLT4H9Ms+uVxxL6QxtrjNbq/3fP8AnUYDkQ0r/+mOaU0nVX1aEH1fshkh2LAgFuuRmdYl8xxewzQPOXIWVVdEQOVZgRsjnXJPxGSynzVRotF1MA+oTx0jjuHftvTEDvAxCLzNyhD6+hCMcfabsOg9+g+CXx88prbKp6iPaCrR44U64+TVpueUawQPDVR0jWcKSk9v/RXOSHiBd6BCErZdPCrU3gCpFOvxc3awA+Bj6MQHDOGFnN1jGhD3I/GXdS4chuYrKiDEvU/p849QElMVxWM2n9acbgZJ5QoQiv9YaLbz0MnAHL8Oxo9TYUMPl5YSeiWynJtepSHs1AMr+ARgEoXZhFzTi+acxGV2TM5Wy/j7ATlq0ffYsNmQh0XzsqMwNAUwyNk+B73KIXIvwgEjZgmuSGRkI57Q5sPcsiCSGopaxTIZ1lORpQRiMNTUvVGfkoQaHIhRoA8/nhkff0zQiObezATyTm2a3v0h5HRBFDdWOQeO+57U7sYizC6uM1TWSu4+zRNRci4CsHUJAckn+fYZLOm24SLfvRAvWKFYRWeseDDPrWq241YSOt0Ho+PUAN4ZjAcJ+9zxEXXk1FOKVofVJfEhFEJX095FKS5LDQb7TnYKau9vfwo48cC/N44WMOXp0iCyyJejVJAFmij2QmFjRpIAdGwM4DheI1VZSNu/gsFyHvKsfz71xeg5L/XIW+hkdMAxKRBu+BbO9Bk/yvWtqaf5fj1QFGus7n5m8Vm/mUMObn70zLiROAc+MKEDM8NIfWYG/dIrgVTlh3CO56TDsHF91b/moTXB6PAdfKs9FbXNcelsuF6k9G0q/I70x5hEd9KnpZCnVq/qGFTcmZgUNH9l0bxXA2QF1DrG9sezIPn2Myw6PJWjbTKxQKToM5PoVpH9SzO+WcugC8w4YL6q3fsuWtOze1brmYFJRf3uTnKLKIZUBXxwwMh4ZkfZAwKidxS+Lzf1WoG/p+KvuQ5zAOAFIbCdMPlGJaq+JyQ==</Data>
</PidData>`
      this.handleResponse(data)
    } else {
      this.biometricService.capture(this.deviceType).subscribe(res => {
        this.handleResponse(res)
      }, err => {
        this.handleError(err)
      })
    }
  }

  handleError(err: any) {
    this.status = "Error in capturing fingerprint. Please check your device is connected and RD Service is up and running"
    clearInterval(this.interval);
    console.log(err);
  }

  handleResponse(dvcData) {
    clearInterval(this.interval);
    this.status = 'Captured'
    setTimeout(() => { 
    this.dialogRef.close({ success: true, data: dvcData });
    })
  }
}
