<div class="container-fluid customehigt bg-light">
  <div class="container">
    <div class="row py-5">
      <div class="senderbox">
        <h1 class="mb-0">Balance Enquiry</h1>
        <form action="">
          <div class="row p-3 m-0">
              <div class="col-xl-6">
                  <mat-form-field>
                      <mat-label>Select Bank</mat-label>
                      <mat-select >
                        <mat-option value="deepak">
                          deepak
                        </mat-option>
                        <mat-option value="kumar">
                          kumar
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
              </div>
              <div class="col-xl-6 mt-auto mb-3">
                  <mat-form-field>
                    <mat-label>Mobile No.</mat-label>
                    <input matInput required (keypress)="numberOnly($event)">
                    
                  </mat-form-field>
                </div>
                <div class="col-xl-6 mb-3">
                  <mat-radio-group aria-label="Select an option">
                  <mat-radio-button checked value="1">Aadhar No. (12 Digit)</mat-radio-button>
                  <mat-radio-button value="2">Virtual ID (16 Digit)</mat-radio-button>
                  </mat-radio-group>
                  <mat-form-field>
                      <mat-label>12 Digit Aadhar No.</mat-label>
                      <input matInput required (keypress)="numberOnly($event)">
                      
                    </mat-form-field >
                    <mat-form-field *ngif="Virtualid">
                      <mat-label>16 Digit Virtual ID</mat-label>
                      <input matInput required (keypress)="numberOnly($event)">
                      
                    </mat-form-field>
                </div>
                
                <div class="col-xl-6">
                    <label class="selectbiometric" for="">Select Biometric Device</label>
                  <mat-radio-group aria-label="Select an option">
                      <mat-radio-button checked value="m">Mentra</mat-radio-button>
                      <mat-radio-button value="s">Startek</mat-radio-button>
                      <mat-radio-button value="mo">Morpho</mat-radio-button>
                      </mat-radio-group>
                </div>
                <div class="col-xl-6">
                  <button mat-raised-button  class="validateacc"><i class="fa fa-paper-plane" aria-hidden="true"></i>
                      Capture</button>
                </div>
          </div>
      </form>
    </div>

  </div>
  </div>
</div>