<div class="container-fluid customehigt bg-light">

  <div class="container" *ngIf="kycPending">
    <div class="row justify-content-center col-xl-12 py-5">
      <span><b>Your Resgistration is pending for AEPS, to process any finacial transaction you need to have eKYC done before ! </b></span>
    </div>
    <div class="row justify-content-center col-xl-12 py-2">
      <a class="mr-3 p-2 forgetpass" (click)="initiateKYC()">
        <i class="fa fa-id-card"></i>
        Initiate KYC
      </a>
    </div>
  </div>

  <div class="container-fluid" *ngIf="!kycPending">
    <div class="row py-2 justify-content-center">
      <!-- Sender -->
      <!-- <div class="col-xl-6 mb-3"> -->
      <div class="senderbox">
        <h1 class="mb-0">AEPS</h1>
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xl-5">
              <form class="p-4" [formGroup]="transactionForm">
                <div *ngIf="outletLoginExpired" style='background-color: lightgrey;'>
                  <h3> <b>Note: </b> <span class='p2' style='cursor: pointer; color:red'
                      (click)="processOutletLogin()">Click here</span> for biometric login before performing any AEPS
                    transaction</h3>
                </div>
                <div *ngIf="outletLoginError" style='color: red' class="py-4">
                  {{outletLoginErrorMsg}}
                </div>
                <div class="row" *ngIf="!outletLoginExpired">
                  <div class="col-xl-12">
                    <mat-form-field>
                      <mat-label>Transaction Type</mat-label>
                      <mat-select disableRipple formControlName="txnType">
                        <mat-option *ngFor="let txnType of txnTypes" value={{txnType.key}}>{{txnType.value}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-12">
                    <mat-form-field>
                      <mat-label>Customer Mobile No.</mat-label>
                      <input matInput autocomplete="off" formControlName="mobile" maxlength="10"
                        (keypress)="numberOnly($event)" required>
                    </mat-form-field>
                  </div>
                  <br>
                  <div class="col-xl-12 mb-3">
                    <div class="serviceimg">
                      <!-- (click)="setBank(HDFC BANK)"  -->
                      <button mat-button>
                        <img src="../../assets/images/logo/icici.png" style="height: 22px;"
                          (click)='setBank("ICICI BANK")'></button>

                      <button mat-button>
                        <img src="../../assets/images/logo/sbi.jpg" style="height: 23px;"
                          (click)='setBank("STATE BANK OF INDIA")'></button>

                      <button mat-button>
                        <img src="../../assets/images/logo/hdfc.png" style="height: 22px;"
                          (click)='setBank("HDFC BANK")'></button>

                      <button mat-button>
                        <img src="../../assets/images/logo/pnb.png" style="height: 23px;"
                          (click)='setBank("PUNJAB NATIONAL BANK")'></button>
                    </div>
                  </div>
                  <div class="col-xl-12 mb-3">
                    <mat-form-field>
                      <input type="text" placeholder="Select Bank" matInput formControlName="bankName"
                        [matAutocomplete]="auto" required>
                      <mat-autocomplete #auto="matAutocomplete" (optionSelected)='setBank($event.option.value)'>
                        <mat-option *ngFor="let bank of filteredBanks | async" [value]="bank.bankName">
                          {{bank.bankName}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-12 mb-3">
                    <mat-form-field>
                      <mat-label>Aadhaar Number</mat-label>
                      <input maxlength="12" autocomplete="off" matInput formControlName="aadhaarNumber"
                        (keypress)="numberOnly($event)" class="Upper" required>
                    </mat-form-field>
                  </div>
                  <div class="row col-xl-12"
                    *ngIf="transactionForm.value.txnType == 'AEPS_CASH_WITHDRAWAL' || transactionForm.value.txnType == 'AEPS_AADHAAR_PAY'">
                    <div class="col-xl-12">
                      <mat-form-field>
                        <mat-label>Enter Amount (minimum &#8377;{{minAmount}})</mat-label>
                        <input matInput autocomplete="off" minlength="3" maxlength="10" formControlName="amount"
                          (keypress)="numberOnly($event)" required>
                      </mat-form-field>
                    </div>
                    <div class="col-xl-2">
                      <button mat-raised-button (click)="setTxnAmount(500)">500</button>
                    </div>
                    <div class="col-xl-2">
                      <button mat-raised-button (click)="setTxnAmount(1000)">1000</button>
                    </div>
                    <div class="col-xl-2">
                      <button mat-raised-button (click)="setTxnAmount(1500)">1500</button>
                    </div>
                    <div class="col-xl-2">
                      <button mat-raised-button (click)="setTxnAmount(2000)">2000</button>
                    </div>
                    <div class="col-xl-2">
                      <button mat-raised-button (click)="setTxnAmount(5000)">5000</button>
                    </div>
                    <div class="col-xl-2">
                      <button mat-raised-button (click)="setTxnAmount(10000)">10000</button>
                    </div>
                  </div>
                  <div class="row col-xl-12"><br></div>
                  <div class="row col-xl-12 text-center p-4">
                    <mat-radio-group aria-label="Select an option" formControlName="device">&nbsp;&nbsp;&nbsp;
                      <mat-radio-button *ngFor="let dvc of devices" value={{dvc}}>{{dvc}}</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="row col-xl-12 row"><br></div>
                  <div class="col-xl-12" style="font-size:75%; color:rgb(4, 4, 153)">
                    I hereby give my consent and submit voluntarily at my own discretion, my Aadhaar number for the
                    purpose of authenticating an AePS transaction on InstantPay.
                    The Aadhaar number submitted herewith shall not be used for any purpose other than mentioned, or as
                    per the requirements of the law. (listen audio)
                  </div>
                  <br> <br> <br> <br>
                  <div class="col-xl-12 text-center">
                    <mat-progress-bar mode="indeterminate" *ngIf="processing"></mat-progress-bar>
                    <br>
                    <button class="btnprimary mx-auto" mat-raised-button (click)="process()"
                      [disabled]='transactionForm.invalid || processing'>Submit</button>
                    &nbsp;&nbsp;&nbsp;
                    <button class="btn mx-auto" mat-raised-button (click)="resetForm()">Reset</button>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-xl-1"></div>

            <div class="col-xl-6">
              <div class="tbldata m-3">
                <div class="col-xl-12 table-responsive p-0">
                  <table class="table table-hover table-list ">
                    <thead>
                      <tr>
                        <td><b>Recent Transactions</b></td>
                        <td></td>
                        <td>View All</td>
                      </tr>
                    </thead>
                  </table>
                  <table class="table table-hover table-list ">
                    <tbody>
                      <tr>
                        <th>Date</th>
                        <th>Reference Id</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Status</th>
                      </tr>
                      <tr *ngFor="let txn of transactions">
                        <td>{{txn.createdAt | date: 'medium'}}</td>
                        <td>{{txn.txnId}}</td>
                        <td>{{txn.typeDescr}}</td>
                        <td>{{txn.transactionAmount | currency : '&#8377;'}}</td>
                        <td>{{txn.statusDescr}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Sender -->


    <!-- </div> -->
  </div>
</div>