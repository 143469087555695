import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface MiniStatement {
  date : Date,	
  amount:number,	
  narration:string,	
  txnType:string,
}

@Component({
  selector: 'app-transactionreport',
  templateUrl: './transactionreport.component.html',
  styleUrls: ['./transactionreport.component.css']
})
export class TransactionreportComponent implements OnInit {

  txnId: string
  txnType: string = 'Cash Withdrawal'
  miniStatement : MiniStatement[]
  displayedColumns: string[] = ['date', 'txnType', 'amount', 'narration'];
  data : any
  timeStamp: any
  status :string

  constructor( @Inject(MAT_DIALOG_DATA) public txnResponse) { 
    console.log(txnResponse)
    this.data = txnResponse.data
    this.miniStatement = this.data.miniStatement
    this.timeStamp = txnResponse.timestamp
    this.txnId = txnResponse.txnId
    this.txnType = txnResponse.txnType
    this.status = txnResponse.status
  }

  ngOnInit(): void {
    console.log(this.data)
  }

}
 