import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-otpvalidation',
  templateUrl: './otpvalidation.component.html',
  styleUrls: ['./otpvalidation.component.css']
})
export class OtpvalidationComponent implements OnInit {


  OTPForm: FormGroup;
  hide = true;
  parentData = {otpRefNo: null}

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<OtpvalidationComponent>) { 
    this.parentData = data
  }

  ngOnInit(): void {

    this.OTPForm = new FormGroup({
      otp: new FormControl('', (Validators.required, Validators.minLength(4))),
      otpRefNo: new FormControl(this.parentData.otpRefNo)
    })
  }

  submitOTP() {
    this.dialogRef.close({ data: this.OTPForm.value });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
