import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../app/login/login.component';
import { ForgotpasswordComponent } from '../app/forgotpassword/forgotpassword.component';
import { RegistrationComponent } from '../app/registration/registration.component';
import { HomeComponent } from '../app/home/home.component';
import { NotfoundComponent } from '../app/notfound/notfound.component';
import { AuthGuard } from './auth.guard';
import { DashboardComponent } from '../app/dashboard/dashboard.component';
import { LoginheaderComponent } from '../app/loginheader/loginheader.component';
import { ProfileupdateComponent } from './profileupdate/profileupdate.component';
import { AgenActivationListComponent } from './agen-activation-list/agen-activation-list.component';
import { UserlistComponent } from './userlist/userlist.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TermsandconditionComponent } from './termsandcondition/termsandcondition.component';
import { RechargeComponent } from './utility/recharge/recharge.component';
import { UtilitytransactionlistComponent } from './utilitytransactionlist/utilitytransactionlist.component';
import { DepositRequestComponent } from './Accounts/deposit-request/deposit-request.component';
import { DepositlistComponent } from './Accounts/depositlist/depositlist.component';
import { DepositupdatereportComponent } from './Accounts/depositupdatereport/depositupdatereport.component';
import { CreditrequestlistComponent } from './Accounts/creditrequestlist/creditrequestlist.component';
import { CreditrequestComponent } from './Accounts/creditrequest/creditrequest.component';
import { CreditlistComponent } from './Accounts/creditlist/creditlist.component';
import { MoneyTransferComponent } from './DMT/money-transfer/money-transfer.component';
import { CashWithdrawalComponent } from './AEPS/cash-withdrawal/cash-withdrawal.component';
import { BalanceEnquiryComponent } from './AEPS/balance-enquiry/balance-enquiry.component';
import { CashoutRequestComponent } from './AEPS/cashout-request/cashout-request.component';
import { CashoutRequestHistoryComponent } from './AEPS/cashout-request-history/cashout-request-history.component';
import { AccountStatementComponent } from './List/account-statement/account-statement.component';
import { DmtTransectionListComponent } from './List/dmt-transection-list/dmt-transection-list.component';
import { AgentPaymentListComponent } from './List/agent-payment-list/agent-payment-list.component';
import { AddCreditComponent } from './List/add-credit/add-credit.component';
import { CategoryCreationComponent } from './List/category-creation/category-creation.component';
import { CategoryAgentMappingComponent } from './List/category-agent-mapping/category-agent-mapping.component';
import { CategorylistComponent } from './List/categorylist/categorylist.component';
import { CommissionCreationComponent } from './List/commission-creation/CommissionCreationComponent';

import { UtilityModule } from './utility/utility.module';
import { AepsModule } from './AEPS/aeps.module';
import { AirModule } from './air/air.module';
import { RailModule } from './rail/rail.module';
import { AdminModule } from './admin/admin.module';
import { PgTransferModule } from './pg-transfer/pg-transfer.module';
import { PaymentsModule } from './payments/payments.module';

const routes: Routes = [
        // {path:'',redirectTo:'/home',pathMatch:'full'},
        { path: '', component: LoginComponent },
        { path: 'home', component: HomeComponent },
        { path: 'about', component: AboutComponent },
        { path: 'contact', component: ContactComponent },
        { path: 'privacy-policy', component: PrivacypolicyComponent },
        { path: 'terms-and-conditions', component: TermsandconditionComponent },
        { path: 'login', component: LoginComponent },
        // {path: 'utilitytransactionlist', component: UtilitytransactionlistComponent, canActivate: [AuthGuard]},
        { path: 'forgotpassword', component: ForgotpasswordComponent },
        {
                path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'loginheader', component: LoginheaderComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'profile', component: ProfileupdateComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'agentactivation', component: AgenActivationListComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'users', component: UserlistComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'depositreuquest', component: DepositRequestComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'depositlist', component: DepositlistComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'depositupdate', component: DepositupdatereportComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: ['OO'], allowedUsers: ['MT0O3', 'MT0O4'] }
        },
        {
                path: 'creditlist', component: CreditlistComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'creditrequest', component: CreditrequestComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'creditupdate', component: CreditrequestlistComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: ['OO'], allowedUsers: [] }
        },
        {
                path: 'moneytransfer', component: MoneyTransferComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'cashwithdrawal', component: CashWithdrawalComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'balanceenquiry', component: BalanceEnquiryComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'recharge', component: RechargeComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'cashout-request', component: CashoutRequestComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'cashout-request-history', component: CashoutRequestHistoryComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'accountstatement', component: AccountStatementComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'dmttransactions', component: DmtTransectionListComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'agentpaymentlist', component: AgentPaymentListComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: ['OO', 'D'], allowedUsers: [] }
        },
        {
                path: 'addcredit', component: AddCreditComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'categorycreation', component: CategoryCreationComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: ['OO', 'SA'], allowedUsers: [] }
        },
        {
                path: 'commissioncreation', component: CommissionCreationComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: ['SA', 'A', 'OO', 'OOU'], allowedUsers: [] }
        },
        {
                path: 'agent-category-mapping', component: CategoryAgentMappingComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'categorylist', component: CategorylistComponent, canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        { path: 'registration', component: RegistrationComponent },
        // {path: '**', component: NotfoundComponent},
        { path: 'utility', loadChildren: './utility/utility.module#UtilityModule' },
        { path: 'aeps', loadChildren: './AEPS/aeps.module#AepsModule' },
        {
                path: 'air', loadChildren: './air/air.module#AirModule', canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        {
                path: 'rail', loadChildren: './rail/rail.module#RailModule', canActivate: [AuthGuard],
                data: { allowedUserTypes: [], allowedUsers: [] }
        },
        { path: 'pg', loadChildren: './pg-transfer/pg-transfer.module#PgTransferModule' },
        {
                path: 'admin', loadChildren: './admin/admin.module#AdminModule', canActivate: [AuthGuard],
                data: { allowedUserTypes: ['OO'], allowedUsers: [] }
        },
        { path: 'payments', loadChildren: './payments/payments.module#PaymentsModule' }
];

@NgModule({
        imports: [RouterModule.forRoot(routes)],
        exports: [RouterModule]
})
export class AppRoutingModule { }
