<div class="container-fluid customeheight customehigt bg-light px-0">

  <div class="container-fluid">

    <div class="registrationform py-2">
      <div class="depositrequest">
        <div class="row">
          <div class="col-xl-12">
            <h3>Agent Payment List</h3>
          </div>
        </div>
        <form class="form-group mb-0" [formGroup]="AgentPaymentForm">
          <div class="row justify-content-between ">

            <div class="col-xl-3">
              <mat-form-field>
                <mat-label>Agent Name/Code</mat-label>
                <input (click)="resetAgentCode()" type="text" placeholder="Select Agent Code" aria-label="Number"
                  matInput formControlName="AgentCode" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let binddata of filteredAgents | async" [value]="binddata.agentCodeAndName">
                    {{binddata.agentCodeAndName}}
                  </mat-option>
                </mat-autocomplete>
                <!-- <mat-select  disableRipple  #AgentCode [(ngModel)]="selectedValueAgentCode"
             formControlName="AgentCode">
            <mat-option value="">All</mat-option>
            <mat-option  *ngFor="let binddata of _AgentcodeBind.data" [value]="binddata.agentCodeAndName.split('(')[1].split(')')[0]" >{{binddata.agentCodeAndName}}</mat-option >
            </mat-select > -->
              </mat-form-field>
            </div>
            <div class="col-xl-4">
              
                <button class="btn  btnprimary" mat-raised-button (click)="searchAgent()">Search</button>
                <!-- <button   mat-raised-button color="primary"class="downloadexal" data-toggle="tooltip"
            data-placement="top" title="Export as Excel" (click)="ExportTOExcel()"><i class="fa fa-download" aria-hidden="true"></i></button> -->
              
              <!-- <p class="text-danger mt-2 text-center font-weight-bold text-capitalize" *ngIf="Showmessage">{{AgentPaymentResponse.statusMessage}}</p>   -->

            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row mb-5 " #TABLE>
      <div class="col-xl-12 table-responsive">
        <table class="table table-hover table-list ">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Agency Name / Code</th>
              <th scope="col">Parent Id</th>
              <th scope="col">Available Credit</th>
              <th scope="col">Deposit</th>
              <th scope="col">Net Balance</th>
              <th scope="col">Status</th>
              <th scope="col">User Type</th>
            </tr>

          </thead>
          <tbody [formGroup]="AgentPaymentForm" *ngIf="Showmessage">


            <tr *ngFor="let data of AgentPaymentResponse.walletSummaryData;let i = index">
              <td>{{i+1}}</td>
              <td><span style="cursor: pointer; color: #ff0000;"
                  (click)="Fun_Manula(data.totalDeposit,data.agentCode)">{{data.agencyName}} / {{data.agentCode}}</span>
              </td>
              <td>{{data.parent}}</td>
              <!-- <td><a class="linkbtn" (click)="opanagentdetails()">{{data.agencyName}}</a></td> -->
              <td>{{data.availableCredit}}</td>
              <td>{{data.totalDeposit}}</td>
              <td>{{data.netBalance}}</td>
              <td>{{data.activeStatus}}</td>
              <td>{{data.userType}}</td>

            </tr>

          </tbody>
        </table>
        <!-- <p >{{_Userlist.statusMessage}}</p> -->
      </div>
    </div>

    <div class="agentdetailsbox" *ngIf="agentdetailspop">
      <div class="innerboxagntde">
        <div class="closebtn" (click)="closeagentdetailsbtn()">
          <img src="../../../assets/images/icons/close.svg" alt="">
        </div>
        <h5>Manual Credit/Debit</h5>
        <form class="form-group" [formGroup]="AddCreditForm">

          <div class="row">
            <div class="col-xl-2">
              <mat-form-field>
                <mat-label>Agent Name/Code </mat-label>
                <input matInput readonly formControlName="AgentCode" [(ngModel)]="_AgentcodeSend">
              </mat-form-field>
            </div>

            <div class="col-xl-2">
              <mat-form-field>
                <mat-label>Transaction Type</mat-label>
                <mat-select *ngIf="_Usertype == 'OO' || _Usertype == 'OOU'" disableRipple
                  formControlName="TransactionType">
                  <mat-option *ngFor="let txnType of txnTypes " [value]="txnType.code">{{txnType.descr}}</mat-option>

                </mat-select>
                <mat-select *ngIf="_Usertype == 'D'" disableRipple formControlName="TransactionType">
                  <mat-option value="Dist Transfer">Dist Transfer</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-xl-2">
              <mat-form-field>
                <mat-label>Transfer Type</mat-label>
                <mat-select disableRipple formControlName="TransferType">
                  <mat-option value="ADD">ADD</mat-option>
                  <mat-option value="REDUCE">REDUCE</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-xl-2">
              <mat-form-field>
                <mat-label>Available Balance (Deposit)</mat-label>
                <input matInput readonly formControlName="AvailableBalance" [(ngModel)]="AvailableSend">
              </mat-form-field>
            </div>

            <div class="col-xl-2">
              <mat-form-field>
                <mat-label>Amount</mat-label>
                <input id="txtAmount" appAppTwoDigitDecimaNumber maxlength="8" formControlName="Amount" matInput
                  placeholder="Amount" autocomplete="off">
              </mat-form-field>
            </div>

            <div class="col-xl-2">
              <mat-form-field>
                <mat-label>Remarks</mat-label>
                <textarea matInput placeholder="Remarks" formControlName="Remarks" maxlength="150"></textarea>
              </mat-form-field>
            </div>

            <div class="col-xl-1">
              <button class="btn btnprimary" [disabled]="!this.AddCreditForm.valid" mat-raised-button
                (click)="AddCredit()"> Submit </button>
              <!-- <button class="btn-block registrationbtn" disabled mat-raised-button  >Back</button> -->
              <!-- <p class="text-danger mt-2 text-center font-weight-bold text-capitalize" *ngIf="WalletUpdateShowmessage">{{WalletUpdateResponse.statusMessage}}</p> -->
            </div>
          </div>
        </form>
      </div>
    </div>

    <div hidden class="row pl-5 pr-5 pb-5">
      <div class="col-xl-12 table-responsive p-0">
        <table class="table table-hover table-list summnery ">
          <thead class="bg-danger">
            <tr>
              <th colspan="2" class="text-center" scope="col">Summary</th>

            </tr>
          </thead>
          <tbody>
            <tr>
              <td><strong>Total Distributor's Credit</strong></td>
              <td class="text-right"><strong>INR 0</strong></td>
            </tr>
            <tr>
              <td><strong>Total Agent's Credit</strong></td>
              <td class="text-right"><strong>INR 0</strong></td>
            </tr>


          </tbody>

        </table>
      </div>
    </div>

  </div>
</div>


<div class="loader-maine" *ngIf="loaderhide">
  <div class="loader">
    <!--<div class="loader-inner">cvxcv</div>-->
    <div class="loader-inner"></div>
    <div class="loader-inner box"></div>
    <div class="box-1"></div>
    <div class="box-2"></div>
    <div class="box-3"></div>
  </div>
</div>