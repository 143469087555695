import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CategoryService } from '../../Services/category.service';
import * as XLSX from 'xlsx';
import { AuthService } from 'src/app/Services/auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MastersService } from 'src/app/Services/masters.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-category-creation',
  templateUrl: './category-creation.component.html',
  styleUrls: ['./category-creation.component.css']
})

export class CategoryCreationComponent implements OnInit {
  @ViewChild('TABLE') table: ElementRef;
  loaderhide = false;
  addcategorypanel = false;
  CategoryCreationForm: FormGroup;
  CategoryListForm: FormGroup;
  Categorycreationcreate = false;
  Categorycreationbtn = true;
  _Responsedata;
  Loginresponse;
  CategoryCreationFormResponse;
  _CategoryListResponse;
  categories: Observable<any[]>;
  products: Observable<any[]>;

  Showmessage = false;
  ShowmessageCreateCat = false;
  _Usertype: any;
  ShowListdata = false;
  selectedValue: string;
  selectedValue1: string;
  Checkedvalue: any;
  loading = true

  // _CategoryBind:any

  constructor(private categoryService: CategoryService,
    private _AuthAgentService: AuthService,
    private masterDataService: MastersService,
    private route: Router,
    private alertService: ToastrService) {

  }

  ngOnInit(): void {

    this._AuthAgentService.CheckSesstion();
    this.Checkedvalue = 'true';
    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata = (JSON.parse(this.Loginresponse) as any);
    this._Usertype = this._Responsedata.loginData[0].userType;

    if (this._Usertype == 'OO') {
      this.ShowListdata = true;
    }


    this.masterDataService.fetchProduct('all').subscribe(data => {
      let productData: any = data;
      this.products = productData.data
    });

    this.CategoryListForm = new FormGroup({
      Category: new FormControl(''),
      Product: new FormControl('', Validators.required),
      Type: new FormControl('All', [Validators.required])
    });


    this.CategoryCreationForm = new FormGroup({
      Category: new FormControl('', [Validators.required, Validators.maxLength(7)]),
      Product: new FormControl(''),
      IsDirect: new FormControl("true", [Validators.required]),
    });
  }


  fun_CreateCat() {
    this.loaderhide = true;
    console.log(JSON.stringify(this.CategoryCreationForm.value))
    this.CategoryCreationForm.value.IsDirect = this.CategoryCreationForm.value.IsDirect == "true"
    
    this.categoryService.CreateCategory(this.CategoryCreationForm.value)
      .subscribe(
        TransactionList => {
          this.loaderhide = false;
          this.CategoryCreationFormResponse = TransactionList;
          this.ShowmessageCreateCat = true;
          // this.Categorycreationbtn=false
          if (this.CategoryCreationFormResponse.statusCode == 0) {
            this.alertService.success(this.CategoryCreationFormResponse.statusMessage);
            this.CategoryCreationForm.reset();
            this.addcategorypanel = false;
          }
          else {
            this.alertService.error(this.CategoryCreationFormResponse.statusMessage);
          }
        },
        (error) => {
          console.log(error);
          this.loaderhide = false;
        });
  }

  fun_GetCategory() {
    this.Categorycreationcreate = false;
    this.Categorycreationbtn = true;
    this.categoryService.CategoryList(this.CategoryListForm.value)
      .subscribe(
        TransactionList => {
          this.Showmessage = true;
          this._CategoryListResponse = TransactionList;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  fun_ShowCat() {
    // this.Categorycreationcreate=true;
    // this.Categorycreationbtn=false;
    this.addcategorypanel = true;
  }

  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'File');
    /* save to file */
    XLSX.writeFile(wb, 'File.xlsx');
  }

  onChangeProductdataSelected(id: string) {
    if (id == undefined)
      return;
    this.fun_GetCategory();
  }


  closeagentdetailsbtn() {
    this.addcategorypanel = false;
    this.CategoryCreationForm.reset();
  }

  onSelectionChange(val) {
    this.CategoryCreationForm.value.IsDirect = val;
  }
}
