<div class="container-fluid customehigt bg-light">
  <div class="container">
    <div class="row py-5 justify-content-center">
      <!-- Sender -->
      <div class="col-xl-12 mb-3">
        <div class="senderbox">
          <h1 class="mb-0">Cashout Request History</h1>
          <div class="tbldata m-3">
            <div class="col-xl-12 table-responsive p-0">
              <table class="table table-hover table-list ">
                <thead>
                  <tr>
                    <td>Requested Amount</td>
                    <td>Charges</td>
                    <td>Received Amount </td>
                    <td>Payment Type </td>
                    <td>Bank RRN</td>
                    <td>Requested Date</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>500</td>
                    <td>5</td>
                    <td>495 </td>
                    <td>IMPS </td>
                    <td>000000</td>
                    <td>05/05/2020</td>
                    <td>Transection Successful</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- Sender -->
    </div>
  </div>
</div>