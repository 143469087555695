<div class="container-fluid customeheight bg-light">
  <div class="container-fluid">
    <div class="row py-2">
      <div class="depositrequest">
        <h3>User List</h3>
        <form class="form-group mb-0" [formGroup]="UserlistForm">
          <div class="row">
            <div class="col-xl-2 col-6">
              <mat-form-field class="example-full-width">
                <mat-label>Registration FromDate</mat-label>
                <input matInput [matDatepicker]="pickerFromDate" formControlName="FromDate">
                <mat-datepicker-toggle matSuffix [for]="pickerFromDate">
                </mat-datepicker-toggle>
                <mat-datepicker #pickerFromDate></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-xl-2 col-6">
              <mat-form-field class="example-full-width">
                <mat-label>Registration Todate</mat-label>
                <input matInput [matDatepicker]="pickerTodate" formControlName="Todate">
                <mat-datepicker-toggle matSuffix [for]="pickerTodate">
                </mat-datepicker-toggle>
                <mat-datepicker #pickerTodate></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-xl-2 col-6 col-sm-3 col-md-3">
              <mat-form-field>
                <mat-label>Status</mat-label>
                <mat-select disableRipple formControlName="UserStatus">
                  <mat-option value="">All</mat-option>
                  <mat-option value="Active">Active</mat-option>
                  <mat-option value="Inactive">Inactive</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-xl-3">
              <mat-form-field>
                <mat-label>Agent Code/Name</mat-label>
                <input (click)="resetAgentCode()" type="text" placeholder="Select Agent Code" aria-label="Number"
                  matInput formControlName="AgentCode" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let binddata of filteredAgents | async" [value]="binddata.agentCodeAndName">
                    {{binddata.agentCodeAndName}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="col-xl-3">
              <div class="buttnclass">
                <button class="btn-block btnprimary" mat-raised-button (click)="Funsearch()">Search</button>
                <!-- <p class="alert-danger" *ngIf="Showmessage">{{C_LoginResponse.statusMessage}}</p>  -->
                <button mat-raised-button color="primary" class="downloadexal" data-toggle="tooltip"
                  data-placement="top" title="Export as Excel" (click)="ExportTOExcel()"><i class="fa fa-download"
                    aria-hidden="true"></i></button>
              </div>
              <!-- <p class="text-danger mt-2 text-center font-weight-bold" *ngIf="Showmessage">{{_Userlist.statusMessage}} 
              </p> -->
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row mb-5" #TABLE>
      <div class="col-xl-12 table-responsive p-0">
        <table class="table table-hover table-list ">
          <thead>
            <tr>
              <td scope="col">S.No</td>
              <td scope="col">Name</td>
              <td scope="col">Email</td>
              <td scope="col">Mobile</td>
              <td scope="col">Agency Name/<br>Code</td>
              <td scope="col">Registration<br>Date</td>
              <td scope="col">KYC</td>
              <td *ngIf="!User_TypeEmp && agentProfile.dmt" scope="col">Use Payout</td>
              <td scope="col">User <br> type</td>
              <td scope="col">Pin <br> Code</td>
              <td scope="col">Address</td>
              <td scope="col">City</td>
              <td scope="col">State</td>
              <td scope="col">Parent <br> Id</td>
              <td *ngIf="this._Usertype != 'S' || this._Usertype != 'D' " scope="col">Sales <br> Code</td>
              <td *ngIf="!User_TypeSales || !User_TypeDist" scope="col" class="text-center">Activate / Inactivate</td>
              <td *ngIf="this._Usertype == 'S'" scope="col">Status</td>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let data of _Userlist; let i = index">
              <td>{{i+1}}</td>

              <td>{{data.firstName}}</td>
              <td>{{data.email}}</td>
              <td>{{data.mobile}}</td>
              <td>{{data.agencyName}}/<br>{{data.agentCode}}</td>
              <td>{{data.registrationDate | date: 'mediumDate'}}</td>
              <td>{{agentProfile.kycStatus}}</td>

              <ng-container *ngIf="data.userType=='Agent'; then isAgent; else isOther">
              </ng-container>
              <ng-template #isAgent>
                <td *ngIf="!User_TypeEmp && agentProfile.dmt">
                  <div class="actionbtyn" *ngIf="data.usePayout">
                    <button data-toggle="modal" data-toggle="tooltip" data-placement="top" title="Click to Use DMT"
                      data-target="#PopActive" type="button" class=" btn-success" mat-raised-button
                      (click)="switchDMTMode(data.usePayout,data.agentCode)"><i class="fa fa-check"
                        aria-hidden="true"></i></button>
                  </div>
                  <!-- <div class="actionbtyn" *ngIf="data.dmtstatus == 'Payout'"> -->
                  <div class="actionbtyn" *ngIf="!data.usePayout">
                    <button data-toggle="modal" data-toggle="tooltip" data-placement="top" title="Click to Use Payout"
                      data-target="#Deactive" mat-raised-button type="button" class=" btn-danger"
                      (click)="switchDMTMode(data.usePayout,data.agentCode)"><i class="fa fa-times"
                        aria-hidden="true"></i></button>
                  </div>
                </td>
              </ng-template>
              <ng-template #isOther>
                <td *ngIf="!User_TypeEmp">---</td>
              </ng-template>

              <td>{{data.userType}}</td>
              <td>{{data.pinCode}}</td>
              <td>{{data.state}}</td>
              <td>{{data.city}}</td>
              <td>{{data.state}}</td>
              <ng-container *ngIf="data.userType=='Agent' ||  data.userType=='Distributor'; then hasName; else noName">
              </ng-container>
              <ng-template #hasName>
                <td>
                  <p style="cursor: pointer; color: #ff0000;"
                    (click)="parentIdpop('Parent', data.agencyName, data.agentCode, data.parent)">
                    {{data.parent}}
                  </p>
                </td>
              </ng-template>
              <ng-template #noName>
                <td>{{data.parent}}</td>
              </ng-template>
              <ng-container
                *ngIf="data.userType=='Agent' ||  data.userType=='Distributor'; then hasSalesCode; else noSalesCode">
              </ng-container>
              <ng-template #hasSalesCode>
                <td *ngIf="!User_TypeSales">
                  <p style="cursor: pointer; color: #ff0000;"
                    (click)="parentIdpop('Sales', data.agencyName, data.agentCode,data.salesCode)">
                    {{data.salesCode}}</p>
                </td>
              </ng-template>
              <ng-template #noSalesCode>
                <td *ngIf="!User_TypeSales">---</td>
              </ng-template>

              <td *ngIf="!User_TypeSales">
                <div class="actionbtyn" *ngIf="data.status == 'INACTIVE'">
                  <button data-toggle="modal" data-toggle="tooltip" data-placement="top" title="Active"
                    data-target="#PopActive" type="button" class=" btn-success" mat-raised-button
                    (click)="fun_Actionuser(1,data.agentCode, data.agencyName)"><i class="fa fa-check" aria-hidden="true"></i></button>
                </div>
                <div class="actionbtyn" *ngIf="data.status == 'ACTIVE'">
                  <button data-toggle="modal" data-toggle="tooltip" data-placement="top" title="Inactive"
                    data-target="#Deactive" mat-raised-button type="button" class=" btn-danger"
                    (click)="fun_Actionuser(3,data.agentCode, data.agencyName)"><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
              </td>
              <td *ngIf="this._Usertype == 'S'">{{data.status}}</td>
            </tr>
          </tbody>
        </table>
        <!-- <p class="text-danger mt-2 text-center font-weight-bold text-capitalize" *ngIf="UserActiveInactivemessage">
          {{_UserActiveInactiveResponse.statusMessage}}</p> -->
      </div>
    </div>
  </div>
</div>

<div class="agentdetailsbox" *ngIf="perentid">
  <div class="innerboxagntde morebox">
    <div class="closebtn" (click)="closeagentdetailsbtn()">
      <img src="../../../assets/images/icons/close.svg" alt="">
    </div>
    <h5>Update {{ popupChangeType }} mapping of {{ selectedAgencyName}} - {{selectedAgencyCode }}</h5>
    <div class="row justify-content-between">
      <div class="col-xl-12 px-4">
        <form action="" [formGroup]="AgentMoveForm">
          <!-- <mat-form-field>
          <mat-label>Type</mat-label>
          <mat-select disableRipple >
            <mat-option value="Parent" >Parent </mat-option>
            <mat-option value="Salesperson" >Salesperson </mat-option>
          </mat-select>
        </mat-form-field> -->
          <mat-form-field>
            <mat-label>Name/Code</mat-label>
            <mat-select disableRipple formControlName="Parent" [(ngModel)]="agencyParentOrSalesPerson">
              <mat-option *ngFor="let binddata of _AgentMappinglist" [value]="binddata.code">
                {{binddata.agentCodeAndName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <div class="col-xl-12 text-right">
        <button class="btn-success" mat-raised-button (click)="fun_AgentMove()">Update</button>
        <!-- <p class="text-danger text-center adderrormsg" *ngIf="_AgentMovemessage">{{this._AgentMovelist.statusMessage}} -->
        <!-- </p> -->
      </div>
    </div>
  </div>
</div>

<div class="agentdetailsbox" *ngIf="panno">
  <div class="innerboxagntde morebox">
    <div class="closebtn" (click)="closeagentdetailsbtn()">
      <img src="../../../assets/images/icons/close.svg" alt="">
    </div>
    <h5>Update AEPS KYC Mobile No.</h5>
    <div class="row justify-content-between">
      <div class="col-xl-6 px-4 row">
        <form action="">
          <div class="col-xl-6">
            <mat-form-field>
              <mat-label>Old Mobile Number</mat-label>
              <input matInput>
            </mat-form-field>
          </div>
          <div class="col-xl-6">
            <mat-form-field>
              <mat-label>Aadhar Mobile Number</mat-label>
              <input matInput>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="col-xl-12 text-center">
        <button class="cashsubmit" mat-raised-button (click)="fun_AgentMove()">Get OTP</button>
        <!-- <p class="text-danger text-center adderrormsg" *ngIf="_AgentMovemessage">{{this._AgentMovelist.statusMessage}} -->
        <!-- </p> -->
      </div>
      <div class="col-xl-6 px-4">
        <form action="">
          <div class="col-xl-6">
            <mat-form-field>
              <mat-label>Old Mobile OTP</mat-label>
              <input matInput>
            </mat-form-field>
          </div>
          <div class="col-xl-6">
            <mat-form-field>
              <mat-label>Aadhar Mobile OTP</mat-label>
              <input matInput>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="col-xl-12 text-center">
        <button class="cashsubmit" mat-raised-button (click)="fun_AgentMove()">Submit</button>
        <!-- <p class="text-danger text-center adderrormsg" *ngIf="_AgentMovemessage">{{this._AgentMovelist.statusMessage}} -->
        <!-- </p> -->
      </div>
    </div>
  </div>
</div>


<div class="loader-maine" *ngIf="loaderhide">
  <div class="loader">
    <!--<div class="loader-inner">cvxcv</div>-->
    <div class="loader-inner"></div>
    <div class="loader-inner box"></div>
    <div class="box-1"></div>
    <div class="box-2"></div>
    <div class="box-3"></div>
  </div>
</div>