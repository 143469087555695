<div class="container" *ngIf="loading">
  <h3>Loading...</h3>
</div>

<form class="form-group" [formGroup]="ProfileForm">

  <div class="container" *ngIf="!loading" style="background-color: white;">

    <div class="row py-3">
      <div class="heading row">My Profile </div>
    </div>

    <div class="row py-3 my-2">

      <a *ngIf="IsKYCRequired" class="mr-3 p-2 forgetpass" (click)="opanDialog('kyc','kyc')">
        <i class="fa fa-id-card"></i> Initiate KYC</a>

      <a class="mr-3  p-2 forgetpass" (click)="opanDialog('RESETPASSWORD', 'Reset Password')">
        <i class="fa fa-key"></i>
        Change Password
      </a>
      <a *ngIf="IsRailEnrolled" class="mr-3 p-2 forgetpass"
        (click)="opanDialog('railonline', 'Reset Rail Txn Password')">
        <i class="fa fa-key"></i> Change Rail Txn Password</a>
    </div>
    <!-- <div class="row pl-5 pr-5 pt-3 justify-content-center">
                <div class="col-xl-4">
                  <mat-form-field class="uniqueid" appearance="fill">
                    <mat-label> Unique ID</mat-label>
                    <input  matInput formControlName="UserId" readonly >
                    
                  
                    <div *ngIf="unique.invalid && (unique.dirty || unique.touched)" class="text-danger">
                      <mat-error *ngIf="unique.errors.required">Name is required.</mat-error>
                      <mat-error *ngIf="unique.errors.minlength">Name must be at least 3 characters long.</mat-error>
                    </div>
                    </mat-form-field>
                </div>
              </div> -->
    <div class="row pl-5 pr-5 ">
      <div class="col-xl-8">
        <mat-form-field>
          <mat-label>Agency Name</mat-label>
          <input matInput formControlName="AgencyName">

        </mat-form-field>
      </div>
      <div class="col-xl-4">
        <mat-form-field>
          <mat-label>GST No</mat-label>
          <input matInput formControlName="GSTNo">

        </mat-form-field>
      </div>
    </div>
    <div class="row pl-5 pr-5 ">
      <div class="col-xl-4">
        <mat-form-field>
          <mat-label> Name as on Pan Card </mat-label>
          <input autocomplete="off" appAlphabetOnly matInput formControlName="PanName">
        </mat-form-field>
      </div>
      <div class="col-xl-4">
        <mat-form-field>
          <mat-label> Pan Card Number </mat-label>
          <input autocomplete="off" matInput formControlName="PanNumber" maxlength="10">
        </mat-form-field>
      </div>
      <div (click)="viewDoc('pc')" class="col-xl-4 mt-3" style="cursor: pointer" *ngIf="ProfileForm.value.PanCardDoc">
        <mat-icon >cloud_download</mat-icon> PanCard
      </div>      
    </div>
    <div class="row pl-5 pr-5 ">
      <div class="col-xl-6">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput placeholder="Ex. pat@example.com" formControlName="Email">
        </mat-form-field>
      </div>
      <div class="col-xl-6">
        <mat-form-field>
          <mat-label>Mobile</mat-label>
          <input autocomplete="off" appNumberOnlyDirective matInput placeholder="Mobile" formControlName="Mobile"
            maxlength="10">

        </mat-form-field>
      </div>
    </div>
    <div class="row pl-5 pr-5 ">
      <div class="col-xl-12">
        <mat-form-field>
          <mat-label>OutletId</mat-label>
          <input matInput placeholder="Ex. pat@example.com" formControlName="OutletId" readonly>
        </mat-form-field>
      </div>
    </div>
    <div class="row pl-5 pr-5 ">
      <div class="col-xl-6">
        <mat-form-field>
          <mat-label>First Name</mat-label>
          <input appAlphabetOnly matInput placeholder="Name" formControlName="FirstName">
          <!-- <div *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger">
                  <mat-error *ngIf="name.errors.required">Name is required.</mat-error>
                  <mat-error *ngIf="name.errors.minlength">Name must be at least 3 characters long.</mat-error>
                </div> -->
        </mat-form-field>
      </div>
      <div class="col-xl-6">
        <mat-form-field>
          <mat-label>Last Name</mat-label>
          <input appAlphabetOnly matInput placeholder="Name" formControlName="LastName" required>
          <!-- <div *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger">
                  <mat-error *ngIf="name.errors.required">Name is required.</mat-error>
                  <mat-error *ngIf="name.errors.minlength">Name must be at least 3 characters long.</mat-error>
                </div> -->
        </mat-form-field>
      </div>
    </div>
    <div class="row pl-5 pr-5 ">
      <div class="col-xl-12">
        <mat-form-field>
          <mat-label>Address </mat-label>
          <textarea matInput formControlName="Address"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row pl-5 pr-5 ">
      <div class="col-xl-4">
        <mat-form-field>
          <mat-label>State</mat-label>
          <mat-select disableRipple #State formControlName="State">
            <mat-option *ngFor="let state of states" [value]="state.state">
              {{state.state}}</mat-option>
          </mat-select>
        </mat-form-field>

      </div>
      <div class="col-xl-4">
        <mat-form-field>
          <mat-label>City</mat-label>
          <mat-select disableRipple #State formControlName="City">
            <mat-option *ngFor="let city of cities" [value]="city.city">{{city.city}}
            </mat-option>

          </mat-select>


        </mat-form-field>
      </div>

      <div class="col-xl-4">
        <mat-form-field>
          <mat-label>Pin Code</mat-label>
          <input autocomplete="off" appNumberOnlyDirective matInput maxlength="6" placeholder="Ex. 94105"
            formControlName="PinCode">
        </mat-form-field>
      </div>
    </div>
    <div class="row pl-5 pr-5 ">

      <div class="col-xl-4">
        <mat-form-field>
          <mat-label>Address Proof Type</mat-label>
          <mat-select disableRipple formControlName="TypeOfAddress">
            <mat-option value="Adharcard">Aadhar Card</mat-option>
            <mat-option value="Passport">Passport</mat-option>
            <mat-option value="Votercard">Voter Card</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-xl-4">
        <mat-form-field>
          <mat-label> Address Proof Number </mat-label>
          <input matInput formControlName="AddressProofNumber">
        </mat-form-field>
      </div>

      <div (click)="viewDoc('ap')" class="col-xl-4 mt-3" style="cursor: pointer" *ngIf="ProfileForm.value.AddressProofDoc">
        <mat-icon >cloud_download</mat-icon> Address Proof
      </div>
    </div>

  </div>

  <div style="display: flex; justify-content:center; align-items: center">
    <button [disabled]="!this.ProfileForm.valid" class="btnprimary" mat-raised-button (click)="updateProfile()">Update
      Profile</button>
  </div>

  <br><br>
</form>