<div class="container-fluid aboutbg">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 text-center">
                <h1>About Us</h1>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-light py-5">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 text-justify abcont">
               <p>{{MERCHANT_NAME}} is an organization where all online services are comes under single plateform. {{MERCHANT_NAME}} Mission is to recognize that our constantly changing industry is both a challenge and an opportunity. We see our potential not just to react to those changes, but to anticipate them, to maintain and leverage our core strengths and developing new opportunities for growth. {{MERCHANT_NAME}} methodologies are time-tested and evolved models complete with the right teams, right technical skill-sets, domain strengths and management that help deliver projects and products on time, on budget and as per your specification. </p>
               <p>{{MERCHANT_NAME}} is one among the leading travel technology company, Specialized in developing Flight Reservation System, Flight Booking Engine, We understand the needs and the requirements of Global Travel Companies, Travel Agency and Agents has constantly been one of the biggest business happening towards the airline ticketing system in the travel industry. We help our customers to access the Global Distribution System, Consolidates, XML and API Providers, To find the best airline fare, low cost air carriers, and domestic and international air carriers for economical air transit. Tour & travel have always been popular whether for business or for enjoyment.</p>
               <p>Through our Travel API the travel agents can use our web application to fulfill the travel related needs of their customers. By integrating this travel api with your own software solution, you as a travel service provider, can offer unbeatable travel related services to your potential clients. Our travel api enables you to perform numerous activities pertaining to travel consultation, searching for trip plans, seat layout, providing customized tour packages, booking tickets (airways, railways and roadways), cancelling tickets, generating reports for booking and cancellation, etc. Our end to end travel management technology solution enables you to offer unbeatable travel related services to your potential clients. We offer you a unproblematic base in the form of faultless travel related services which you can use to develop a strong customer segment for yourself. {{MERCHANT_NAME}} combines experience with technology to give the best to its clients. It implements tried and tested methodologies and models that are constantly upgraded for better performance. With the optimum combination of right technical skill sets, domain strengths and management of teams, {{MERCHANT_NAME}} offers nothing but the best to its clients. The travel portal development company not only offers customized portal solutions for each client keeping in mind their requirement and budget, but also offers 24X7 portal maintenance program in the most cost effective manner. Many travel businesses have gained immensely from {{MERCHANT_NAME}} professional and dedicated services that allow their clients to get exactly what they want.</p>
            </div>
        </div>
    </div>
</div>