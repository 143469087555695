import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

const httpoptons = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  })
};

@Injectable()
export class AccountsService {
  constructor(private httpClient: HttpClient) { }



  DepositRequest(data: any) {
    const daattt = JSON.stringify(data.getRawValue());
    return this.httpClient.post(environment.Api_urlaccounts + 'DepositRequest', daattt, httpoptons);
  }

  fetchDepositRequests(data: any) {

    const Fdate = new Date(data.FromDate);
    const Tdate = new Date(data.Todate);
    const F = this.FormatDate(Fdate);
    const T = this.FormatDate(Tdate);

    const pvalue = '?FromDate=' + F + '&Todate=' + T + '&AgentCode=' + data.AgentCode + '&BankName=' + encodeURIComponent(data.BankName) +
      '&TypeofSubmit=' + data.TypeofSubmit +'&Status=' + data.Status;
    return this.httpClient.get(environment.Api_urlaccounts + 'DepositRequests' + pvalue);
  }

  // DepositRequestList(data: any) {

  //   const Fdate = new Date(data.FromDate);
  //   const Tdate = new Date(data.Todate);
  //   const F = this.FormatDate(Fdate);
  //   const T = this.FormatDate(Tdate);

  //   const pvalue = '?FromDate=' + F + '&Todate=' + T + '&LoggedInUserType=' + data.LoggedInUserType + '&Merchant=' + data.Merchant + '&Mode=' + data.Mode + '&BankName=' + data.BankName + '&TypeofSubmit=' + data.TypeofSubmit + '&AgentCode=' + data.AgentCode;
  //   return this.httpClient.get(environment.Api_urlaccounts + 'DepositRequestList' + pvalue);
  // }


  updateDepositRequest(data: any) {
    return this.httpClient.put(environment.Api_urlaccounts + 'depositupdate', data);
  }

  CreditReq(data: any) {

    const daattt = JSON.stringify(data.getRawValue());
    return this.httpClient.post(environment.Api_urlaccounts + 'CreditRequest', daattt, httpoptons);
  }

  CreditList(data: any) {

    const Fdate = new Date(data.FromDate);
    const Tdate = new Date(data.Todate);
    const F = this.FormatDate(Fdate);
    const T = this.FormatDate(Tdate);
    const ag = data.AgentCode;
    if (ag == undefined) {
      data.AgentCode = '';
    }
    const pvalue1 = '?FromDate=' + F + '&Todate=' + T + '&LoggedInUserType=' + data.LoggedInUserType + '&Merchant=' + data.Merchant + '&AgentCode=' + data.AgentCode;

    const pvalue = '?FromDate=' + F + '&Todate=' + T + '&AgentCode=' + data.AgentCode + '&Merchant=' + data.Merchant + '&Mode=' + data.Mode + '&LoggedInUserType=' + data.LoggedInUserType + '&LoggedInAgentCode=' + data.LoggedInAgentCode;
    return this.httpClient.get(environment.Api_urlaccounts + 'CreditList' + pvalue);
  }

  CreditRequestListeditList(data: any) {
    const Fdate = new Date(data.FromDate);
    const Tdate = new Date(data.Todate);
    const F = this.FormatDate(Fdate);
    const T = this.FormatDate(Tdate);
    const pvalue = '?FromDate=' + F + '&Todate=' + T + '&LoggedInUserType=' + data.LoggedInUserType + '&Merchant=' + data.Merchant;

    return this.httpClient.get(environment.Api_urlaccounts + 'CreditRequestList' + pvalue);
  }

  ReqDCreditAccept(Fulldata: any) {


    return this.httpClient.put(environment.Api_urlaccounts + 'CreditUpdate', Fulldata, httpoptons);
  }


  ReqDCreditDecline(data: any) {


    return this.httpClient.put(environment.Api_urlaccounts + 'CreditReject', data, httpoptons);
  }


  

  fetchTxnTypes(agentCode: string){
    return this.httpClient.get(`${environment.Api_urlaccounts}txntypes/${encodeURI(agentCode)}`);
  }

  fetchTxnTypesByRole(){
    return this.httpClient.get(`${environment.Api_urlaccounts}txntypesbyrole`);
  }

  fetchBankList(merchant: string){
    return this.httpClient.get(`${environment.CMS_BASE_URL}/api/banks?filters[merchant]=${merchant}`);
  }

  fetchDepositModes(){
    return this.httpClient.get(`${environment.CMS_BASE_URL}/api/deposit-modes`);
  }


  FormatDate(date: Date): string {

    const day = date.getDate();
    const months = date.getMonth() + 1;
    const year = date.getFullYear();
    let day1;
    let months1;

    if (day < 10) {
      day1 = `0${day}`;
    } else { day1 = `${day}`; }

    if (months < 10) {
      months1 = `0${months}`;
    } else { months1 = `${months}`; }
    return `${year}-${months1}-${day1}`;
  }
}

