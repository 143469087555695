<div class="container-fluid customeheight bg-light">

  <div class="container" *ngIf="kycPending">
    <div class="row justify-content-center col-xl-12 py-5">
      <span><b>Your KYC is pending, to process any finacial transaction you need to have eKYC done before ! </b></span>
    </div>
    <div class="row justify-content-center col-xl-12 py-2">
      <a class="mr-3 p-2 forgetpass" (click)="initiateKYC()">
        <i class="fa fa-id-card"></i>
        Initiate KYC
      </a>
    </div>
  </div>

  <div class="container" *ngIf="!kycPending">
    <div class="row justify-content-center pt-2">
      <div class="col-xl-6 mb-3">
        <div class="senderbox">
          <h1 class="mb-0">Sender</h1>
          <form class="p-4" [formGroup]="Senderdetailform">
            <div class="row">
              <div class="col-xl-7">
                <mat-form-field>
                  <mat-label>Sender Mobile No.</mat-label>
                  <input matInput autocomplete="off" placeholder="Enter Mobile No." maxlength="10"
                    formControlName="Mobile" (keypress)="numberOnly($event)" id="txtMobile" #Amount
                    (keyup)="onKeySearch($event)">
                  <!-- <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                      <mat-icon>close</mat-icon>
                      </button> 
                      onfocus="this.value=''" -->
                </mat-form-field>
              </div>
              <div class="col-xl-5">
                <div class="senderbtn">
                  <button class="btn btnprimary" mat-raised-button (click)="GetSender()"
                    [disabled]="!this.Senderdetailform.valid">Search</button>
                  <button class="refreshdetails" (click)="fun_Allclear()"><i class="fa fa-refresh"
                      aria-hidden="true"></i>
                  </button>
                </div>
              </div>

              <!-- <p class="text-danger text-center adderrormsg" *ngIf="SenderResponseResponseMeg">
                {{_SenderResponse.statusMessage}}</p> -->
            </div>
          </form>
        </div>
      </div>
      <!-- Sender -->


      <!-- Sender Details -->
      <div class="col-xl-6 mb-3" *ngIf="_Checkstatus==0 && _CheckstatusDiv">
        <div class="senderbox">
          <h1 class="mb-0">Sender Details</h1>
          <form class="p-4 my-auto">

            <div class="row mb-2  senderdetails justify-content-between">
              <div class="col-xl-4">
                <p> <i class="fa fa-user" aria-hidden="true"></i>
                  {{_SenderResponse.remitter.name}}</p>
              </div>
              <div class="col-xl-8">
                <p class="limitrs">You can send upto Rs.
                  {{_SenderResponse.remitter.remainingLimit}}</p>
              </div>
            </div>
            <div class="row senderdetails justify-content-between">
              <div class="col-xl-4">
                <p> <i class="fa fa-address-card-o" aria-hidden="true"></i>
                  KYC Status </p>
              </div>
              <div class="col-xl-8">
                <div class="progress" *ngIf="_SenderResponse.remitter.kycStatus==0">
                  <div class="progress-bar" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0"
                    aria-valuemax="100">KYC NOT DONE</div>
                </div>
                <div class="progress" *ngIf="_SenderResponse.remitter.kycStatus==1">
                  <div class="progress-bar kycdone" role="progressbar" style="width: 100%" aria-valuenow="100"
                    aria-valuemin="0" aria-valuemax="100">KYC DONE</div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- Sender Details -->
    </div>


    <!-- add users -->
    <form class="remitterregistration" *ngIf="showRemitterRegistration" [formGroup]="RemitterRegistrationform">
      <div class="row justify-content-between sandcashhead m-0">
        <div class="col-xl-4 col-6 pl-0">
          <h3>Sender Registration</h3>
        </div>
        <div class="col-xl-4 col-6 my-auto text-right"><button type="button" data-toggle="tooltip" data-placement="top"
            title="Back" (click)="CL_Remiterregic()">
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
          </button></div>
      </div>
      <div class="row pt-3">
        <div class="col-xl-3">
          <mat-form-field>
            <mat-label>Mobile</mat-label>
            <input matInput maxlength="10" readonly formControlName="Mobile" (keypress)="numberOnly($event)"
              autocomplete="off">

          </mat-form-field>
        </div>
        <div class="col-xl-3">
          <mat-form-field>
            <mat-label>First Name (min 4 char)</mat-label>
            <input autocomplete="off" matInput formControlName="FirstName" class="Upper" appAlphabetOnly minlength="4"
              maxlength="30">

          </mat-form-field>
        </div>
        <div class="col-xl-3">
          <mat-form-field>
            <mat-label>Last Name (min 4 char)</mat-label>
            <input matInput formControlName="LastName" class="Upper" appAlphabetOnly minlength="4" maxlength="30"
              autocomplete="off">

          </mat-form-field>
        </div>
        <div class="col-xl-3">
          <mat-form-field>
            <mat-label>Date of Birth</mat-label>
            <input formControlName="DOB" matInput [matDatepicker]="pickerDOB">
            <mat-datepicker-toggle matSuffix [for]="pickerDOB">
            </mat-datepicker-toggle>
            <mat-datepicker #pickerDOB></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-6">
          <mat-form-field>
            <mat-label>Address</mat-label>
            <input matInput maxlength="200" formControlName="Address" autocomplete="off">

          </mat-form-field>
        </div>
        <div class="col-xl-3">
          <mat-form-field>
            <mat-label>Pin Code</mat-label>
            <input matInput maxlength="6" formControlName="PinCode" (keypress)="numberOnly($event)" autocomplete="off">

          </mat-form-field>
        </div>
        <div class="col-xl-3">
          <mat-form-field>
            <mat-select formControlName="StateCode" placeholder="Choose State">
              <mat-option *ngFor="let state of states" [value]="state.code">
                {{state.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3">
          <mat-form-field>
            <mat-label>Registration OTP</mat-label>
            <input matInput maxlength="6" formControlName="Otp" (keypress)="numberOnly($event)" autocomplete="off"
              placeholder="OTP">
          </mat-form-field>
        </div>
      </div>
      <div class="row justify-content-center" *ngIf="remiiteraddsubmit">
        <div class="col-xl-4">
          <div class="addbanibtn text-center remmitregist">
            <button mat-raised-button [disabled]="!this.RemitterRegistrationform.valid" class="submitaccount  btn-block"
              (click)="fun_RemitterRegistration()">
              <i class="fa fa-paper-plane" aria-hidden="true"></i>Submit</button>
            <!-- <p class="text-success adderrormsg" *ngIf="OtpSendMsgDiv">{{_OtpSendMsg}}</p> -->
            <!-- <p class="text-success adderrormsg" *ngIf="OtpSendMsgDiv">Send OTP</p> -->
          </div>
        </div>
      </div>
      <!-- <p class="bg-danger text-white" *ngIf="OtpSendMsgDiv">{{_OtpSendMsg}}</p> -->
      <!-- <div class="row justify-content-center" *ngIf="RemitterRegOtpDiv" [formGroup]="RemitterRegOtpVerifyform">
        <div class="col-xl-2">
          <mat-form-field>
            <mat-label>Enter OTP</mat-label>
            <input matInput maxlength="6" (keypress)="numberOnly($event)" formControlName="Otp" required
              autocomplete="off">
          </mat-form-field>
        </div>
        
        <div class="col-xl-3">
          <div class="addbanibtn remmitregist">
            <button mat-raised-button [disabled]="!this.RemitterRegOtpVerifyform.valid" class="validateacc  btn-block"
              (click)="fun_RemitterRegVerifyOtp()">
              <i class="fa fa-paper-plane" aria-hidden="true"></i>
              Validate</button>
          </div>
          
        </div>
        <div class="col-xl-3">
          <div class="addbanibtn remmitregist">
            <button mat-raised-button class="submitaccount  btn-block" (click)="fun_RemitterRegistration()">
              <i class="fa fa-paper-plane" aria-hidden="false"></i>
              Resend</button>
          </div>
          
        </div>
      </div> -->
    </form>
    <!-- add users -->


    <!-- Beneficiary Details -->
    <div class="row mt-4" *ngIf="_Checkstatus==0 && _CheckstatusDiv ">
      <h2 class="mx-3 banidetails">Beneficiary Details</h2>
      <div class="col-xl-12 table-responsive ">
        <table class="table mb-0 table-hover table-list ">
          <thead>
            <tr>
              <td>S.no</td>
              <td>Beneficiary Name</td>
              <td>Bank Account</td>
              <td>Bank Name </td>
              <td>IFSC Code</td>
              <td>Validation Status</td>
              <td> &nbsp;</td>
              <td hidden> &nbsp;</td>
              <td hidden>&nbsp;</td>
              <td hidden>&nbsp;</td>
              <td hidden>Beneficiary Mobile</td>
              <td>&nbsp;</td>
            </tr>
          </thead>

        </table>
        <div class="benificiarylist">
          <table class="table table-hover table-list m-0 ">

            <div *ngFor="let data of _SenderResponse.remitter.beneficiaries;let i = index">
              <tr id="beni_{{ i }}" class="accordion-toggle">

                <td #id>{{i+1}}</td>
                <td #Send_name>{{data.name}}</td>
                <td #Send_account>{{data.account}}</td>
                <td #Send_bank>{{data.bank}}</td>
                <td #Send_ifsc>{{data.ifsc}}</td>
                <td *ngIf="data.status!= 1">
                  <button class="validatebtn" mat-raised-button
                    (click)="fun_AccountValidaton(data.name,data.account,data.ifsc, data.id,data.bankId)">Validate</button>
                </td>
                <td *ngIf="data.status == 1">
                  <p class="verified"><i class="fa fa-check" aria-hidden="true"></i>
                  </p>
                </td>
                <!-- <td class="my-auto px-0">
                  <p class="text-danger text-center adderrormsg m-0"
                    *ngIf="AccountValidatonResponseMessage && ValidatonResponseMessageID==(i+1)">
                    {{AccountValidatonResponse.statusMessage}}</p>
                </td> -->

                <!-- <td #Send_status *ngIf="data.status !=1">Un-Verified</td> -->
                <td hidden #Send_status>{{data.status}}</td>

                <td hidden #benificiaryId>{{data.id}}</td>
                <td hidden #Send_mobile>{{data.mobile}}</td>

                <td hidden #remitterId>{{_SenderResponse.remitter.id}}</td>


                <td class="text-right">
                  <button class="benibtn deletbanibtnmobile mr-4"
                    (click)="fun_BenificiaryDelete(benificiaryId,remitterId)"> <i class="fa fa-trash-o"
                      aria-hidden="true"></i>
                  </button>
                  <Button mat-raised-button data-toggle="tooltip" data-placement="top" data-toggle="collapse"
                    [attr.data-target]="'#benitog_' + i" title="View Details"
                    class="btn-warning viedetails benibtn mr-1 "><i class="fa fa-eye" aria-hidden="true"></i>
                  </Button>
                  <Button mat-raised-button data-toggle="tooltip" data-placement="top" title="Fund Transfer"
                    class="benibtn btn-success "
                    (click)="showMoneyTransferPopUp(Send_name,Send_account,Send_bank,Send_mobile,Send_ifsc,Send_status,benificiaryId)"><i
                      class="fa fa-arrow-right" aria-hidden="true"></i>
                  </Button>
                </td>

              </tr>
              <tr class="toggletbl">
                <td colspan="8" class="hiddenRow">
                  <div class="accordian-body collapse" id="benitog_{{ i }}">
                    <div class="col-xl-12 py-3 table-responsive">
                      <div class="banidetailsbox">
                        <div class="titilebox">Beneficiary Name</div>
                        <div class="databiox" #send_name>{{data.name}}</div>
                      </div>
                      <div class="banidetailsbox">
                        <div class="titilebox">Bank Account </div>
                        <div class="databiox" #send_account>{{data.account}}</div>
                      </div>
                      <div class="banidetailsbox">
                        <div class="titilebox">Bank Name</div>
                        <div class="databiox" #send_bank>{{data.bank}}</div>
                      </div>
                      <div class="banidetailsbox">
                        <div class="titilebox">Beneficiary Mobile</div>
                        <div class="databiox" #send_mobile>{{data.mobile}}</div>
                      </div>
                      <div class="banidetailsbox">
                        <div class="titilebox">IFSC Code</div>
                        <div class="databiox" #send_ifsc>{{data.ifsc}}</div>
                      </div>
                      <div class="banidetailsbox">
                        <div class="titilebox">Account Validation Status</div>
                        <div class="databiox" #send_status>
                          <span class="text-success" *ngIf="data.status == 1">Verified</span>
                          <span class="text-danger" *ngIf="data.status != 1">Unverified</span>
                        </div>
                      </div>
                <td hidden #benificiaryId>{{data.id}}</td>
                <div class="banidetailsbox py-3 justify-content-center border-bottom text-center">

                  <button class="btn btnprimary deletbanibtn" mat-raised-button
                    (click)="fun_BenificiaryDelete(benificiaryId,remitterId)"> <i class="fa fa-trash-o"
                      aria-hidden="true"></i> Delete Beneficiary </button>
                  <button type="button" class="registrationbtn sandcash" mat-raised-button
                    (click)="showMoneyTransferPopUp(send_name,send_account,send_bank,send_mobile,send_ifsc,send_status,benificiaryId)">
                    <i class="fa fa-money" aria-hidden="true"></i> Send Cash </button>
                </div>
                <p class="text-danger text-center adderrormsg" *ngIf="DeleteBeniDivResponseMeg">
                  {{_DeleteBeneficiaryResponse.statusMessage}}</p>

            </div>
        </div>

        </td>
        </tr>
      </div>
      </table>
    </div>

    <!-- delete account popup -->

    <div class="agentdetailsbox" *ngIf="deletaccount">
      <div class="innerboxagntde">
        <div class="closebtn" (click)="fun_hidesandpopdelac()">
          <img src="../../../assets/images/icons/close.svg" alt="">
        </div>
        <h5>Delete Account!</h5>
        <div class="row justify-content-between">
          <div class="col-xl-12">
            <div class="deleteaccountmain">
              <h4>Are You Sure? Delete Account!</h4>
              <form [formGroup]="BenificiaryDeleteValidateform">
                <mat-form-field>
                  <mat-label>Enter OTP</mat-label>
                  <input formControlName="Otp" matInput autocomplete="off" required maxlength="6"
                    (keypress)="numberOnly($event)">


                </mat-form-field>

                <div class="deletepopup">

                  <div class="addbanibtn">
                    <button mat-raised-button type="submit" class="submitaccount" (click)="fun_BeniDeleteValidate()"><i
                        class="fa fa-trash" aria-hidden="true"></i> Delete
                      Account</button>
                    <button mat-raised-button data-dismiss="modal" aria-label="Close" class="validateacc"
                      (click)="fun_BeniverifyResndotp()"><i class="fa fa-paper-plane" aria-hidden="true"></i>
                      Resend otp?</button>
                    <p class="text-danger text-center adderrormsg" *ngIf="DeleteAccountResponseMeg">
                      {{_DeleteBeneficiaryResponseData.statusMessage}}</p>

                    <p class="text-danger text-center adderrormsg" *ngIf="ResendOtpBeniDiv">
                      {{_ValidateBeniResendOtpResponse.statusMessage}}</p>
                  </div>
                </div>


              </form>
            </div>

          </div>

        </div>
      </div>
    </div>

    <!-- delete account popup -->
    <!-- Send cash popup -->



    <div class="agentdetailsbox customesand" *ngIf="sancashpophide">
      <div class="innerboxagntde">
        <div class="closebtn" (click)="fun_hidesandpop()">
          <img src="../../../assets/images/icons/close.svg" alt="">
        </div>
        <h5>Send Cash</h5>
        <div class="row justify-content-between mobileover">
          <div class="col-xl-12">
            <div class="deleteaccountmain">

              <form action="">
                <div class="row">
                  <div class="col-xl-4">
                    <div class="sandcashdata">
                      <label>Beneficiary Name</label>
                      <!-- <input [(ngModel)]="Send_Name" > -->
                      <p>{{this.Send_name}} </p>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="sandcashdata">
                      <label>Bank Account</label>
                      <p>{{this.Send_account}} </p>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="sandcashdata">
                      <label>Bank Name</label>
                      <p>{{this.Send_bank}} </p>
                    </div>
                  </div>
                  <div class="col-xl-12 mt-4" [formGroup]="Transferform">
                    <div class="row justify-content-center">
                      <div class="col-xl-4">
                        <input type="text" id="txtsendamt" placeholder="Enter Amount" class="form-control amountinpy"
                          formControlName="Amount" (keypress)="numberOnly($event)" maxlength="6" autocomplete="off">
                        <p class="text-danger"><b>{{ Transferform.get('Amount').value | numbertowords | titlecase
                            }}</b>
                        </p>
                        <!-- (change)="inputChange($event)" -->

                        <div *ngIf="CheckAmount.invalid && (CheckAmount.dirty || CheckAmount.touched)"
                          class="text-danger">
                          <mat-error *ngIf="CheckAmount.errors.required">Amount is required.</mat-error>
                          <mat-error *ngIf="CheckAmount.errors.minlength">Amount must be at least 100.</mat-error>
                        </div>
                        <p class="text-danger text-center adderrormsg" *ngIf="PleaseenteramountMeg">
                          Please enter amount</p>
                        <!-- <div *ngIf="limitAmountMsg"></div> -->
                        <p class="text-danger text-center adderrormsg" *ngIf="limitAmountMsg">
                          <mat-icon>error_outline</mat-icon>
                          EXCEEDING YOUR FIRST LIMIT OF - {{this.RemainingLimit}}
                        </p>
                        <p class="text-danger text-center adderrormsg" *ngIf="LimitamountMeg">
                          <mat-icon>error</mat-icon>
                          EXCEEDING YOUR FINAL LIMIT OF - {{this.newRemaininglimit}}
                        </p>

                      </div>
                      <div class="col-xl-3 pt-3">
                        <div class="transectiontyp">
                          <mat-radio-group aria-label="Select an option" formControlName="TxnMode"
                            [(ngModel)]="selectedStatus">
                            <mat-radio-button checked value="IMPS">IMPS</mat-radio-button>
                            <mat-radio-button value="NEFT">NEFT</mat-radio-button>
                          </mat-radio-group>

                        </div>
                      </div>
                      <div class="col-xl-7 mt-3 text-center">
                        <p class="m-0" id="words"></p>
                      </div>
                    </div>
                    <div class="row mt-3 justify-content-center">
                      <div class="col-xl-7 text-center">
                        <button *ngIf="Areyousend" mat-raised-button class="cashsubmit" (click)="fun_Areyousure()"
                          [disabled]="!this.Transferform.valid">Submit</button>

                        <div><br><br></div>

                        <div class="proceedbtn" *ngIf="Areyousure">
                          <h2 class="text-danger"><b>ARE YOU SURE?</b></h2>
                          <button mat-raised-button class="sandcashppro" [disabled]="!this.Transferform.valid"
                            (click)="transferMoney()"><i class="fa fa-check" aria-hidden="true"></i>
                            Send money</button>
                          <button mat-raised-button class="rejecttra" (click)="fun_areyoucancel()"><i
                              class="fa fa-times" aria-hidden="true"></i>
                            Reset</button>
                        </div>
                        <p class="text-danger text-center adderrormsg" *ngIf="_FinalSendMoneyMessage">
                          {{_SendMoneyResponse.statusMessage}}</p>
                      </div>
                    </div>
                  </div>
                </div>

              </form>
            </div>

          </div>

        </div>
      </div>
    </div>

    <!-- Send cash popup -->

    <!-- Receipt -->
    <div class="agentdetailsbox" *ngIf="receiptmoney">
      <div class="innerboxagntde">
        <!-- <div class="closebtn" (click)="closeagentdetailsbtn()">
          <img src="../../../assets/images/icons/close.svg" alt="">
        </div> -->
        <div class="receiptoverflow">
          <div class="row justify-content-between" id="printsection">
            <div class="col-xl-12">
              <h5 class="mb-0">Transaction Receipt</h5>
            </div>
            <div class="col-xl-12">
              <div class="row justify-content-between align-items-center">
                <div class="col-md-6">
                  <tr class="mt-2 text-center font-weight-bold">
                    <td>
                      <div class="printagentname">{{_SendMoneyResponse.accountDetail.agencyName}}</div>
                    </td>
                  </tr>
                </div>
                <div class="col-md-6 text-right">
                  <img src="../../../assets/images/logo/MET/logo.png">
                </div>
              </div>
              <table class="transaction-receipt mobileresosive1 transectionsdata mb-3" border="1">
                <thead>
                  <tr>
                    <td>Sender Mobile</td>
                    <td>Benificiery Name</td>
                    <td>Account Number</td>
                    <td>Bank Name</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{_SendMoneyResponse.accountDetail.mobileNumber}}</td>
                    <td>{{_SendMoneyResponse.accountDetail.benificieryName}}</td>
                    <td>{{_SendMoneyResponse.accountDetail.accountNumber}}</td>
                    <td>{{_SendMoneyResponse.accountDetail.bankName}}</td>
                  </tr>

                </tbody>
              </table>
              <table class="transaction-receipt mobileresosive2 transectionsdata mb-3" border="1">
                <thead>
                  <tr>
                    <td>S.No</td>
                    <td>Transaction Id</td>
                    <!-- <td>Order Id</td> -->
                    <td>Operator Id</td>
                    <td>Txn Amount</td>
                    <!-- <td>Charge</td> -->
                    <td>Status</td>
                    <!-- <td>TxnDate</td> -->
                  </tr>
                </thead>
                <tbody [formGroup]="Transferform">
                  <tr *ngFor="let data of _SendMoneyResponse.response;let i = index">
                    <td>{{i+1}}</td>
                    <td>{{data.transactionId}}</td>
                    <!-- <td>{{data.apiReferenceId}}</td> -->
                    <td>{{data.ipayOprId}}</td>
                    <td>{{data.amount}} </td>
                    <!-- <td>{{data.customerCharge}}</td> -->
                    <td>{{data.status}}</td>
                    <!-- <td *ngif='_SendMoneyResponse.status === "Insufficient Wallet Balance"'>Failed</td> -->
                    <!-- <td>{{data.txnDate|date : "dd/MM/yyyy  h:mm:ss a"}}</td> -->
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-xl-3">
              <div class="recptbtnbx">
                <button class="backrecpt" mat-raised-button (click)="closeagentdetailsbtn()">Back</button>
                <button class="printrecpt" [useExistingCss]="true" mat-raised-button printSectionId="printsection"
                  ngxPrint>Print</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Receipt -->


    <!-- <div class="agentdetailsbox" *ngIf = "receiptmoney"> -->
    <!-- <div class="agentdetailsbox">
      <div class="innerboxagntde">
        <!- <div class="closebtn" (click)="closeagentdetailsbtn()">
          <img src="../../../assets/images/icons/close.svg" alt="">
        </div> --
        <div class="receiptoverflow">
          <div class="row justify-content-between" id="printsection">
            <div class="col-xl-12"><h5 class="mb-0">Transaction Receipt</h5></div>
            <div class="col-xl-12">
              <div class="row justify-content-between align-items-center">
                <div class="col-md-6">
                  <tr class="mt-2 text-center font-weight-bold">
                    <td><div class="printagentname">Agent Name</div></td>
                  </tr>
                </div>
                <div class="col-md-6 text-right">
                  <img src="../../../assets/images/logo/logo.png">
                </div>
              </div>
              <table class="transaction-receipt transectionsdata" border="1">
                <thead>

                  <tr>
                    <td>S.No</td>
                    <td>Transaction Id</td>
                    <td>Order Id</td>
                    <!-<td>Ref Number</td>
                        <td>Opr Id</td>--
                    <td>Amount</td>
                    <td>Status</td>


                  </tr>
                </thead>
                <tbody [formGroup]="Transferform">


                  <tr *ngFor="let data of _SendMoneyResponse.response;let i = index">
                    <td>{{i+1}}</td>


                    <td>{{data.transactionId}}</td>
                    <td>{{data.ipayReferenceId}}</td>
                    <--<td>{{data.ipayRefNumber}}</td>
                        <td>{{data.ipayOprId}} </td>--
                    <td>{{data.amount}}</td>
                    <td>{{data.status}}</td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-xl-5">
              <div class="recptbtnbx">
                <button class="backrecpt" mat-raised-button (click)="closeagentdetailsbtn()">Back</button>
                <button class="printrecpt" [useExistingCss]="true" mat-raised-button printSectionId="printsection" ngxPrint>Print</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Receipt -->

    <!-- <p >{{_Userlist.statusMessage}}</p> -->
  </div>
  <div class="col-xl-12 mb-4">

    <div class="row justify-content-end mt-3">
      <div class="col-xl-9 my-auto">
        <!-- <p class="text-danger text-center adderrormsg" *ngIf="AddBeniDivResponseMeg">
          {{_AddBeneficiaryResponse.statusMessage}}</p> -->


      </div>
      <div class="col-xl-3">
        <button style="width: 100%;" class="btn btn-block btnprimary" id="btn_AddBeneficiary" mat-raised-button
          (click)="AddBeneficiary()"> <i class="fa fa-plus" aria-hidden="true"></i> Add Beneficiary</button>
      </div>
    </div>
  </div>


</div>
<!-- Beneficiary Details -->

<!-- Add Beneficiary -->
<div class="row mt-4 mb-5" id="addbanibox" *ngIf="_AddBeneficiary">
  <div class="col-xl-12">
    <div class="sendcashbox">
      <div class="row justify-content-between sandcashhead m-0">
        <div class="col-xl-4 col-6">
          <h3>Add Beneficiary</h3>
        </div>
        <div class="col-xl-4 col-6 my-auto text-right">
          <button type="button" class="mr-1" data-toggle="tooltip" data-placement="top" title="Reset"
            (click)="fun_clear_AddBeneficiary()">
            <i class="fa fa-refresh" aria-hidden="true"></i>
          </button>
          <button type="button" data-toggle="tooltip" data-placement="top" title="Back" (click)="CL_Beneficiary()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </div>

      <form [formGroup]="beneficiaryForm">
        <div class="row p-4">
          <div class="col-xl-4 mb-3">
            <mat-form-field>
              <input type="text" placeholder="Select Bank" aria-label="Number" matInput formControlName="Bank"
                [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let bank of filteredBanks | async" [value]="bank.bank_name"
                  (click)="fun_onselectbank(bank)">
                  {{bank.bank_name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

          </div>
          <div class="col-xl-3 mb-3">
            <mat-form-field>
              <mat-label>Bank Account No.</mat-label>
              <input (keypress)="numberOnly($event)" maxlength="20" matInput autocomplete="off" required
                formControlName="AccountNumber">
            </mat-form-field>
          </div>
          <div class="col-xl-3 mb-3">
            <mat-form-field>
              <mat-label>IFSC Code</mat-label>
              <input maxlength="12" matInput autocomplete="off" required formControlName="IfscCode">
            </mat-form-field>
          </div>
        </div>
        <div class="row pl-4">
          <div class="col-xl-4 mb-3">
            <mat-form-field>
              <mat-label>Beneficiary Name</mat-label>
              <input appAlphabetOnly matInput formControlName="Name" class="Upper" required maxlength="100">
            </mat-form-field>
          </div>
          <div class="col-xl-2 mb-2">
            <mat-form-field>
              <mat-label>Date of Birth</mat-label>
              <input formControlName="DOB" matInput [matDatepicker]="pickerDOB">
              <mat-datepicker-toggle matSuffix [for]="pickerDOB">
              </mat-datepicker-toggle>
              <mat-datepicker #pickerDOB></mat-datepicker>
            </mat-form-field>
          </div>          
          <div class="col-xl-3">
            <mat-form-field>
              <mat-label>Beneficiary Mobile No.</mat-label>
              <input matInput autocomplete="off" formControlName="Mobile" maxlength="10" required
                (keypress)="numberOnly($event)">
            </mat-form-field>
          </div>
          <!-- <div class="col-xl-6 my-auto">
                <input  checked class="example-margin" type="checkbox" [(ngModel)]="theCheckbox"  data-md-icheck (change)="toggleVisibility($event)"/>Validate Account Number
              
              </div> -->

        </div>
        <div class="row pl-4">
          <div class="col-xl-4 mb-3">
            <mat-form-field>
              <mat-label>Address</mat-label>
              <input  matInput formControlName="Address"  required maxlength="255">
            </mat-form-field>
          </div>
          <div class="col-xl-2">
            <mat-form-field>
              <mat-label>PinCode</mat-label>
              <input matInput autocomplete="off" formControlName="PinCode" maxlength="10" required
                (keypress)="numberOnly($event)">
            </mat-form-field>
          </div>
          <div class="col-xl-2">
            <mat-form-field>
              <mat-select formControlName="StateCode" placeholder="Choose State">
                <mat-option *ngFor="let state of states" [value]="state.code">
                  {{state.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>          
        </div>        
        <div class="row justify-content-end p-3">
          <div class="col-xl-6">
            <div class="addbanibtn">
              <button *ngIf="btnBenisubmit" mat-raised-button type="submit" class="submitaccount"
                [disabled]="!this.beneficiaryForm.valid || isAccountValidated" (click)="fun_beniAddAccValidtion()"><i
                  class="fa fa-paper-plane" aria-hidden="true"></i> Account Validate </button>

              <button *ngIf="btnBenisubmit" mat-raised-button type="submit" class="submitaccount"
                [disabled]="!this.beneficiaryForm.valid" (click)="Registerbeneficiary()"><i class="fa fa-paper-plane"
                  aria-hidden="true"></i> Submit</button>
            </div>

            <!-- <p class="text-danger text-center adderrormsg" *ngIf="AccountValidatonResponseMessageSubmit">
              {{AccountValidatonResponse.statusMessage}}</p> -->
          </div>

        </div>
      </form>

    </div>
  </div>
</div>
<!-- Add Beneficiary -->
</div>
</div>

<div class="loader-maine" *ngIf="loaderhide">

  <div class="loader">
    <!--<div class="loader-inner">cvxcv</div>-->
    <div class="loader-inner"></div>
    <div class="loader-inner box"></div>
    <div class="box-1"></div>
    <div class="box-2"></div>
    <div class="box-3"></div>
  </div>

</div>

<script src="src/assets/js/Dmt.js"></script>