import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InitService } from '../init.service';
import { AuthService } from '../Services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  logoUrl = '';
  constructor(private initService: InitService, private authService: AuthService) {
  }

  ngOnInit(): void {
    
    if (localStorage.getItem('mData') != null) {
      this.initApp();
    }

    this.initService.changeEmitted$.subscribe(action => {
      if (action === 'init-data-fetch') {
        this.initApp();
      }
    })

  }

  initApp() {
    let mData : any 
    if (localStorage.getItem('mData') != null) {
      mData = JSON.parse(localStorage.getItem('mData'))
      document.documentElement.style.setProperty('--menu-item-bg-color', mData.colors.menu.mIClr)
      this.logoUrl = environment.CMS_BASE_URL  + mData.logo.data.attributes.url
      document.documentElement.style.setProperty('--bg-img', `url(${environment.CMS_BASE_URL}${mData.bgimage.data.attributes.url})`)
    }
  }

}
