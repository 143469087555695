<div class="container-fluid customehigt bg-light">
  <div class="container-fluid ">
    <div class="row py-2">

      <div class="depositrequest">
        <h3>Agent Category Mapping</h3>
        <form class="form-group mb-0" [formGroup]="CategoryagentmappingForm">


          <div class="row">
            <div class="col-xl-3 col-6">
              <mat-form-field>
                <mat-label>Select Product</mat-label>

                <mat-select disableRipple #Product (selectionChange)='onChangeProductdataSelected($event)'
                  formControlName="Product">
                  <mat-option>Select</mat-option>
                  <mat-option *ngFor="let binddata of _ProductBind" [value]="binddata.productName">
                    {{binddata.productName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-xl-3 col-6">
              <mat-form-field>
                <mat-label>Category</mat-label>
                <mat-select disableRipple #Product formControlName="Category">
                  <mat-option value="">All</mat-option>
                  <mat-option *ngFor="let binddata of _CategoryProductLisdBind" [value]="binddata.category">
                    {{binddata.category}}</mat-option>

                </mat-select>

              </mat-form-field>
            </div>

            <div class="col-xl-3" *ngIf="_Usertype">
              <mat-form-field>
                <mat-label>Agent Code/Name</mat-label>

                <!-- <mat-select disableRipple #AgentCode [(ngModel)]="selectedValueAgentCode" formControlName="AgentCode">
                  <mat-option>Select</mat-option>
                  <mat-option *ngFor="let binddata of _AgentcodeBind.data"
                    [value]="binddata.agentCodeAndName.split('(')[1].split(')')[0]">{{binddata.agentCodeAndName}}
                  </mat-option>

                </mat-select> -->
                <input (click)="resetAgentCode()" type="text" placeholder="Select Agent Code" aria-label="Number"
                  matInput formControlName="AgentCode" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let binddata of filteredAgents | async" [value]="binddata.agentCodeAndName">
                    {{binddata.agentCodeAndName}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-xl-3">
              <div class="buttnclass">
                <button class="btn-block btnprimary mr-3" mat-raised-button (click)="CategorySearch()"
                  [disabled]="!CategoryagentmappingForm.valid">Search</button>

                <button mat-raised-button color="primary" class="downloadexal" data-toggle="tooltip"
                  data-placement="top" title="Export as Excel" (click)="ExportTOExcel()"><i class="fa fa-download"
                    aria-hidden="true"></i></button>
              </div>
              <!-- <p class="text-danger mt-2 text-center font-weight-bold" *ngIf="Showmessage">
                {{_CategoryLisdBind.statusMessage}}</p> -->
            </div>
            <!-- <p class="text-danger mt-2 text-center font-weight-bold" *ngIf="Selectanyone">Please select any one Category
              / Agent Code</p> -->
          </div>
        </form>
      </div>
    </div>
    <div class="row mb-5" [formGroup]="CategoryagentmappingForm" #TABLE>
      <div class="col-xl-12 table-responsive p-0">
        <table class="table table-hover table-list ">
          <thead>
            <tr>
              <td>S.No</td>
              <td>Agent Code/Name</td>
              <td>Product </td>
              <td>Category</td>
              <td>Update</td>
            </tr>
          </thead>
          <tbody *ngIf="Showmessage">


            <tr *ngFor="let data of _CategoryLisdBind.data;let i = index">
              <td>{{i+1}}</td>
              <td #agentCode>{{data.agentCode}} / {{data.agentName}}</td>
              <td #product>{{data.product}}</td>

              <td #CategoryName>{{data.category.split(',')[0]}}</td>

              <td><button class="btn-success" mat-raised-button data-toggle="tooltip" data-placement="top"
                  title="Update" (click)="fun_UpadteCategory(data.category,data.agentCode, data.product)"><i
                    class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- <div [formGroup]="CategoryUpdateForm"  class="modal fade" id="slipAA" tabindex="-1" role="dialog" aria-labelledby="slip" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title my-auto">Update Category</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="col-xl-12">
            <mat-form-field >
                <mat-label> Agent Code</mat-label>
                <input formControlName="AgentCode"[(ngModel)]="AgentBind" matInput maxlength="10" readonly>
           </mat-form-field>
               </div>
               <div class="col-xl-12">
                <mat-form-field >
                    <mat-label>Product</mat-label>
                    <input  formControlName="Product" [(ngModel)]="ProductBind" matInput  maxlength="10" #updaateDistributorCom readonly>
               </mat-form-field>
                   </div>
          <div class="col-xl-12">
           
           <mat-form-field >
            <mat-label>Category</mat-label>
            <mat-select  disableRipple    formControlName="Category">
              <mat-option>Select</mat-option>
              <mat-option  *ngFor="let binddata of _CategoryProductLisdBind.data" [value]="binddata.categoryName" >{{binddata.categoryName}}</mat-option >

            </mat-select >

            </mat-form-field>


               </div>
               <button class="slipbtn" mat-raised-button class="slipbtn"  (click)="CategoryUpdate()" >Update</button>
               
               <p class="text-danger mt-2 text-center font-weight-bold" *ngIf="ShowmessageUpdateCat">{{_UpdateCategoryLisdBind.statusMessage}}</p> 

        </div>
      </div>
       </div>
  </div> -->


<!-- update new popup -->
<div class="agentdetailsbox" [formGroup]="CategoryUpdateForm" id="slipAA" *ngIf="categoryupdatepop">
  <div class="innerboxagntde morebox">
    <div class="closebtn" (click)="closeagentdetailsbtn()">
      <img src="../../../assets/images/icons/close.svg" alt="">
    </div>
    <h5>Update Category</h5>
    <div class="row justify-content-between">
      <div class="col-xl-12">
        <mat-form-field>
          <mat-label> Agent Code</mat-label>
          <input formControlName="AgentCode"  matInput maxlength="10" readonly>
        </mat-form-field>
      </div>
      <div class="col-xl-12">
        <mat-form-field>
          <mat-label>Product</mat-label>
          <input formControlName="Product"  matInput maxlength="10" #updaateDistributorCom
            readonly>
        </mat-form-field>
      </div>
      <div class="col-xl-12">

        <mat-form-field>
          <mat-label>Category</mat-label>
          <mat-select disableRipple formControlName="Category">
            <mat-option>Select</mat-option>
            <mat-option *ngFor="let binddata of _CategoryProductLisdBind" [value]="binddata.category">
              {{binddata.category}}
            </mat-option>

          </mat-select>

        </mat-form-field>


      </div>
      <div class="col-xl-12 text-right">
        <button class="btnprimary" mat-raised-button [disabled]="!CategoryUpdateForm.valid" (click)="CategoryUpdate()">Update</button>

        <!-- <p class="text-danger mt-2 text-center font-weight-bold" *ngIf="ShowmessageUpdateCat">
          {{_UpdateCategoryLisdBind.statusMessage}}</p> -->
      </div>
    </div>

  </div>
</div>
<!-- update new popup -->

<div class="loader-maine" *ngIf="loaderhide">
  <div class="loader">
    <!--<div class="loader-inner">cvxcv</div>-->
    <div class="loader-inner"></div>
    <div class="loader-inner box"></div>
    <div class="box-1"></div>
    <div class="box-2"></div>
    <div class="box-3"></div>
  </div>
</div>