<div class="contaner-fluid customeheight bg-light" >
 
    <div class="container">
        <div class="row pt-2 justify-content-center align-items-center">
            <div class="col-xl-4">
                    <div class="cradit-request"> 
                        <h3>Credit Request</h3>
                        <form [formGroup]="CreditRequestForm">
                            <div class="col-xl-12 p-0">
                                <mat-form-field >
                                  <mat-label>Amount</mat-label>
                                  <input  (keypress)="numberOnly($event)" matInput placeholder="Enter Amount" formControlName="RequestedAmount" maxlength="6">
                                </mat-form-field>
                                <p class="text-danger"><b>{{ CreditRequestForm.get('RequestedAmount').value  | numbertowords | titlecase }}</b></p>
                                <mat-form-field>
                                    <mat-label>Remark</mat-label>
                                    <textarea matInput placeholder="Remarks" formControlName="AgentRemarks" maxlength="120"></textarea>
                                  </mat-form-field>
                                  <button [disabled]="!this.CreditRequestForm.valid" class="btn-block btnprimary" mat-raised-button  (click)="Funcreditreq()" >Submit</button>
                                  <p class="text-danger mt-2 text-center font-weight-bold text-capitalize" *ngIf="Showmessage">{{_CreditReq.statusMessage}}</p>
                              </div>
                        </form>
                    </div>
            </div>
        </div>
    </div>
    
</div>