import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AccountsService } from 'src/app/Services/accounts.service';
import * as XLSX from 'xlsx';
import { AuthService } from 'src/app/Services/auth.service';
import { CategoryService } from 'src/app/Services/category.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { WalletService } from 'src/app/Services/wallet.service';
import { UserService } from 'src/app/Services/user.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-account-statement',
  templateUrl: './account-statement.component.html',
  styleUrls: ['./account-statement.component.css']
})
export class AccountStatementComponent implements OnInit {
  @ViewChild('TABLE') table: ElementRef;
  public DOBDateFrom = new Date();
  AccountStatementForm: FormGroup;
  Loginresponse;
  _Responsedata;
  AccountStatementListResponse;
  Showmessage = false;
  _Usertype: any;
  ShowListdata = false;
  Showmessagelist = false;
  _AgentcodeBind: any;
  ShowmessageAgentcode = false;
  loaderhide = false;
  filteredAgents: Observable<any[]>;
  txnTypes: Observable<any[]>;
  // get AgentCode(): any { return this.AccountStatementForm.get('AgentCode'); }

  constructor(private _AccountsService: AccountsService,
    private walletService: WalletService,
    private alertService: ToastrService,
    private _AuthAgentService: AuthService,
    private userService: UserService,
    private _CategoryService: CategoryService) { }

  private filterAgentDropDown() {
    this.filteredAgents = this.AccountStatementForm.get('AgentCode').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  ngOnInit(): void {
    this._AuthAgentService.CheckSesstion();
    try {
      this.userService.AgentcodeBind().subscribe(
        StatecityData => {
          this._AgentcodeBind = StatecityData;
          this.filterAgentDropDown();
          if (this._AgentcodeBind.statusCode === 0) {
            this.ShowmessageAgentcode = true;
          }
        });
    } catch (error) {

    }


    this.Loginresponse = localStorage.getItem('loginData');
    this._Responsedata = (JSON.parse(this.Loginresponse) as any);
    this._Usertype = this._Responsedata.loginData[0].userType;
    if (this._Usertype == 'OO' || this._Usertype == 'OOU' || this._Usertype == 'D' || this._Usertype == 'S') {
      this.ShowListdata = true;
    }


    this._AccountsService.fetchTxnTypes(this._Responsedata.loginData[0].identifier).subscribe(res => {
      let data: any = res
      this.txnTypes = data;
    })

    this.AccountStatementForm = new FormGroup({
      FromDate: new FormControl(new Date),
      Todate: new FormControl(new Date),
      AgentCode: new FormControl(''),
      ACode: new FormControl(''),
      Mode: new FormControl('WEB'),
      Merchant: new FormControl(this._Responsedata.loginData[0].merchant),
      LoggedInUserType: new FormControl(this._Responsedata.loginData[0].userType),
      TransactionType: new FormControl(''),
      TransactionId: new FormControl(''),
      LoggedInAgentCode: new FormControl(this._Responsedata.loginData[0].identifier),
    });

    this.Fun_AccountStatementList();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const filteredData = this._AgentcodeBind.data.filter(option => option.agentCodeAndName.toLowerCase().indexOf(filterValue) === 0);
    this.AccountStatementForm.get('ACode').setValue(filteredData[0].agentCode);
    return filteredData;
  }

  Fun_AccountStatementList() {

    // if ( this._Usertype == 'OOU' || this._Usertype == 'D') {

    //   this.AccountStatementForm.value.AgentCode = this.AccountStatementForm.value.AgentCode.split('(')[1].split(')')[0];
    // }

    // if (this.AccountStatementForm.value.AgentCode != "") {
    //   this.AccountStatementForm.get('AgentCode').setValue(this.AccountStatementForm.value.ACode);
    // }
    this.loaderhide = true;
    const formData = this.AccountStatementForm.value;
    if (formData.AgentCode != '') {
      formData.AgentCode = this.AccountStatementForm.value.ACode;
    }
    // console.log(formData)
    this.walletService.LedgerDetails(formData)
      .subscribe(
        response => {
          this.AccountStatementListResponse = response;
          this.loaderhide = false;
          if (this.AccountStatementListResponse.statusCode == 1) {
            this.alertService.error(this.AccountStatementListResponse.statusMessage);
            this.AccountStatementwait();
          }
          else {
            // this.alertService.success(this.AccountStatementListResponse.statusMessage);
            this.Showmessagelist = true;
            this.Showmessage = false;
          }
          // this.AccountStatementForm.value.ACode = "";
          // this.filterAgentDropDown() ;
        },
        (error) => {
          this.alertService.error("Unable to fetch record, please try again later");
          this.loaderhide = false;
          console.log(error);
        }
      );
  }

  AccountStatementwait(): void {
    this.Showmessage = true;
    this.Showmessagelist = false;

    setTimeout(function () {
      this.Showmessage = false;
      this.Showmessagelist = false;

    }.bind(this), 2000);
  }

  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'File');

    /* save to file */
    XLSX.writeFile(wb, 'Leadger.xlsx');
  }

  resetAgentCode() {
    this.AccountStatementForm.get('AgentCode').setValue('');
    this.AccountStatementForm.get('ACode').setValue('');
  }

}
