
<div class="container-fluid bg-light pb-4">
    <div class="container">
    
        <div class="row pt-4 m-0 justify-content-between">
            <div class="dashbox">
                <div class="dash-servicebox dash-servicebox1">
                   <div class="dashboardinner">
                    <h1>25486</h1>
                    <h2>Aeps</h2>
                   </div>
                </div>
            </div>
            <div class="dashbox">
                <div class="dash-servicebox dash-servicebox2">
                    <div class="dashboardinner">
                        <h1>25486</h1>
                        <h2>DMT</h2>
                       </div>
                </div>
            </div>
            <div class="dashbox">
                <div class="dash-servicebox dash-servicebox3">
                    <div class="dashboardinner">
                        <h1>25486</h1>
                        <h2>Recharge </h2>
                       </div>
                </div>
            </div>
            <div class="dashbox">
                <div class="dash-servicebox dash-servicebox4">
                    <div class="dashboardinner">
                        <h1>25486</h1>
                        <h2>Air</h2>
                       </div>
                </div>
            </div>
            <div class="dashbox">
                <div class="dash-servicebox dash-servicebox5">
                    <div class="dashboardinner">
                        <h1>{{profileData.totalBalance}}</h1>
                        <h2>Wallet</h2>
                       </div>
                </div>
            </div>
        </div> 
        <div class="row mt-4">
            <div class="col-xl-8">
                <!-- <img src="../../assets/images/background/charts.jpg" style="width: 100%;" class="img-fluid"> -->
                <google-chart #chart
                [title]="trendTitle"
                [type]="trendType"
                [data]="trendData"
                [options]="trendOptions"
                [width]="700"
                [height]="400">
             </google-chart>
            </div>
            <div class="col-xl-4">
                <!-- <img src="../../assets/images/background/charts.jpg" style="width: 100%;" class="img-fluid"> -->
                <google-chart #chart
                [title]="salesTitle"
                [type]="salesType"
                [data]="salesData"
                [width]="350"
                [height]="300">
             </google-chart>
            </div>
        </div>
    </div>
</div>