
const API_BASE_URL = 'https://api.fintrav.in'

export const environment = {
  CCAVENUE_ACCESS_CODE: 'AVOQ42KL24BN35QONB',
  AEPS_DUMMY_RESPONSE: false,
  DMT_DUMMY_RESPONSE: false,
  production: true,
  Api_urlcommission: `${API_BASE_URL}/api/commission/`,
  Api_urlaccounts: `${API_BASE_URL}/api/accounts/`,
  Api_urlwallet: `${API_BASE_URL}/api/wallet/`,
  Api_urlmasters: `${API_BASE_URL}/api/user/`,
  Api_urldmt: `${API_BASE_URL}/api/dmt/`,
  Api_urlairsvc: `${API_BASE_URL}/api/airsvc/`,
  Api_urlair: `${API_BASE_URL}/api/air/`,
  Api_urlrail: `${API_BASE_URL}/api/rail/`,
  Api_urluser: `${API_BASE_URL}/api/user/`,
  Api_urlauth: `${API_BASE_URL}/api/auth/`,
  Api_urlcoreconfig: `${API_BASE_URL}/api/core/`,
  API_BASE_URL: `${API_BASE_URL}`,
  CMS_BASE_URL: `${API_BASE_URL}/cms`,
  API_paymentsvc: `${API_BASE_URL}/api/payments`,
  localStorageKey : 'fgdr456ee',
  airlineImageBaseUrl: 'https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons',
};
